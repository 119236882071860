import {
    BlockInfo,
    Container,
    DataAndChartBlockStyled,
    FilterBlockStyled,
    MainBlockStyled,
    PieChartWithHeaderBlockStyled,
    SaveButtonStyled,
    BlockPieChart,
    PieChartsBlock,
    BarChartWithHeader,
    LineChartWithHeaderStyled,
    SpinnerBlock,
    LineChartWithHeader,
    ContainerIntervalMarkGraph,
    IntervalMarkGraphBlock,
    BlockIntervalMarkGraph,
    BarChartBlock,
    DoubleBarChart,
    BlockBarChart,
    AreaBumpBlock,
    LegendBlock,
    ColorBlock,
    LegendNameBlock,
    Tooltip,
    LegendContainer, BlockNameWithColor
} from "./Statistics.styled";
import React, {useEffect, useState} from "react";
import {ReactComponent as SaveIcon} from "../../assets/pictures/SaveIcon.svg";
import {LineChart} from "./lineChart/LineChart";
import {PieChart} from "./pieChart/PieChart";
import {useAnalyticsDataQuery} from "../../store/api/globalApi";
import {
    computationsCategoryDataForPieChart,
} from "./computations/Computations";
import {BarChart} from "./barChart/BarChart";
import {path} from "../../../utils/path";
import {useNavigate} from "react-router-dom";
import {DateSelector} from "./Selector/DateSelector";
import {Oval} from "react-loader-spinner";
import {consistentTimePeriodsForCharts} from "./Selector/contants";
import {IIntervalMarkGraph, ISelectedFilters} from "./interface";
import {computationsDataForBarChart, maxValueForBarChart} from "./barChart/Computations";
import {computationsDataForLineChart} from "./lineChart/ComputationsLineChart";
import {MultipleLinesChart} from "./lineChart/MultipleLinesChart";
import {calculateDataForAreaBumpGraph} from "./lineChart/ComputationsMultipleLineChart";
import {Selector} from "./Selector/Selector";
import {
    Category,
    DailyLife,
    Education, Healthy,
     Leisure,
} from "./constants";
import {useGetUserQuery} from "../../store/api/authApi";


export const Statistics = () => {
    const now = new Date();
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>({company: [], department: [], users: []});
    const [timeInterval, setTimeInterval] = useState<(Date | null)[]>([todayStart, now]);
    const [intervalMarkGraph, setIntervalMarkGraph] = useState<IIntervalMarkGraph>({
        bar: consistentTimePeriodsForCharts[1],
        line: consistentTimePeriodsForCharts[0],
        multipleLines: consistentTimePeriodsForCharts[0],
    })
    const navigate = useNavigate()
    const {
        data: queryData,
        isLoading
    } = useAnalyticsDataQuery({
        timePeriod: timeInterval.map(dateString => dateString?.toISOString()),
        filterArray: selectedFilters
    })
    // console.log(queryData)

    const {isError} = useGetUserQuery({isAdmin: 'admin'});
    useEffect(() => {
        if (isError) {
            navigate(`${path.login}`)
        }
    }, [isError, navigate]);

    // const hasChatId = selectedFilters.some(item => item.field === 'chatId');

    function UsersForSelector(fieldForMap: string) {
        const result: { id: string, name: string, }[] = []
        // @ts-ignore
        queryData && queryData[fieldForMap]?.map((elem: { _id: string, chatId: string, name: string }) => {
            if (fieldForMap === 'users') {
                if (elem.chatId) {
                    result.push({
                        id: elem.chatId,
                        name: elem.name
                    })
                }
            } else {
                result.push({
                    id: elem._id,
                    name: elem.name,
                })
            }

        })
        return result
    }

    // function differentTime(arg: Date) {
    //     const now = new Date()
    //     const different = Math.ceil((now.getTime() - new Date(arg).getTime()) / (1000 * 3600 * 24))
    //     if (different > 30) {
    //         return (
    //             <>
    //                 {Math.floor(different / 30)}міс. {different % 30}дн.
    //             </>
    //         )
    //     } else {
    //         return <>{different} днів</>
    //     }
    // }
    //
    // function timeDifferent() {
    //     if (typeof timeInterval[0] === 'string') {
    //         return timeInterval[0]
    //     } else if (timeInterval.length === 2 && typeof timeInterval[1] === "string") {
    //         // return `${(timeInterval[1] - timeInterval[0]) / (24 * 60 * 60 * 1000)} днів`
    //     } else {
    //         return ''
    //     }
    // }

    const [hoveredLegend, setHoveredLegend] = useState<string | null>(null);
    // const [tooltipPosition, setTooltipPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

    const dataMap: { [key: string]: { [key: string]: string } } = {
        'Розвиток': Education,
        'Дозвілля': Leisure,
        'Повсякденність': DailyLife,
        'Здоровʼя': Healthy,
    };

    const handleMouseEnter = (name: string) => {
        setHoveredLegend(name);
    };

    const handleMouseLeave = () => {
        setHoveredLegend(null);
    };

    return (
        <Container>
            <BlockInfo>
                {isLoading ? <SpinnerBlock><Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="rgba(82, 97, 107, 1)"
                    ariaLabel="oval-loading"
                    strokeWidth={7}
                    wrapperStyle={{}}
                    wrapperClass=""
                    secondaryColor={'rgba(82, 97, 107, 0.1)'}
                /></SpinnerBlock> : <MainBlockStyled>
                    <FilterBlockStyled>
                        <Selector
                            padding={'3px 5px'}
                            margin={'0'}
                            borderRadius={'5px'}
                            border={'1px solid #52616B'}
                            borderRight={'none'}
                            size={'13.3vw'}
                            optionsCategory={UsersForSelector('company')}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            header={'Компанії'}
                            fieldForFilters={'company'}
                        />
                        <Selector
                            padding={'3px 5px'}
                            margin={'0'}
                            borderRadius={'5px'}
                            border={'1px solid #52616B'}
                            borderRight={'none'}
                            size={'13.3vw'}
                            optionsCategory={UsersForSelector('department')}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            header={'Відділ'}
                            fieldForFilters={'department'}
                        />
                        <Selector
                            padding={'3px 5px'}
                            margin={'0 '}
                            borderRadius={'5px'}
                            border={'1px solid #52616B'}
                            borderRight={'none'}
                            size={'13.3vw'}
                            optionsCategory={UsersForSelector('users')}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            header={'Користувачі'}
                            fieldForFilters={'users'}
                        />
                        <DateSelector
                            timeInterval={timeInterval}
                            setTimeInterval={setTimeInterval}
                            intervalMarkGraph={intervalMarkGraph}
                            setIntervalMarkGraph={setIntervalMarkGraph}
                            padding={'3px 0'}
                            margin={'0 '}
                            borderRadius={'5px'}
                            border={'1px solid #52616B'}
                            borderRight={'none'}
                            size={timeInterval.length > 1 ? '18.5vw' : '11vw'}
                        />
                        <SaveButtonStyled>
                            <div>
                                <SaveIcon/>
                            </div>
                        </SaveButtonStyled>
                    </FilterBlockStyled>
                    <DataAndChartBlockStyled>
                        <BarChartWithHeader marginheader={1} height={'100%'} width={'46%'}>
                            <BarChartBlock marginheader={1} height={'90%'} width={'99%'}>
                                <ContainerIntervalMarkGraph>
                                    <BlockIntervalMarkGraph>
                                        {consistentTimePeriodsForCharts.map((elem: string, index: number) => (
                                            <IntervalMarkGraphBlock active={intervalMarkGraph.multipleLines === elem}
                                                                    key={index}
                                                                    onClick={() => setIntervalMarkGraph({
                                                                        ...intervalMarkGraph,
                                                                        multipleLines: elem
                                                                    })}>
                                                {elem}
                                            </IntervalMarkGraphBlock>
                                        ))}
                                    </BlockIntervalMarkGraph>
                                </ContainerIntervalMarkGraph>
                                <AreaBumpBlock>
                                    {/*{queryData && <AreaBumpChart*/}
                                    {/*    data={calculateDataForAreaBumpGraph(queryData?.journal, timeInterval,intervalMarkGraph.areaBump)}/>}*/}
                                    {/*{queryData && <BumpChart data={calculateDataForAreaBumpGraph(queryData?.journal, timeInterval,intervalMarkGraph.areaBump)} />}*/}
                                    {queryData && <MultipleLinesChart
                                        data={calculateDataForAreaBumpGraph(queryData?.results, timeInterval, intervalMarkGraph.multipleLines)}/>}
                                </AreaBumpBlock>
                            </BarChartBlock>
                            <h1>Графік зміни настрою відносно категорій</h1>
                        </BarChartWithHeader>
                        <LineChartWithHeader marginheader={-1} height={'90%'} width={'48%'}>
                            <ContainerIntervalMarkGraph>
                                <BlockIntervalMarkGraph>
                                    {consistentTimePeriodsForCharts.map((elem: string, index: number) => (
                                        <IntervalMarkGraphBlock active={intervalMarkGraph.line === elem} key={index}
                                                                onClick={() => setIntervalMarkGraph({
                                                                    ...intervalMarkGraph,
                                                                    line: elem
                                                                })}>
                                            {elem}
                                        </IntervalMarkGraphBlock>
                                    ))}
                                </BlockIntervalMarkGraph>
                            </ContainerIntervalMarkGraph>
                            <LineChartWithHeaderStyled>
                                {/*<MyResponsiveAreaBump />*/}
                                {queryData && <LineChart
                                    data={[
                                        {
                                            'id': 'емоція',
                                            data: computationsDataForLineChart(queryData?.results, timeInterval[0], timeInterval[1], intervalMarkGraph.line)
                                        }]}

                                />}
                            </LineChartWithHeaderStyled>
                            <h1>Графік зміни настрою</h1>
                        </LineChartWithHeader>
                    </DataAndChartBlockStyled>
                    <DataAndChartBlockStyled>
                        <BarChartWithHeader marginheader={1} height={'106%'} width={'46%'}>
                            <BarChartBlock marginheader={1} height={'100%'} width={'99%'}>
                                <ContainerIntervalMarkGraph>
                                    <BlockIntervalMarkGraph>
                                        {consistentTimePeriodsForCharts.slice(1).map((elem: string, index: number) => (
                                            <IntervalMarkGraphBlock active={intervalMarkGraph.bar === elem} key={index}
                                                                    onClick={() => setIntervalMarkGraph({
                                                                        ...intervalMarkGraph,
                                                                        bar: elem
                                                                    })}>
                                                {elem}
                                            </IntervalMarkGraphBlock>
                                        ))}
                                    </BlockIntervalMarkGraph>
                                </ContainerIntervalMarkGraph>
                                <DoubleBarChart>
                                    {queryData && <BarChart
                                        graph={true}
                                        data={computationsDataForBarChart(queryData?.results, timeInterval, intervalMarkGraph.bar)}
                                        max={maxValueForBarChart(computationsDataForBarChart(queryData?.results, timeInterval, intervalMarkGraph.bar))}
                                    />}
                                    <BlockBarChart>
                                        {queryData && <BarChart
                                            graph={false}
                                            intervalMarkGraph={intervalMarkGraph.bar}
                                            data={computationsDataForBarChart(queryData?.results, timeInterval, intervalMarkGraph.bar)}
                                            max={maxValueForBarChart(computationsDataForBarChart(queryData?.results, timeInterval, intervalMarkGraph.bar))}
                                        />}
                                    </BlockBarChart>
                                </DoubleBarChart>
                            </BarChartBlock>
                            <h1>Графік кількості записів</h1>
                        </BarChartWithHeader>
                        <PieChartsBlock>
                            {isLoading ? <Oval
                                visible={true}
                                height="50"
                                width="50"
                                color="rgba(82, 97, 107, 1)"
                                ariaLabel="oval-loading"
                                strokeWidth={7}
                                wrapperStyle={{}}
                                wrapperClass=""
                                secondaryColor={'rgba(82, 97, 107, 0.1)'}
                            /> : <PieChartWithHeaderBlockStyled marginhorizontal={0} sizeWidth={46}>
                                <BlockPieChart border={true}>
                                    {isLoading ? <Oval
                                        visible={true}
                                        height="50"
                                        width="50"
                                        color="rgba(82, 97, 107, 1)"
                                        ariaLabel="oval-loading"
                                        strokeWidth={7}
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        secondaryColor={'rgba(82, 97, 107, 0.1)'}
                                    /> : <PieChart innerRadius={0}
                                                   data={computationsCategoryDataForPieChart(queryData?.journal)}/>}
                                </BlockPieChart>
                                <h1>Співвідношення категорій</h1>
                            </PieChartWithHeaderBlockStyled>}
                            <LegendBlock>
                                {Object.entries(Category).map(([name, color], index,arr) => (
                                    <LegendContainer key={index}>
                                        <LegendNameBlock
                                            onMouseEnter={() => handleMouseEnter(name)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <ColorBlock color={color} />
                                            {name}
                                        </LegendNameBlock>
                                        {hoveredLegend === name && index !== arr.length-1 && index !== arr.length-2 && (
                                            <Tooltip>
                                                {Object.entries(dataMap[name] || {})?.map(([subName, subColor], subIndex) => (
                                                    <BlockNameWithColor key={subIndex} >
                                                        <ColorBlock color={subColor} /> {subName}
                                                    </BlockNameWithColor>
                                                ))}
                                            </Tooltip>
                                        )}
                                    </LegendContainer>
                                ))}
                            </LegendBlock>
                        </PieChartsBlock>
                    </DataAndChartBlockStyled>
                </MainBlockStyled>}
            </BlockInfo>
        </Container>
    )
}