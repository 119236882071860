import React, {useState} from "react";
import {ReactComponent as ArrowForSelectorOpen} from "../../../assets/pictures/ArrowForSelectorOpen.svg";
import {ReactComponent as ArrowForSelectorClose} from "../../../assets/pictures/ArrowForSelectorClose.svg";
import {
    ArrowBlock,
    BlockList,
    Container,
    NameSelector,
    SelectorStyled,
    VariantChoose
} from "./Selector.styled";
import {ISelectedFilters} from "../interface";
import {consistentTimePeriodsForCharts} from "./contants";


type Props = {
    fieldForFilters: 'company' | 'department' | 'users'
    optionsCategory: Array<{ id: string, name: string }>
    borderRight?: string
    borderRadius?: string
    border?: string
    margin?: string
    padding?: string
    setSelectedFilters: React.Dispatch<React.SetStateAction<ISelectedFilters>>
    selectedFilters: any
    size: string
    header: string

}
export const Selector = ({
                             optionsCategory,
                             fieldForFilters,
                             borderRight,
                             setSelectedFilters,
                             selectedFilters,
                             size,
                             header,
                             borderRadius,
                             border,
                             margin,
                             padding
                         }: Props) => {
    const [openList, setOpenList] = useState(false)
    const [checked, setChecked] = useState<string[]>([]);
    const [chosenOption, setChosenOption] = useState<string[]>([])

    function changeCheckbox(obj: {
        id: string;
        name: string;
    }) {
        const has = selectedFilters[fieldForFilters]?.some((item: string) => item === obj.id)
        // const has = selectedFilters.some((item: any) => item[fieldForFilters].some((elem: any) => elem === obj.id))

        if (checked.includes(obj.id) || has) {
            setChecked(prev => prev.filter(item => item !== obj.id))

            setSelectedFilters(prevState => {
                const newFilters = {...prevState};
                newFilters[fieldForFilters] = newFilters[fieldForFilters].filter(item => item !== obj.id);
                return newFilters;
            });
        } else {
            setSelectedFilters(prevFilters => {
                const newFilters = {...prevFilters};
                newFilters[fieldForFilters] = [...newFilters[fieldForFilters], obj?.id];

                return newFilters;
            });
            setChecked(prev => [...prev, obj.id])
        }
        setChosenOption(prev => {
            return chosenOption.includes(obj.name) ? prev.filter(element => element !== obj.name) : [...prev, obj.name];
        })
    }

    const offWatchBlock = () => {
        setOpenList(false)
    }
    // console.log(optionsCategory)
    return (
        <Container>
            <SelectorStyled padding={padding} margin={margin} border={border} borderRadius={borderRadius} size={size}
                            borderRight={borderRight} onClick={() => setOpenList(!openList)}>
                <NameSelector content={chosenOption.length > 0}>
                    {chosenOption.length ? chosenOption.join(',') : header}
                </NameSelector>
                <ArrowBlock>
                    {openList ? <ArrowForSelectorClose/> : <ArrowForSelectorOpen/>}
                </ArrowBlock>
            </SelectorStyled>
            {openList &&
                <BlockList onMouseLeave={offWatchBlock}>
                    {optionsCategory.map((elem, index) => (
                        <VariantChoose key={index}>
                            {elem.name}
                            <input type={'checkbox'}
                                   checked={checked.includes(elem.id)}
                                   name={elem.name}
                                // id={elem.id}
                                   value={elem.id}
                                   onChange={() => changeCheckbox(elem)}
                            />
                        </VariantChoose>
                    ))}
                </BlockList>}
        </Container>
    )
}

