import {yellowDuckApi} from "./globalApi";

export const injectedCardsApi = yellowDuckApi.injectEndpoints({
    endpoints: (build) => ({
        changeActiveUser: build.query({
            query: (id) => ({
                url: `/api/card/active/${id}`,
                method: 'POST',
            })
        }),
        personalInfoAboutUser: build.query({
            query: (body) => ({
                url: `/api/card/personalInfo`,
                method: 'POST',
                body
            })
        }),
        getUsers: build.query({
            query: () => ({
                url: `/api/card/fullInfo`,
                method: 'GET',
            }),
        }),
        getUserTests: build.query({
            query: (body) => ({
                url: `/api/card/getEmployeeTest`,
                method: 'POST',
                body
            }),
        }),

    })
});

export const {
    useGetUsersQuery,
    useGetUserTestsQuery,
    useLazyChangeActiveUserQuery,
    useLazyCustomerTestQuery,
    useLazyPersonalInfoAboutUserQuery,
} = injectedCardsApi;