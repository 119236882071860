import {yellowDuckApi} from "./globalApi";
import {IUniversity} from "../interface";

export const injectedTestsApi = yellowDuckApi.injectEndpoints({
    endpoints: (build) => ({
        getTests: build.query({
            query: (body) => ({
                url: '/api/tests',
                method: 'PUT',
                body
            }),
            // transformResponse: (response: any) => response.employee
        }),
        sendTestResults: build.query({
            query: (body) => ({
                url: '/api/tests/sendResult',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => response.status
        }),
        addTest: build.query({
            query: (body) => ({
                url: '/api/tests/add',
                method: 'POST',
                body
            }),
        }),
        changeCounterTests: build.query({
            query: (body) => ({
                url: '/api/tests/changeCounter',
                method: 'POST',
                body
            }),
        }),
        deleteTest: build.query({
            query: (body) => ({
                url: '/api/tests/delete',
                method: 'DELETE',
                body
            })
        }),
        getUniversityList: build.query<IUniversity[], { ut: string; lc: string; exp: string }>({
            query: ({ut, lc, exp}) => ({
                url: '/api/universityList',
                method: 'GET',
                params: {ut, lc, exp},
            }),
        }),
        sendFormAcquaintanceAnswers: build.query({
            query:(body) => ({
                url:'/api/form/answers',
                method:'POST',
                body
            })
        }),
    })
});

export const {
    useLazyDeleteTestQuery,
    useLazyChangeCounterTestsQuery,
    useLazyAddTestQuery,
    useLazySendTestResultsQuery,
    useGetTestsQuery,
    useLazyGetTestsQuery,
    useLazyGetUniversityListQuery,
    useLazySendFormAcquaintanceAnswersQuery,
} = injectedTestsApi;