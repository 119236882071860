import React, { FC, FunctionComponent, useState, useEffect, useRef } from "react";
import { AddButton } from "../constants";
import {
    BlockForSVG, BlockListOptionSocial,
    BlockSVGWithInput, ButtonAddInput,
    ContainerSocial,
    InputContainer,
    QuestionBlock,
    StyledInputForSocial
} from "./Questions.styled";

interface Props {
    name: string;
    userAbilityWriteMsg: boolean;
    require: boolean;
    list: { name: string; svg: FunctionComponent<any> }[];
    setAnswers: React.Dispatch<React.SetStateAction<any>>;
    field: string;
    fieldsForUpdate: string;
}

export const QuestionSocialNetworks: FC<Props> = ({
                                                      name,
                                                      userAbilityWriteMsg,
                                                      require,
                                                      list,
                                                      field,
                                                      setAnswers,
                                                  }: Props) => {
    const [openLists, setOpenLists] = useState<boolean[]>(Array(list.length).fill(false));
    const [text, setText] = useState<string[]>([]);
    const [selected, setSelected] = useState<FunctionComponent<any>[]>([list[0].svg]);
    const ref = useRef<(HTMLDivElement | null)[]>([]);

    const add = () => {
        setSelected((prev) => {
            setOpenLists([...openLists, false]);
            return [...prev, list[0].svg];
        });
        setText((prev) => [...prev, ""]);
        setAnswers((prevItems: any[]) => {
            return [...prevItems, { field, name: list[0].name }];
        });
    };

    const changeSocial = (variant: FunctionComponent<any>, index: number, social: string) => {
        setSelected((prev) => {
            const newArr = [...prev];
            newArr[index] = variant;
            return newArr;
        });
        setOpenLists((prev) => {
            const newArr = [...prev];
            newArr[index] = false;
            return newArr;
        });
        setAnswers((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item: { field: string }) => item.field === field);
            if (itemsWithField[index]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[index]);
                updatedItems[globalIndex] = { ...updatedItems[globalIndex], name: social };
                return updatedItems;
            } else {
                return [...prevItems, { field, name: social }];
            }
        });
    };

    const toggleOpenList = (index: number) => {
        setOpenLists((prev) => {
            const newArr = [...prev];
            newArr[index] = !newArr[index];
            return newArr;
        });
    };

    const handleClickOutside = (event: MouseEvent) => {
        ref.current.forEach((element, index) => {
            if (element && !element.contains(event.target as Node)) {
                setOpenLists((prev) => {
                    const newArr = [...prev];
                    newArr[index] = false;
                    return newArr;
                });
            }
        });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAddLink = (index: number) => {
        setAnswers((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item: { field: string }) => item.field === field);
            if (itemsWithField[index]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[index]);
                updatedItems[globalIndex] = { ...updatedItems[globalIndex], link: text[index] };
                return updatedItems;
            } else {
                return [...prevItems, { field, link: text[index] }];
            }
        });
    };

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        const value = e.target.value;
        setText((prevState) => {
            const newArr = [...prevState];
            newArr[index] = value;
            return newArr;
        });

        setAnswers((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item: { field: string }) => item.field === field);
            if (itemsWithField[index]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[index]);
                updatedItems[globalIndex] = { ...updatedItems[globalIndex], link: value };
                return updatedItems;
            } else {
                return [...prevItems, { field, link: value }];
            }
        });
    };

    return (
        <ContainerSocial>
            <QuestionBlock>{name}</QuestionBlock>
            <InputContainer>
                {selected.map((Elem, index, arr) => (
                    <BlockSVGWithInput key={index} marginRight={index !== arr.length - 1 ? '22px' : '0'}>
                        <BlockForSVG onClick={() => toggleOpenList(index)}>
                            {Elem && <Elem />}
                        </BlockForSVG>
                        <StyledInputForSocial
                            type="text"
                            value={text[index] || ""}
                            onChange={(e) => {
                                if (userAbilityWriteMsg) {
                                    handleChangeText(e, index);
                                }
                            }}
                            onBlur={() => {
                                handleAddLink(index);
                                changeSocial(selected[index], index, list.find((item) => item.svg === selected[index])?.name || "");
                            }}
                            required={require}
                        />
                        {index === arr.length - 1 && (
                            <ButtonAddInput>
                                <div onClick={add}>{AddButton}</div>
                            </ButtonAddInput>
                        )}
                        <BlockListOptionSocial ref={el => ref.current[index] = el} show={openLists[index]} indexList={index!== arr.length-1}>
                            {list.map((elem, indexVariant) => {
                                const SvgIcon = elem.svg;
                                return (
                                    <div key={indexVariant} onClick={() => changeSocial(SvgIcon, index, elem.name)}>
                                        <div><SvgIcon /></div>
                                        <div>{elem.name}</div>
                                    </div>
                                );
                            })}
                        </BlockListOptionSocial>
                    </BlockSVGWithInput>
                ))}
            </InputContainer>
        </ContainerSocial>
    );
};