import React from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import {DatePickerStatsWrapper} from "./DatePickerFormat.styled.";
import {consistentTimePeriods, consistentTimePeriodsForCharts} from "./contants";
import {uk} from 'date-fns/locale/uk'
import {IIntervalMarkGraph} from "../interface";

registerLocale('uk', uk);

interface IProps {
    // setVisibleOptionIndex: React.Dispatch<number | null>
    // setIsOptionsVisible: React.Dispatch<boolean>
    setTimeGaps: React.Dispatch<string>
    timeGaps: string
    day?: boolean
    week?: boolean
    month?: boolean
    quarter?: boolean
    year?: boolean
    timeInterval: (Date | null)[]
    setTimeInterval: React.Dispatch<(Date | null)[]>
    intervalMarkGraph: IIntervalMarkGraph
    setIntervalMarkGraph: React.Dispatch<IIntervalMarkGraph>
}

export const DatePickerFormat: React.FC<IProps> = ({timeInterval, timeGaps, setTimeGaps, setTimeInterval,intervalMarkGraph,setIntervalMarkGraph}) => {
    // Функція для створення початку дня
    const getDayBoundary = (date: Date | null, startOfDay: boolean): Date | null => {
        if (!date) return null;

        const boundaryDate = new Date(date);

        if (startOfDay) {
            boundaryDate.setHours(0, 0, 0, 0); // Початок дня
        } else {
            boundaryDate.setHours(23, 59, 59, 999); // Кінець дня
        }

        return boundaryDate;
    };

    // Обробник зміни дати в DatePicker
    const handleChange = (dates: [Date, Date] | [null, null]): void => {
        if (dates[1] === null) {
            // Встановлюємо початок дня для початкової дати
            setTimeInterval([getDayBoundary(dates[0], true), null]);
        } else {
            // Встановлюємо кінець дня для кінцевої дати
            setTimeInterval([dates[0], getDayBoundary(dates[1], false)]);
        }

        // Інші дії при зміні дати, наприклад, оновлення графіка
        setIntervalMarkGraph({
            ...intervalMarkGraph,
            line: consistentTimePeriodsForCharts[1],
            multipleLines: consistentTimePeriodsForCharts[1],
        });
    };
    const handleDateChange = (dates: [Date | null, Date | null]) => {
        if (dates[0] !== null) {
            // Встановлення початку дня для першої дати
            dates[0].setHours(0, 0, 0, 0);
        }
        if (dates[1] !== null) {
            // Встановлення кінця дня для другої дати
            dates[1].setHours(23, 59, 59, 999);
        }
        setTimeInterval([dates[0], dates[1]]);
    };


    const handleQuarterChange = (dates: [Date | null, Date | null]) => {
        if (dates[0] !== null) {
            // Обчислення першого дня кварталу для першої дати
            const firstQuarterMonth = Math.floor(dates[0].getMonth() / 3) * 3;
            dates[0] = new Date(dates[0].getFullYear(), firstQuarterMonth, 1);
        }
        if (dates[1] !== null) {
            // Обчислення останнього дня кварталу для другої дати
            const lastQuarterMonth = Math.floor(dates[1].getMonth() / 3) * 3 + 2;
            const lastDayOfMonth = new Date(dates[1].getFullYear(), lastQuarterMonth + 1, 0).getDate();
            dates[1] = new Date(dates[1].getFullYear(), lastQuarterMonth, lastDayOfMonth);
        }
        setTimeInterval([dates[0], dates[1]]);
    };

    const handleYearChange = (dates: [Date | null, Date | null]) => {
        if (dates[0] !== null) {
            // Обчислення першого дня року для першої дати
            dates[0] = new Date(dates[0].getFullYear(), 0, 1);
        }
        if (dates[1] !== null) {
            // Обчислення останнього дня року для другої дати
            dates[1] = new Date(dates[1].getFullYear(), 11, 31);
        }
        setTimeInterval([dates[0], dates[1]]);
    };

    const getPickerRelativeTimeParameter = () => {
        switch (timeGaps) {
            case consistentTimePeriods[0]:
                return <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={new Date()}
                    startDate={timeInterval[0]}
                    maxDate={new Date()}
                    endDate={timeInterval[1]}
                    onChange={handleChange}
                    selectsRange
                />
            case consistentTimePeriods[1]:
                return <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={new Date()}
                    startDate={timeInterval[0]}
                    endDate={timeInterval[1]}
                    onChange={handleChange}
                    selectsRange
                />
            case consistentTimePeriods[2]:
                return <DatePicker
                    selected={new Date()}
                    onChange={(date: [Date | null, Date | null]) => handleDateChange(date)}
                    selectsRange
                    startDate={timeInterval[0]}
                    endDate={timeInterval[1]}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                />
            case consistentTimePeriods[3]:
                return <DatePicker
                    selected={new Date()}
                    onChange={(date: [Date | null, Date | null]) => handleQuarterChange(date)}
                    selectsRange
                    startDate={timeInterval[0]}
                    endDate={timeInterval[1]}
                    dateFormat="yyyy, QQQ"
                    showQuarterYearPicker
                />
            case consistentTimePeriods[4]:
                return <DatePicker
                    selected={new Date()}
                    onChange={(date: any) => setTimeInterval([date[0].setHours(0, 0, 0, 0),date[1]])}
                    selectsRange
                    startDate={timeInterval[0]}
                    endDate={timeInterval[1]}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                />
            case consistentTimePeriods[5]:
                return <DatePicker
                    selected={new Date()}
                    onChange={(date: [Date | null, Date | null]) => handleYearChange(date)}
                    selectsRange
                    startDate={timeInterval[0]}
                    endDate={timeInterval[1]}
                    dateFormat="yyyy"
                    showYearPicker
                />
            default:
                return <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={new Date()}
                    startDate={timeInterval[0]}
                    endDate={timeInterval[1]}
                    onChange={(date: any) => setTimeInterval([date[0].setHours(0, 0, 0, 0),date[1]])}
                    selectsRange
                />
        }
    }
    return (
        <DatePickerStatsWrapper>
            {getPickerRelativeTimeParameter()}
        </DatePickerStatsWrapper>
    )
}