import styled, {keyframes} from 'styled-components'
import React, {useRef} from 'react'
import px2vw from '../../../utils/px2vw'
import {ReactComponent as ArrowForLogin} from '../../assets/pictures/ArrowForLogin.svg'
import {ReactComponent as ArrowForSection} from '../../assets/pictures/ArrowForSection.svg'
import {ReactComponent as MindsGamesIcon} from '../../assets/pictures/MindsGamesIcon.svg'
import {ReactComponent as MindsWorkshopIcon} from '../../assets/pictures/MindsWorkshopIcon.svg'
import {ReactComponent as MindsFactoryIcon} from '../../assets/pictures/MindsFactoryIcon.svg'
import {ReactComponent as ArrowForBackInTop} from '../../assets/pictures/ArrowForBackInTop.svg'
import {ReactComponent as Document} from '../../assets/pictures/Document.svg'
import {ReactComponent as Document2} from '../../assets/pictures/Document2.svg'
import {ReactComponent as Document3} from '../../assets/pictures/Document3.svg'
import {ReactComponent as Picture} from '../../assets/pictures/Picture.svg'
import {ReactComponent as Picture2} from '../../assets/pictures/Picture2.svg'
import {ReactComponent as Picture3} from '../../assets/pictures/Picture3.svg'
import {ReactComponent as Video} from '../../assets/pictures/Video.svg'
import {Link} from 'react-router-dom'
import {path} from "../../../utils/path";


export const Landing = () => {
    const targetMindsGames = useRef<null | HTMLDivElement>(null)
    const targetMindsWorkshop = useRef<null | HTMLDivElement>(null)
    const targetMindsFactory = useRef<null | HTMLDivElement>(null)
    const targetMindsMain = useRef<null | HTMLDivElement>(null)


    const scrollToTargetMindsGames = () => {
        targetMindsGames.current?.scrollIntoView({behavior: 'smooth'})
    }
    const scrollToTargetMindsWorkshop = (): void => {
        targetMindsWorkshop.current?.scrollIntoView({behavior: 'smooth'})
    }
    const scrollToTargetMindsFactory = (): void => {
        targetMindsFactory.current?.scrollIntoView({behavior: 'smooth'})
    }
    const scrollToTargetMindsMain = (): void => {
        targetMindsMain.current?.scrollIntoView({behavior: 'smooth'})
    }
    // const handleLinkClick = (arg: string): void => {
    //     window.open(arg)
    // }

    return (
        <Container>
            <StyledBlockNameWithBtn ref={targetMindsMain}>
                {/*<Switch toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}/>*/}
                <StyledBlockName>
                    <Name>Minds</Name>
                    <Description>Фабрика думок</Description>
                </StyledBlockName>
                <ButtonLoginStyled to={`${path.login}`} bgColors={'#E3C565'}>
                    <span>Увійти</span>
                    <IconLogin size={60} margin={-45} delay={'0.8s'}>
                        <ArrowForLogin/>
                    </IconLogin>
                </ButtonLoginStyled>
            </StyledBlockNameWithBtn>
            <StyledBlockButtonSelect>
                <BtnForGoToRead onClick={scrollToTargetMindsGames} bgColors={'#52616B'}>
                    <InfoStyled colors={'#52616B'}>
                        Games
                        <span>
             У Minds ти не знайдеш себе. Але можливо тебе знайде Minds.
            </span>
                    </InfoStyled>
                    <BlockImgAndIcon>
                        <Icon long={'0.5s'} size={125} margin={15} left={175} stroke={'#52616B'}>
                            <MindsGamesIcon/>
                        </Icon>
                        <ArrowSize margin={120} left={30} stroke={'#52616B'}>
                            <ArrowForSection/>
                        </ArrowSize>
                    </BlockImgAndIcon>
                </BtnForGoToRead>
                <BtnForGoToRead onClick={scrollToTargetMindsWorkshop} bgColors={'#52616B'}>
                    <InfoStyled color={'#C9D6DF'} colors={'#52616B'}>
                        Workshop
                        <span>
              Навчання - це єдина робота, яку можна отримати у Minds.
            </span>
                    </InfoStyled>
                    <BlockImgAndIcon>
                        <Icon long={'0.7s'} size={120} margin={20} left={180} stroke={'#52616B'}>
                            <MindsWorkshopIcon/>
                        </Icon>
                        <ArrowSize margin={120} left={30} stroke={'#52616B'}>
                            <ArrowForSection/>
                        </ArrowSize>
                    </BlockImgAndIcon>
                </BtnForGoToRead>
                <BtnForGoToRead onClick={scrollToTargetMindsFactory} bgColors={'#52616B'}>
                    <InfoStyled color={'#C9D6DF'} colors={'#52616B'}>
                        Factory
                        <span>
              У Factory на роботу можна не тільки запізнюватись, а і взагалі не приходити.
            </span>
                    </InfoStyled>
                    <BlockImgAndIcon>
                        <Icon long={'0.9s'} size={120} margin={15} left={175} stroke={'#52616B'}>
                            <MindsFactoryIcon/>
                        </Icon>
                        <ArrowSize margin={125} left={30} stroke={'#52616B'}>
                            <ArrowForSection/>
                        </ArrowSize>
                    </BlockImgAndIcon>
                </BtnForGoToRead>
            </StyledBlockButtonSelect>
            <BlockDetailsInformationStyled ref={targetMindsGames}>
                <BlockContentStyled>
                    <DescriptionBlock>
                        Games
                        {/* <span>{text.mindsGamesDescription}</span> */}
                        {
                            <span>
                Minds Games - це перший крок на шляху до нашої команди. Включає прискорене вивчення бази Computer Science, тести на психологічні
                складові та розумові здібності, та фінальний розподільний тест. Це етап відбору та сегментного розподілу кандидатів на їх майбутні
                робочі місця.
                <br/>
                <br/>
                Психологічні тести допоможуть визначити рівень стійкості до стресових ситуацій та роботи у невизначеності. Це - важливі для нас
                критерії, тому проходження буде обов'язковим.
                <br/>
                <br/>
                Люди, котрі потрапили до Minds Games, проходять первинний етап самоосвіти, створюючи цим свій психологічний портрет, демонструють свої
                здібності.
                <br/>
                <br/>
                Головне завдання – відібрати кращих та найбільш зацікавлених працівників, дати їм базові уявлення про можливу співпрацю, провести
                первинну діагностику. Це етап професійного відбору.
                <br/>
                <br/>
                Під час навчання у кожного, хто отримує освіту в Minds, буде особиста картка. Це цифровий документ, в який буде внесено дані про
                успіхи у роботі і навчанні. Ціль кожної карти – виокремлення всіх робочих та особистих якостей працівника, накопичення якомога більшої
                кількості інформації про нього.
              </span>
                        }
                        <DocumentStyled top={0} bottom={13}>
                            <span>DOCUMENTS</span>
                            <IconDocument
                                onClick={()=>{}}
                                // () => {
                                //     handleLinkClick('https://docs.google.com/document/d/1mjVUu4VcYimPfj6Z9x0F1JoejUqTqBQ4_IuSy7DWvPg/edit?usp=sharing')
                                // }}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                                px={0}
                            >
                                <p>
                                    План
                                    <br/> “Computer Science”
                                </p>
                                <Document/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Лекції
                                    <br/> “Computer Science”
                                </p>
                                <Document2/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}
                                // () => {
                                //     handleLinkClick('https://docs.google.com/document/d/1aysS2ea-8apdJ6DG_DFx3xL1th0InlhysSlh6ZRcEZw/edit')
                                // }
                            }
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Семінари
                                    <br/> “Computer Science”
                                </p>
                                <Document3/>
                            </IconDocument>
                            <IconDocument
                                onClick={() =>{}}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Практики
                                    <br/> “Computer Science”
                                </p>
                                <Document3/>
                            </IconDocument>
                            <SpanStyled left={35}>FORMS</SpanStyled>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={50}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Форма
                                    <br/> знайомства
                                </p>
                                <Document3/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={50}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Тест
                                    <br/> “16 персоналій”
                                </p>
                                <Document3/>
                            </IconDocument>
                        </DocumentStyled>
                    </DescriptionBlock>
                    <ImgBlockStyled>
                        <BlockImgAndIcon>
                            <div>
                                <ArrowSize onClick={scrollToTargetMindsMain} margin={-30} left={-20}>
                                    <ArrowForBackInTop/>
                                </ArrowSize>
                            </div>
                            <Icon size={170} margin={220} left={-20} stroke={'#52616B'}>
                                <MindsGamesIcon/>
                            </Icon>
                            <div>
                                <ArrowSize onClick={scrollToTargetMindsWorkshop} margin={165} left={-20}
                                           stroke={'#52616B'}>
                                    <ArrowForSection/>
                                </ArrowSize>
                            </div>
                        </BlockImgAndIcon>
                    </ImgBlockStyled>
                </BlockContentStyled>
            </BlockDetailsInformationStyled>
            <BlockDetailsInformationStyled ref={targetMindsWorkshop}>
                <BlockContentStyled>
                    <DescriptionBlock>
                        Workshop
                        <span>
              Мета Minds Workshop - змінити взаємозв’язки мотивацій серед учасників навчального процесу. Виявити справжню зацікавленість і потреби,
              тому що у кожного своє призначення.
              <br/>
              <br/>
              Внутрішня токенізована система оплати. Її можна порівняти з власною валютною політикою будь-якої держави. Існують різні валюти, тому що
              всі країни мають власний економічний ландшафт. Уряди можуть використовувати переваги власної валюти, щоб впливати на обмінні курси,
              виробництво, ціни чи доходи. Ми маємо на меті те ж саме.
              <br/>
              <br/>
              Технологія блокчейн дає можливість досягти спільного консенсусу щодо істини, яка описує історію станів цифрової екосистеми, у формі
              книги транзакцій, або ж бази даних. Найфундаментальніші аспекти праці людей у Minds Factory покладаються на централізоване джерело
              правди, яке діє як гарант безпеки усього проєкту. У загальних рисах, токени можна описати як кількісне представлення децентралізованої
              довіри у Minds Factory.
              <br/>
              <br/>У Workshop до карти додається новий блок інформації про результати його тестів, роботи, взаємозв’язку із робочим середовищем тощо.
            </span>
                        <DocumentStyled bottom={10}>
                            <span>DOCUMENTS</span>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    План
                                    <br/> “Web Development”
                                </p>
                                <Document/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Лекції
                                    <br/> “Web Development”
                                </p>
                                <Document2/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Семінари
                                    <br/> “Web Development”
                                </p>
                                <Document3/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Практики
                                    <br/> “Web Development”
                                </p>
                                <Document3/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={15}
                                margin={55}
                                size={110}
                                color={'none'}
                                hover={'none'}
                                bottom={5}
                            >
                                <p>
                                    Лабораторні
                                    <br/> “Web Development”
                                </p>
                                <Document3/>
                            </IconDocument>
                            <SpanStyled left={40}>VIDEOS</SpanStyled>
                            <IconDocument
                                onClick={()=>{}}
                                left={20}
                                margin={55}
                                size={105}
                                color={'none'}
                                hover={'none'}
                                bottom={-2}
                            >
                                <p>
                                    Introduction
                                    <br/> “Web Development”
                                </p>
                                <Video/>
                            </IconDocument>
                        </DocumentStyled>
                    </DescriptionBlock>
                    <ImgBlockStyled>
                        <BlockImgAndIcon>
                            <div>
                                <ArrowSize onClick={scrollToTargetMindsMain} margin={-30} left={-20}>
                                    <ArrowForBackInTop/>
                                </ArrowSize>
                            </div>
                            <Icon size={165} margin={215} left={-25} stroke={'#52616B'}>
                                <MindsWorkshopIcon/>
                            </Icon>
                            <div>
                                <ArrowSize onClick={scrollToTargetMindsFactory} margin={135} left={-20}
                                           stroke={'#52616B'}>
                                    <ArrowForSection/>
                                </ArrowSize>
                            </div>
                        </BlockImgAndIcon>
                    </ImgBlockStyled>
                </BlockContentStyled>
            </BlockDetailsInformationStyled>
            <BlockDetailsInformationStyled ref={targetMindsFactory}>
                <BlockContentStyled>
                    <DescriptionBlock>
                        Factory
                        <span>
              Minds Factory - дослідницький простір який допомагає людині реалізувати свої здібності, займаючись улюбленою справою та отримуючи за це
              гроші.
              <br/>
              <br/>
              Мета проєкту - створити умови поетапного досягнення професійної самореалізації в умовах невизначеності майбутнього. Ми хочемо створити
              свою ідеологію, яка дасть робітникам відповіді на питання “хто я?” і “де я?”, та розкриє поняття щастя у професійній самореалізації.
              <br/>
              <br/>
              Minds Factory попри робочі процеси досліджує явища, які можуть забезпечити компанії безсмертя та багатовікове існування, включає
              науково-дослідні та дослідно-конструкторські роботи, базовані на досвіді всіх систем в організації.
              <br/>
              <br/>
              Minds Factory довершує картку-досьє фінальним блоком, який включає в себе всі дані про співробітника, від його вступу у Games,
              “підмайстер’я” та безпосередньо Factory. Отримана інформація завдяки своєму об’єму створює розгорнутий портрет людини у хронологічному
              порядку.
            </span>
                        <DocumentStyled bottom={13}>
                            <span>PICTURES</span>
                            <IconDocument
                                onClick={()=>{}}
                                left={20}
                                margin={50}
                                size={100}
                                padding={15}
                                color={'#52616B'}
                                px={3}
                                hover={'#e3c565'}
                                bottom={5}
                            >
                                <Picture/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={20}
                                margin={50}
                                size={100}
                                padding={15}
                                color={'#52616B'}
                                px={3}
                                hover={'#e3c565'}
                                bottom={5}
                            >
                                <Picture2/>
                            </IconDocument>
                            <IconDocument
                                onClick={()=>{}}
                                left={20}
                                margin={50}
                                size={100}
                                padding={15}
                                color={'#52616B'}
                                px={3}
                                hover={'#e3c565'}
                                bottom={5}
                            >
                                <Picture3/>
                            </IconDocument>
                        </DocumentStyled>
                    </DescriptionBlock>
                    <ImgBlockStyled>
                        <BlockImgAndIcon>
                            <div>
                                <ArrowSize onClick={scrollToTargetMindsMain} margin={-30} left={-20}>
                                    <ArrowForBackInTop/>
                                </ArrowSize>
                            </div>
                            <Icon size={155} margin={200} left={-5} stroke={'#52616B'}>
                                <MindsFactoryIcon/>
                            </Icon>
                        </BlockImgAndIcon>
                    </ImgBlockStyled>
                </BlockContentStyled>
            </BlockDetailsInformationStyled>
        </Container>
    )
}

const GlobalAnimation = keyframes`
    0% {
        opacity: 0.5
    }
    100% {
        opacity: 1;
    }
`

const breatheAnimation = keyframes`
    0% {
        margin-left: ${px2vw(240)};
        transform: rotate(0.1turn)
    }
    100% {
        margin-left: ${px2vw(25)};
        transform: rotate(0)
    }
`

const breatheAnimationLogin = keyframes`
    0% {
        z-index: -1;
        stroke: none;
        margin-left: ${px2vw(-79)};
    }
    100% {
        z-index: 1;
        stroke: ${props => props.theme.txtLogin};
        margin-left: 0;
    }
`

const breatheAnimationHover = keyframes`
    from {
        z-index: -1;
        stroke: none;
        margin-left: ${px2vw(-31)};
    }
    to {
        z-index: 1;
        stroke: ${props => props.theme.txtLogin};
        margin-left: ${px2vw(0)};
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    height: 100%;
`

const StyledBlockNameWithBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${px2vw(20)} ${px2vw(150)} 0px ${px2vw(150)};
`

const StyledBlockName = styled.div`
    width: ${px2vw(160, 320)};
    margin-top: ${px2vw(80)};
`

const Name = styled.div`
    letter-spacing: ${px2vw(15)};
    font-family: 'Comfortaa', serif;
    font-size: ${px2vw(160)};
    color: ${props => props.theme.logoColor};
    animation-name: ${GlobalAnimation};
    animation-duration: 0.8s;
`
const Description = styled.div`
    letter-spacing: ${px2vw(9.5)};
    font-weight: 300;
    font-size: ${px2vw(30)};
    margin-left: ${px2vw(6)};
    margin-top: ${px2vw(-30)};
    color: #52616b;
    animation-name: ${GlobalAnimation};
    animation-duration: 1.5s;
`

const ButtonLoginStyled = styled(Link)<{ bgColors: string }>`
    display: flex;
    flex-direction: column;
    width: ${px2vw(270)};
    height: ${px2vw(250)};
    margin-top: ${px2vw(40)};
    padding: ${px2vw(20)};
    background-color: ${props => props.theme.buttonLogin};
    border: 1px solid ${props => props.bgColors};
    color: ${props => props.theme.txtLogin};
    text-decoration: none;
    font-weight: 50;
    font-size: ${px2vw(25)};
    cursor: pointer;
    animation-name: ${GlobalAnimation};
    animation-duration: 0.8s;

    &:hover {
        & svg {
            animation: ${breatheAnimationHover} 0.9s infinite;
        }
    }


    // @media (min-width: 768px) {
        //   width: ${px2vw(310)};
        //   min-height: ${px2vw(310)};
    //   height: 100%;
    // }

    // @media (min-width: 1024px) {
        //   width: ${px2vw(310)};
        //   min-height: ${px2vw(310)};
    //   height: 100%;
    // }
`
const StyledBlockButtonSelect = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 ${px2vw(100)};
    animation-name: ${GlobalAnimation};
    animation-duration: 1.5s;
`

const BtnForGoToRead = styled.div<{ bgColors: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${px2vw(310)};
    min-height: ${px2vw(310)};
    height: 100%;
    margin-top: ${px2vw(40)};
    border: 1px solid ${props => props.bgColors};
    font-family: 'Comfortaa',sans-serif;
    overflow: hidden;
    cursor: pointer;
    animation-name: ${GlobalAnimation};
    animation-duration: 0.8s;

    // @media (min-width: 768px) {
        //   width: ${px2vw(310)};
        //   min-height: ${px2vw(310)};
    //   height: 100%;
    // }
    //
    // @media (min-width: 1024px) {
        //   width: ${px2vw(310)};
        //   min-height: ${px2vw(310)};
    //   height: 100%;
    //   overflow: hidden;
    // }

    &:hover {
        background-color: ${props => props.theme.bgBtnGoToRead};
        border: 1px solid ${props => props.theme.btnGoToReadBorder};

        & div {
            background-color: ${props => props.theme.bgBtnGoToRead};
        }

        & span {
            background-color: ${props => props.theme.bgBtnGoToRead};
        }

        & svg {
            background-color: ${props => props.theme.bgBtnGoToRead};

            stroke: ${props => props.theme.strokeSvg};
        }
    }
`
const InfoStyled = styled.div<{ colors: string }>`
    display: flex;
    flex-direction: column;
    width: ${px2vw(170)};
    color: ${props => props.theme.logoColor};
    font-size: ${px2vw(25)};
    margin-left: ${px2vw(15)};
    margin-top: ${px2vw(15)};

    & span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Verdana', sans-serif;
        //text-align: justify;
        margin-top: ${px2vw(15)};
        font-size: ${px2vw(15)};
        color: ${props => props.colors};
    }
`

const IconLogin = styled.svg<{ margin: number, size: number, delay: string }>`
    margin-top: ${props => px2vw(props.margin)};
    width: ${props => px2vw(props.size)};
    min-height: ${props => px2vw(props.size)};
    fill: none;
    animation: ${breatheAnimationLogin} 0.8s forwards;
        /* animation-duration: ${props => props.delay}; */
    /* animation-delay: 1s; */
    background: none;
    stroke: ${props => props.theme.txtLogin};
    @media (min-width: 2000px) {
        margin-top: ${px2vw(-20)};
    }
`

const Icon = styled.svg<{ left: number, margin: number, size: number, stroke: string, long?: string }>`
    margin-left: ${props => px2vw(props.left)};
    margin-top: ${props => px2vw(props.margin)};
    width: ${props => px2vw(props.size)};
    min-height: ${props => px2vw(props.size)};
    fill: none;
    animation: ${breatheAnimation} 0.8s forwards;
    animation-delay: ${props => props.long};
    background: none;
    stroke: ${props => props.stroke};
`
const IconDocument = styled.a<{
    left: number,
    margin: number,
    size: number,
    padding?: number,
    px?: number,
    hover: string,
    bottom: number
}>`
    margin-left: ${props => px2vw(props.left)};
    margin-top: ${props => px2vw(props.margin)};
    width: ${props => px2vw(props.size)};
    min-height: ${props => px2vw(props.size)};
    fill: none;
    cursor: pointer;
    stroke: #52616b;
    padding: ${props => px2vw(props.padding ? props.padding : 0)};
    border: 1px solid ${props => props.color};
    border-radius: ${props => px2vw(props.px ? props.px : 0)};
    text-decoration: none;
    color: #52616b;

    &:hover {
        color: #e3c565;
        stroke: #e3c565;
        border: 1px solid ${props => props.hover};
    }

    & p {
        margin-bottom: ${props => px2vw(props.bottom)};
        text-align: center;
        font-size: ${px2vw(8)};
            /* letter-spacing: ${px2vw(6)}; */
    }
`
const ArrowSize = styled.svg<{ margin: number, left: number }>`
    margin-top: ${props => px2vw(props.margin)};
    margin-left: ${props => px2vw(props.left)};
    width: ${px2vw(35)};
    min-height: ${px2vw(35)};
    fill: none;
    cursor: pointer;
    stroke: #52616b;

`
const BlockImgAndIcon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    height: ${px2vw(20)};

    & div {
        width: 0;
        height: 0;
        @media (min-width: 1920px) {
            margin-top: 0.6vw;
            height: 3vh;
        }

        &:hover {
            & svg {
                stroke: #e3c565;
            }
        }
    }
`
const BlockDetailsInformationStyled = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    min-height: ${px2vw(190, 768)};
    padding: ${px2vw(80)} ${px2vw(170)};
    margin-top: ${px2vw(60)};

    // @media (min-width: 768px) {
        //   min-height: ${px2vw(706)};
    //   height: 100%;
    // }
    //
    // @media (min-width: 1024px) {
        //   min-height: ${px2vw(756)};
    //   height: 100%;
    //   overflow: hidden;
    // }
`
const BlockContentStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid #52616b;
    overflow: hidden;
    margin-top: -30px;
`

const DescriptionBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: ${px2vw(980)};
    margin-top: ${px2vw(20)};
    margin-left: ${px2vw(20)};
    color: ${props => props.theme.logoColor};
    font-size: ${px2vw(30)};
    padding: ${px2vw(15)};
    font-family: 'Comfortaa',sans-serif;

    & span {
        line-height: ${px2vw(18)};
        word-spacing: ${px2vw(5)};
        text-align: justify;
        font-size: ${px2vw(15)};
        font-family: 'Verdana',sans-serif;
        color: #52616b;
        margin-top: ${px2vw(10)};
    }
`
const ImgBlockStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 25%;
`
const DocumentStyled = styled.div<{ top?: number, bottom: number }>`
    display: flex;
    flex-direction: row;
    margin-bottom: ${px2vw(10)};

    & span {
        padding-bottom: ${props => px2vw(props.top ? props.top : 0)};
        font-size: ${px2vw(17)};
        letter-spacing: ${px2vw(6)};
        color: ${props => props.theme.logoColor};
        writing-mode: vertical-rl;
        transform: scale(-1);
    }

    & a {
        margin-bottom: ${props => px2vw(props.bottom)};
    }
`
const SpanStyled = styled.span<{ left: number }>`
    font-size: ${px2vw(17)};
    margin-left: ${props => px2vw(props.left)};
    margin-bottom: ${px2vw(25)};
    letter-spacing: ${px2vw(6)};
    color: #c9d6df;
    writing-mode: vertical-rl;
    transform: scale(-1);
`

