import React from 'react'
import './Pop-up.styled'
import {Modal, ModalContent, ModalSecondContent} from "./Pop-up.styled";

type Props = {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    children: React.ReactNode
    testing?: boolean
}

export const PopUp = ({active, setActive, children, testing}: Props) => {
    return (
        <Modal test={testing} className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
            <ModalContent className={active ? 'modal__content active' : 'modal__content'}
                          onClick={(e: { stopPropagation: () => void; }) => e.stopPropagation()}>
                {children}
            </ModalContent>
        </Modal>
    )
}