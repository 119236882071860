import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 2vw;
    width: 20vw;
    height: 30vh;
    margin-top: 5px;
    background-color: #1E2022;
    border-radius: 5px;
    border: 1px solid rgba(227, 197, 101, 1);
    overflow: scroll;
    z-index: 1;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
export const ListTestsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 85%;
  height: 25%;
  background-color: rgba(82, 97, 107, 0.5);
  border-radius: 10px;
  margin: 10px;
  padding: 5px 10px;
  color: rgba(219, 232, 241, 1);
  cursor: pointer;

  & div {
    & svg {
      width: 17px;
      height: 17px;
    }
  }

`
export const TestBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 95%;
  height: 50px;
  margin: 5px 0;
`
export const BtnApply = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(227, 197, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 3px 10px;
  cursor: pointer;
  font-size: 12px;
  font-family: Verdana,sans-serif;
  &:hover {
    background-color: rgba(227, 197, 101, 0.7);
  }
  &:active {
    background-color: rgba(227, 197, 101, 1);
  }
  &:focus {
    outline: 0;
  }
`