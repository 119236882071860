import {ResponsiveRadar} from '@nivo/radar'

type Props = {
    data: any
}

export const MyResponsiveRadar = ({data}: Props) => {
    return (
        <>
            <ResponsiveRadar
                data={data}
                keys={['оцінка']}
                indexBy="taste"
                valueFormat=" >-.2f"
                margin={{top: 20, right: 0, bottom: 0, left: 0}}
                borderWidth={0}
                borderColor={{from: 'color', modifiers: []}}
                gridShape="linear"
                gridLabelOffset={15}
                dotSize={5}
                dotColor={{theme: 'background'}}
                dotBorderWidth={2}
                dotBorderColor={{from: 'color', modifiers: []}}
                dotLabel="value"
                colors={{scheme: 'yellow_orange_red'}}
                fillOpacity={1}
                blendMode="color"
                motionConfig="default"
                legends={[]}
            />

        </>
    )
}