import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35vw;
    height: 85vh;
    z-index: 2;
    font-family: Verdana, sans-serif;
    overflow: scroll;

    & h1 {
        font-size: 1.2em;
        margin: 1vw 0;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`

export const QuestionBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 5px 0;
    position: relative;

    & textarea {
        margin-right: 2px;
        line-height: 20px;
        width: 40%;
        background-color: rgba(82, 97, 107, 0.5);
        border: 0.5px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        padding: 3px;
        color: rgba(219, 232, 241, 1);
        resize: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

        &::placeholder {
            color: rgba(219, 232, 241, 1);
        }
    }
`
export const ButtonAddBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5px 0;

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        width: 10%;
        background-color: rgba(82, 97, 107, 0.3);
        opacity: 0.7;
        border: 1px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        cursor: pointer;

        &:active {
            background-color: rgba(82, 97, 107, 0.1);
        }
    }
`
export const ButtonSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        border-radius: 5px;
        border: none;
        background-color: rgba(227, 197, 101, 1);
        color: rgba(82, 97, 107, 1);
        cursor: pointer;
    }
`
