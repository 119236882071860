import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1E2022;
  border: 1px solid rgba(82, 97, 107, 1);
  border-radius: 5px;
  position: absolute;
  width: 30vw;
  z-index: 3;
  cursor: auto;
  top: 25%;
`
export const QuestionBlock = styled.div<{active:boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  margin: 2px;
  position: relative;
  background-color: ${props=>props.active ? "rgba(227, 197, 101, 1)":'rgba(82, 97, 107, 0.5)'};
  border: 1px solid rgba(82, 97, 107, 1);
  box-shadow: inset 0 10px 10px -10px rgba(255, 255, 255, 0.15);
  font-size: 14px;
  color: ${props => props.active ? 'rgba(14, 15, 18, 1)' : 'rgba(219, 232, 241, 1)'};
  cursor: pointer;
  &:hover {
    background-color: rgba(227, 197, 101, 0.7);
  }

  &:active {
    background-color: rgba(227, 197, 101, 1);
  }

  &:focus {
    outline: 0;
  }
`
