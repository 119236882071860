import React, {FC, useState} from "react";
import {
    AddButton,
    ETypesInput,
    messengers,
    socialNetworks
} from "../constants";
import {QuestionText} from "../questionsType/QuestionText";
import {QuestionDatePicker} from "../questionsType/QuestionDatePicker";
import {QuestionPhoneNumber} from "../questionsType/QuestionPhoneNumber";
import {QuestionTextWithDropDownList} from "../questionsType/QuestionTextWithDropDownList";
import {
    IQuestions
} from "../interface";
import {QuestionSocialNetworks} from "../questionsType/QuestionSocialNetworks";
import {ButtonAddBlock} from "../Acquaintance.styled";

interface Props {
    listQuestions: IQuestions[],
    setState: React.Dispatch<React.SetStateAction<any>>
    fieldsForUpdate: string[]
    indexBlock?: number
    visibleButton: boolean
    previosWorkAnswers?:any[]
}

export const ContainerQuestions: FC<Props> = ({listQuestions, setState, fieldsForUpdate, visibleButton,previosWorkAnswers}) => {
    const [listBlock, setListBlock] = useState<number>(visibleButton ? 0 : 1);
    return (<>
        {Array.from({length: listBlock}, (_, indexBlock) => {
            return (listQuestions.map((elem: IQuestions, index: number) => {
                switch (elem.type) {
                    case ETypesInput.TEXT:
                        if (elem.values?.length) {
                            return <QuestionTextWithDropDownList name={elem.question} list={elem.values}
                                                                 userAbilityWriteMsg={elem?.userAbilityWriteMsg ? elem?.userAbilityWriteMsg : true}
                                                                 require={elem.require} field={elem.field}
                                                                 setAnswers={setState}
                                                                 fieldsForUpdate={fieldsForUpdate[index]} indexField={indexBlock}/>
                        } else {
                            return <QuestionText field={elem.field} name={elem.question} require={elem.require}
                                                 type={elem.type} setAnswers={setState} indexField={indexBlock}
                                                 fieldsForUpdate={fieldsForUpdate[index]}/>
                        }
                    case ETypesInput.DATE:
                        return <QuestionDatePicker name={elem.question} require={elem.require}
                                                   selectsRange={elem?.selectsRange}
                                                   showMonthYearPicker={elem?.monthPicker}
                                                   showYearPicker={elem?.yearPicker} setAnswers={setState}
                                                   field={elem.field} formatDate={elem?.formatDate} indexField={indexBlock} fieldsForUpdate={fieldsForUpdate[index]}/>
                    case ETypesInput.PHONE:
                        return <QuestionPhoneNumber name={elem.question} fieldsForUpdate={fieldsForUpdate[index]} indexField={indexBlock} field={elem.field} setAnswers={setState}/>
                    case ETypesInput.SOCIAL:
                        return <QuestionSocialNetworks name={elem.question}
                                                       userAbilityWriteMsg={elem?.userAbilityWriteMsg ? elem?.userAbilityWriteMsg : true}
                                                       require={elem.require} list={socialNetworks} setAnswers={setState} field={elem.field} fieldsForUpdate={fieldsForUpdate[index]}/>
                    case ETypesInput.MESSENGER:
                        return <QuestionSocialNetworks name={elem.question}
                                                       userAbilityWriteMsg={elem?.userAbilityWriteMsg ? elem?.userAbilityWriteMsg : true}
                                                       require={elem.require} list={messengers} setAnswers={setState} field={elem.field} fieldsForUpdate={fieldsForUpdate[index]}/>
                    default:
                        return <QuestionText field={elem.field} name={elem.question} require={elem.require}
                                             type={elem.type} setAnswers={setState} indexField={indexBlock}
                                             fieldsForUpdate={fieldsForUpdate[index]} previosWorkAnswers={previosWorkAnswers && previosWorkAnswers}/>
                }
            }))
        })}
        {visibleButton && <ButtonAddBlock>
            <div onClick={() => setListBlock(listBlock + 1)}>
                {AddButton}
            </div>
        </ButtonAddBlock>}
    </>)
}