import React from 'react'
import {path} from '../../../utils/path'
import {
    BlockForm,
    Forms,
    BoxUserName,
    BlockButton,
    BoxLogin,
    BoxSubmit,
    BlockRegister,
    Links,
    BlockLoginStyled,
    Bth
} from './Login.styled'
import {useState} from 'react'
import axios from 'axios'
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {useAppSelector} from "../../store/auth.slice";
import {useLazyLoginQuery} from "../../store/api/authApi";
import {ELogin} from "./constants";

axios.defaults.withCredentials = true

export const Login: React.FC = () => {
    const history = useNavigate()
    const {isError} = useAppSelector((state) => state.auth);
    const [fetchRepos,] = useLazyLoginQuery()
    const [inputs, setInputs] = useState<{ email: string, password: string }>({
        email: '',
        password: '',
    })
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInputs(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const userData = {
            email: inputs.email,
            password: inputs.password,
        };
        fetchRepos(userData).then(r => {
            if (r.isSuccess && r.data.user.isAdmin) {
                history(path.admin)
            } else if (r.isSuccess && !r.data.user.isAdmin) {
                history(path.knowledgeTesting)
            } else {
                return
            }
        })
    };

    return (
        <BlockLoginStyled>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <BlockForm>
                <Forms onSubmit={handleSubmit} slot={'40'}>
                    <h2>{ELogin.ENTRANCE}</h2>
                    <form>
                        <BoxUserName>
                            <input name="email" onChange={handleChange} type={'email'} value={inputs.email} required
                                   autoComplete="off"/>

                            <label>{ELogin.EMAIL}</label>
                        </BoxUserName>
                        <BoxUserName>
                            <input name="password" onChange={handleChange} type="password" value={inputs.password}
                                   required autoComplete="off"/>

                            <label>{ELogin.PASSWORD}</label>
                        </BoxUserName>
                        <BlockButton>
                            <BoxLogin>
                                <div>
                                    <Bth type="submit">
                                        <span>{ELogin.BTNENTRANCE}</span>
                                    </Bth>
                                    {isError && <p>{ELogin.ERROR}</p>}
                                </div>
                            </BoxLogin>
                            <BoxSubmit>
                                <BlockRegister>
                                    <p>{ELogin.DONTHAVEACCOUNT}</p>
                                    <Links to={`${path.registration}`} onSubmit={handleSubmit}>
                                        {ELogin.REGISTRATION}
                                    </Links>
                                </BlockRegister>
                            </BoxSubmit>
                        </BlockButton>
                    </form>
                </Forms>
            </BlockForm>
        </BlockLoginStyled>
    )
}
