import {
    AddButton,
    AddButtonBlockStyled, AddNewQuestionBlock, BlockChoseTEstFormat,
    BlockSettings,
    BlockSpecificationStyled,
    BtnChangeSettings, ButtonCheckInformationTest,
    ChangeSettings, CheckAllInformationAboutTestBlock,
    CheckboxStyled,
    Container,
    CustomOption,
    CustomSelect, EvaluationInputs, FormatTestBlock,
    GeneralContainer, IdenticalTypeQuestionSelect,
    NextBlockAfterGeneral, SameAnswersBlock,
    Settings, StyledBlockEvaluation,
    StyledLabel,
    StyledVisibleBlockWithResult,
} from "./AddNewTest.styled";
import {ReactComponent as AddTestSVG} from "../../../assets/pictures/ButtonAddNewTest.svg";
import React, {ChangeEvent, useRef, useState} from "react";
import {departmentList, subjectList, typeList} from "../optionVariantList";
import {KeysSettings} from "./KeysSettings";
import {toast} from "react-toastify";
import {TableAllAnswerAndQuestions} from "./TableAllAnswerAndQuestions";
import {ReverseQuestion} from "./Reversequestion";
import {
    calculationCategoryMethod,
    conclusionsRoaster,
    EButtonNextStep,
    EErrorMsg, ETitleName,
    formatTest,
    TypeQuestion
} from "./constants";
import {ICategory, IConclusion, ISpecification, IQuestion} from "./interface";
import {CheckEnteredData} from "./СheckEnteredData";
import {useLazyAddTestQuery} from "../../../store/api/testsApi";

type Props = {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddNewTest: React.FC<Props> = ({active, setActive}) => {
    const [specification, setSpecification] = useState<ISpecification>({
        name: '',
        shortDescription: '',
        description: '',
        format: formatTest.test,
        subject: '',
        stage: '',
        topic: '',
        type: '',
        amountQuestions: undefined,
        duplicateAnswer: false,
        amountAnswers: 0,
        reverseQuestion: false,
        identicalTypeQuestion: false
    })
    const [setupStage, setSetupStage] = useState({settings: true, temple: false, keys: false})
    const [error, setError] = useState<boolean>(false)
    const [errorFiledList, setErrorFiledList] = useState<boolean>(false)
    const [list, setList] = useState<IQuestion[]>([])
    const [answers, setAnswers] = useState<string[]>([])
    const [temporaryAnswer, setTemporaryAnswer] = useState<string>('')
    const [reverseQuestionsList, setReverseQuestionsList] = useState<number[]>([])
    const [reverseQuestionListForUser, setReverseQuestionListForUser] = useState<number[]>([]);
    const [openTableReverseQuestions, setOpenTableReverseQuestions] = useState<boolean>(false)
    const [identicalTypeQuestion, setIdenticalTypeQuestion] = useState<string>('')
    const [category, setCategory] = useState<ICategory[]>([])

    //conclusion
    const [conclusion, setConclusion] = useState<IConclusion[][]>([])
    const [conclusionText, setConclusionText] = useState<string>('')
    const [conclusionAmount, setConclusionAmount] = useState<number[]>([])
    const [conclusionDescription, setConclusionDescription] = useState<string[][]>([])
    const [minRange, setMinRange] = useState<number[][]>([])
    const [maxRange, setMaxRange] = useState<number[][]>([])

    //total conclusion
    const [conclusionTotalAmount, setConclusionTotalAmount] = useState<number>(0)
    const [conclusionTotalDescription, setConclusionTotalDescription] = useState<string[]>([])
    const [minTotalRange, setMinTotalRange] = useState<number[]>([])
    const [maxTotalRange, setMaxTotalRange] = useState<number[]>([])

    const [calculationMethod, setCalculationMethod] = useState<string>('')
    const [customMethod, setCustomMethod] = useState<string[]>([])
    const [calculationCategoryOnCustomMethod, setCalculationCategoryOnCustomMethod] = useState<string>(calculationCategoryMethod.sum)
    const [minEvaluation, setMinEvaluation] = useState<number | undefined>(undefined)
    const [maxEvaluation, setMaxEvaluation] = useState<number | undefined>(undefined)
    const [checkData, setCheckData] = useState<boolean>(false)
    const [fetch] = useLazyAddTestQuery()

    const containerRef = useRef<HTMLDivElement>(null);

    const handleSpecificationChange = (fieldName: keyof ISpecification, value: string | number, amountQuestion?: number) => {
        setSpecification((prevSpec) => ({
            ...prevSpec,
            [fieldName]: value,
        }));
        if (value === formatTest.test) {
            setSetupStage({settings: true, temple: false, keys: false})
        }
        if (fieldName === 'amountQuestions' && Number(value) > 0) {
            setList(Array(Number(value)).fill({question: '', type: '', answers: [], value: []}));
        }
        if (fieldName === 'amountAnswers' && amountQuestion) {
            setList(Array(Number(amountQuestion)).fill({
                question: '',
                type: '',
                answers: Array.from({length: Number(value)}, () => '')
            }));

        }
    };
    const nextStepButton = () => {
        if (specification.format === formatTest.test) {
            if (specification.name && specification.description && specification.shortDescription && specification.subject && specification.stage && specification.topic && specification.type && list.length) {
                setList(prev => {
                    return [...prev].map((item) => ({
                        ...item,
                        answers: [...answers],
                    }));
                });
                setSetupStage(prevState => ({...prevState, settings: false, keys: true}))
                setError(false)
            } else {
                setError(true)
            }
        }

        if (specification.format === formatTest.form) {
            if (specification.name && specification.stage && list.length) {
                setList(prev => {
                    return [...prev].map((item) => ({
                        ...item,
                        answers: [...answers],
                    }));
                });
                setSetupStage(prevState => ({...prevState, settings: false, keys: true}))
                setError(false)
            } else {
                setError(true)

            }
        }
    }
    const addTestInDB = () => {
        fetch({
            name: specification.name,
            shortDescription: specification.shortDescription,
            description: specification.description,
            format: specification.format,
            subject: specification.subject,
            stage: specification.stage,
            topic: specification.topic,
            type: specification.type,
            content: list,
            keys: conclusion?.flat(),
            categories: category,
            calculationMethod: calculationMethod,
            formulaCalculation: {formula: customMethod, categoryCalc: calculationCategoryOnCustomMethod},
            conclusionType: conclusionText
        }).then((r) => {
            if (r.isSuccess) {
                notifySuccess()
                setActive(false)
            } else if (r.isError) {
                notifyError()
            }
        })
    }
    const notifySuccess = () => toast.success('Тест успішно доданий', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const notifyError = () => toast.error('Сталась помилка', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const handleCheckboxChange = (fieldName: string, e: ChangeEvent<HTMLInputElement>) => {
        setSpecification((prevSpec) => ({
            ...prevSpec,
            [fieldName]: e.target.checked,
        }));
        if (!e.target.checked && fieldName !== 'reverseQuestion') {
            setAnswers([])
            setOpenTableReverseQuestions(false)
            setReverseQuestionsList([])
        }
        if (fieldName === 'reverseQuestion' && e.target.checked) {
            setOpenTableReverseQuestions(true)
        }
        if (fieldName === 'reverseQuestion' && !e.target.checked) {
            setOpenTableReverseQuestions(false)
            setReverseQuestionsList([])
            setReverseQuestionListForUser([])
        }
    };
    const addAnswer = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        setAnswers((prevArray) => {
            const newArray = [...prevArray];
            newArray[index] = temporaryAnswer ? temporaryAnswer : e.target.value;
            return newArray;
        });

    }
    const handleKeyDown = (e: any): void => {
        if (e.key === 'Enter' && e.target.value !== '') {
            scrollToBottom()
            setAnswers(prevState => {
                return [...prevState, e.target.value]
            })
            setTemporaryAnswer('')

        }
    };
    const handleKeySettings = () => {

        setSetupStage({
            settings: false,
            temple: false,
            keys: true,
        })
        setList(prev => {
            return [...prev].map((item) => ({
                ...item,
                answers: [...answers],
            }));
        });

    }

    const handleAddValueInQuestions = () => {
        const values: number[] = []
        if (typeof minEvaluation === 'number' && typeof maxEvaluation === 'number') {
            for (let i = minEvaluation; i <= maxEvaluation; i++) {
                values.push(i);
            }
        }
        setList(prev => {
            return prev.map((item) => ({
                ...item,
                value: values
            }));
        });
    }
    const textarea = useRef<HTMLTextAreaElement | null>(null)
    const textareaInputHandler = () => {
        if (textarea.current) {
            textarea.current.style.height = '18px';
            textarea.current.style.height = `${Math.min(textarea.current.scrollHeight, 100)}px`;
        }
    };
    const handleUpdateConclusion = () => {
        switch (conclusionText) {
            case conclusionsRoaster[0]:
                setConclusion(prevState => {
                    const newConclusion: IConclusion[][] = prevState.slice(); // Створюємо копію попереднього стану

                    // Перевірка чи вже існує масив з об'єктами з іменем "Total"
                    const existingTotalConclusionIndex = newConclusion.findIndex(conclusions => conclusions.every(conclusion => conclusion.name === "Total"));

                    if (existingTotalConclusionIndex !== -1) {
                        // Якщо масив з іменем "Total" вже існує, перевіряємо чи його кількість відповідає conclusionTotalAmount
                        if (newConclusion[existingTotalConclusionIndex].length !== conclusionTotalAmount) {
                            // Якщо кількість не відповідає, оновлюємо масив з "Total"
                            newConclusion[existingTotalConclusionIndex] = newConclusion[existingTotalConclusionIndex].map((item, i) => ({
                                ...item,
                                range: [minTotalRange[i] ?? 0, maxTotalRange[i] ?? 0],
                                description: conclusionTotalDescription[i] ?? ''
                            }));
                        } else {
                            // Якщо кількість відповідає, оновлюємо значення вже існуючих об'єктів "Total"
                            newConclusion[existingTotalConclusionIndex] = newConclusion[existingTotalConclusionIndex].map((item, i) => ({
                                ...item,
                                range: [minTotalRange[i] ?? 0, maxTotalRange[i] ?? 0],
                                description: conclusionTotalDescription[i] ?? ''
                            }));
                        }
                    } else {
                        // Якщо масиву з іменем "Total" ще не існує, створюємо його
                        if (conclusionTotalAmount) {
                            const totalConclusion: IConclusion[] = Array.from({length: conclusionTotalAmount}, (_, i) => ({
                                name: "Total",
                                range: [minTotalRange[i] ?? 0, maxTotalRange[i] ?? 0],
                                description: conclusionTotalDescription[i] ?? ''
                            }));
                            newConclusion.push(totalConclusion);
                        }
                    }
                    return newConclusion.filter(row => row.some(item => item.name === 'Total'));
                });


                break
            case conclusionsRoaster[1]:
                setConclusion(prevState => {
                    const newConclusion: IConclusion[][] = prevState.map((conclusionsForCategory, index) => {
                        const categoryAmount = conclusionAmount[index];
                        if (categoryAmount) {
                            return Array.from({length: categoryAmount}, (_, i) => ({
                                name: category[index].name,
                                range: [minRange[index][i] ?? 0, maxRange[index][i] ?? 0],
                                description: conclusionDescription[index][i] ?? ''
                            }));
                        } else {
                            return conclusionsForCategory; // If categoryAmount is 0 or undefined, keep the existing conclusions for this category
                        }
                    });

                    // Перевірити чи вже існує масив з об'єктами з іменем "Total"
                    const existingTotalConclusionIndex = newConclusion.findIndex(conclusions => conclusions.every(conclusion => conclusion.name === "Total"));

                    if (existingTotalConclusionIndex !== -1) {
                        // Якщо масив з іменем "Total" вже існує, перевіряємо чи його кількість відповідає conclusionTotalAmount
                        if (newConclusion[existingTotalConclusionIndex].length !== conclusionTotalAmount) {
                            // Якщо кількість не відповідає, оновлюємо масив з "Total"
                            newConclusion[existingTotalConclusionIndex] = newConclusion[existingTotalConclusionIndex].map((item, i) => ({
                                ...item,
                                range: [minTotalRange[i] ?? 0, maxTotalRange[i] ?? 0],
                                description: conclusionTotalDescription[i] ?? ''
                            }));
                        } else {
                            // Якщо кількість відповідає, оновлюємо значення вже існуючих об'єктів "Total"
                            newConclusion[existingTotalConclusionIndex] = newConclusion[existingTotalConclusionIndex].map((item, i) => ({
                                ...item,
                                range: [minTotalRange[i] ?? 0, maxTotalRange[i] ?? 0],
                                description: conclusionTotalDescription[i] ?? ''
                            }));
                        }
                    } else {
                        // Якщо масиву з іменем "Total" ще не існує, створюємо його
                        if (conclusionTotalAmount) {
                            const totalConclusion: IConclusion[] = Array.from({length: conclusionTotalAmount}, (_, i) => ({
                                name: "Total",
                                range: [minTotalRange[i] ?? 0, maxTotalRange[i] ?? 0],
                                description: conclusionTotalDescription[i] ?? ''
                            }));
                            newConclusion.push(totalConclusion);
                        }
                    }

                    return newConclusion;
                });
                break
            case conclusionsRoaster[2]:
                setConclusion(prevState => {
                    const newConclusion: IConclusion[][] = prevState.map((conclusionsForCategory, index) => {
                        const categoryAmount = conclusionAmount[index];
                        if (categoryAmount) {
                            return Array.from({length: categoryAmount}, (_, i) => ({
                                name: category[index].name,
                                range: [minRange[index][i] ?? 0, maxRange[index][i] ?? 0],
                                description: conclusionDescription[index][i] ?? ''
                            }));
                        } else {
                            return conclusionsForCategory; // If categoryAmount is 0 or undefined, keep the existing conclusions for this category
                        }
                    });
                    return newConclusion
                });
                break
        }
    }
    const handleAddTypeInAllQuestions = (e: ChangeEvent<HTMLSelectElement>) => {
        setList(prevList => {
            return prevList.map(item => ({
                ...item,
                type: e.target.value
            }));
        });
        setIdenticalTypeQuestion(e.target.value)
    }

    const scrollToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    };
    const scrollToBottom = () => {
        if (containerRef.current instanceof HTMLElement) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;
        }
    };
    const handleAddQuestion = () => {
        setList(prevState => {
            return [...prevState, {
                question: '',
                type: '',
                answers: [],
                value: [],
                category: ''
            }]
        })
    }

    function areAllFieldsFilled(): void {
        if(specification.format !== formatTest.form) {
        for (const item of list) {
            if (item.type !== 'text') {
                if (!item.question || !item.type || !item.category || !item.value || !item.answers) {
                    return setErrorFiledList(true);
                } else if (
                    (Array.isArray(item.value) && item.value.length === 0) ||
                    (Array.isArray(item.answers) && item.answers.length === 0)
                ) {
                    return setErrorFiledList(true);
                } else if (Array.isArray(item.value) && Array.isArray(item.answers) && item.value.length !== item.answers.length && item.value.length > 0 && item.answers.length > 0) {
                    return setErrorFiledList(true);
                }
            }
        }
        } else {
            for (const item of list) {
                if (item.type !== 'text') {
                    if (!item.question || !item.type || !item.answers) {
                        return setErrorFiledList(true);
                    } else if (Array.isArray(item.answers) && item.answers.length === 0) {
                        return setErrorFiledList(true);
                    }
                }
            }
        }
        setErrorFiledList(false);
        handleUpdateConclusion()
        setCheckData(true)
        scrollToTop()
    }

    return <GeneralContainer className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
        <StyledVisibleBlockWithResult keys={setupStage.keys}>
            <Settings>
                <ChangeSettings className={active ? 'modal__content active' : 'modal__content'}
                                onClick={(e: { stopPropagation: () => void; }) => e.stopPropagation()}>
                    <BtnChangeSettings active={setupStage.settings || specification.format === formatTest.form}
                                       onClick={() => setSetupStage(prev => ({
                                           ...prev,
                                           settings: true,
                                           questions: false,
                                           keys: false
                                       }))}>
                        {ETitleName.SETTINGSTEST}</BtnChangeSettings>
                    {(specification.format !== formatTest.form && setupStage.keys) &&
                        <BtnChangeSettings active={setupStage.keys && !setupStage.settings}
                                           onClick={() => handleKeySettings()}>Ключі</BtnChangeSettings>}
                </ChangeSettings>
                <NextBlockAfterGeneral className={active ? 'modal__content active' : 'modal__content'}
                                       onClick={(e: { stopPropagation: () => void; }) => e.stopPropagation()}>
                    <Container>
                        {
                            (setupStage.settings || specification.format === formatTest.form) &&

                            <>
                                <div>{ETitleName.HEADERTITLE}</div>
                                <BlockChoseTEstFormat>
                                    <FormatTestBlock
                                        format={specification.format === formatTest.test}
                                        border={true}
                                        onClick={() => handleSpecificationChange('format', formatTest.test)}>{formatTest.testName}</FormatTestBlock>
                                    <FormatTestBlock
                                        format={specification.format === formatTest.form}
                                        border={false}
                                        onClick={() => handleSpecificationChange('format', formatTest.form)}>{formatTest.formName}</FormatTestBlock>
                                </BlockChoseTEstFormat>
                                <BlockSpecificationStyled>
                                    <div>{ETitleName.TESTNAME}</div>
                                    <input type={'text'} value={specification.name}
                                           placeholder={'Введи назву тесту'}
                                           onChange={(e) => handleSpecificationChange('name', e.target.value)}/>
                                </BlockSpecificationStyled>
                                {(specification.format !== formatTest.form) && <BlockSpecificationStyled>
                                    <div>{ETitleName.TESTTHEME}</div>
                                    <input type={'text'} value={specification.topic}
                                           placeholder={'Введи назву теми'}
                                           onChange={(e) => handleSpecificationChange('topic', e.target.value)}/>
                                </BlockSpecificationStyled>}
                                <BlockSpecificationStyled>
                                    <div>{ETitleName.TESTSHORTDESCRIPTION}</div>
                                    <textarea ref={textarea} onInput={textareaInputHandler}
                                              value={specification.shortDescription}
                                              placeholder={'Введи опис тесту'}
                                              onChange={(e) => handleSpecificationChange('shortDescription', e.target.value)}/>
                                </BlockSpecificationStyled>
                                <BlockSpecificationStyled>
                                    <div>{ETitleName.TESTDESCRIPTION}</div>
                                    <textarea ref={textarea} onInput={textareaInputHandler}
                                              value={specification.description}
                                              placeholder={'Введи опис тесту'}
                                              onChange={(e) => handleSpecificationChange('description', e.target.value)}/>
                                </BlockSpecificationStyled>
                                {(specification.format !== formatTest.form) && <BlockSpecificationStyled>
                                    <div>{ETitleName.TESTSUBJECT}</div>
                                    <CustomSelect value={specification.subject}
                                                  onChange={(e: {
                                                      target: { value: string; };
                                                  }) => setSpecification((prevSpec) => ({
                                                      ...prevSpec,
                                                      subject: e.target.value
                                                  }))}>
                                        {subjectList.map((elem: { value: string, name: string }, index: number) => (
                                            <CustomOption key={index} value={elem.value}>{elem.name}</CustomOption>
                                        ))}
                                    </CustomSelect>
                                </BlockSpecificationStyled>}
                                <BlockSpecificationStyled>
                                    <div>{ETitleName.TESTDEPATMENT}</div>
                                    <CustomSelect value={specification.stage}
                                                  onChange={(e: {
                                                      target: { value: string; };
                                                  }) => setSpecification((prevSpec) => ({
                                                      ...prevSpec,
                                                      stage: e.target.value
                                                  }))}>
                                        {departmentList.map((elem: { value: string, name: string }, index: number) => (
                                            <CustomOption key={index} value={elem.value}>{elem.name}</CustomOption>
                                        ))}
                                    </CustomSelect>
                                </BlockSpecificationStyled>
                                {(specification.format !== formatTest.form) && <BlockSpecificationStyled>
                                    <div>{ETitleName.TESTTYPE}</div>
                                    <CustomSelect value={specification.type}
                                                  onChange={(e: {
                                                      target: { value: string; };
                                                  }) => setSpecification((prevSpec) => ({
                                                      ...prevSpec,
                                                      type: e.target.value
                                                  }))}>
                                        {typeList.map((elem: { value: string, name: string }, index: number) => (
                                            <option key={index} value={elem.value}>{elem.name}</option>
                                        ))}
                                    </CustomSelect>
                                </BlockSpecificationStyled>}
                                <BlockSpecificationStyled>
                                    <div>{ETitleName.AMOUNTQUESTIONS}</div>
                                    <input type="number"
                                           value={specification.amountQuestions}
                                           placeholder={'Введи кількість запитань'}
                                           min={0}
                                           onChange={(e) => handleSpecificationChange('amountQuestions', e.target.value)}/>
                                </BlockSpecificationStyled>
                                {(specification.format !== formatTest.form) && <StyledBlockEvaluation>
                                    <div>{ETitleName.EVALUATIONQUESTIONS}</div>
                                    <EvaluationInputs>
                                        <input type={'number'} placeholder={'min'} min={0} value={minEvaluation}
                                               onChange={(e) => {
                                                   setMinEvaluation(e.target.valueAsNumber)
                                               }}
                                               onBlur={() => handleAddValueInQuestions()}
                                        />
                                        <input type={'number'} placeholder={'max'} min={0} value={maxEvaluation}
                                               onChange={(e) => {
                                                   setMaxEvaluation(e.target.valueAsNumber)
                                               }}
                                               onBlur={() => handleAddValueInQuestions()}
                                        />
                                    </EvaluationInputs>
                                </StyledBlockEvaluation>}
                                {(specification.format !== formatTest.form) && <BlockSettings>
                                    <div>{ETitleName.REVERSEQUESTIONS}</div>
                                    <StyledLabel>
                                        <CheckboxStyled
                                            type={"checkbox"}
                                            checked={specification.reverseQuestion}
                                            disabled={list.length === 0}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange('reverseQuestion', e)}
                                        />
                                        {reverseQuestionsList.length > 0 &&
                                            <div> Запитання: {reverseQuestionListForUser.join(', ')}</div>}
                                    </StyledLabel>
                                </BlockSettings>}
                                <BlockSettings>
                                    <div>{ETitleName.IDENTICALTYPEQUESTIONS}</div>
                                    <StyledLabel>
                                        <CheckboxStyled
                                            type={"checkbox"}
                                            checked={specification.identicalTypeQuestion}
                                            disabled={list.length === 0}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange('identicalTypeQuestion', e)}
                                        />
                                        {specification.identicalTypeQuestion &&
                                            <IdenticalTypeQuestionSelect value={identicalTypeQuestion}
                                                                         onChange={(e) => handleAddTypeInAllQuestions(e)}>
                                                <option value="" disabled selected hidden>Обери тип відповідей</option>
                                                {TypeQuestion.map((elem: {
                                                    value: string,
                                                    text: string
                                                }, index: number) => (
                                                    <option key={index} value={elem.value}>{elem.text}</option>
                                                ))}
                                            </IdenticalTypeQuestionSelect>}
                                    </StyledLabel>
                                </BlockSettings>
                                {identicalTypeQuestion != TypeQuestion[0].value && <BlockSettings>
                                    <div>{ETitleName.DUPLICATEANSWERS}</div>
                                    <StyledLabel>
                                        <CheckboxStyled
                                            type={"checkbox"}
                                            disabled={list.length === 0}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange('duplicateAnswer', e)}
                                        />
                                    </StyledLabel>
                                </BlockSettings>}
                                <SameAnswersBlock test={(specification.format !== formatTest.form)} ref={containerRef}>
                                    {answers.length > 0 && answers.map((answer: string, index: number) => (
                                        <BlockSpecificationStyled key={index}>
                                            {index === 0 ? <div>{ETitleName.ANSWERS}</div> : <div></div>}
                                            <input type="text"
                                                   value={answer}
                                                   onChange={(e) => {
                                                       addAnswer(e, index)
                                                   }}/>
                                        </BlockSpecificationStyled>
                                    ))}
                                    {specification.duplicateAnswer && <BlockSpecificationStyled>
                                        {answers.length > 0 ? <div></div> : <div>{ETitleName.ANSWERS}</div>}
                                        <input type="text"
                                               value={temporaryAnswer}
                                               placeholder={'Введи відповідь'}
                                               onChange={(e) => setTemporaryAnswer(e.target.value)}
                                               onKeyDown={handleKeyDown}
                                        />
                                    </BlockSpecificationStyled>}
                                </SameAnswersBlock>
                            </>}
                        {openTableReverseQuestions && <ReverseQuestion amountAnswers={specification.amountQuestions}
                                                                       reverseQuestionsList={reverseQuestionsList}
                                                                       setReverseQuestionsList={setReverseQuestionsList}
                                                                       setOpenTableReverseQuestions={setOpenTableReverseQuestions}
                                                                       setList={setList}
                                                                       list={list}
                                                                       reverseQuestionListForUser={reverseQuestionListForUser}
                                                                       setReverseQuestionListForUser={setReverseQuestionListForUser}
                        />}
                        {/*{setupStage.questions &&*/}
                        {/*    <AnswerAndQuestion list={list} setList={setList} specification={specification}/>}*/}
                        {setupStage.settings && <AddButtonBlockStyled>
                            {error && <span>{EErrorMsg.ERROR}</span>}
                            <AddButton onClick={() => nextStepButton()}>{EButtonNextStep.ADD}</AddButton>
                        </AddButtonBlockStyled>}
                        {setupStage.keys && specification.format !== formatTest.form &&
                            <KeysSettings category={category} setCategory={setCategory} conclusion={conclusion}
                                          setConclusion={setConclusion} conclusionText={conclusionText}
                                          setConclusionText={setConclusionText} calculationMethod={calculationMethod}
                                          setCalculationMethod={setCalculationMethod} customMethod={customMethod}
                                          setCustomMethod={setCustomMethod}
                                          calculationCategoryOnCustomMethod={calculationCategoryOnCustomMethod}
                                          setCalculationCategoryOnCustomMethod={setCalculationCategoryOnCustomMethod}
                                          conclusionAmount={conclusionAmount} setConclusionAmount={setConclusionAmount}
                                          conclusionDescription={conclusionDescription}
                                          setConclusionDescription={setConclusionDescription}
                                          minRange={minRange} setMinRange={setMinRange} maxRange={maxRange}
                                          setMaxRange={setMaxRange}
                                          conclusionTotalAmount={conclusionTotalAmount}
                                          setConclusionTotalAmount={setConclusionTotalAmount}
                                          conclusionTotalDescription={conclusionTotalDescription}
                                          setConclusionTotalDescription={setConclusionTotalDescription}
                                          minTotalRange={minTotalRange} setMinTotalRange={setMinTotalRange}
                                          maxTotalRange={maxTotalRange} setMaxTotalRange={setMaxTotalRange}
                                          setCheckData={setCheckData}
                            />}
                    </Container>
                </NextBlockAfterGeneral>
            </Settings>
            {setupStage.keys && <Settings top={'20px'}>
                <NextBlockAfterGeneral className={active ? 'modal__content active' : 'modal__content'}
                                       onClick={(e: { stopPropagation: () => void; }) => e.stopPropagation()}>
                    <Container ref={containerRef} height={checkData ? "" : '75.35vh'}>
                        {checkData ? <CheckEnteredData setCheckData={setCheckData} calculationMethod={calculationMethod}
                                                       customMethod={customMethod}
                                                       calculationCategoryOnCustomMethod={calculationCategoryOnCustomMethod}
                                                       specification={specification} conclusion={conclusion.flat()}
                                                       category={category} list={list}
                                                       confirmFn={addTestInDB}/> : <>Питання / відповіді
                            {list.map((elem: IQuestion, index: number) => (
                                <TableAllAnswerAndQuestions category={category}
                                                            specification={specification}
                                                            reverseQuestionsList={reverseQuestionsList}
                                                            setReverseQuestionsList={setReverseQuestionsList}
                                                            identicalTypeQuestion={identicalTypeQuestion}
                                                            setIdenticalTypeQuestion={setIdenticalTypeQuestion}
                                                            key={index}
                                                            question={elem}
                                                            index={index}
                                                            setList={setList}
                                                            reverseQuestionListForUser={reverseQuestionListForUser}
                                                            setReverseQuestionListForUser={setReverseQuestionListForUser}
                                />
                            ))}
                            <AddNewQuestionBlock>
                                <div onClick={handleAddQuestion}>
                                    <AddTestSVG/>
                                </div>
                            </AddNewQuestionBlock>
                        </>}
                    </Container>
                    {!checkData && <CheckAllInformationAboutTestBlock>
                        {errorFiledList && <div>{EErrorMsg.ERROR}</div>}
                        <ButtonCheckInformationTest onClick={() => areAllFieldsFilled()}>
                            Додати тест</ButtonCheckInformationTest>
                        {/*<button onClick={()=>console.log(list)}>qq</button>*/}
                    </CheckAllInformationAboutTestBlock>}
                </NextBlockAfterGeneral>
            </Settings>}
        </StyledVisibleBlockWithResult>
    </GeneralContainer>
}
