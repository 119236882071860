export const months: string[] = ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"]

export enum EGeneralProfileInformation {
    NAME = 'імʼя:',
    STAGE = 'етап:',
    DEPARTMENT = 'відділ:',
    DAYINCOMPANY = 'днів у компанії:',
    DAYINCOMPANYFROM = 'від:'
}

export const contactDetails: { label: string, key: string }[] = [
    {label: 'телефон:', key: 'phone'},
    {label: 'пошта:', key: 'email'},
    {label: 'домашня адреса:', key: 'homeAddress'},
    {label: 'контактна особа:', key: 'personEmergency'},
]

export const enum ESpecificQuestions {
    NAME = 'П.І.Б.:',
    AGE = 'вік:',
    BIRTHDATE = 'дата народження:',
    YEARSEDUCATION = 'роки навчання:',
    WORKINGPERIOD = 'період роботи:'
}

export const personalDetails = [
    {label: 'П.І.Б.:', key: ''},
    {label: 'вік:', key: ''},
    {label: 'дата народження:', key: 'birthDate'},
    {label: 'стать:', key: 'sex'},
    {label: 'сімейний стан:', key: 'maritalStatus'},
    {label: 'діти:', key: 'children'},
    {label: 'громадянство:', key: 'citizenship'},
]
export const educationDetails = [
    {label: 'освітній рівень:', key: 'educationLevel'},
    {label: 'заклад:', key: 'name'},
    {label: 'спеціальність:', key: 'direction'},
    {label: 'роки навчання:', key: 'yearsEducation'}]
export const additionalEducationDetails = [
    {label: 'назва:', key: 'name'},
    {label: 'напрямок:', key: 'direction'},
    {label: 'роки навчання:', key: 'yearsEducation'}]
export const previousJobDetails = [
    {label: 'назва компанії:', key: 'nameCompany'},
    {label: 'посада:', key: 'position'},
    {label: 'перелік обов’язків:', key: 'listResponsibilities'},
    {label: 'період роботи:', key: 'workingPeriod'}]
export const additionalInfoDetails = [
    {label: 'хобі:', key: 'hobby'},
    {label: 'водійські права:', key: 'driverLicense'},]
export const otherInfoDetails = [
    {
        label: 'проєкт, який би хотілось втілити в наступні 10 років:',
        key: 'projectInNext10Years'
    },
    {
        label: 'причина звільнення з попереднього місця роботи:',
        key: 'reasonDismissalFromPreviousJob'
    },
    {
        label: 'що найбільше дратувало у попередньому керівництві:',
        key: 'whatAnnoyedInPreviousManagement'
    },
    {
        label: 'Якби у вас було настільки багато грошей, що незалежно від того яку суму ви витрачали б на день — їх все одно завжди вистачало б на усе, тобто якби вам взагалі не потрібно було перейматися з приводу матеріальної сторони свого життя, чим би ви тоді займалися:',
        key: 'whatDoIfNotDependOnMoney'
    },
]
export const foreignLanguageDetails = [
    {label: 'мова:', key: 'name'},
    {label: 'рівень володіння:', key: 'levelProficiency'},]
export const socialDetails = [
    {label: 'назва:', key: 'name'},
    {label: 'посилання:', key: 'link'},]

export const enum EHeadersBlock {
    CONTACTDETAILS = 'Контактні дані',
    PERSONALDETAILS = 'Персональні дані',
    EDUCATION = 'Навчання',
    ADDITIONALEDUCATIONS = 'Додаткова освіта (курси)',
    PREVIOUSJOB = 'Попередній досвід роботи',
    FOREIGNLANGUAGE = 'Іноземні мови',
    ADDITIONALINFO = 'Додаткова інформація',
    OTHERQUESTIONS = 'Інші запитання',
    SOCIAL = 'Cоц.мережі/месенджери'
}
