export const optionsCategory = [
    { value: 'Розвиток', text: 'Розвиток',field: 'category' },
    { value: 'Дозвілля', text: 'Дозвілля',field: 'category' },
    { value: 'Повсякденність', text: 'Повсякден.',field: 'category' },
    { value: 'Здоровʼя', text: 'Здоровʼя',field: 'category' },
    { value: 'План 📋', text: 'План 📋',field: 'category' },
]
export const optionsEmotionLevel = [
    { value: '😁 14 - Щастя', name: '😁 Щастя',field:'emotionalLevel' },
    { value: '😃 13 - Радість', name: '😃 Радість',field:'emotionalLevel' },
    { value: '😊 12 - Задоволення', name: '😊 Задовол.',field:'emotionalLevel' },
    { value: '😏 11 - Натхнення', name: '😏 Натхнення',field:'emotionalLevel' },
    { value: '😐 10 - Спокій', name: '😐 Спокій',field:'emotionalLevel' },
    { value: '😬 9 - Хвилювання', name: '😬 Хвилювання',field:'emotionalLevel' },
    { value: '😩 8 - Розчарування', name: '😩 Розчаруван.',field:'emotionalLevel' },
    { value: '😴 7 - Втома', name: '😴 Втома',field:'emotionalLevel' },
    { value: '😖 6 - Сором', name: '😖 Сором',field:'emotionalLevel' },
    { value: '😬 5 - Сум', name: '😬 Сум',field:'emotionalLevel' },
    { value: '😱 4 - Страх', name: '😱 Страх',field:'emotionalLevel' },
    { value: '😡 3 - Гнів', name: '😡 Гнів',field:'emotionalLevel' },
    { value: '🤮 2 - Огида', name: '🤮 Огида',field:'emotionalLevel' },
    { value: '😞 1 - Відчай', name: '😞 Відчай',field:'emotionalLevel' },
]
export const optionsEvent = [
    { value: 'Навчання', name: 'Навчання',field:'event' },
    { value: 'Робота', name: 'Робота',field:'event' },
    { value: 'Саморозвиток', name: 'Саморозвиток',field:'event' },
    { value: 'Хобі', name: 'Хобі',field:'event' },
    { value: 'Спорт', name: 'Спорт',field:'event' },
    { value: 'Сімʼя', name: 'Сімʼя',field:'event' },
    { value: 'Культурні заходи', name: 'Культурні заходи',field:'event' },
    { value: 'Догляд за собою', name: 'Догляд за собою',field:'event' },
    { value: 'Дорога', name: 'Дорога',field:'event' },
    { value: 'Сон', name: 'Сон',field:'event' },
    { value: 'Домашні справи', name: 'Домашні справи',field:'event' },
    { value: 'Їжа', name: 'Їжа',field:'event' },
    { value: 'Надзвичайна подія', name: 'Надзвичайна подія',field:'event' },
    { value: 'Лікування', name: 'Лікування',field:'event' },
    { value: 'План 📋', name: 'План 📋',field:'event' },
    { value: 'Думки 💭', name: 'Думки 💭',field:'event' },
]

export const optionsStage = [
    { value: '', text: 'Етап' },
    { value: 'Games', text: 'Games' },
    { value: 'Workshop', text: 'Workshop' },
    { value: 'Factory', text: 'Factory' },

]