import styled from "styled-components";

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5vh;
  border-bottom: 1px solid #52616B;
  & span {
    font-family: Verdana,sans-serif;
    font-weight: 100;
    margin: auto 10px;
    font-size: 1em;
    color: #C9D6DF;
  }

  & div {
    display: flex;
    flex-direction: row;
  }
`

export const SnowBlock = styled.div`
  height: 4.8vh;
  width: 90%;
  position: absolute;
  z-index: -1;
  & div {
    width: 90vw;
  }
`

export const AvatarAndLogOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 4%;
  margin-right: 1%;
  background: none;
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  & svg {
    cursor: pointer;
    fill: #C9D6DF;
    width: 70%;
    height: 70%;
  }
  
`