import React from "react";
import {Container, QuestionBlock} from "./ReverseQuestion.styled";
import {BtnTab, CloseButton, ListChosenQuestion, Row} from "./CustomMethod.styled";
import {IQuestion} from "./interface";

type Props = {
    setOpenTableReverseQuestions: React.Dispatch<React.SetStateAction<boolean>>
    amountAnswers: number | undefined
    reverseQuestionsList: number[]
    setReverseQuestionsList: React.Dispatch<React.SetStateAction<number[]>>
    setList: React.Dispatch<React.SetStateAction<IQuestion[]>>
    list:IQuestion[]
    reverseQuestionListForUser: number[]
    setReverseQuestionListForUser: React.Dispatch<React.SetStateAction<number[]>>

}

export const ReverseQuestion:React.FC<Props> = ({amountAnswers, reverseQuestionsList, setReverseQuestionsList,setOpenTableReverseQuestions,list,setList,setReverseQuestionListForUser,reverseQuestionListForUser}: Props) => {
    const arrayUsingArrayFrom = amountAnswers ? Array.from({length: amountAnswers}, (_, index: number) => index) : [];
    const handleChange = (question: number) => {
        setReverseQuestionsList((prevArray) => {
            if (reverseQuestionsList.includes(question)) {
                return reverseQuestionsList.filter(elem => question != elem)
            } else {
                return [...prevArray, question]
            }
            }
        )
        if(reverseQuestionListForUser.includes(question+1)){
            setReverseQuestionListForUser(prevState => {
                return [...prevState].filter(elem => question+1 != elem)
            })
        } else {
            setReverseQuestionListForUser(prevState => {
                return [...prevState,question+1]
            })
        }
    }

    const closeTab = () => {
        setList(prevState =>
            prevState.map((question, index) => {
                if (reverseQuestionsList.includes(index)) {
                    const reversedValue = Array.isArray(question.value) ? question.value.slice().reverse() : question.value;
                    return { ...question, value: reversedValue };
                }
                return question;
            })
        );
        setOpenTableReverseQuestions(false);
    };
    return (
        <Container onClick={(e: { stopPropagation: () => void; }) => e.stopPropagation()}>
            <ListChosenQuestion>
                Вибрані відповіді: {reverseQuestionListForUser.join(', ')}
            </ListChosenQuestion>
            <Row>
                {arrayUsingArrayFrom?.map((elem: any, index: number) => (
                    <QuestionBlock
                        onClick={() => handleChange(elem)}
                        active={reverseQuestionsList.includes(elem)}
                        key={index}>
                        {elem+1}
                    </QuestionBlock>
                ))}
            </Row>
            <BtnTab>
                <CloseButton onClick={closeTab}>Готово</CloseButton>
            </BtnTab>
        </Container>
    )
}
