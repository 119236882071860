import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`
export const MainUnitStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContentBlockStyled = styled.div`
  display: flex;
  flex-direction: row;
`
export const ContentTestStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 84.5vh;
    margin: 1vw 1vw 0 1vw;
    border: 1px solid #52616B;
    border-radius: 25px;
    & span {
        margin: 0 0 5px 0;
        font-size: 1.2em;
        color: #C9D6DF;
    }
`

