import React, {useState} from "react";
import {
    Container,
    OptionsList,
    RowTable,
    SelectedOption,
    SelectWrapper,
    Option,
    Table,
    QuestionInRow,
    BlockQuestionAndAnswer,
    BlockAnswers,
    QuestionDetail,
    ContentQuestionWithSelector,
    SettingsBlock,
    SettingsOpen,
    ArrowBlockForSelect,
    NameSelectBlock,
    DetailedDescription,
    OptionItem,
    TypeQuestionBlock,
    OptionsTypeQuestionContainer,
    SvgRemoveStyled,
    BlockRatingStyled,
    BlockChoseRatingStyled,
    VariantRating,
    RatingSelected,
    AnswersNameBlock,
    RateNameBlock,
    SettingsAndDeleteBlock,
    RemoveBlock
} from "./TableAllAnswerAndQuestions.styled";
import {ReactComponent as ArrowDown} from "../../../assets/pictures/ArrowForSelectorOpen.svg";
import {ReactComponent as Settings} from "../../../assets/pictures/Settings.svg";
import {ReactComponent as Remove} from "../../../assets/pictures/Remove.svg";
import {formatTest, Rating, TypeQuestion} from "./constants";
import {ICategory, ISpecification, IQuestion} from "./interface";


type Props = {
    question: IQuestion
    textInput?: string
    onTextChange?: (arg: string) => void
    setList: React.Dispatch<React.SetStateAction<IQuestion[]>>
    index: number
    identicalTypeQuestion: string
    setIdenticalTypeQuestion: React.Dispatch<React.SetStateAction<string>>
    reverseQuestionsList: number[]
    setReverseQuestionsList: React.Dispatch<React.SetStateAction<number[]>>
    specification: ISpecification
    category: ICategory[]
    reverseQuestionListForUser: number[]
    setReverseQuestionListForUser: React.Dispatch<React.SetStateAction<number[]>>
}

export interface OptionsListProps {
    isOpen: boolean;

}

export const TableAllAnswerAndQuestions: React.FC<Props> = ({
                                                                identicalTypeQuestion,
                                                                question,
                                                                setList,
                                                                index,
                                                                setIdenticalTypeQuestion,
                                                                reverseQuestionsList,
                                                                setReverseQuestionsList,
                                                                specification,
                                                                category,
                                                                setReverseQuestionListForUser
                                                            }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenRating, setIsOpenRating] = useState<boolean[]>([])
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [newAnswers, setNewAnswers] = useState<string[]>([]);
    const [settings, setSettings] = useState<boolean[]>([])

    const [showOptions, setShowOptions] = useState(false);

    const handleBlockClick = () => {
        setShowOptions(!showOptions);
    };

    const handleOptionClicks = (option: string, index: number) => {
        setList((prevList) => {
            const updatedList = [...prevList];
            updatedList[index] = {
                ...updatedList[index],
                type: option,
            };
            return updatedList;
        });
        if (specification.format !== formatTest.form) {
            setIdenticalTypeQuestion(option)
        }
        setShowOptions(false);
    }
    const handleOptionClick = (option: string, index: number) => {
        setSelectedOption(option);
        setIsOpen(false);
        setList(prevState => {
            const newList = [...prevState]
            newList[index].category = option
            return newList
        })
    };
    const handleChangeQuestion = (index: number, value: string) => {
        setList((prevList) => {
            if (index < 0 || index >= prevList.length) {
                return prevList;
            }

            const updatedList = [...prevList];
            updatedList[index] = {
                ...updatedList[index],
                question: value,
            };

            return updatedList;
        });
    }
    const handleAnswerChange = (index: number, answerIndex: number, newValue: string) => {
        setList((prevList) => {
            // Перевіряємо, чи індекс не виходить за межі масиву
            if (index < 0 || index >= prevList.length) {
                return prevList;
            }

            const updatedList = [...prevList];
            // @ts-ignore
            const updatedAnswers = [...updatedList[index].answers];

            // Перевіряємо, чи індекс відповіді не виходить за межі масиву
            if (answerIndex >= 0 && answerIndex < updatedAnswers.length) {
                updatedAnswers[answerIndex] = newValue;
                updatedList[index] = {
                    ...updatedList[index],
                    answers: updatedAnswers,
                };
            }

            return updatedList;
        });
    };
    const handleAddAnswer = (index: number) => {
        setList((prevList) => {
            // Перевіряємо, чи індекс не виходить за межі масиву
            if (index < 0 || index >= prevList.length || newAnswers[index] === undefined) {
                return prevList;
            }

            const updatedList = [...prevList];
            // @ts-ignore
            const updatedAnswers = [...updatedList[index].answers, newAnswers[index]];

            updatedList[index] = {
                ...updatedList[index],
                answers: updatedAnswers,
            };


            return updatedList;
        });
        setNewAnswers([])
    };
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            handleAddAnswer(index);
        }
    };
    const handleFocus = (index: number) => {
        handleAddAnswer(index);
    };
    const handleSettings = (index: number) => {
        setSettings(prev => {
            const updatedList = [...prev];
            updatedList[index] = !updatedList[index];
            return updatedList
        })
    }
    const handleRate = (index: number) => {
        setIsOpenRating(prev => {
            const updatedList = [...prev];
            updatedList[index] = !updatedList[index];
            return updatedList
        })
    }
    const handleRemoveAnswer = (index: number, answerIndex: number) => {
        setList((prevList) => {
            if (index < 0 || index >= prevList.length) {
                return prevList;
            }
            const updatedList = [...prevList];
            // @ts-ignore
            const updatedAnswers = [...updatedList[index].answers];
            // @ts-ignore
            const updatedValues = [...updatedList[index].value];

            // Видаляємо відповідь за вказаним індексом
            updatedAnswers.splice(answerIndex, 1);

            // Видаляємо значення з масиву value за вказаним індексом
            updatedValues.splice(answerIndex, 1);

            updatedList[index] = {
                ...updatedList[index],
                answers: updatedAnswers,
                value: updatedValues,
            };

            return updatedList;
        });
    };
    const handleChangeReverseQuestion = (e: any, questionNumber: number) => {
        if (!e.target.checked) {
            const updatedList = reverseQuestionsList.filter((item: number) => item !== questionNumber);
            setReverseQuestionListForUser(prevState => {
                return [...prevState].filter(elem => questionNumber + 1 !== elem)
            })
            setReverseQuestionsList(updatedList);
        } else {
            setReverseQuestionsList(prev => [...prev, questionNumber]);
            setReverseQuestionListForUser(prevState => {
                return [...prevState, questionNumber + 1]
            })
        }
        setList(prevState => {
            return [...prevState].map((question: IQuestion, index: number) => {
                if (index === questionNumber) { // Перевірка індексу
                    // Оновлення потрібного об'єкту
                    return {
                        ...question,
                        // @ts-ignore
                        value: [...question.value].reverse()
                    };
                }
                return question
            })
        });
    }

    const handleChangeValueAnswer = (value: number, index: number, indexAnswer: number) => {
        setList(prevState => {
            return prevState.map((question, indexQuestion) => {
                if (indexQuestion === index) {
                    // @ts-ignore
                    const updatedValue = [...question.value];
                    updatedValue[indexAnswer] = value;
                    return {...question, value: updatedValue};
                }
                return question;
            });
        });
    }

    const removeQuestion = (index: number): void => {
        setList(prevState => {
            const updatedList = [...prevState];
            updatedList.splice(index, 1);
            return updatedList;
        });
    };

    return <Container>
        <Table>
            <RowTable>
                <QuestionDetail>
                    <div>{index + 1}. Запитання</div>
                    <SettingsAndDeleteBlock>
                        {(specification.format !== formatTest.form) &&
                            <SettingsBlock onClick={() => handleSettings(index)}
                                           isActive={settings[index]}>
                                <Settings/>
                                {settings[index] && <SettingsOpen
                                    onMouseLeave={() => setSettings(prevState => [...prevState, settings[index] = false])}>
                                    <div>
                                        Реверсивне
                                        <input type={'checkbox'} checked={reverseQuestionsList.includes(index)}
                                               onChange={e => handleChangeReverseQuestion(e, index)}/>
                                    </div>
                                </SettingsOpen>}
                            </SettingsBlock>}
                        <RemoveBlock onClick={() => removeQuestion(index)}>
                            <Remove/>
                        </RemoveBlock>
                        {/*<DetailRemoveBtn>{EDeleteTitle.TITLE}</DetailRemoveBtn>*/}
                    </SettingsAndDeleteBlock>
                </QuestionDetail>
                <ContentQuestionWithSelector>
                    <QuestionInRow test={specification.format !== formatTest.form} value={question.question}
                                   onChange={(e: {
                                       target: { value: string; };
                                   }) => handleChangeQuestion(index, e.target.value)}/>
                    <div>
                        <TypeQuestionBlock onClick={handleBlockClick}>
                            {(specification.identicalTypeQuestion && TypeQuestion.find((type) => type.value === question.type)?.text[0]) || (question.type && TypeQuestion.find((type) => type.value === question.type)?.text[0]) || 'Тип відповіді'}
                            {(question.type || specification.identicalTypeQuestion) &&
                                <DetailedDescription>{(specification.identicalTypeQuestion && TypeQuestion.find((type) => type.value === identicalTypeQuestion)?.text) || (question.type && TypeQuestion.find((type) => type.value === question.type)?.text) || 'Тип відповіді'}</DetailedDescription>}
                            <ArrowBlockForSelect><ArrowDown/></ArrowBlockForSelect>
                            <OptionsTypeQuestionContainer onMouseLeave={() => setShowOptions(false)}
                                                          showOptions={showOptions}>
                                {TypeQuestion.map((elem: { text: string, value: string }, indexQuestion: number) => (
                                    <OptionItem key={indexQuestion}
                                                onClick={() => handleOptionClicks(elem.value, index)}>{elem.text}</OptionItem>
                                ))}
                            </OptionsTypeQuestionContainer>
                        </TypeQuestionBlock>
                    </div>
                    {(specification.format !== formatTest.form) && <SelectWrapper onMouseLeave={() => setIsOpen(false)}>
                        <SelectedOption onClick={() => setIsOpen(!isOpen)}>
                            <NameSelectBlock>{selectedOption ? selectedOption : 'Категорія'}</NameSelectBlock>
                            <ArrowBlockForSelect><ArrowDown/></ArrowBlockForSelect>
                        </SelectedOption>
                        <OptionsList isOpen={isOpen}>
                            {category?.map((option, indexCategory: number) => (
                                <Option key={indexCategory} onClick={() => handleOptionClick(option.name, index)}>
                                    {option.name}
                                </Option>
                            ))}
                        </OptionsList>
                    </SelectWrapper>}
                </ContentQuestionWithSelector>
                {question.type !== 'text' && <><QuestionDetail>
                    <AnswersNameBlock>Відповіді</AnswersNameBlock>
                    {(specification.format !== formatTest.form) && Array.isArray(question?.answers) && question?.answers?.length >= 1 &&
                        <RateNameBlock
                            width={question?.answers?.length === 1 ? '38vw' : question?.answers?.length > 2 ? '18vw' : '17vw'}>Оцінка</RateNameBlock>}
                    {(specification.format !== formatTest.form) && Array.isArray(question?.answers) && question?.answers?.length >= 2 &&
                        <RateNameBlock justifyContent={'flex-end'} width={'17vw'}
                                       margin={'1.4vw'}>Оцінка</RateNameBlock>}
                </QuestionDetail>
                    <BlockQuestionAndAnswer>
                        <BlockAnswers>
                            {Array.isArray(question.answers) && question.answers?.map((elem: string, answerIndex: number) => (
                                <div>
                                    <input value={elem}
                                           onChange={(e) => handleAnswerChange(index, answerIndex, e.target.value)}/>
                                    {(specification.format !== formatTest.form) &&
                                        <BlockRatingStyled key={answerIndex} onClick={() => handleRate(answerIndex)}>
                                            {/*<NameRatingStyled key={answerIndex}>Oцінка</NameRatingStyled>*/}
                                            <RatingSelected>
                                                {Array.isArray(question.value) && question.value[answerIndex]}
                                                <ArrowBlockForSelect><ArrowDown/></ArrowBlockForSelect>
                                            </RatingSelected>
                                            {isOpenRating[answerIndex] &&
                                                <BlockChoseRatingStyled>
                                                    {Rating.map((rate: number, indexAnswer: number) => (
                                                        <VariantRating
                                                            onClick={() => handleChangeValueAnswer(rate, index, answerIndex)}
                                                            key={indexAnswer}>{rate}</VariantRating>
                                                    ))}
                                                </BlockChoseRatingStyled>}
                                        </BlockRatingStyled>}
                                    <SvgRemoveStyled onClick={() => handleRemoveAnswer(index, answerIndex)}>
                                        <Remove/>
                                    </SvgRemoveStyled>

                                </div>
                            ))}
                            <div>
                                <input
                                    type="text"
                                    value={newAnswers[index] || ''}
                                    onChange={(e) => {
                                        const updatedNewAnswers = [...newAnswers];
                                        updatedNewAnswers[index] = e.target.value;
                                        setNewAnswers(updatedNewAnswers);
                                    }}
                                    onKeyDown={(e) => handleKeyPress(e, index)}
                                    onBlur={() => handleFocus(index)}
                                    placeholder="Нова відповідь"
                                />
                            </div>
                        </BlockAnswers>
                    </BlockQuestionAndAnswer></>}
            </RowTable>
        </Table>
    </Container>
}