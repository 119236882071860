import React from "react";
import {Route, Routes} from "react-router-dom";
import {path} from "../../utils/path";
import {Landing} from "../pages/landing/Landing";
import {HomeAdmin} from "../pages/homeAdmin/HomeAdmin";
import {Profile} from "../pages/profile/Profile";
import {Login} from "../pages/authorization/Login";
import {Registration} from "../pages/authorization/Registration";
import {EmployeeCard} from "../pages/EmployeeCard/EmployeeCard";
import {Journal} from "../pages/journal/Journal";
import {TestsEvaluation} from "../pages/testsEvaluation/TestsEvaluation";
import {UserHistory} from "../pages/userHistory/UserHistory";
import {UserStatistics} from "../pages/userStatistics/UserStatistics";
import {Favourite} from "../pages/favourite/Favourite";
import {Statistics} from "../pages/statistics/Statistics";
import {Testing} from "../pages/testing/Testing";
import {ProtectedRoute} from "./ProtectedRoute";
import {AdminRoute} from "./ProtectedAdminRoute";

export const Router: React.FC = () => {

    return <Routes>
        <Route path={path.home} element={<Landing/>}/>
        <Route path={path.login} element={<Login/>}/>
        <Route path={path.registration} element={<Registration/>}/>
        {/*<Route element={<ProtectedRoute/>}>*/}
            {/*<Route element={<AdminRoute/>}>*/}
                <Route path={path.admin} element={<HomeAdmin/>}/>
                <Route path={path.profile} element={<Profile/>}/>
                <Route path={path.adminPage} element={<EmployeeCard/>}/>
                <Route path={path.journal} element={<Journal/>}/>
                <Route path={path.userStatistics} element={<UserStatistics/>}/>
                <Route path={path.favourite} element={<Favourite/>}/>
                <Route path={path.statistics} element={<Statistics/>}/>
            {/*</Route>*/}
            <Route path={path.testsEvaluation} element={<TestsEvaluation/>}/>
            <Route path={path.userHistory} element={<UserHistory/>}/>
            <Route path={path.knowledgeTesting} element={<Testing/>}/>
            <Route path="*" element={<Landing/>}/>
        {/*</Route>*/}
    </Routes>
}