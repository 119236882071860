import {Container, RowInTable, TableStyled} from "./Table.styled";
import React, {useState} from "react";
import {useLazyPersonalInfoAboutUserQuery} from "../../../store/api/cardsApi";

type Props = {
    data?: Array<object>
    setUsers: React.Dispatch<React.SetStateAction<any>>
}

export const Table = ({data, setUsers}: Props) => {
    const header = ['Етап', 'Роль', 'Імʼя', 'Остання подія', 'Час події', 'Локація', 'Непрочитані', 'Емоція']
    const [fetchRepos, {data: info}] = useLazyPersonalInfoAboutUserQuery()
    const [selected, setSelected] = useState('')
    function getInfo(arg: string) {
        setSelected(arg)
        fetchRepos({employeeId: arg}).then((res) => {
            setUsers(res.data.user)
        })
    }

    return (
        <Container>
            <TableStyled>
                <thead>
                <RowInTable>
                    {header.map((item: string, index: number) => (
                        <td key={index}>{item}</td>
                    ))}
                </RowInTable>
                </thead>
                <tbody>
                {data && data.map((elem: any, index: number) => (
                    <RowInTable select={selected === elem.employeeId} key={index}
                                onClick={() => getInfo(elem.employeeId)}>
                        <td>{elem.stage}</td>
                        <td>{elem.role}</td>
                        <td>{elem.name}</td>
                        <td>{elem.event}</td>
                        <td>{new Date(elem.eventStartTime).toLocaleTimeString() + ' - ' + new Date(elem.eventEndTime).toLocaleTimeString()}</td>
                        <td>{elem.location}<span>{elem.location}</span></td>
                        <td>{elem.numView}</td>
                        <td>{elem.emotionalLevel.replace(/[^[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, '')}</td>
                    </RowInTable>
                ))}

                </tbody>
            </TableStyled>
        </Container>
    )
}