import {ResponsiveBar} from '@nivo/bar'
import React from "react";
import {consistentTimePeriodsForCharts} from "../Selector/contants";
import {LegendsEventForCharts} from "../constants";
import {ContainerChartStyled, CustomTooltipBlockStyled, HiddenChartBlockStyled} from "./BarChart.styled";

const test = [
    {
        "country": "AD",
        "hot dog": 172,
        "hot dogColor": "hsl(298, 70%, 50%)",
        "burger": 25,
        "burgerColor": "hsl(107, 70%, 50%)",
        "sandwich": 158,
        "sandwichColor": "hsl(153, 70%, 50%)",
        "kebab": 141,
        "kebabColor": "hsl(78, 70%, 50%)",
        "fries": 172,
        "friesColor": "hsl(347, 70%, 50%)",
        "donut": 29,
        "donutColor": "hsl(222, 70%, 50%)"
    },
    {
        "country": "AE",
        "hot dog": 178,
        "hot dogColor": "hsl(79, 70%, 50%)",
        "burger": 132,
        "burgerColor": "hsl(312, 70%, 50%)",
        "sandwich": 113,
        "sandwichColor": "hsl(109, 70%, 50%)",
        "kebab": 104,
        "kebabColor": "hsl(260, 70%, 50%)",
        "fries": 16,
        "friesColor": "hsl(63, 70%, 50%)",
        "donut": 62,
        "donutColor": "hsl(31, 70%, 50%)"
    },
    {
        "country": "AF",
        "hot dog": 90,
        "hot dogColor": "hsl(246, 70%, 50%)",
        "burger": 178,
        "burgerColor": "hsl(74, 70%, 50%)",
        "sandwich": 67,
        "sandwichColor": "hsl(316, 70%, 50%)",
        "kebab": 154,
        "kebabColor": "hsl(131, 70%, 50%)",
        "fries": 61,
        "friesColor": "hsl(101, 70%, 50%)",
        "donut": 57,
        "donutColor": "hsl(338, 70%, 50%)"
    },
    {
        "country": "AG",
        "hot dog": 191,
        "hot dogColor": "hsl(176, 70%, 50%)",
        "burger": 198,
        "burgerColor": "hsl(16, 70%, 50%)",
        "sandwich": 140,
        "sandwichColor": "hsl(29, 70%, 50%)",
        "kebab": 108,
        "kebabColor": "hsl(343, 70%, 50%)",
        "fries": 5,
        "friesColor": "hsl(110, 70%, 50%)",
        "donut": 33,
        "donutColor": "hsl(58, 70%, 50%)"
    },
    {
        "country": "AI",
        "hot dog": 66,
        "hot dogColor": "hsl(155, 70%, 50%)",
        "burger": 3,
        "burgerColor": "hsl(95, 70%, 50%)",
        "sandwich": 184,
        "sandwichColor": "hsl(64, 70%, 50%)",
        "kebab": 48,
        "kebabColor": "hsl(330, 70%, 50%)",
        "fries": 139,
        "friesColor": "hsl(49, 70%, 50%)",
        "donut": 126,
        "donutColor": "hsl(322, 70%, 50%)"
    },
    {
        "country": "AL",
        "hot dog": 177,
        "hot dogColor": "hsl(53, 70%, 50%)",
        "burger": 200,
        "burgerColor": "hsl(218, 70%, 50%)",
        "sandwich": 142,
        "sandwichColor": "hsl(3, 70%, 50%)",
        "kebab": 1,
        "kebabColor": "hsl(260, 70%, 50%)",
        "fries": 4,
        "friesColor": "hsl(248, 70%, 50%)",
        "donut": 39,
        "donutColor": "hsl(237, 70%, 50%)"
    },
    {
        "country": "AM",
        "hot dog": 34,
        "hot dogColor": "hsl(232, 70%, 50%)",
        "burger": 37,
        "burgerColor": "hsl(301, 70%, 50%)",
        "sandwich": 29,
        "sandwichColor": "hsl(235, 70%, 50%)",
        "kebab": 6,
        "kebabColor": "hsl(237, 70%, 50%)",
        "fries": 89,
        "friesColor": "hsl(251, 70%, 50%)",
        "donut": 43,
        "donutColor": "hsl(101, 70%, 50%)"
    }
]

const theme = {
    "text": {
        "fontSize": 11,
        "fill": "#333333",
        "outlineWidth": 0,
        "outlineColor": "transparent"
    },
    "axis": {
        "domain": {
            "line": {
                "stroke": "rgba(82, 97, 107, 1)",
                "strokeWidth": 1
            }
        },
        "legend": {
            "text": {
                "fontSize": 16,
                "fill": "#C9D6DF",
                "outlineWidth": 1,
            }
        },
        "ticks": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 0
            },
            "text": {
                "fontSize": 12,
                "fill": "#C9D6DF",
                "outlineWidth": 0,
                "outlineColor": "transparent"
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "rgba(82, 97, 107, 0.5)",
            "strokeWidth": 1
        }
    },
    "legends": {
        "title": {
            "text": {
                "fontSize": 14,
                "fill": "#C9D6DF",
                "outlineWidth": 0,
                "outlineColor": "transparent"
            }
        },
        "text": {
            "fontSize": 20,
            "fill": "#C9D6DF",
            "outlineWidth": 0,
            "outlineColor": "transparent"
        },
        "ticks": {
            "line": {},
            "text": {
                "fontSize": 10,
                "fill": "#C9D6DF",
                "outlineWidth": 0,
                "outlineColor": "transparent"
            }
        }
    },
    "annotations": {
        "text": {
            "fontSize": 13,
            "fill": "#C9D6DF",
            "outlineWidth": 2,
            "outlineColor": "#C9D6DF",
            "outlineOpacity": 1
        },
        "link": {
            "stroke": "#000000",
            "strokeWidth": 1,
            "outlineWidth": 2,
            "outlineColor": "#C9D6DF",
            "outlineOpacity": 1
        },
        "outline": {
            "stroke": "#000000",
            "strokeWidth": 2,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "symbol": {
            "fill": "#000000",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        }
    },
    "tooltip": {
        container: {
            background: "#ffffff",
            fontSize: 12,
            height: 10, // Додайте висоту для контейнера
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    }
}

const themeForY = {
    "text": {
        "fontSize": 11,
        "fill": "#333333",
        "outlineWidth": 0,
        "outlineColor": "transparent"
    },
    "axis": {
        "domain": {
            "line": {
                "stroke": "rgba(82, 97, 107, 0)",
                "strokeWidth": 1
            }
        },
        "legend": {
            "text": {
                "fontSize": 16,
                "fill": "",
                "outlineWidth": 1,
            }
        },
        "ticks": {
            "line": {
                "stroke": "",
                'fontSize': 0,
                "strokeWidth": 0
            },
            "text": {
                "fontSize": 12,
                "fill": "rgb(201, 214, 223)",
                "outlineWidth": 0,
                "outlineColor": "transparent"
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "rgba(82, 97, 107, 0.5)",
            "strokeWidth": 1
        }
    },
    "legends": {
        "title": {
            "text": {
                "fontSize": 14,
                "fill": "",
                "outlineWidth": 0,
                "outlineColor": "transparent"
            }
        },
        "text": {
            "fontSize": 20,
            "fill": "",
            "outlineWidth": 0,
            "outlineColor": "transparent"
        },
        "ticks": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 0 // Встановлюємо товщину лінії позначок на 0, щоб приховати їх
            },
            "text": {
                "fontSize": 10,
                "fill": "",
                "outlineWidth": 0,
                "outlineColor": "transparent"
            }
        }
    },
    "annotations": {
        "text": {
            "fontSize": 13,
            "fill": "",
            "outlineWidth": 2,
            "outlineColor": "",
            "outlineOpacity": 1
        },
        "link": {
            "stroke": "",
            "strokeWidth": 1,
            "outlineWidth": 2,
            "outlineColor": "",
            "outlineOpacity": 1
        },
        "outline": {
            "stroke": "",
            "strokeWidth": 2,
            "outlineWidth": 2,
            "outlineColor": "",
            "outlineOpacity": 1
        },
        "symbol": {
            "fill": "",
            "outlineWidth": 2,
            "outlineColor": "",
            "outlineOpacity": 1
        }
    },
    "tooltip": {
        container: {
            background: "",
            fontSize: 12,
            height: 10, // Додайте висоту для контейнера
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    }
}
// const customColors = ['rgba(227, 197, 101, 0.1)', 'rgba(227, 197, 101, 0.2)', 'rgba(227, 197, 101, 0.3)', 'rgba(227, 197, 101, 0.3)', 'rgba(227, 197, 101, 0.4)', 'rgba(227, 197, 101, 0.5)', 'rgba(227, 197, 101, 0.6)', 'rgba(227, 197, 101, 0.7)', 'rgba(227, 197, 101, 0.8)', 'rgba(227, 197, 101, 0.9)', 'rgba(227, 197, 101, 1)'];
const customColors = [
    'rgba(227, 197, 101, 1)', // Yellow
    'rgba(75, 192, 192, 1)', // Teal
    'rgba(255, 99, 132, 1)', // Red
    'rgba(54, 162, 235, 1)', // Blue
    'rgba(255, 206, 86, 1)', // Light Yellow
    'rgba(153, 102, 255, 1)', // Purple
    'rgba(255, 159, 64, 1)', // Orange
    'rgba(0, 204, 102, 1)', // Green
    'rgba(255, 0, 255, 1)', // Magenta
    'rgba(0, 102, 204, 1)', // Dark Blue
    'rgba(102, 102, 153, 1)' // Greyish Blue
];

interface CustomTooltipProps {
    id: string | number;
    value: number;
}

const colorMap: { [key: string]: string } = {
    'думки 💭': '#e41a1c',
    'лікування': '#377eb8',
    'надзвичайна подія': '#4daf4a',
    'їжа': '#984ea3',
    'домашні справи': '#ff7f00',
    'догляд за собою': '#ffff33',
    'культурні заходи': '#a65628',
    'спорт': '#f781bf',
    'хобі': '#999999',
    'cаморозвиток': '#66c2a5',
    'дорога': '#fc8d62',
    'робота': '#8da0cb',
    'навчання': '#e78ac3',
    'сон': '#a6d854',
    'думки': '#ffd92f',
    'дозвілля': '#e5c494',
    "здоров'я": '#b3b3b3',
    'повсякденність': '#1b9e77',
    'розвиток': '#d95f02',
    'план 📋': '#7570b3',
    'відпочинок': '#e7298a'
};


const CustomTooltip: React.FC<CustomTooltipProps> = ({id, value,}) => (
    <CustomTooltipBlockStyled>
        <strong>{id}</strong>: {value}
    </CustomTooltipBlockStyled>
);


export const BarChart = ({data, max, graph, intervalMarkGraph /* see data tab */}: any) => {
    const columnWidth = intervalMarkGraph === consistentTimePeriodsForCharts[1] ? 50 : 100; // Ширина одного стовпця в пікселях
    const chartWidth = data.length * columnWidth; // Загальна ширина графіка
    return (
        <ContainerChartStyled graph={graph} chartWidth={chartWidth}>
            {graph ? <HiddenChartBlockStyled>
                    <ResponsiveBar
                        theme={themeForY}
                        data={data}
                        keys={[
                            'думки 💭',
                            'лікування',
                            'надзвичайна подія',
                            'їжа',
                            'домашні справи',
                            'догляд за собою',
                            'культурні заходи',
                            'спорт',
                            'хобі',
                            'cаморозвиток',
                            'дорога',
                            'робота',
                            'навчання',
                            'сон',
                            'думки',
                            'дозвілля',
                            "здоров'я",
                            'повсякденність',
                            'розвиток',
                            'план 📋',
                            'відпочинок'
                        ]}
                        indexBy="timePeriod"
                        margin={{top: 13, right: 0, bottom: 30, left: 25}}
                        padding={0.2}
                        maxValue={max}
                        // valueScale={{type: 'linear'}}
                        // indexScale={{type: 'band', round: true}}
                        colors={{scheme: 'yellow_orange_brown'}}
                        // labelSkipWidth={12}
                        // labelSkipHeight={12}
                        // defs={[
                        //     {
                        //         id: 'dots',
                        //         type: 'patternDots',
                        //         background: 'inherit',
                        //         color: '#38bcb2',
                        //         size: 4,
                        //         padding: 1,
                        //         stagger: true
                        //     },
                        //     {
                        //         id: 'lines',
                        //         type: 'patternLines',
                        //         background: 'inherit',
                        //         color: '#eed312',
                        //         rotation: -45,
                        //         lineWidth: 6,
                        //         spacing: 10
                        //     }
                        // ]}
                        // fill={[
                        //     {
                        //         match: {
                        //             id: 'fries'
                        //         },
                        //         id: 'dots'
                        //     },
                        //     {
                        //         match: {
                        //             id: 'sandwich'
                        //         },
                        //         id: 'lines'
                        //     }
                        // ]}
                        // borderColor={{
                        //     from: 'color',
                        //     modifiers: [
                        //         [
                        //             'darker',
                        //             1
                        //         ]
                        //     ]
                        // }}
                        // axisTop={null}
                        // axisRight={null}
                        // tooltip={({id, value, color}) => <CustomTooltip id={id} value={value} color={color}/>}
                        axisBottom={null}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: (e) => (Math.floor(e) === e ? e : "")
                        }}

                        // labelTextColor={{
                        //     from: 'color',
                        //     modifiers: [
                        //         [
                        //             'darker',
                        //             3
                        //         ]
                        //     ]
                        // }}
                        // legends={[
                        //     {
                        //         dataFrom: 'keys',
                        //         anchor: 'bottom-right',
                        //         direction: 'column',
                        //         justify: false,
                        //         translateX: 120,
                        //         translateY: 0,
                        //         itemWidth: 100,
                        //         itemHeight: 20,
                        //         itemsSpacing: 2,
                        //         symbolSize: 20,
                        //         itemDirection: 'left-to-right',
                        //         effects: [
                        //             {
                        //                 on: 'hover',
                        //                 style: {
                        //                     itemOpacity: 1
                        //                 }
                        //             }
                        //         ]
                        //     }
                        // ]}
                        // role="application"
                        // ariaLabel="Nivo bar areaBumpChart demo"
                        // barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
                    />
                </HiddenChartBlockStyled> :
                <ResponsiveBar
                    theme={theme}
                    data={data}
                    keys={[
                        'Думки 💭',
                        'Лікування',
                        'Надзвичайна подія',
                        'Їжа',
                        'Домашні справи',
                        'Догляд за собою',
                        'Культурні заходи',
                        'Спорт',
                        'Хобі',
                        'Саморозвиток',
                        'Дорога',
                        'Робота',
                        'Навчання',
                        'Сон',
                        'Думки',
                        'Дозвілля',
                        "Здоров'я",
                        'Повсякденність',
                        'Розвиток',
                        'План 📋',
                        'Відпочинок'
                    ]}
                    indexBy="timePeriod"
                    margin={{top: 13, right: 0, bottom: 30, left: 35}}
                    padding={0.2}
                    maxValue={max}
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={({id}) => LegendsEventForCharts[id as string] || '#000'}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'fries'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'sandwich'
                            },
                            id: 'lines'
                        }
                    ]}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1
                            ]
                        ]
                    }}
                    axisTop={{
                        tickSize: 0,
                        tickPadding: 100,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 40,
                    }}
                    tooltip={({id, value,}) => <CustomTooltip id={id} value={value}/>}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: 32,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        format: (e) => (Math.floor(e) === e ? '' : "")
                    }}
                    axisRight={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 40
                    }}

                    labelTextColor={({data}) => LegendsEventForCharts[data.id as string] === 'rgba(197,206,225,1)' ? 'black' : 'white'}
                    enableGridX={true} // Включаємо вертикальні лінії сітки
                    enableGridY={true} // Включаємо горизонтальні лінії сітки
                    // legends={[
                    //     {
                    //         dataFrom: 'keys',
                    //         anchor: 'bottom-right',
                    //         direction: 'column',
                    //         justify: false,
                    //         translateX: 120,
                    //         translateY: 0,
                    //         itemWidth: 100,
                    //         itemHeight: 20,
                    //         itemsSpacing: 2,
                    //         symbolSize: 20,
                    //         itemDirection: 'left-to-right',
                    //         effects: [
                    //             {
                    //                 on: 'hover',
                    //                 style: {
                    //                     itemOpacity: 1
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // ]}
                    role="application"
                    ariaLabel="Nivo bar chart demo"
                    barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
                />
            }
        </ContainerChartStyled>
    )
}