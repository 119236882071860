import React, {FC, useEffect, useState} from "react";
import {ReactComponent as Avatar} from '../../assets/pictures/Avatar.svg'
import {ReactComponent as Journal} from '../../assets/pictures/Journal.svg'
import {ReactComponent as ChartBtn} from '../../assets/pictures/ChartBtn.svg'
import {ReactComponent as History} from '../../assets/pictures/HistoryUser.svg'
import {Btn} from "../EmployeeCard/ProfileInformation/Btn";
import {
    BlockAvatarStyled,
    BlockBtnWithAvatar, BtnAddPhoto, BtnDeletePhoto,
    Container,
    ContentBlockStyled, ContentTestStyled, DetailInformation,
    MainUnitStyled,
    ProfileInformationBlock
} from "./Profile.styled";
import {InfoAboutUser} from "../EmployeeCard/ProfileInformation/ProfileInformation.styled";
import {EGeneralProfileInformation} from "../EmployeeCard/ProfileInformation/constants";
// import {imgDB,txtDB} from "./yellowDuckFireBase.config";
// import {ref,uploadBytes,getDownloadURL} from 'firebase/storage'
// import {addDoc,collection,getDocs} from 'firebase/firestore'
import {useGetUserQuery} from "../../store/api/authApi";
import {
    useDeleteAvatarMutation,
    useGetAvatarQuery,
    useLazyGetAvatarQuery,
    useUploadAvatarMutation
} from "../../store/api/fireBaseApi";
import {ToastContainer} from "react-toastify";

export const Profile: FC = () => {
    const {data} = useGetUserQuery('');
    const [getAvatar] = useLazyGetAvatarQuery()
    const [uploadAvatar] = useUploadAvatarMutation();
    const [deleteAvatar] = useDeleteAvatarMutation();
    const {data: avatar} = useGetAvatarQuery()
    const [txt, setTxt] = useState('')
    const [img, setImg] = useState(avatar)
    const [test, setTest] = useState<any[]>([])

    function differentTime(arg: Date): string | number {
        const now = new Date()
        const different = Math.ceil((now.getTime() - new Date(arg).getTime()) / (1000 * 3600 * 24))
        if (different > 30) {
            return (
                `${Math.floor(different / 30)} міс. ${different % 30} дн.`
            )
        } else {
            return different
        }
    }

    // const handleUpload = (e:any):void => {
    //     const img = ref(imgDB,`profilesAvatar/${Date.now()}`)
    //     console.log(e.target.files[0])
    //     uploadBytes(img,e.target.files[0]).then(data => {
    //         console.log(data,'img')
    //         getDownloadURL(data?.ref).then(value => {
    //             setImg(value)
    //         })
    //     })
    // }
    // const handleClick = async () => {
    //     const valRef = collection(txtDB,'mindsDataBase')
    //     await addDoc(valRef,{txtVal:txt,imgUrl:img})
    //     alert('Data added successfully')
    // }

    // const getData = async () => {
    //     const valRef = collection(txtDB,'mindsDataBase')
    //     const dataDB = await getDocs(valRef)
    //     const allData = dataDB?.docs?.map(val => ({...val.data(),id:val.id}))
    //     setTest(allData)
    // }

    // useEffect(() => {
    //     getData()
    // }, []);
    const handleClick = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.style.display = 'none';

        fileInput.onchange = async (event: Event) => {
            const input = event.target as HTMLInputElement;
            if (input.files && input.files.length > 0) {
                const file = input.files[0];
                const formData = new FormData();
                formData.append('name', 'avatar'); // Тут ви можете передати додаткові дані, якщо потрібно
                formData.append('image', file);

                try {
                    await uploadAvatar(formData)
                    await getAvatar()
                } catch (error) {
                    console.error('Upload failed:', error);
                }
            }
        };

        fileInput.click();
    };

    const handleDeleteAvatar = async () => {
        try {
            await deleteAvatar()
            await getAvatar()
        } catch (error) {
            console.error('Delete failed:', error);
        }
    }
    return (
        <Container>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <MainUnitStyled>
                <ContentBlockStyled>
                    <ProfileInformationBlock>
                        <BlockBtnWithAvatar>
                            <BlockAvatarStyled>
                                {avatar?.downloadUrl ? <><BtnDeletePhoto onClick={handleDeleteAvatar}>X</BtnDeletePhoto><img src={avatar.downloadUrl} alt={data?.name}/></> :
                                    <BtnAddPhoto onClick={handleClick}>
                                        <div>+</div>
                                    </BtnAddPhoto>}
                                {/*<Avatar/>*/}
                            </BlockAvatarStyled>
                            {/*<Btn active={true} sideMarkingRight={'none'} >*/}
                            {/*    <div></div>*/}
                            {/*</Btn>*/}
                            {/*<Btn sideMarkingRight={'none'}>*/}
                            {/*    <History/>*/}
                            {/*</Btn>*/}
                            {/*<Btn sideMarkingLeft={'none'}>*/}
                            {/*    <Journal/>*/}
                            {/*</Btn>*/}
                            {/*<Btn sideMarkingLeft={'none'}>*/}
                            {/*    <ChartBtn/>*/}
                            {/*</Btn>*/}
                            {/*<Btn sideMarkingRight={'none'}>*/}
                            {/*    <History/>*/}
                            {/*</Btn>*/}
                        </BlockBtnWithAvatar>

                        <DetailInformation>
                            <InfoAboutUser>
                                <div>
                                    {data && <span>{EGeneralProfileInformation.NAME} snuppyD</span>}
                                </div>
                                <div>
                                    {data && <span>{EGeneralProfileInformation.STAGE} Factory</span>}
                                </div>
                                <div>
                                    {data && <span>{EGeneralProfileInformation.DEPARTMENT} Minds School</span>}
                                </div>
                                <div>
                                    {data &&
                                        <span>{EGeneralProfileInformation.DAYINCOMPANY} {differentTime(data.createdAt)}</span>}
                                </div>
                                {/*<input onChange={(e) => setTxt(e.target.value)} />*/}
                                {/*<input type={'file'} onChange={(e) => handleUpload(e)} />*/}
                                {/*<button onClick={handleClick}>qq</button>*/}
                                {/*{test.map(value =>*/}
                                {/*    <div>*/}
                                {/*        <h1>{value.txtVal}</h1>*/}
                                {/*        <img src={value.imgUrl} alt={value.txtVal} height={'300px'} width={'200px'}  />*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </InfoAboutUser>
                        </DetailInformation>

                    </ProfileInformationBlock>
                    <ContentTestStyled></ContentTestStyled>
                </ContentBlockStyled>
            </MainUnitStyled>
        </Container>
    );
}