import {
    BlockCardWithMapStyled,
    Container,
    ContentBlockStyled,
    MainUnitStyled,
    TestBlock,
    TestMapBlock
} from "./HomeAdmin.styled";
import {useAllInfoQuery, } from "../../store/api/globalApi";
import {MarkersMap} from "./map/Maps";
import {Card} from "./card/Card";
import {Table} from "./table/Table";
import {MyResponsiveRadar} from "./charts/MyResponsiveRadar";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {path} from "../../../utils/path";
import {useGetUserQuery} from "../../store/api/authApi";

export const HomeAdmin: React.FC = () => {

    const navigate = useNavigate()
    const [users, setUsers] = useState<any>()
    const {data} = useAllInfoQuery('');
    const {isError} = useGetUserQuery({isAdmin: 'admin'});
    if (isError) {
        navigate(`${path.login}`)
    }
    return (
        <Container>
            <MainUnitStyled>
                <ContentBlockStyled>

                    <BlockCardWithMapStyled>
                        <Card user={users} counterJournal={data?.journal.length}
                              company={data?.company}
                              infoUser={data?.infoForTable}/>
                        <TestMapBlock>
                            <MarkersMap id={String(Math.random())}
                                        coordinates={users ? [{
                                            latitude: 49.229902,
                                            longitude: 28.426015,
                                            userTelegramName: users.fullName
                                        }, {
                                            latitude: 49.229902,
                                            longitude: 28.426015,
                                            userTelegramName: 'q'
                                        }] : [{
                                            latitude: 49.229902,
                                            longitude: 28.426015,
                                            userTelegramName: 'Minds'
                                        }]}/>
                        </TestMapBlock>
                    </BlockCardWithMapStyled>
                    <BlockCardWithMapStyled>
                        <Table setUsers={setUsers} data={data?.infoForTable}/>
                        <TestBlock>
                            <MyResponsiveRadar
                                data={[
                                    {
                                        "taste": "JavaScript",
                                        "оцінка": 100,
                                    },
                                    {
                                        "taste": "React",
                                        "оцінка": 66,
                                    },
                                    {
                                        "taste": "Node.js",
                                        "оцінка": 100,
                                    },
                                    {
                                        "taste": "Mongo",
                                        "оцінка": 54,
                                    },
                                    {
                                        "taste": "Express",
                                        "оцінка": 89,
                                    }
                                ]}
                            />
                        </TestBlock>
                    </BlockCardWithMapStyled>
                </ContentBlockStyled>
            </MainUnitStyled>
        </Container>
    )
}