interface iPath {
    home: string,
    profile:string,
    admin: string
    login: string
    registration: string
    adminPage: string
    userPage: string
    journal: string
    userHistory: string
    userStatistics: string
    testsEvaluation: string
    favourite: string
    statistics: string
    knowledgeTesting: string
}

export const path: iPath = {
    home: '/',
    profile:'/profile',
    admin: '/admin',
    login: '/login',
    registration: '/registration',
    adminPage: '/card',
    userPage: '/userPanel',
    journal: '/journal',
    userHistory:'/userHistory',
    userStatistics: '/userStatistics',
    testsEvaluation: '/testsEvaluation',
    favourite: '/favourite',
    statistics: '/statistics',
    knowledgeTesting: '/tests'
}
