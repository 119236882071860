import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 25px;
  padding: 0 25px 0 0;
`
export const InformationBlockWithoutPhotos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #C9D6DF;
  height: 100%;
  margin-left: 12vw;
  padding: 0 45px;
`
export const PhotoWithBtnBlock = styled.div`
  display: flex;
  flex-direction: column;
  //border: 1px solid white;
  align-items: center;
  height: 35%;
  width: 13vw;
  z-index: 2;
  position: absolute;
  
`

export const PhotoBlock =styled.div`
  width: 100%;
  height: 90%;
  border: 1px solid #C9D6DF;
  background-color: #1E2022;
  & img {
    width: 100%;
    height: 100%;
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }
`

export const BlockButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  height: 10%;
  margin-right: 16px;
  cursor: pointer;
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 20%;
    height: 100%;
    border: 1px solid #C9D6DF;
    border-top: none;
    & svg {
      width: 80%;
      height: 80%;
      fill: #C9D6DF;
      stroke: #C9D6DF;
    }
    
  }

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 20%;
    height: 100%;
    border: 1px solid #C9D6DF;
    border-top: none;
    & svg {
      width: 80%;
      height: 80%;
      fill: #C9D6DF;
      stroke: #C9D6DF;
    }
  }
`

export const InfoBlockStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: wheat;
  width: 25vw;
  height: 80%;
  & div {
    display: flex;
    flex-direction: column;
    color: #C9D6DF;
    font-size: 14px;

    & span {
      color: #C9D6DF;
      margin: 7px 0;
      font-family: Verdana, sans-serif;
    }
  }
`

export const ChartBlockStyled = styled.div`
  width: 35%;
  height: 100%;
  
`