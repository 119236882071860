import {IDataForLineChart, IEventForLineChart} from "../computations/interface";
import {consistentTimePeriodsForCharts} from "../Selector/contants";

function getLastDayOfWeek(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    const day = date.getDay(); // День тижня (0 для неділі, 1 для понеділка, ...)
    const diff = 6 - (day === 0 ? 6 : day - 1); // Різниця до наступної неділі
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff);
}

function getFirstDayOfNextWeek(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    const day = date.getDay(); // День тижня (0 для неділі, 1 для понеділка, ...)
    const diff = 7 - (day === 0 ? 6 : day - 1); // Різниця до наступного понеділка
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff);
}

function getLastDayOfPreviousWeek(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    const day = date.getDay(); // День тижня (0 для неділі, 1 для понеділка, ...)
    const diff = day + (day === 0 ? 7 : 0); // Різниця до попередньої неділі
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - diff);
}

function getFirstDayOfWeek(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    const day = date.getDay(); // День тижня (0 для неділі, 1 для понеділка, ...)
    const diff = (day === 0 ? -6 : 1 - day); // Різниця до поточного понеділка
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff);
}

function getLastDayOfMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function getFirstDayOfNextMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

function getLastDayOfPreviousMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth(), 0);
}

function getFirstDayOfMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth(), 1);
}


const calculatePeriod = (start: Date, end: Date, periodLabel: string, data: IEventForLineChart[], result: IDataForLineChart[]) => {
    let sumEmotionalLevel = 0;
    let eventCount = 0;

    data.forEach(event => {
        const eventDate = new Date(event.eventStartTime);
        if (eventDate >= start && eventDate <= end) {
            const emotionalLevelMatch = event.emotionalLevel.match(/(\d+)/);
            const emotionalLevel = emotionalLevelMatch ? parseInt(emotionalLevelMatch[0], 10) : 0;

            sumEmotionalLevel += emotionalLevel;
            eventCount++;
        }
    });

    const resultObject = {x: periodLabel, y: eventCount > 0 ? Math.round(sumEmotionalLevel / eventCount) : 0};
    result.push(resultObject);
};

export function computationsDataForLineChart(data: IEventForLineChart[], startChosenTimePeriod: Date | null, endChosenTimePeriod: Date | null, periodType: string) {
    const startDate = startChosenTimePeriod ? new Date(startChosenTimePeriod) : new Date();
    const endDate = endChosenTimePeriod ? new Date(endChosenTimePeriod) : startChosenTimePeriod ? new Date(startChosenTimePeriod) : new Date();
    const result: IDataForLineChart[] = [];

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);


    if (periodType === consistentTimePeriodsForCharts[0]) {
        for (let hour = new Date(startDate); hour <= endDate; hour.setHours(hour.getHours() + 1)) {
            const startOfHour = new Date(hour);
            const endOfHour = new Date(hour);
            endOfHour.setHours(hour.getHours() + 1, 0, 0, -1);
            const periodLabel = `${('0' + startOfHour.getDate()).slice(-2)}.${('0' + (startOfHour.getMonth() + 1)).slice(-2)} ${('0' + startOfHour.getHours()).slice(-2)}:00`;
            calculatePeriod(startOfHour, endOfHour, periodLabel, data, result);
        }
    }
    if (periodType === consistentTimePeriodsForCharts[1]) {
        for (let day = new Date(startDate); day <= endDate; day.setDate(day.getDate() + 1)) {
            const startOfDay = new Date(day);
            const endOfDay = new Date(day);
            endOfDay.setHours(23, 59, 59, 999);
            const periodLabel = `${('0' + startOfDay.getDate()).slice(-2)}.${('0' + (startOfDay.getMonth() + 1)).slice(-2)}`;
            calculatePeriod(startOfDay, endOfDay, periodLabel, data, result);
        }
    }
    if (periodType === consistentTimePeriodsForCharts[2]) {
        const end = getLastDayOfWeek(startDate)
        const periodLabel = `${('0' + startDate.getDate()).slice(-2)}.${('0' + (startDate.getMonth() + 1)).slice(-2)} - ${('0' + end.getDate()).slice(-2)}.${('0' + (end.getMonth() + 1)).slice(-2)}`;
        calculatePeriod(startDate, end, periodLabel, data, result);
        for (let weekStart = new Date(getFirstDayOfNextWeek(startDate)); weekStart <= getLastDayOfPreviousWeek(endDate); weekStart.setDate(weekStart.getDate() + 7)) {
            const weekEnd = new Date(weekStart);
            weekEnd.setDate(weekEnd.getDate() + 6);
            weekEnd.setHours(23, 59, 59, 999);
            const periodLabel = `${('0' + weekStart.getDate()).slice(-2)}.${('0' + (weekStart.getMonth() + 1)).slice(-2)} - ${('0' + weekEnd.getDate()).slice(-2)}.${('0' + (weekEnd.getMonth() + 1)).slice(-2)}`;
            calculatePeriod(weekStart, weekEnd, periodLabel, data, result);
        }
        const start = getFirstDayOfWeek(endDate)
        const secondPeriodLabel = `${('0' + start.getDate()).slice(-2)}.${('0' + (start.getMonth() + 1)).slice(-2)} - ${('0' + endDate.getDate()).slice(-2)}.${('0' + (endDate.getMonth() + 1)).slice(-2)}`;
        calculatePeriod(start, endDate, secondPeriodLabel, data, result);
    }
    if (periodType === consistentTimePeriodsForCharts[3]) {
        const end = getLastDayOfMonth(startDate)
        const periodLabel = `${('0' + startDate.getDate()).slice(-2)}.${('0' + (startDate.getMonth() + 1)).slice(-2)} - ${('0' + end.getDate()).slice(-2)}.${('0' + (end.getMonth() + 1)).slice(-2)}`;
        calculatePeriod(startDate, end, periodLabel, data, result);
        for (let monthStart = new Date(getFirstDayOfNextMonth(startDate)); monthStart <= getLastDayOfPreviousMonth(endDate); monthStart.setMonth(monthStart.getMonth() + 1)) {
            const monthEnd = new Date(monthStart.getFullYear(), monthStart.getMonth() + 1, 0, 23, 59, 59, 999);
            const periodLabel = `${('0' + monthStart.getDate()).slice(-2)}.${('0' + (monthStart.getMonth() + 1)).slice(-2)} - ${('0' + monthEnd.getDate()).slice(-2)}.${('0' + (monthEnd.getMonth() + 1)).slice(-2)}`;
            calculatePeriod(monthStart, monthEnd, periodLabel, data, result);
        }
        const start = getFirstDayOfMonth(endDate)
        const secondPeriodLabel = `${('0' + start.getDate()).slice(-2)}.${('0' + (start.getMonth() + 1)).slice(-2)} - ${('0' + endDate.getDate()).slice(-2)}.${('0' + (endDate.getMonth() + 1)).slice(-2)}`;
        calculatePeriod(start, endDate, secondPeriodLabel, data, result);
    }
    if (periodType === consistentTimePeriodsForCharts[4]) {
        const end = getLastDayOfMonth(startDate)
        const periodLabel = `${('0' + startDate.getDate()).slice(-2)}.${('0' + (startDate.getMonth() + 1)).slice(-2)} - ${('0' + end.getDate()).slice(-2)}.${('0' + (end.getMonth() + 1)).slice(-2)}`;
        calculatePeriod(startDate, end, periodLabel, data, result);
        for (let quarterStart = new Date(getFirstDayOfNextMonth(startDate)); quarterStart <= getLastDayOfPreviousMonth(endDate); quarterStart.setMonth(quarterStart.getMonth() + 3)) {
            const quarterEnd = new Date(quarterStart.getFullYear(), quarterStart.getMonth() + 3, 0, 23, 59, 59, 999);
            const periodLabel = `${('0' + quarterStart.getDate()).slice(-2)}.${('0' + (quarterStart.getMonth() + 1)).slice(-2)} - ${('0' + quarterEnd.getDate()).slice(-2)}.${('0' + (quarterEnd.getMonth() + 1)).slice(-2)}`;
            calculatePeriod(quarterStart, quarterEnd, periodLabel, data, result);
        }
        const start = getFirstDayOfMonth(endDate)
        const secondPeriodLabel = `${('0' + start.getDate()).slice(-2)}.${('0' + (start.getMonth() + 1)).slice(-2)} - ${('0' + endDate.getDate()).slice(-2)}.${('0' + (endDate.getMonth() + 1)).slice(-2)}`;
        calculatePeriod(start, endDate, secondPeriodLabel, data, result);
    }

    return result
}
