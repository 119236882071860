import React, { Dispatch, FC, SetStateAction, useState } from "react";
import {
    BlockListOption,
    Container,
    InputContainer,
    QuestionBlock,
    StyledInput
} from "./Education.styled";
import {
    AddButton,
    CategoryEducationalInstitution,
    educationFields,
    EducationLevel,
    EQuestionCategoryVNZ,
    EQuestionEducationLevel,
    EQuestionReginVNZ,
    EQuestionSpecialty,
    EQuestionVNZ,
    EQuestionYearsOfStudy,
    RegionCode,
} from "../constants";
import { ButtonAddBlock } from "../Acquaintance.styled";
import DatePicker from "react-datepicker";
import { DatePickerStatsWrapper } from "../questionsType/Questions.styled";
import {useLazyGetUniversityListQuery} from "../../../store/api/testsApi";

interface Props {
    setState: any;
    visibleButton: boolean;
    field: string;
}

export const Education: FC<Props> = ({ setState, visibleButton, field }) => {
    const [listQuestions, setListQuestions] = useState(1);
    const [text, setText] = useState<string[]>([]);
    const [specialty, setSpecialty] = useState<string[]>([]);
    const [date, setDate] = useState<[Date | null, Date | null][]>([[null, null]]);
    const [category, setCategory] = useState<{ name: string, value?: string }[]>([{name: '', value: ''}]);
    const [region, setRegion] = useState<{ name: string, value?: string }[]>([{name: '', value: ''}]);
    const [university, setUniversity] = useState<{ name: string, value?: string }[]>([{name: '', value: ''}]);
    const [data, setData] = useState<any>([]);
    const [educationLevelList, setEducationLevelList] = useState<boolean[]>(Array(listQuestions).fill(false));
    const [categoryOptions, setCategoryOptions] = useState<boolean[]>(Array(listQuestions).fill(false));
    const [regionOptions, setRegionOptions] = useState<boolean[]>(Array(listQuestions).fill(false));
    const [universityOptions, setUniversityOptions] = useState<boolean[]>(Array(listQuestions).fill(false));
    const [fetch] = useLazyGetUniversityListQuery();

    const conditionOpenList = (
        condition: boolean,
        index: number,
        list: boolean[],
        setStateList: Dispatch<SetStateAction<boolean[]>>
    ) => {
        const newOpenLists = [...list];
        newOpenLists[index] = condition;
        setStateList(newOpenLists);
    };

    const handleFilterChange = (
        field: 'name' | 'value',
        value: string,
        index: number,
        stateForUpdate: { name: string, value?: string }[],
        setStateForUpdate: Dispatch<SetStateAction<{ name: string, value?: string }[]>>
    ): void => {
        const newOpenLists = [...stateForUpdate];
        newOpenLists[index] = {
            ...newOpenLists[index],
            [field]: value
        };

        setStateForUpdate(newOpenLists);
    };

    const handleChangeParam = (
        name: string,
        value: string,
        index: number,
        stateForUpdate: { name: string, value?: string }[],
        setStateForUpdate: Dispatch<SetStateAction<{ name: string, value?: string }[]>>
    ) => {
        setStateForUpdate(() => {
            const newOpenLists = [...stateForUpdate];
            newOpenLists[index] = {
                ...newOpenLists[index],
                name: name,
                value: value
            };
            return newOpenLists;
        });
    };

    const getUniversity = (index: number) => {
        // @ts-ignore
        fetch({ut: category[index].value, lc: region[index]?.value, exp: "json"}).then((res) => {
            if (res?.data) {
                setData(res.data);
            }
        });
    };

    const handleChangeEducationLevel = (
        indexField: number,
        value: string,
        indexEducationFields: number
    ) => {
        setState((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item: { field: string }) => item.field === field);
            if (itemsWithField[indexField]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[indexField]);
                updatedItems[globalIndex] = {
                    ...updatedItems[globalIndex],
                    [educationFields[indexEducationFields]]: value
                };

                return updatedItems;
            } else {
                return [...prevItems, {field, [educationFields[indexEducationFields]]: value}];
            }
        });
    };

    const handleChangeYearsStudy = (
        indexField: number,
        dates: [Date | null, Date | null],
        indexEducationFields: number
    ) => {
        const [start, end] = dates;

        setDate(prevState => {
            const newArr = [...prevState];
            newArr[indexField] = dates;
            return newArr;
        });

        setState((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item: { field: string }) => item.field === field);
            if (itemsWithField[indexField]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[indexField]);
                updatedItems[globalIndex] = {
                    ...updatedItems[globalIndex],
                    [educationFields[indexEducationFields]]: [start, end]
                };

                return updatedItems;
            } else {
                return [...prevItems, {field, [educationFields[indexEducationFields]]: [start, end]}];
            }
        });
    };

    const addBlock = () => {
        setListQuestions(listQuestions + 1);
        setCategory(prevState => {
            return [...prevState, {name: '', value: ''}];
        });
        setRegion(prevState => {
            return [...prevState, {name: '', value: ''}];
        });
        setUniversity(prevState => {
            return [...prevState, {name: '', value: ''}];
        });
        setDate(prevState => {
            return [...prevState, [null, null]];
        });
    };

    return (
        <>
            {Array.from({length: listQuestions}, (_, indexBlock) => {
                return (
                    <Container key={indexBlock}>
                        <QuestionBlock>
                            <div>{EQuestionEducationLevel}</div>
                            <InputContainer>
                                <StyledInput
                                    type='text'
                                    onFocus={() => conditionOpenList(true, indexBlock, educationLevelList, setEducationLevelList)}
                                    value={text[indexBlock]}
                                    required={true}
                                />
                                <BlockListOption show={educationLevelList[indexBlock]}
                                                 onMouseLeave={() => conditionOpenList(false, indexBlock, educationLevelList, setEducationLevelList)}>
                                    {EducationLevel.map((elem, index) => (
                                        <div key={index} onClick={() => {
                                            conditionOpenList(false, indexBlock, educationLevelList, setEducationLevelList);
                                            setText(prevState => {
                                                const newArr = [...prevState];
                                                newArr[indexBlock] = elem;
                                                return newArr;
                                            });
                                            handleChangeEducationLevel(indexBlock, elem, 0);
                                        }}>{elem}</div>
                                    ))}
                                </BlockListOption>
                            </InputContainer>
                        </QuestionBlock>
                        <QuestionBlock disabledOpacity={text[indexBlock] === EducationLevel[0]}>
                            <div>{EQuestionCategoryVNZ}</div>
                            <InputContainer>
                                <StyledInput
                                    type='text'
                                    onFocus={() => conditionOpenList(true, indexBlock, categoryOptions, setCategoryOptions)}
                                    value={category[indexBlock]?.name}
                                    required={text[indexBlock] !== EducationLevel[0]}
                                    disabled={text[indexBlock] === EducationLevel[0]}
                                    onChange={(e) => handleFilterChange('name', e.target.value, indexBlock, category, setCategory)}
                                />
                                <BlockListOption show={categoryOptions[indexBlock]}
                                                 onMouseLeave={() => conditionOpenList(false, indexBlock, categoryOptions, setCategoryOptions)}>
                                    {CategoryEducationalInstitution.filter(elem => elem.name.toLowerCase().includes(category[indexBlock].name.toLowerCase())).map((elem, index) => (
                                        <div key={index} onClick={() => {
                                            handleChangeParam(elem.name, elem.value, indexBlock, category, setCategory);
                                            conditionOpenList(false, indexBlock, categoryOptions, setCategoryOptions);
                                        }}>{elem.name}</div>
                                    ))}
                                </BlockListOption>
                            </InputContainer>
                        </QuestionBlock>
                        <QuestionBlock disabledOpacity={text[indexBlock] === EducationLevel[0]}>
                            <div>{EQuestionReginVNZ}</div>
                            <InputContainer>
                                <StyledInput
                                    type='text'
                                    onFocus={() => conditionOpenList(true, indexBlock, regionOptions, setRegionOptions)}
                                    value={region[indexBlock]?.name}
                                    onChange={(e) => handleFilterChange('name', e.target.value, indexBlock, region, setRegion)}
                                    required={text[indexBlock] !== EducationLevel[0]}
                                    disabled={text[indexBlock] === EducationLevel[0]}
                                />
                                <BlockListOption show={regionOptions[indexBlock]}
                                                 onMouseLeave={() => conditionOpenList(false, indexBlock, regionOptions, setRegionOptions)}>
                                    {RegionCode.filter(elem => elem.name.toLowerCase().includes(region[indexBlock].name.toLowerCase())).map((elem, index) => (
                                        <div key={index} onClick={() => {
                                            handleChangeParam(elem.name, elem.value, indexBlock, region, setRegion);
                                            conditionOpenList(false, indexBlock, regionOptions, setRegionOptions);
                                        }}>{elem.name}</div>
                                    ))}
                                </BlockListOption>
                            </InputContainer>
                        </QuestionBlock>
                        <QuestionBlock disabledOpacity={text[indexBlock] === EducationLevel[0]}>
                            <div>{EQuestionVNZ}</div>
                            <InputContainer>
                                <StyledInput
                                    type='text'
                                    onFocus={() => {
                                        getUniversity(indexBlock);
                                        conditionOpenList(true, indexBlock, universityOptions, setUniversityOptions);
                                    }}
                                    value={university[indexBlock]?.name}
                                    onChange={(e) => {
                                        handleFilterChange('name', e.target.value, indexBlock, university, setUniversity);
                                        handleChangeEducationLevel(indexBlock, e.target.value, 2);
                                    }}
                                    required={text[indexBlock] !== EducationLevel[0]}
                                    disabled={text[indexBlock] === EducationLevel[0]}

                                />
                                {data && <BlockListOption show={universityOptions[indexBlock]}
                                                          onMouseLeave={() => conditionOpenList(false, indexBlock, universityOptions, setUniversityOptions)}>
                                    {data?.filter((elem: {
                                        university_name: string;
                                    }) => elem.university_name.toLowerCase().includes(university[indexBlock].name.toLowerCase())).map((elem: {
                                        university_name: string
                                    }, index: number) => (
                                        <div key={index} onClick={() => {
                                            handleFilterChange('name', elem.university_name, indexBlock, university, setUniversity);
                                            handleChangeEducationLevel(indexBlock, elem.university_name, 2);
                                            conditionOpenList(false, indexBlock, universityOptions, setUniversityOptions);
                                        }}>{elem.university_name}</div>
                                    ))}
                                </BlockListOption>}
                            </InputContainer>
                        </QuestionBlock>
                        <QuestionBlock disabledOpacity={text[indexBlock] === EducationLevel[0]}>
                            <div>{EQuestionSpecialty}</div>
                            <InputContainer>
                                <StyledInput
                                    type='text'
                                    value={specialty[indexBlock]}
                                    required={text[indexBlock] !== EducationLevel[0]}
                                    disabled={text[indexBlock] === EducationLevel[0]}
                                    onChange={(e) => {
                                        setSpecialty(prevState => {
                                            const newArr = [...prevState];
                                            newArr[indexBlock] = e.target.value;
                                            return newArr;
                                        });
                                    }}
                                    onBlur={() => handleChangeEducationLevel(indexBlock, specialty[indexBlock], 1)}
                                />
                            </InputContainer>
                        </QuestionBlock>
                        <QuestionBlock>
                            <div>{EQuestionYearsOfStudy}</div>
                            <DatePickerStatsWrapper marginRight={true}>
                                <DatePicker
                                    dateFormat="yyyy"
                                    selected={date[indexBlock][0]}
                                    startDate={date[indexBlock][0]}
                                    maxDate={new Date()}
                                    endDate={date[indexBlock][1]}
                                    onChange={(dates: [Date | null, Date | null]) => {
                                        handleChangeYearsStudy(indexBlock, dates, 3);
                                    }}
                                    selectsRange
                                    showYearPicker
                                    required={text[indexBlock] !== EducationLevel[0]}

                                />
                            </DatePickerStatsWrapper>
                        </QuestionBlock>
                    </Container>
                );
            })}
            {visibleButton && <ButtonAddBlock>
                <div onClick={addBlock}>
                    {AddButton}
                </div>
            </ButtonAddBlock>}
        </>
    );
}