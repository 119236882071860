import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    z-index: 3;
`
export const BlockAllFixedTimes = styled.div`
    display: flex;
    font-family: Verdana, sans-serif;
    border: 1px solid #52616B;
    border-radius: 5px;
    color: rgba(219, 232, 241, 1);
    padding: 1px;
`

export const BlockFixedTimeIntervals = styled.div<{ active: boolean }>`
    display: flex;
    padding: 2px 3px;
    border-radius: 5px;
    background-color: ${props => props.active ? 'rgba(82, 97, 107, 0.3)' : 'transparent'};
    margin: 1px;
    align-items: center;
    cursor: pointer;

    &:hover {
        scale: 1.1;
        background-color: rgba(82, 97, 107, 0.5);
    }
`
export const BlockOptionsTheTimePeriod = styled.div<{ visible: boolean }>`
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    flex-direction: column;
    background-color: rgba(17, 18, 22, 1);
    border: 1px solid #52616B;
    border-radius: 5px;
    position: absolute;
    top: 3vh;
    z-index: 2;
    right: 0;
`
export const BlockDatePicker = styled.div<{ visible: boolean }>`
display: ${({ visible }) => (visible ? 'block' : 'none')};
    position: absolute;
    right: 5vw;
`

export const BlockOption = styled.div`
    display: flex;
    padding: 3px;
    font-family: Verdana, sans-serif;
    color: rgba(219, 232, 241, 1);
    position: relative;
        // &:hover ${BlockDatePicker} {
    //     display: flex;
    //
    // }
`
export const SvgCalendarVariant = styled.div`
    display: flex;
    padding: 2px;
    //margin-left: 2%;
    cursor: pointer;
    position: relative;

    & svg {
        width: 1.5vw;
        height: 2.5vh;
        fill: #64748b;
    }
    // &:hover ${BlockOptionsTheTimePeriod} {
    //     display: flex;
    // }
`



//old version

export const DateSelectorStyled = styled.div<{
    borderRight?: string,
    size?: string,
    borderRadius?: string,
    border?: string,
    margin?: string,
    padding?: string
}>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.size};
    font-family: Verdana, sans-serif;
    font-size: 1em;
    text-align: center;
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    border-right: ${props => props.borderRight ? props.borderRight : '1px solid rgba(227, 197, 101, 1)'};
    border: ${props => props.border};
    border-radius: ${props => props.borderRadius};
    background-color: transparent;
    color: rgba(219, 232, 241, 1);
    cursor: pointer;
`

export const NameSelector = styled.div`
    display: flex;
    justify-content: center;
    width: 80%;
`

export const ArrowBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 10%;

    & svg {
        width: 17px;
        height: 17px;
    }
`
export const BlockList = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 2;
`
export const DatePickerStatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    input {
        width: 155px;
        font-size: 14px;
        background: #ffffff22;
        color: #fff;
        padding: 0 4px;
        background: rgba(255, 255, 255, 0.11);
        border: 0.3px solid rgba(255, 255, 255, 0.1);
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
    }

    .react-datepicker {
        background: #3c434c;
    }

    .react-datepicker__day,
    .react-datepicker__current-month,
    .react-datepicker-time__input,
    .react-datepicker__month-container,
    .react-datepicker__input-time-container,
    .react-datepicker-time__caption,
    .react-datepicker__time-list-item,
    .react-datepicker__time-list {
        background: #3c434c;
        color: #fff;
        border: none;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
    .react-datepicker__week-number--selected,
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__close-icon::after {
        background: rgb(41, 48, 56) !important;
        color: rgb(240, 185, 11) !important;
    }

    .react-datepicker__day--outside-month {
        color: #ffffff40;
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected,
    .react-datepicker-time__input,
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker__header {
        background: rgb(41, 48, 56) !important;
        color: rgb(240, 185, 11) !important;
    }

    .react-datepicker__day-name {
        background: rgb(41, 48, 56) !important;
        color: #fff !important;
    }

    .react-datepicker__time-list-item:hover {
        background: rgb(240, 185, 11) !important;
        color: #000;
    }
`
export const VariantChoose = styled.div<{
    width: string,
    height: string,
    border: string,
    top?: string,
    cursor: string
}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 5px;
    cursor: pointer;
    background-color: rgba(17, 18, 22, 1);
    border: 0.5px solid rgba(82, 97, 107, 1);
    color: rgba(219, 232, 241, 1);
    font-family: Verdana, sans-serif;

    & input {
        appearance: none;
        border: 1px solid transparent;
        outline: none;
        background-color: rgba(17, 18, 22, 1);
        width: ${props => props.width};
        height: ${props => props.height};
        border-radius: 20px;
        margin-right: 5px;
        position: relative;
        margin-top: ${props => props.top};
        color: rgba(82, 97, 107, 1);
        cursor: ${props => props.cursor};

        &::-webkit-calendar-picker-indicator {
            filter: invert(1);
            margin-left: 5px;
        }

        &:not(:checked) {
            border-color: ${props => props.border};
        }

        &:checked {
            border-color: rgba(227, 197, 101, 1);
        }

        &:checked::after {
            content: '✔'; /* Позначка в середині */
            color: rgba(227, 197, 101, 1);
            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`