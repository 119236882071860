import styled from "styled-components";
import PhoneInput from "react-phone-input-2";

export const Container = styled.div<{disabledOpacity?:boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 3px 1px;
    opacity: ${props =>props.disabledOpacity ? 0.3 : 1};
    & div {
        font-size: 14px;
    }
`
export const QuestionBlock = styled.div`
    //width: fit-content;
    width: 50%;
`
export const StyledInput = styled.input`
    width: 38%; /* Встановлюємо ширину input відповідно до контейнера */
    background-color: rgba(82, 97, 107, 0.5);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);
    

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }
`
export const StyledTextarea = styled.textarea`
    width: 38%; /* Встановлюємо ширину input відповідно до контейнера */
    background-color: rgba(82, 97, 107, 0.5);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);
    resize: none;
    max-height: 20vh;
    
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }
`
export const DatePickerStatsWrapper = styled.div<{marginRight?:boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: ${props=>props.marginRight? '2px' : 0};

    input {
        width: 13.25vw;
        background-color: rgba(82, 97, 107, 0.5);
        border: 1px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        padding: 3px;
        color: rgba(219, 232, 241, 1);
        accent-color: rgba(227, 197, 101, 1);

        &::placeholder {
            color: rgba(219, 232, 241, 1);
        }
    }

    .react-datepicker {
        background: #3c434c;
    }

    .react-datepicker__day,
    .react-datepicker__current-month,
    .react-datepicker-time__input,
    .react-datepicker__month-container,
    .react-datepicker__input-time-container,
    .react-datepicker-time__caption,
    .react-datepicker__time-list-item,
    .react-datepicker__time-list {
        background: #3c434c;
        color: #fff;
        border: none;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
    .react-datepicker__week-number--selected,
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__close-icon::after {
        background: rgb(41, 48, 56) !important;
        color: rgb(240, 185, 11) !important;
    }

    .react-datepicker__day--outside-month {
        color: #ffffff40;
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected,
    .react-datepicker-time__input,
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker__header {
        background: rgb(41, 48, 56) !important;
        color: rgb(240, 185, 11) !important;
    }

    .react-datepicker__day-name {
        background: rgb(41, 48, 56) !important;
        color: #fff !important;
    }

    .react-datepicker__time-list-item:hover {
        background: rgb(240, 185, 11) !important;
        color: #000;
    }
`

export const StyledPhoneInput = styled(PhoneInput)`
    .flag-dropdown {
        background-color: rgba(82, 97, 107, 1);
        border: 1px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        
        color: rgba(219, 232, 241, 1);
    }

    .form-control {
        
        background-color: rgba(82, 97, 107, 0.5);
        border: 1px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        color: rgba(219, 232, 241, 1);
        width: 100%;
        &::placeholder {
            color: rgba(219, 232, 241, 1);
        }
    }
`;
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%; /* Set the container width */
    & svg {
        width: 17px;
        height: 17px;
    }
`;
export const BlockListOption = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: absolute;
    top: calc(100% + 5px); /* З'являється під input */
    //left: 0;
    right: 0;
    width: 59%; /* Встановлюємо ширину списку відповідно до контейнера */
    max-height: 40vh;
    background-color: rgba(82, 97, 107, 1);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    z-index: 10;
    overflow: scroll;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }

    & div {
        padding: 2px;
        color: rgba(219, 232, 241, 1);
        cursor: pointer;
        &:hover {
            background-color: rgba(227, 197, 101, 0.5);
        }
    }
`;

export const ContainerRadioInputs = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`
export const ContainerHeaderWithInput = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    & label {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
`

export const RadioInput = styled.input`
    accent-color: rgba(227, 197, 101, 1);
`
export const StyledInputForRadio = styled.input`
    width: 95%; /* Встановлюємо ширину input відповідно до контейнера */
    background-color: rgba(82, 97, 107, 0.5);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);
    margin-left: 2px;

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }
`
export const StyledInputWithDropList = styled.input`
    width: 57%; /* Встановлюємо ширину input відповідно до контейнера */
    background-color: rgba(82, 97, 107, 0.5);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);
    

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }
`
export const ContainerSocial = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 3px 1px;
    & div {
        font-size: 14px;
    }
`
export const BlockSVGWithInput = styled.div<{ marginRight?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 3px;
    margin-top: 3px;
    margin-right: ${props => props.marginRight ? props.marginRight : 0};
    position: relative; /* Set position to relative */
`;
export const StyledInputForSocial = styled.input`
    width: 43%; /* Set input width according to container */
    background-color: rgba(82, 97, 107, 0.5);
    border: 1px solid rgba(82, 97, 107, 1);
    border-left: none;
    border-radius: 0 5px 5px 0;
    padding: 3px;
    color: rgba(219, 232, 241, 1);

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }
`;
export const BlockForSVG = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(82, 97, 107, 0.5);
    border: 1px solid rgba(82, 97, 107, 1);
    border-right: none;
    border-radius: 5px 0 0 5px;
    height: 100%;
    padding: 4px;

    & svg {
        width: 16px;
        height: 16px;
    }
`;

export const BlockListOptionSocial = styled.div<{ show: boolean,indexList:boolean }>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: calc(100% + 5px); /* Appears below the input */
    right: 0;
    width: ${props=>props.indexList ? '52%' : '59%'}; /* Set list width according to container */
    max-height: 40vh;
    background-color: rgba(82, 97, 107, 1);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    z-index: 10;
    overflow: scroll;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }

    & div {
        display: flex;
        flex-direction: row;
        padding: 2px;
        color: rgba(219, 232, 241, 1);
        cursor: pointer;

        & div {
            & svg {
                width: 17px;
                height: 17px;
            }
            &:hover {
                background-color: transparent;
            }
        }
        &:hover {
            background-color: rgba(227, 197, 101, 0.5);
        }
    }
`;

export const ButtonAddInput = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    & div {
      display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        background-color: rgba(82, 97, 107, 0.5);
        border: 1px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        cursor: pointer;
        &:active {
            background-color: rgba(82, 97, 107, 0.1);   
        }
    }
`