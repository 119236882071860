import styled from "styled-components";
import {OptionsListProps} from "./TableAllAnswerAndQuestions";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5%;
`
export const RowTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 10px;
  border: 1px solid rgba(227, 197, 101, 1);
  border-radius: 5px;
  margin-bottom: 15px;
`
export const SelectWrapper = styled.div`
  position: relative;
  width: 20%;
`
export const SelectedOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #52616B;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ArrowBlockForSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  & svg {
    width: 14px;
    height: 14px;
  }
`
export const NameSelectBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  overflow: hidden; /* Ховати вміст, що перевищує розмір блоку */
  text-overflow: ellipsis; /* Додає мітку (...) для відображення, що текст обрізаний */
  white-space: nowrap; /* Заборонити перенесення тексту на новий рядок */
  font-size: 12px;
`
export const OptionsList = styled.div<OptionsListProps>`
  position: absolute;
  left: 0;
  width: 110%;
  background-color: rgba(30, 31, 35, 1);
  border: 1px solid #52616B;
  border-top: none;
  border-radius: 0 0 5px 5px;
  display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
  z-index: 2;
  //overflow: hidden; /* Ховати вміст, що перевищує розмір блоку */
  //text-overflow: ellipsis; /* Додає мітку (...) для відображення, що текст обрізаний */
  //white-space: nowrap; /* Заборонити перенесення тексту на новий рядок */
`;

export const Option = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: rgba(227, 197, 101, 1)
  }
`;
export const QuestionInRow = styled.textarea<{test:boolean}>`
  display: flex;
  align-items: center;
  color: rgba(219, 232, 241, 1);
  border: 1px solid #52616B;
  padding: 3px;
  border-radius: 5px;
  width: ${props=>props.test ? '45%' : '65%'};
  height: 20px;
  background-color: transparent;
  resize: none;
  line-height: 20px
`
export const BlockQuestionAndAnswer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ContentQuestionWithSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  margin: 10px 0;
`
export const QuestionDetail = styled.div<{justifyContent?:string}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${props=>props.justifyContent? props.justifyContent : 'space-between'};
`
export const AnswersNameBlock=styled.div`
    display: flex;
    justify-content: flex-start;
    width: 19vw;
`
export const RateNameBlock=styled.div<{justifyContent?:string,margin?:string,width?:string}>`
    display: flex;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    width: ${props => props.width ? props.width : '34%'};
    margin-right: ${props => props.margin ? props.margin : '0'};
`
export const RemoveBlock = styled.div`
  position: relative;
  cursor: pointer;
  margin: 0 3px;
  &:hover {
    & svg {
      fill: rgba(227, 197, 101, 1)
    }
  }
  & svg {
    width: 19px;
    height: 19px;
    fill: rgba(219, 232, 241, 1);

  }
`
export const DetailRemoveBtn = styled.div`
display: none;
justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 25px;
  z-index: 99;
  font-size: 11px;
  padding: 2px;
  background-color: #52616B;
  width: fit-content;
  white-space: nowrap;
  ${RemoveBlock}:hover & {
    display:block;
  }
`
export const SettingsAndDeleteBlock = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: visible;

  ${RemoveBlock}:hover + ${DetailRemoveBtn} {
    display: block;
  }
`


export const SettingsBlock = styled.div<{ isActive: boolean }>`
  position: relative;
  cursor: pointer;
  margin: 0 3px;
  &:hover {
    & svg {
      fill: rgba(227, 197, 101, 1)
    }
  }
  & svg {
    width: 19px;
    height: 19px;
    fill: ${props => props.isActive ? 'rgba(227, 197, 101, 1)' : 'rgba(219, 232, 241, 1)'};

  }
`
export const SettingsOpen = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 7vw;
  height: 5vh;
  background-color: rgba(30, 31, 35, 1);
  border: 1px solid #52616B;
  padding: 3px;
  border-radius: 5px;
  position: absolute;
  z-index: 3;
  //margin-top: 25px;
  //left: 74%;
  right: 1%;

  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-size: 12px;

    & input {
      accent-color: rgba(227, 197, 101, 1);
    }
  }
`
export const NameRatingStyled = styled.div`
  display: flex;
  position: absolute;
  bottom: 25px;
  padding: 3px;
  text-align: center;
  z-index: 1;
  font-size: 10px;
  color: rgba(219, 232, 241, 1);
  cursor: pointer;
`
export const BlockAnswers = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  & input {
    color: rgba(219, 232, 241, 1);
    margin: 10px 5px;
    border: 1px solid #52616B;
    padding: 3px;
    border-radius: 5px;
    background-color: transparent;

    &::placeholder {
      color: rgba(219, 232, 241, 0.5);
    }
  }

  & div:not(${NameRatingStyled}) {
    display: flex;
    align-items: center;
    width: 100%;
  }
`
export const BlockAnswer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const BlockRatingStyled = styled.div`
  position: relative;
  width: 20%;
  border: 1px solid #52616B;
  border-radius: 5px;
  margin: 0 5px;
  padding: 1px 3px;
  display: flex;
  justify-content: center;
`

export const RatingSelected = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  cursor: default;
`
export const BlockChoseRatingStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: rgba(30, 31, 35, 1);
  border: 1px solid #52616B;
  border-radius: 5px;
  top: 100%;
  right: 0.5%;
  height: 10vh;
  overflow: scroll;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
export const VariantRating = styled.div`
  display: flex;
  justify-content: center;
  cursor: default;
  font-size: 12px;
  background-color: rgba(30, 31, 35, 1);
  //padding: 3px 5px;

  &:hover {
    color: #1E2022;
    background-color: rgba(227, 197, 101, 1)
  }
`
export const SvgRemoveStyled = styled.div`
  display: flex;
  align-items: center;
  width: 11%;
  height: 100%;
  cursor: pointer;

  & svg {
    stroke: #52616B;
    fill: #52616B;
    width: 17px;
    height: 17px;

    &:hover {
      stroke: rgba(222, 108, 108, 1);
      fill: rgba(222, 108, 108, 1);
    }
  }
`
export const TypeQuestionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8vw;
  padding: 3px 5px;
  border: 1px solid #52616B;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  font-size: 12px;
`
export const OptionsTypeQuestionContainer = styled.div<{ showOptions: boolean }>`
  display: ${(props) => (props.showOptions ? 'block' : 'none')};
  position: absolute;
  border: 1px solid #52616B;
  width: 8.3vw;
  border-top: none;
  background-color: rgba(30, 31, 35, 1);
  border-radius: 0 0 5px 5px;
  top: 28px;
  right: 1px;
  color: rgba(219, 232, 241, 1);
  z-index: 2;
`;
export const OptionItem = styled.div`
  padding: 3px 5px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: rgba(227, 197, 101, 0.9);
    color: rgba(14, 15, 18, 1);
  }
`;
export const DetailedDescription = styled.div`
  position: absolute;
  background-color: #52616B;
  padding: 3px;
  display: none;
  z-index: 1;
  font-size: 10px;
  margin-bottom: 50px;
  color: rgba(219, 232, 241, 1);
  ${TypeQuestionBlock}:hover & {
    display: flex;
    text-align: center;
  }
`;