// // export function getEventLastAndTwoLastWeek(arr: any[]) {
// //     // Отримати поточну дату
// //     const currentDate = new Date();
// //
// // // Розділити події на тиждень тому та два тижні тому
// // const lastWeekAgoEvents: any[] = [];
// // const twoWeeksAgoEvents: any[] = [];
// //
// // arr?.forEach(event => {
// //     const eventDate: Date = new Date(event.eventStartTime);
// //
// //     // @ts-ignore
// //     if (currentDate - eventDate < 7 * 24 * 60 * 60 * 1000) {
// //         lastWeekAgoEvents.push(event);
// //     } else { // @ts-ignore
// //         if (currentDate - eventDate < 14 * 24 * 60 * 60 * 1000) {
// //             twoWeeksAgoEvents.push(event);
// //         }
// //     }
// // });
//
// // Сформувати результат у відповідному форматі
// const result = [];
//
// if (lastWeekAgoEvents.length > 0) {
//     result.push({
//         period: 'last week ago',
//         events: lastWeekAgoEvents,
//     });
// } else {
//     result.push({
//         period: 'last week ago',
//         events: [],
//     });
// }
//
// if (twoWeeksAgoEvents.length > 0) {
//     result.push({
//         period: 'two weeks ago',
//         events: twoWeeksAgoEvents,
//     });
// } else {
//     result.push({
//         period: 'two weeks ago',
//         events: [],
//     });
// }
//
// return result;
// }

import { IEmotionalEntry, IWeekData} from "./interface";


export function normalizeDate(date: Date | number): number {
    const normalized = new Date(date);
    // normalized.setHours(0, 0, 0, 0);
    return normalized.getTime();
}

export const counterThought = (data: any[]) => {
    return data && data.reduce((total, obj: { thoughts: string[] }) => total + obj.thoughts.length, 0)
}

export const averageEmotionalLvl = (arr?: Array<{ emotionalLevel: string }>) => {
    let average = 0
    let amount = 0
    // @ts-ignore
    if (arr?.length > 0) {
        arr?.map((item) =>
            Number(item.emotionalLevel.split(' ')[1]) ? ((average += Number(item.emotionalLevel.split(' ')[1])) && amount++) : false
        )
        return (Number((average / amount).toFixed(2)))
    } else {
        return 0
    }
}

export const counterThoughtWithoutWork = (data: Array<{ thoughts: string[], eventStartTime: Date }>) => {
    const timeToCompare1 = new Date();
    timeToCompare1.setHours(17, 30, 0);
    const timeToCompare2 = new Date();
    timeToCompare2.setHours(10, 0, 0);
    const filteredEvent = data && data.filter((elem: { thoughts: string[], eventStartTime: Date }) => {
        if (new Date(elem.eventStartTime).toLocaleTimeString() > timeToCompare1.toLocaleTimeString() || new Date(elem.eventStartTime).toLocaleTimeString() < timeToCompare2.toLocaleTimeString()) {
            return elem
        }
    })
    return filteredEvent && filteredEvent.reduce((total, obj: { thoughts: string[] }) => total + obj.thoughts.length, 0)
}

export const amountSymbol = (params: Array<{ thoughts: string[], doing: string, result: string }>) => {
    return params && params.reduce(function (acc, obj: { thoughts: string[], doing: string, result: string }) {
        if (obj.thoughts || obj.doing || obj.result) {
            return acc + obj.thoughts.length + obj.doing.length + obj.result.length
        } else {
            return acc
        }
    }, 0)
}

export const percentageRatio = (lastWeek: number, twoLastWeek: number): number => {
    const difference: number = Math.abs(lastWeek - twoLastWeek)

    if (twoLastWeek === 0 && lastWeek !== 0) return 100

    if (difference === 0) return 0

    return Number(((difference / twoLastWeek) * 100).toFixed(2))
}


// для подій

// export const computationsEventDataForPieChart = (data:Array<{category:string,event:string}>, params: string) => {
//     let result:Array<number> = []
//
//        const filteredArr =  data && data.filter((elem:{category: string, event: string}) => {
//             if (params === 'Думки 💭' || params === 'План 📋') {
//                 return elem.category === params
//             }
//             return elem.event === params
//         })
//
//     result.push(filteredArr?.length)
//     console.log(result)
// }

const computationsDataForPieChart = (data: Array<{ category: string }>, params: string, dataCategoryForPieChart: Array<{
    id: string,
    label: string,
    value: number
}>) => {

    const filteredArr = data && data.filter((elem: { category: string }) => {
        return elem.category === params
    })
    if (filteredArr?.length > 0) dataCategoryForPieChart.push({id: params, label: params, value: filteredArr?.length})
}
export const computationsCategoryDataForPieChart = (res: Array<{ category: string }>) => {
    let dataCategoryForPieChart: Array<{ id: string, label: string, value: number }> = []
    const optionsEvent = [
        'Розвиток',
        'Дозвілля',
        'Повсякденність',
        'Здоровʼя',
        'Думки 💭',
        'План 📋',
    ]
    optionsEvent.forEach(element => {
        switch (element) {
            case 'Розвиток':
                return computationsDataForPieChart(res, 'Розвиток', dataCategoryForPieChart)
            case 'Дозвілля':
                return computationsDataForPieChart(res, 'Дозвілля', dataCategoryForPieChart)
            case 'Повсякденність':
                return computationsDataForPieChart(res, 'Повсякденність', dataCategoryForPieChart)
            case 'Здоровʼя':
                return computationsDataForPieChart(res, 'Здоровʼя', dataCategoryForPieChart)
            case 'Думки 💭':
                return computationsDataForPieChart(res, 'Думки 💭', dataCategoryForPieChart)
            case 'План 📋':
                return computationsDataForPieChart(res, 'План 📋', dataCategoryForPieChart)

            default:
                break
        }
    })
    return dataCategoryForPieChart
}



export function computationsDataForLineChart(array: any[]) {
    // Створюємо об'єкт для групування значень за проміжками годин
    const groupedByHourRange = array?.reduce((acc, current) => {
        const hour = parseInt(current.eventStartTime.split('T')[1].substring(0, 2));
        const emotionValue = parseInt(current.emotionalLevel.split(' ')[1]);

        // Задаємо проміжок годин для групування (наприклад, з 0 до 1, з 1 до 2 і т.д.)
        const hourRange = Math.floor(hour);

        if (!acc[hourRange]) {
            acc[hourRange] = {total: 0, count: 0}
        }

        acc[hourRange].total += emotionValue ? emotionValue : 1;
        acc[hourRange].count += emotionValue ? 1 : 1;
        return acc;
    }, {});

    if (groupedByHourRange) {
        // Створюємо результат для всіх проміжків годин у добі
        return Array.from({length: 24}, (_, i) => {
            const hourRange = i;
            const average = groupedByHourRange[hourRange]
                ? (groupedByHourRange[hourRange].total / groupedByHourRange[hourRange].count).toFixed(0)
                : 0;

            return {x: `${hourRange}:00`, y: parseFloat(<string>average)};
        }).filter((_, index) => index % 2 === 0);
    }
}


// const arr = [{
// eventStartTime: '2023-11-27T12:02:49.821+00:00',
//     emotionalLevel: '😬 5 - Сум',
// },{
//     eventStartTime: '2023-11-23T12:02:49.821+00:00',
//     emotionalLevel: '😬 7 - Радість',
// },{
//     eventStartTime: '2023-11-25T12:02:49.821+00:00',
//     emotionalLevel: '😐 10 - Спокій',
// },{
//     eventStartTime: '2023-11-24T12:02:49.821+00:00',
//     emotionalLevel: '😁 14 - Щастя',
// },{
//     eventStartTime: '2023-11-24T10:02:49.821+00:00',
//     emotionalLevel: '😁 10 - Натхнення',
// }]
//
// const result = [
//     {x:'27.11',y:5},
//     {x:'26.11',y:0},
//     {x:'25.11',y:10},
//     {x:'24.11',y:24},
//     {x:'23.11',y:7},
//     {x:'22.11',y:0},
//     {x:'21.11',y:0}
// ]
export function transformData(inputData: any[], dateRange: (number | string)[]) {
    let startDate: number | Date;
    let endDate: string | number | Date;

    if (dateRange[0] === '7 днів') {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 днів тому
    } else if (dateRange[0] === '30 днів') {
        endDate = new Date();
        startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate());
    } else if (dateRange[0] === '90 днів') {
        endDate = new Date();
        startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 3, endDate.getDate());
    } else if (dateRange[0] === '180 днів') {
        endDate = new Date();
        startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 6, endDate.getDate());
    } else if (dateRange[0] === '360 днів') {
        endDate = new Date();
        startDate = new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate());
    } else {
        [startDate, endDate] = dateRange.map(date => new Date(date));
    }

    const groupedData = inputData.reduce((acc, item) => {
        const itemDate = new Date(item.eventStartTime);
        itemDate.setHours(0, 0, 0, 0);

        if (itemDate >= startDate && itemDate <= endDate) {
            const formattedDate = `${itemDate.getDate()}.${itemDate.getMonth() + 1}`;
            const emotionLevel = parseInt(item.emotionalLevel.split(' ')[1]) || 0;

            if (!acc[formattedDate]) {
                acc[formattedDate] = {total: 0, count: 0};
            }

            acc[formattedDate].total += emotionLevel;
            acc[formattedDate].count += 1;
        }

        return acc;
    }, {});

    const result = [];
    result.push({
        x: 'Сьогодні',
        y: groupedData[`${new Date(endDate).getDate()}.${new Date(endDate).getMonth() + 1}`]?.total ? (groupedData[`${new Date(endDate).getDate()}.${new Date(endDate).getMonth() + 1}`].total / groupedData[`${new Date(endDate).getDate()}.${new Date(endDate).getMonth() + 1}`].count) : 0
    })
    result.push({
        x: 'Вчора',
        y: groupedData[`${new Date(endDate).getDate() - 1}.${new Date(endDate).getMonth() + 1}`]?.total ? (groupedData[`${new Date(endDate).getDate() - 1}.${new Date(endDate).getMonth() + 1}`].total / groupedData[`${new Date(endDate).getDate() - 1}.${new Date(endDate).getMonth() + 1}`].count) : 0
    })
    for (let i = 2; i <= (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000) - 1; i++) {
        const currentDate: Date = new Date(endDate);
        currentDate.setDate(endDate.getDate() - i);

        const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}`;
        const emotionValue = groupedData[formattedDate]?.total
            ? groupedData[formattedDate].total / groupedData[formattedDate].count
            : 0;

        result.push({x: formattedDate, y: emotionValue});
    }
    return result.reverse();
}


export function processEmotionalData(inputData: IEmotionalEntry[], dateRange: (number | string)[]): IWeekData[] {
    function getWeekNumber(date: Date): number {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        return Math.floor((date.getDate() + dayOfWeek - 1) / 7) + 1;
    }

    function groupDataByWeek(input: IEmotionalEntry[]): Record<number, { totalEmotionalLevel: number; count: number }> {
        const groupedData: Record<number, { totalEmotionalLevel: number; count: number }> = {};

        input.forEach((entry) => {
            const eventDate = new Date(entry.eventStartTime);
            const weekNumber = getWeekNumber(eventDate);

            if (!groupedData[weekNumber]) {
                groupedData[weekNumber] = {totalEmotionalLevel: 0, count: 0};
            }

            const emotionalLevel = parseInt(entry.emotionalLevel.split(' ')[1]) || 0;

            groupedData[weekNumber].totalEmotionalLevel += emotionalLevel;
            groupedData[weekNumber].count += 1;
        });

        return groupedData;
    }

    const result: IWeekData[] = [];
    if (dateRange[0] === '30 днів') {

        const groupedData = groupDataByWeek(inputData);

        for (let i = 1; i <= 4; i++) {
            const weekData = groupedData[i] || {totalEmotionalLevel: 0, count: 0};
            const averageEmotionalLevel =
                weekData.count > 0 ? weekData.totalEmotionalLevel / weekData.count : 0;

            result.push({x: `тиждень ${i}`, y: averageEmotionalLevel});
        }
    } else if (dateRange[0] === '90 днів' || dateRange[0] === '180 днів' || dateRange[0] === '360 днів') {
        const groupedData = groupDataByWeek(inputData);

        const periodsInYear = dateRange[0] === '90 днів' ? 3 : dateRange[0] === '180 днів' ? 6 : 12;

        for (let i = 1; i <= periodsInYear; i++) {
            const monthData = groupedData[i] || {totalEmotionalLevel: 0, count: 0};
            const averageEmotionalLevel =
                monthData.count > 0 ? monthData.totalEmotionalLevel / monthData.count : 0;

            result.push({x: `місяць ${i}`, y: averageEmotionalLevel});
        }
    }

    return result;
}





