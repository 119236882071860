import styled from "styled-components";

export const Container = styled.div<{height?:string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 34vw;
  height: ${props=>props.height? props.height : '80vh'};
  font-family: Verdana, sans-serif;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
export const BlockChoseTEstFormat = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  padding: 5px;
`
export const FormatTestBlock = styled.div<{format:boolean,border:boolean}>`
  display: flex;
  justify-content: center;
  background-color: ${props => props.format ? 'rgba(227, 197, 101, 1)' : 'none'};
  color: ${props => props.format ? 'rgba(14, 15, 18, 1)' : 'none'};
  border-top-left-radius: ${props => props.border ? "5px" : '0'};
  border-bottom-left-radius: ${props => props.border ? "5px" : '0'};
  border-top-right-radius: ${props => props.border ? "0" : '5px'};
  border-bottom-right-radius: ${props => props.border ? "0" : '5px'};
  width: 50%;
  padding: 3px;
  font-size: 14px;
  cursor: pointer;
`
export const BlockSpecificationStyled = styled.div<{width?:string}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;

    & textarea {
        margin-right: 2px;
        line-height: 20px;
        width: ${props => props.width ? props.width : '50%'};
        background-color: rgba(82, 97, 107, 0.5);
        border: 0.5px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        padding: 3px;
        color: rgba(219, 232, 241, 1);
        resize: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

        &::placeholder {
            color: rgba(219, 232, 241, 1);
        }
    }

    & input {
        margin-right: 2px;
        width: ${props => props.width ? props.width : '50%'};
        background-color: rgba(82, 97, 107, 0.5);
        border: 0.5px solid rgba(82, 97, 107, 1);
        border-radius: 5px;
        padding: 3px;
        color: rgba(219, 232, 241, 1);

        &::placeholder {
            color: rgba(219, 232, 241, 1);
        }
    }
`
export const BlockSettings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
  position: relative;
`
export const StyledBlockEvaluation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
`
export const EvaluationInputs = styled.div`
  display: flex;
  width: 52%;

  & input {
    margin: 0 2px;
    width: 50%;
    background-color: rgba(82, 97, 107, 0.5);
    border: 0.5px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);

    &::placeholder {
      color: rgba(219, 232, 241, 1);
    }
  }
`
export const SameAnswersBlock = styled.div<{test?:boolean}>`
  width: 100%;
  max-height: ${props=>props.test? '12vh' : '22vh'};
  overflow: scroll;
  //&::-webkit-scrollbar {
  //  width: 0;
  //  height: 0;
  //}
  //
  //&::-webkit-scrollbar-track {
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  background-color: transparent;
  //}
`
export const StyledLabel = styled.label`
  display: flex;
  align-items: flex-start;
  width: 52%;
`
export const CheckboxStyled = styled.input`
  width: 15px;
  height: 15px;
  accent-color: rgba(227, 197, 101, 1);
`
export const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  & input {
    background-color: rgba(82, 97, 107, 0.5);
    border: 0.5px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    margin-right: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);

    &::placeholder {
      color: rgba(82, 97, 107, 1);
    }

    &:focus {
      outline: none;
    }

    font-size: 1em;
    font-family: Verdana, sans-serif;
  }
`
export const BlockSettingsQuestion = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 2px;
`
export const BlockSettingsType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 43%;
  margin: 0 5px;
`
export const SelectedTypeQuestions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  width: 100%;
  background-color: rgba(82, 97, 107, 0.5);
  border: 0.5px solid rgba(82, 97, 107, 1);
  color: rgba(82, 97, 107, 1);
  font-family: Verdana, sans-serif;
  border-radius: 5px;

  & svg {
    margin-top: 3px;
    width: 15px;
    height: 15px;
  }
`
export const BtnStyled = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(227, 197, 101, 0.7);
  border-radius: 5px;
  border: none;
  padding: 3px 10px;
  cursor: pointer;
  font-size: 1em;
  font-family: Verdana, sans-serif;
  margin: ${props => props.margin};

  &:hover {
    background-color: rgba(227, 197, 101, 0.7);
  }

  &:active {
    background-color: rgba(227, 197, 101, 1);
  }

  &:focus {
    outline: 0;
  }
`
export const Block = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const SvgStyled = styled.svg<{ color?: boolean }>`
  fill: ${props => props.color ? '' : 'rgba(219, 232, 241, 1)'};
  stroke: ${props => props.color ? 'rgba(219, 232, 241, 1)' : ''};
  width: 17px;
  height: 17px;
`
export const BlockList = styled.div`
  display: flex;
  flex-direction: column;
  width: 15vw;
  margin-right: 5px;
  position: absolute;
  top: 32.5vh;
  @media (min-width: 1920px) {
    top: 28.3vh
  }
`
export const VariantChoose = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  background-color: rgba(82, 97, 107, 0.5);
  border: 0.5px solid rgba(82, 97, 107, 1);
  color: rgba(219, 232, 241, 1);
  font-family: Verdana, sans-serif;

  & input {
    appearance: none;
    border: 1px solid transparent;
    outline: none;
    height: 17px;
    width: 17px;
    border-radius: 20px;
    position: relative;

    &:not(:checked) {
      border-color: rgba(227, 197, 101, 0.5);
    }

    &:checked {
      border-color: rgba(227, 197, 101, 1);
    }

    &:checked::after {
      content: '✔'; /* Позначка в середині */
      color: rgba(227, 197, 101, 1);
      font-size: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
export const UlStyled = styled.ul`
  padding-top: 1px;
  padding-left: 1px;
  width: 100%;
  height: 42vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
export const InputQuestion = styled.input`
  width: 100%;
`
export const LabelDuplicateAnswer = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  justify-content: center;

  & input {
    accent-color: rgba(227, 197, 101, 1);
  }

  & span {
    display: none;
    color: rgba(219, 232, 241, 1);
  }

  &:hover {
    & span {
      display: block;
      position: absolute;
      background-color: rgba(82, 97, 107, 0.8);
      padding: 5px;
      margin-top: 7vh;
      width: 140px;
    }
  }
`
export const TodoStyled = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
`
export const AnswerStyled = styled.li`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 5px 20px;
`
export const QuestionWithAnswer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ButtonEditAndDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 25%;
`
export const TodoTextStyled = styled.div<{ textSize: string }>`
  display: flex;
  width: 100%;
  font-size: ${props => props.textSize};
  font-family: Verdana, sans-serif;
`
export const InputForAnswer = styled.input`
  display: flex;
  margin: 5px 20px;
  width: 50%;
`
export const AddButtonBlockStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    align-items: center;
    bottom: 1%;
    width: 100%;
    font-size: 14px;

    & span {
        margin-right: 10px;
        color: rgba(222, 108, 108, 1);
        font-size: 12px;
    }
`
export const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(227, 197, 101, 0.7);
  padding: 5px;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    background-color: rgba(227, 197, 101, 0.7);
  }

  &:active {
    background-color: rgba(227, 197, 101, 1);
  }

  &:focus {
    outline: 0;
  }
`
export const CustomSelect = styled.select`
  margin-right: 2px;
  width: 51.5%;
  background-color: rgba(82, 97, 107, 0.5);
  border: 0.5px solid rgba(82, 97, 107, 1);
  border-radius: 5px;
  padding: 3px;
  color: rgba(219, 232, 241, 1);
  cursor: pointer;
`
export const IdenticalTypeQuestionSelect = styled.select`
  position: relative;
  margin-left: 13px;
  width: 15.1vw;
  background-color: rgba(82, 97, 107, 0.5);
  border: 0.5px solid rgba(82, 97, 107, 1);
  border-radius: 5px;
  padding: 3px;
  color: rgba(219, 232, 241, 1);
  cursor: pointer;
`
export const IdenticalTypeQuestionVariant = styled.div`
  position: relative;
  margin-left: 13px;
  width: 15.1vw;
  background-color: rgba(82, 97, 107, 0.5);
  border: 0.5px solid rgba(82, 97, 107, 1);
  border-radius: 5px;
  padding: 3px;
  color: rgba(82, 97, 107, 1);
  cursor: pointer;
`
export const CustomOption = styled.option`
  background-color: rgba(82, 97, 107, 0.8);
  color: rgba(82, 97, 107, 1);

  &:hover {
    background-color: rgba(82, 97, 107, 1);
    color: rgba(82, 97, 107, 1);
  }
`

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  &.active {
    opacity: 1;
    pointer-events: all;
    z-index: 1;
  }
`
export const StyledVisibleBlockWithResult = styled.div<{keys:boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: ${props=>props.keys ? 'space-around' : 'center'};
  align-items: center;
  width: 100%;
`
export const Settings = styled.div<{top?:string}>`
display: flex;
  flex-direction: column;
  margin-top: ${props=>props.top};
`
export const NextBlockAfterGeneral = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: #1E2022;
  border: 1px solid #52616B;
  transform: scale(0.5);
  transition: 0.4s all;
  max-width: 2500px;
  color: white;

  &.active {
    transform: scale(1);
  }
`
export const ChangeSettings = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1vw;
  transform: scale(0.5);
  transition: 0.4s all;

  &.active {
    transform: scale(1);
  }
`
export const BtnChangeSettings = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px;
  margin-left: 2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${props => props.active ? "rgba(227, 197, 101, 1)" : 'rgba(82, 97, 107, 0.5)'};
  box-shadow: inset 0 10px 10px -10px rgba(255, 255, 255, 0.15);
  font-size: 12px;
  color: ${props => props.active ? 'rgba(14, 15, 18, 1)' : 'rgba(219, 232, 241, 1)'};
  font-family: Verdana, sans-serif;
  cursor: pointer;

  &:hover {
    background-color: rgba(227, 197, 101, 1);
    color: rgba(14, 15, 18, 1);
  }

  &:active {
    background-color: rgba(227, 197, 101, 0.5);
  }
`
export const CheckAllInformationAboutTestBlock = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`
export const ButtonCheckInformationTest = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    margin-top: 10px;
    font-family: Verdana,sans-serif;
    border-radius: 5px;
    background-color: rgba(227, 197, 101, 0.7);
    cursor: pointer;

    &:active {
        background-color: rgba(227, 197, 101, 1);
    }
`
export const AddNewQuestionBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    & div {
        & svg {
            cursor: pointer;
        }    
    }
    
`