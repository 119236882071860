import {
    IDataForAreaBumpChart, IDataPoint,
    IEventForAreaBumpChart,
} from "../computations/interface";
import {consistentTimePeriodsForCharts} from "../Selector/contants";

function findNextMonday(startDate: Date | string | null) {
    const date = new Date(startDate as Date);
    const dayOfWeek = date.getDay(); // День тижня (0 - неділя, 1 - понеділок, ..., 6 - субота)
    if (dayOfWeek === 1) {
        return date;
    }
    // Визначаємо, скільки днів потрібно додати, щоб потрапити на наступний понеділок
    const daysUntilNextMonday = (dayOfWeek === 0) ? 1 : (8 - dayOfWeek);

    // Додаємо цю кількість днів до початкової дати
    date.setDate(date.getDate() + daysUntilNextMonday);

    return date;
}

function findLastSunday(endDate: Date | string | null) {
    const date = new Date(endDate as Date);
    const dayOfWeek = date.getDay(); // День тижня (0 - неділя, 1 - понеділок, ..., 6 - субота)

    // Визначаємо, скільки днів потрібно відняти, щоб потрапити на попередню неділю
    const daysBackToSunday = dayOfWeek === 0 ? 0 : dayOfWeek;

    // Віднімаємо цю кількість днів від початкової дати
    date.setDate(date.getDate() - daysBackToSunday);

    return date;
}

function findLastMonday(date: Date | string | null) {
    const currentDate = new Date(date as Date);
    const currentDayOfWeek = currentDate.getDay();

    // Якщо сьогодні понеділок, повертаємо поточну дату
    if (currentDayOfWeek === 1) {
        return currentDate;
    }

    // В іншому випадку знаходимо кількість днів, які потрібно відняти,
    // щоб отримати дату попереднього понеділка
    const daysBackToMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    currentDate.setDate(currentDate.getDate() - daysBackToMonday);

    return currentDate;
}

function findNextSunday(date: Date | string | null) {
    const currentDate = new Date(date as Date);
    const currentDayOfWeek = currentDate.getDay();

    // Якщо сьогодні неділя, повертаємо поточну дату
    if (currentDayOfWeek === 0) {
        return currentDate;
    }

    // В іншому випадку визначаємо кількість днів до наступної неділі
    const daysUntilNextSunday = 7 - currentDayOfWeek;
    currentDate.setDate(currentDate.getDate() + daysUntilNextSunday);

    return currentDate;
}

function findStartMonth(date: Date | string | null) {
    const currentDate = new Date(date as Date);
    currentDate.setDate(1); // Встановлюємо перший день місяця
    return currentDate;
}

function findEndMonth(date: Date | string | null) {
    const currentDate = new Date(date as Date);
    const currentMonth = currentDate.getMonth();
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1; // Якщо поточний місяць - грудень, наступний буде січень
    const nextMonthFirstDay: any = new Date(currentDate.getFullYear(), nextMonth, 1); // Перший день наступного місяця
    // Останній день поточного місяця
    return new Date(nextMonthFirstDay - 1);
}

function findFirstDayOfNextMonth(dateString: Date | string | null) {
    const date = new Date(dateString as Date);
    if (isNaN(date.getTime())) {
        throw new Error('Invalid Date');
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

function findLastDayOfPreviousMonth(dateString: Date | string | null) {
    // Створюємо новий об'єкт Date, використовуючи поточну дату
    const date = new Date(dateString as Date);
    if (isNaN(date.getTime())) {
        throw new Error('Invalid Date');
    }
    const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    // Віднімаємо один день від першого дня поточного місяця, щоб отримати останній день попереднього місяця
    const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
    lastDayOfPreviousMonth.setDate(0);
    return lastDayOfPreviousMonth;
}

function calculationsDataByHourForGraph(
    events: IEventForAreaBumpChart[],
    startDate: Date | null,
    endDate: Date | null
): IDataForAreaBumpChart[] {
    // Перевіряємо на null і встановлюємо відповідні значення для початку та кінця періоду
    const start = startDate ? new Date(startDate) : new Date();
    start.setHours(0, 0, 0, 0);
    const end = endDate ? new Date(endDate) : new Date(start);
    end.setHours(23, 59, 59, 999);

    // Фільтруємо події за вказаним періодом
    const filteredEvents = events.filter(event => {
        const eventDate = new Date(event.eventStartTime);
        return eventDate >= start && eventDate <= end;
    });

    // Групуємо події за категорією
    const groupedEvents: { [key: string]: IEventForAreaBumpChart[] } = {};
    filteredEvents.forEach(event => {
        if (!groupedEvents[event.category]) {
            groupedEvents[event.category] = [];
        }
        groupedEvents[event.category].push(event);
    });

    // Перелік всіх категорій, які повинні бути присутніми
    const allCategories = ['Розвиток', 'Дозвілля', 'Повсякденність', 'Здоровʼя', 'Думки 💭', 'План 📋'];

    // Обробляємо кожну категорію
    return allCategories.map(category => {
        // Отримуємо події для поточної категорії або пустий масив, якщо категорія відсутня
        const categoryEvents = groupedEvents[category] || [];

        // Обчислюємо рівень емоційності за годину для кожної категорії
        const hourEmotionalLevels: { [key: string]: number[] } = {};
        categoryEvents.forEach(event => {
            const eventDate = new Date(event.eventStartTime);
            const hour = `${String(eventDate.getDate()).padStart(2, '0')}.${String(eventDate.getMonth() + 1).padStart(2, '0')} ${String(eventDate.getHours()).padStart(2, '0')}:00`;
            const level = parseInt(event.emotionalLevel.match(/\d+/)?.[0] || '0');
            if (!hourEmotionalLevels[hour]) {
                hourEmotionalLevels[hour] = [];
            }
            hourEmotionalLevels[hour].push(level);
        });

        // Створюємо дані для категорії, заповнюючи години без подій нулями
        const startHour = new Date(start);
        const endHour = new Date(end);
        const allHours: string[] = [];
        for (let d = new Date(startHour); d <= endHour; d.setHours(d.getHours() + 1)) {
            allHours.push(`${String(d.getDate()).padStart(2, '0')}.${String(d.getMonth() + 1).padStart(2, '0')} ${String(d.getHours()).padStart(2, '0')}:00`);
        }

        const data = allHours.map(hour => {
            const levels = hourEmotionalLevels[hour] || [0]; // використання null для годин без подій
            const avgLevel = levels[0] === null ? null : levels.reduce((a, b) => a + b, 0) / levels.length;
            return { x: hour, y: avgLevel };
        });

        return { id: category, data };
    });
}



function calculationsDataByDayForGraph(
    events: IEventForAreaBumpChart[],
    startDate: Date | null,
    endDate: Date | null
): IDataForAreaBumpChart[] {
    // Перевіряємо на null і встановлюємо відповідні значення для початку та кінця періоду
    const start = startDate ? new Date(startDate) : new Date();
    start.setHours(0, 0, 0, 0);
    const end = endDate ? new Date(endDate) : new Date(start);
    end.setHours(23, 59, 59, 999);

    // Фільтруємо події за вказаним періодом
    const filteredEvents = events.filter(event => {
        const eventDate = new Date(event.eventStartTime);
        return eventDate >= start && eventDate <= end;
    });

    // Групуємо події за категорією
    const groupedEvents: { [key: string]: IEventForAreaBumpChart[] } = {};
    filteredEvents.forEach(event => {
        if (!groupedEvents[event.category]) {
            groupedEvents[event.category] = [];
        }
        groupedEvents[event.category].push(event);
    });

    // Перелік всіх категорій, які повинні бути присутніми
    const allCategories = ['Розвиток', 'Дозвілля', 'Повсякденність', 'Здоровʼя', 'Думки 💭', 'План 📋'];

    // Обробляємо кожну категорію
    return allCategories.map(category => {
        // Отримуємо події для поточної категорії або пустий масив, якщо категорія відсутня
        const categoryEvents = groupedEvents[category] || [];

        // Обчислюємо рівень емоційності за день для кожної категорії
        const dayEmotionalLevels: { [key: string]: number[] } = {};
        categoryEvents.forEach(event => {
            const eventDate = new Date(event.eventStartTime);
            const day = `${eventDate.getFullYear()}-${String(eventDate.getMonth() + 1).padStart(2, '0')}-${String(eventDate.getDate()).padStart(2, '0')}`;
            const level = parseInt(event.emotionalLevel.match(/\d+/)?.[0] || '0');
            if (!dayEmotionalLevels[day]) {
                dayEmotionalLevels[day] = [];
            }
            dayEmotionalLevels[day].push(level);
        });

        // Створюємо дані для категорії, заповнюючи дні без подій нулями
        const startDay = new Date(start);
        const endDay = new Date(end);
        const allDays: string[] = [];
        for (let d = new Date(startDay); d <= endDay; d.setDate(d.getDate() + 1)) {
            allDays.push(`${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`);
        }

        const data = allDays.map(day => {
            const levels = dayEmotionalLevels[day] || [0];
            const avgLevel = levels.reduce((a, b) => a + b, 0) / levels.length;
            const x = new Date(day).toLocaleDateString('uk-UA', {day: '2-digit', month: '2-digit'});
            return {x, y: avgLevel};
        });

        return {id: category, data};
    });
}

function calculationsDataFromStartToEndDayForGraph(
    events: IEventForAreaBumpChart[],
    startDate: Date | string | null,
    endDate: Date | string | null,
    result: IDataForAreaBumpChart[] = []
): IDataForAreaBumpChart[] {
    // Перевірка на null і встановлення відповідних значень для початку та кінця періоду
    const start = startDate ? new Date(startDate) : new Date();
    start.setHours(0, 0, 0, 0);
    const end = endDate ? new Date(endDate) : new Date(start);
    end.setHours(23, 59, 59, 999);

    // Фільтрування подій за вказаним періодом
    const filteredEvents = events.filter(event => {
        const eventDate = new Date(event.eventStartTime);
        return eventDate >= start && eventDate <= end;
    });

    // Групування подій за категорією
    const groupedEvents: { [key: string]: IEventForAreaBumpChart[] } = {};
    filteredEvents.forEach(event => {
        if (!groupedEvents[event.category]) {
            groupedEvents[event.category] = [];
        }
        groupedEvents[event.category].push(event);
    });

    // Перелік всіх категорій, які повинні бути присутніми
    const allCategories = ['Розвиток', 'Дозвілля', 'Повсякденність', 'Здоровʼя', 'Думки 💭', 'План 📋'];

    // Обробка кожної категорії
    allCategories.forEach(category => {
        // Отримання подій для поточної категорії або пустого масиву, якщо категорія відсутня
        const categoryEvents = groupedEvents[category] || [];

        // Обчислення рівня емоційності за період для кожної категорії
        const totalEmotionalLevels = categoryEvents.reduce((acc, event) => {
            const level = parseInt(event.emotionalLevel.match(/\d+/)?.[0] || '0');
            return acc + level;
        }, 0);

        const avgEmotionalLevel = categoryEvents.length ? totalEmotionalLevels / categoryEvents.length : 0;

        // Пошук існуючого запису про категорію в результаті
        const existingCategory = result.find(data => data.id === category);

        const period = `${start.toLocaleDateString('uk-UA', {
            day: '2-digit',
            month: '2-digit'
        })} - ${end.toLocaleDateString('uk-UA', {day: '2-digit', month: '2-digit'})}`;
        const dataPoint = {x: period, y: Math.round(avgEmotionalLevel)};

        // Якщо запис існує, додаємо дані до масиву data
        if (existingCategory) {
            existingCategory.data.push(dataPoint);
        } else {
            // Якщо запис не існує, створюємо новий масив даних
            result.push({
                id: category,
                data: [dataPoint]
            });
        }
    });

    // Повертаємо оновлений масив результатів
    return result;
}

function calculationsDataByWeeklyForGraph(
    events: IEventForAreaBumpChart[],
    startDate: Date | null,
    endDate: Date | null,
    result: IDataForAreaBumpChart[]
): IDataForAreaBumpChart[] {
    // Перевіряємо на null і встановлюємо відповідні значення для початку та кінця періоду
    const start = startDate ? new Date(startDate) : new Date();
    start.setHours(0, 0, 0, 0);
    const end = endDate ? new Date(endDate) : new Date(start);
    end.setHours(23, 59, 59, 999);

    // Групуємо події за категорією
    const groupedEvents: { [key: string]: IEventForAreaBumpChart[] } = {};
    events.forEach(event => {
        if (!groupedEvents[event.category]) {
            groupedEvents[event.category] = [];
        }
        groupedEvents[event.category].push(event);
    });

    // Перелік всіх категорій, які повинні бути присутніми
    const allCategories = ['Розвиток', 'Дозвілля', 'Повсякденність', 'Здоровʼя', 'Думки 💭', 'План 📋'];

    // Функція для отримання наступної дати через 7 днів
    function getNextWeek(date: Date): Date {
        const nextWeek = new Date(date);
        nextWeek.setDate(nextWeek.getDate() + 7);
        return nextWeek;
    }

    // Обробляємо кожну категорію
    allCategories.forEach(category => {
        // Отримуємо події для поточної категорії або пустий масив, якщо категорія відсутня
        const categoryEvents = groupedEvents[category] || [];

        // Обчислюємо рівень емоційності за період для кожної категорії
        const weeklyEmotionalLevels: IDataPoint[] = [];
        let currentStart = new Date(start);

        while (currentStart <= end) {
            const currentEnd = getNextWeek(currentStart);
            const eventsInWeek = categoryEvents.filter(event => {
                const eventDate = new Date(event.eventStartTime);
                return eventDate >= currentStart && eventDate < currentEnd;
            });

            const totalEmotionalLevels = eventsInWeek.reduce((acc, event) => {
                const level = parseInt(event.emotionalLevel.match(/\d+/)?.[0] || '0');
                return acc + level;
            }, 0);

            const avgEmotionalLevel = eventsInWeek.length ? totalEmotionalLevels / eventsInWeek.length : 0;
            const period = `${currentStart.toLocaleDateString('uk-UA', {
                day: '2-digit',
                month: '2-digit'
            })} - ${new Date(currentEnd.getTime() - 1).toLocaleDateString('uk-UA', {
                day: '2-digit',
                month: '2-digit'
            })}`;

            weeklyEmotionalLevels.push({x: period, y: avgEmotionalLevel});

            // Переходимо до наступного тижня
            currentStart = currentEnd;
        }

        // Шукаємо існуючий запис про категорію в результаті
        const existingCategory = result.find(data => data.id === category);

        // Якщо запис існує, додаємо дані до масиву data
        if (existingCategory) {
            existingCategory.data.push(...weeklyEmotionalLevels);
        } else {
            // Якщо запис не існує, створюємо новий масив даних
            result.push({id: category, data: weeklyEmotionalLevels});
        }
    });

    // Повертаємо оновлений масив результатів
    return result;
}

function calculationsDataByMonthlyForGraph(
    events: IEventForAreaBumpChart[],
    startMonthDate: Date | null,
    endMonthDate: Date | null,
    result: IDataForAreaBumpChart[]
): IDataForAreaBumpChart[] {
    // Перевірка на null і встановлення відповідних значень для початку та кінця періоду
    const start = startMonthDate ? new Date(startMonthDate) : new Date();
    start.setHours(0, 0, 0, 0);
    const end = endMonthDate ? new Date(endMonthDate) : new Date(start);
    end.setHours(23, 59, 59, 999);

    // Групування подій за категорією
    const groupedEvents: { [key: string]: IEventForAreaBumpChart[] } = {};
    events.forEach(event => {
        if (!groupedEvents[event.category]) {
            groupedEvents[event.category] = [];
        }
        groupedEvents[event.category].push(event);
    });

    // Створення масиву всіх категорій, які мають бути присутніми
    const allCategories = ['Розвиток', 'Дозвілля', 'Повсякденність', 'Здоровʼя', 'Думки 💭', 'План 📋'];

    // Обробка кожної категорії
    allCategories.forEach(category => {
        // Отримання подій для поточної категорії або пустого масиву, якщо категорія відсутня
        const categoryEvents = groupedEvents[category] || [];

        // Розрахунок періоду для кожної категорії
        const monthlyEmotionalLevels: IDataPoint[] = [];
        let currentStart = new Date(start);

        while (currentStart <= end) {
            // Знаходження початку і кінця поточного місяця
            const currentEnd = new Date(currentStart.getFullYear(), currentStart.getMonth() + 1, 0, 23, 59, 59, 999);
            const period = `${('0' + currentStart.getDate()).slice(-2)}.${('0' + (currentStart.getMonth() + 1)).slice(-2)} - ${('0' + currentEnd.getDate()).slice(-2)}.${('0' + (currentEnd.getMonth() + 1)).slice(-2)}`;

            // Фільтрація подій за поточний місяць
            const eventsInMonth = categoryEvents.filter(event => {
                const eventDate = new Date(event.eventStartTime);
                return eventDate >= currentStart && eventDate <= currentEnd;
            });

            // Розрахунок рівня емоційності за поточний місяць
            const totalEmotionalLevels = eventsInMonth.reduce((acc, event) => {
                const level = parseInt(event.emotionalLevel.match(/\d+/)?.[0] || '0');
                return acc + level;
            }, 0);
            const avgEmotionalLevel = eventsInMonth.length ? totalEmotionalLevels / eventsInMonth.length : 0;

            monthlyEmotionalLevels.push({x: period, y: avgEmotionalLevel});

            // Перехід до наступного місяця
            currentStart.setMonth(currentStart.getMonth() + 1);
            currentStart.setDate(1);
        }

        // Пошук існуючого запису про категорію в результаті
        const existingCategory = result.find(data => data.id === category);

        // Якщо запис існує, додаємо дані до масиву даних
        if (existingCategory) {
            existingCategory.data.push(...monthlyEmotionalLevels);
        } else {
            // Якщо запис не існує, створюємо новий масив даних
            result.push({id: category, data: monthlyEmotionalLevels});
        }
    });

    // Повертаємо оновлений масив результатів
    return result;
}

function calculationsDataByThreeMonthlyForGraph(
    events: IEventForAreaBumpChart[],
    startMonthDate: Date | null,
    endMonthDate: Date | null,
    result: IDataForAreaBumpChart[]
): IDataForAreaBumpChart[] {
    // Перевірка на null і встановлення відповідних значень для початку та кінця періоду
    const start = startMonthDate ? new Date(startMonthDate) : new Date();
    start.setHours(0, 0, 0, 0);
    const end = endMonthDate ? new Date(endMonthDate) : new Date(start);
    end.setHours(23, 59, 59, 999);

    // Групування подій за категорією
    const groupedEvents: { [key: string]: IEventForAreaBumpChart[] } = {};
    events.forEach(event => {
        if (!groupedEvents[event.category]) {
            groupedEvents[event.category] = [];
        }
        groupedEvents[event.category].push(event);
    });

    // Створення масиву всіх категорій, які мають бути присутніми
    const allCategories = ['Розвиток', 'Дозвілля', 'Повсякденність', 'Здоровʼя', 'Думки 💭', 'План 📋'];

    // Обробка кожної категорії
    allCategories.forEach(category => {
        // Отримання подій для поточної категорії або пустого масиву, якщо категорія відсутня
        const categoryEvents = groupedEvents[category] || [];

        // Розрахунок середнього рівня емоційності за період трьох місяців
        const threeMonthEmotionalLevels: IDataPoint[] = [];
        let currentStart = new Date(start);

        while (currentStart <= end) {
            // Знаходження початку і кінця періоду трьох місяців
            const currentEnd = new Date(currentStart.getFullYear(), currentStart.getMonth() + 3, 0, 23, 59, 59, 999);
            const period = `${('0' + currentStart.getDate()).slice(-2)}.${('0' + (currentStart.getMonth() + 1)).slice(-2)} - ${('0' + currentEnd.getDate()).slice(-2)}.${('0' + (currentEnd.getMonth() + 1)).slice(-2)}`;

            // Фільтрація подій за поточний період трьох місяців
            const eventsInThreeMonths = categoryEvents.filter(event => {
                const eventDate = new Date(event.eventStartTime);
                return eventDate >= currentStart && eventDate <= currentEnd;
            });

            // Розрахунок середнього рівня емоційності за поточний період трьох місяців
            const totalEmotionalLevels = eventsInThreeMonths.reduce((acc, event) => {
                const level = parseInt(event.emotionalLevel.match(/\d+/)?.[0] || '0');
                return acc + level;
            }, 0);
            const avgEmotionalLevel = eventsInThreeMonths.length ? totalEmotionalLevels / eventsInThreeMonths.length : 0;

            threeMonthEmotionalLevels.push({x: period, y: avgEmotionalLevel});

            // Перехід до наступного періоду трьох місяців
            currentStart.setMonth(currentStart.getMonth() + 3);
            currentStart.setDate(1);
        }

        // Пошук існуючого запису про категорію в результаті
        const existingCategory = result.find(data => data.id === category);

        // Якщо запис існує, додаємо дані до масиву даних
        if (existingCategory) {
            existingCategory.data.push(...threeMonthEmotionalLevels);
        } else {
            // Якщо запис не існує, створюємо новий масив даних
            result.push({id: category, data: threeMonthEmotionalLevels});
        }
    });

    // Повертаємо оновлений масив результатів
    return result;
}


export function calculateDataForAreaBumpGraph(data: IEventForAreaBumpChart[], timePeriod: (Date | null)[], intervalMarkGraph: string) {
    const result: IDataForAreaBumpChart[] = [];
    if (intervalMarkGraph === consistentTimePeriodsForCharts[0]) {
        return calculationsDataByHourForGraph(data, timePeriod[0], timePeriod[1])
    } else if (intervalMarkGraph === consistentTimePeriodsForCharts[1]) {
        return calculationsDataByDayForGraph(data, timePeriod[0], timePeriod[1])
    } else if (intervalMarkGraph === consistentTimePeriodsForCharts[2]) {
        calculationsDataFromStartToEndDayForGraph(data, timePeriod[0], findNextSunday(timePeriod[0]), result);
        calculationsDataByWeeklyForGraph(data, findNextMonday(timePeriod[0]), findLastSunday(timePeriod[1]), result);
        calculationsDataFromStartToEndDayForGraph(data, findLastMonday(timePeriod[1]), timePeriod[1], result)
    } else if (intervalMarkGraph === consistentTimePeriodsForCharts[3]) {
        calculationsDataFromStartToEndDayForGraph(data, timePeriod[0], findEndMonth(timePeriod[0]), result);
        calculationsDataByMonthlyForGraph(data, findFirstDayOfNextMonth(timePeriod[0]), findLastDayOfPreviousMonth(timePeriod[1]), result);
        calculationsDataFromStartToEndDayForGraph(data, findStartMonth(timePeriod[1]), timePeriod[1], result)
    } else if (intervalMarkGraph === consistentTimePeriodsForCharts[4]) {
        calculationsDataFromStartToEndDayForGraph(data, timePeriod[0], findEndMonth(timePeriod[0]), result);
        calculationsDataByThreeMonthlyForGraph(data, findFirstDayOfNextMonth(timePeriod[0]), findLastDayOfPreviousMonth(timePeriod[1]), result);
        calculationsDataFromStartToEndDayForGraph(data, findStartMonth(timePeriod[1]), timePeriod[1], result);
    }
    return result
}

//
// const events = [
//     {eventStartTime: new Date('2024-05-01T08:30:00'), category: 'Розвиток', emotionalLevel: "😴 7 - Втома"},
//     {eventStartTime: new Date('2024-05-03T10:15:00'), category: 'Розвиток', emotionalLevel: "😃 4 - Радість"},
//     {eventStartTime: new Date('2024-05-05T10:15:00'), category: 'Розвиток', emotionalLevel: "😃 2 - Радість"},
//     {eventStartTime: new Date('2024-05-02T12:45:00'), category: 'Дозвілля', emotionalLevel: "😖 6 - Сором"},
//     {eventStartTime: new Date('2024-06-04T21:00:00'), category: 'Дозвілля', emotionalLevel: "😁 14 - Щастя"},
//     {eventStartTime: new Date('2024-06-06T21:00:00'), category: 'Дозвілля', emotionalLevel: "😁 12 - Щастя"},
//     {eventStartTime: new Date('2024-05-03T09:00:00'), category: 'Повсякден', emotionalLevel: "😊 12 - Задоволення"},
//     {eventStartTime: new Date('2024-05-05T09:00:00'), category: 'Повсякден', emotionalLevel: "😊 3 - Задоволення"},
//     {eventStartTime: new Date('2024-05-09T09:00:00'), category: 'Повсякден', emotionalLevel: "😊 14 - Задоволення"},
//     {eventStartTime: new Date('2024-05-04T08:30:00'), category: 'Здоровʼя', emotionalLevel: "😐 10 - Спокій"},
//     {eventStartTime: new Date('2024-05-07T08:30:00'), category: 'Здоровʼя', emotionalLevel: "😐 4 - Спокій"},
//     {eventStartTime: new Date('2024-05-10T08:30:00'), category: 'Здоровʼя', emotionalLevel: "😐 12 - Спокій"},
//     {eventStartTime: new Date('2024-05-05T10:15:00'), category: 'Думки 💭', emotionalLevel: "😃 14 - Радість"},
//     {eventStartTime: new Date('2024-05-08T10:15:00'), category: 'Думки 💭', emotionalLevel: "😃 1 - Радість"},
//     {eventStartTime: new Date('2024-05-11T10:15:00'), category: 'Думки 💭', emotionalLevel: "😃 4 - Радість"},
//     {eventStartTime: new Date('2024-05-05T10:15:00'), category: 'План 📋', emotionalLevel: "😃 13 - Радість"},
//     {eventStartTime: new Date('2024-05-09T10:15:00'), category: 'План 📋', emotionalLevel: "😃 6 - Радість"},
//     {eventStartTime: new Date('2024-05-12T10:15:00'), category: 'План 📋', emotionalLevel: "😃 8 - Радість"}
// ];
//
// const startDate = new Date('2024-05-01T00:00:00');
// const endDate = new Date('2024-05-15T00:00:00');

// console.log(processData(events, startDate, endDate));
