import {
    BlockButtons,
    ChartBlockStyled,
    Container,
    InfoBlockStyled,
    InformationBlockWithoutPhotos, PhotoBlock,
    PhotoWithBtnBlock
} from "./Card.styled";
import React from "react";
import {MyResponsiveRadar} from "../charts/MyResponsiveRadar";
import {ReactComponent as Journal} from '../../../assets/pictures/Journal.svg'
import {ReactComponent as Chart} from '../../../assets/pictures/ChartBtn.svg'
import {ReactComponent as Materials} from '../../../assets/pictures/Materials.svg'
import {ReactComponent as Avatar} from '../../../assets/pictures/Avatar.svg'
import {path} from "../../../../utils/path";

type Props = {
    company?: any
    infoUser?: any
    user?: any;
    counterJournal?: number
}
export const Card = ({company, user, counterJournal}: Props) => {
    function differentTime(arg:Date) {
        const now:Date = new Date()
        const different:number = Math.ceil((now.getTime() - new Date(arg).getTime()) / (1000 * 3600 * 24))
        if (different > 30) {
            return (
                <>
                    {Math.floor(different / 30)} міс. {different % 30} дн.
                </>
            )
        } else {
            return <>{different}</>
        }
    }
    return (
        <Container>
            <PhotoWithBtnBlock>
                <PhotoBlock>
                    {/*{user && <img src={require(`../../../../../../backend/images/${user.avatar}`)} alt={'avatar'}/> || company && <img src={require(`../../../../../../backend/images/${company.logo}`)} alt={'logo'}/> || <Avatar />}*/}
                    <Avatar />
                </PhotoBlock>
                <BlockButtons>
                    <div>
                        <Journal />
                    </div>
                    <a href={`${path.statistics}`}>
                        <Chart />
                    </a>
                    <a href={`${path.adminPage}`}>
                        <Materials/>
                    </a>
                </BlockButtons>
            </PhotoWithBtnBlock>
            <InformationBlockWithoutPhotos>
            <InfoBlockStyled>
                {user ?
                    <div>
                        <span>Компанія: </span>
                        <span>Етап: </span>
                        <span>Відділ: </span>
                        <span>Роль: </span>
                        <span>В компанії: </span>
                        <span>Кількість подій: </span>
                        <span>Кількість подій за день: </span>
                        <span>Середній настрій за весь час: </span>
                        <span>Середній настрій за сьогодні: </span>
                    </div> : <div>
                        <span>Кількість працівників: </span>
                        <span>Кількість відділів: </span>
                        <span>Кількість продуктів: </span>
                        <span>Загальна кількість подій: </span>
                        <span>Загальна кількість подій за день: </span>
                        <span>Середня емоційна оцінка: </span>
                    </div>

                }
                {user ? <div>
                    <span>Minds</span>
                        {user.stage ? <span>{user.stage}</span> : <span>Games</span>}
                    <span>{user.department}</span>
                    <span>{user.role}</span>
                    <span>{differentTime(user.createdAt)}</span>
                    <span>{user.countEvent}</span>
                    <span>{user.countEventPerDay}</span>
                    <span>{user.averageEmotionalRating.toFixed(2)}</span>
                    <span>{user.averageEmotionalRatingPerDay.toFixed(2)}</span>
                    </div>
                    :
                            <div>
                                <span>{company?.employeesRoster?.length}</span>
                                <span>{company?.departments?.length}</span>
                                <span>{company?.products?.length}</span>
                                <span>{counterJournal}</span>
                                <span>{company?.eventCounterPerDay.toFixed(2)}</span>
                                <span>{company?.averageEmotionalRating.toFixed(2)}</span>

                            </div>
                }
            </InfoBlockStyled>
            <ChartBlockStyled>
                <MyResponsiveRadar
                    data={[
                        {
                            "taste": "JavaScript",
                            "оцінка": 100,
                        },
                        {
                            "taste": "React",
                            "оцінка": 66,
                        },
                        {
                            "taste": "Node.js",
                            "оцінка": 100,
                        },
                        {
                            "taste": "Mongo",
                            "оцінка": 54,
                        },
                        {
                            "taste": "Express",
                            "оцінка": 89,
                        }
                    ]}
                />
            </ChartBlockStyled>
            </InformationBlockWithoutPhotos>
        </Container>
    )
}