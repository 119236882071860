export const LegendsEventForCharts: { [key: string]: string } = {
    'Навчання': 'rgba(69,111,205,0.3)',
    'Робота': 'rgba(69,111,205,0.5)',
    'Саморозвиток': 'rgba(69,111,205,0.8)',
    'Сімʼя': 'rgba(72,117,44,0.1)',
    'Відпочинок': 'rgba(72,117,44,0.3)',
    'Хобі': 'rgba(72,117,44,0.4)',
    'Спорт': 'rgba(72,117,44,0.7)',
    'Культурні заходи': 'rgba(72,117,44,0.8)',
    'Догляд за собою': 'rgba(218,149,75,0.3)',
    'Дорога': 'rgba(218,149,75,0.4)',
    'Домашні справи': 'rgba(218,149,75,0.6)',
    'Сон': 'rgba(218,149,75,0.7)',
    'Їжа': 'rgba(218,149,75,0.8)',
    'Надзвичайна подія': 'rgba(187,39,26,1)',
    'Лікування': 'rgba(187,39,26,0.5)',
    'План 📋': 'rgba(197,206,225,1)',
    'Думки': 'rgba(99,79,162,1)',
    'Розвиток': 'rgba(69,111,205,1)',
    'Дозвілля': 'rgba(72,117,44,1)',
    'Повсякденність': 'rgba(218,149,75,1)',
    'Здоровʼя': 'rgba(187,39,26,0.3)',
    'Думки 💭': 'rgba(99,79,162,1)',
}

export const Category = {
    'Розвиток': 'rgba(69,111,205,1)',
    'Дозвілля': 'rgba(72,117,44,1)',
    'Повсякденність': 'rgba(218,149,75,1)',
    'Здоровʼя': 'rgba(187,39,26,0.3)',
    'План 📋': 'rgba(197,206,225,1)',
    'Думки': 'rgba(99,79,162,1)',
}

export const Healthy = {
    'Надзвичайна подія': 'rgba(187,39,26,1)',
    'Лікування': 'rgba(187,39,26,0.5)',
}
export const Education = {
    'Навчання': 'rgba(69,111,205,0.3)',
    'Робота': 'rgba(69,111,205,0.5)',
    'Саморозвиток': 'rgba(69,111,205,0.8)',
}
export const Leisure = {
    'Сімʼя': 'rgba(72,117,44,0.1)',
    'Відпочинок': 'rgba(72,117,44,0.3)',
    'Хобі': 'rgba(72,117,44,0.4)',
    'Спорт': 'rgba(72,117,44,0.7)',
    'Культурні заходи': 'rgba(72,117,44,0.8)',
}
export const DailyLife = {
    'Догляд за собою': 'rgba(218,149,75,0.3)',
    'Дорога': 'rgba(218,149,75,0.4)',
    'Домашні справи': 'rgba(218,149,75,0.6)',
    'Сон': 'rgba(218,149,75,0.7)',
    'Їжа': 'rgba(218,149,75,0.8)',
}