import {yellowDuckApi} from "./globalApi";
import {IDeleteAvatar, IGetAvatar, IUploadPhotoInFirebaseResponse, IUserDataForUploadAvatar} from "../interface";

export const injectedFirebaseApi = yellowDuckApi.injectEndpoints({
    endpoints: (build) => ({
        uploadAvatar: build.mutation<IUploadPhotoInFirebaseResponse, FormData>({
            query: (formData) => ({
                url: '/api/profile/uploadAvatar',
                method: 'POST',
                body: formData,
            })
        }),
        getAvatar: build.query<IGetAvatar, string | void>({
            query: (employeeId) => ({
                url: '/api/profile/getAvatar',
                method: 'GET',
                params: {
                    employeeId
                }
            })
        }),
        deleteAvatar: build.mutation<IDeleteAvatar, void>({
            query: () => ({
                url: '/api/profile/deleteAvatar',
                method: 'DELETE',
            })
        }),
    })
});

export const {
    useUploadAvatarMutation,
    useGetAvatarQuery,
    useLazyGetAvatarQuery,
    useDeleteAvatarMutation,
} = injectedFirebaseApi;