// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line
import {PointTooltipProps, ResponsiveLine} from '@nivo/line'
import React from "react";
import {LegendsEventForCharts} from "../constants";

const theme = {
    text: {
        fontSize: 11,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent"
    },
    axis: {
        domain: {
            line: {
                stroke: "rgba(82, 97, 107, 1)",
                strokeWidth: 1
            }
        },
        legend: {
            text: {
                fontSize: 16,
                fill: "#C9D6DF",
                outlineWidth: 1,
            }
        },
        ticks: {
            line: {
                stroke: "#777777",
                strokeWidth: 0
            },
            text: {
                fontSize: 12,
                fill: "#C9D6DF",
                outlineWidth: 0,
                outlineColor: "transparent"
            }
        }
    },
    grid: {
        line: {
            stroke: "rgba(82, 97, 107, 0.5)",
            strokeWidth: 1
        }
    },
    legends: {
        title: {
            text: {
                fontSize: 14,
                fill: "#C9D6DF",
                outlineWidth: 0,
                outlineColor: "transparent"
            }
        },
        text: {
            fontSize: 20,
            fill: "#C9D6DF",
            outlineWidth: 0,
            outlineColor: "transparent"
        },
        ticks: {
            line: {},
            text: {
                fontSize: 10,
                fill: "#C9D6DF",
                outlineWidth: 0,
                outlineColor: "transparent"
            }
        }
    },
    annotations: {
        text: {
            fontSize: 13,
            fill: "#C9D6DF",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1
        },
        link: {
            stroke: "#000000",
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1
        },
        outline: {
            stroke: "#000000",
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1
        },
        symbol: {
            fill: "#000000",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1
        }
    },
    tooltip: {
        container: {
            background: "#ffffff",
            fontSize: 12,
            height: 10, // Додайте висоту для контейнера
            // className: Container,
        },
        basic: {},
        chip: {},
        table: {},
        tableCell: {},
        tableCellValue: {}
    }
}
// const data: any = [
//     {
//         "id": "japan",
//         "color": "hsl(34, 70%, 50%)",
//         "data": [
//             {
//                 "x": "plane",
//                 "y": 86
//             },
//             {
//                 "x": "helicopter",
//                 "y": 105
//             },
//             {
//                 "x": "boat",
//                 "y": 94
//             },
//             {
//                 "x": "train",
//                 "y": 154
//             },
//             {
//                 "x": "subway",
//                 "y": 64
//             },
//             {
//                 "x": "bus",
//                 "y": 60
//             },
//             {
//                 "x": "car",
//                 "y": 231
//             },
//             {
//                 "x": "moto",
//                 "y": 267
//             },
//             {
//                 "x": "bicycle",
//                 "y": 75
//             },
//             {
//                 "x": "horse",
//                 "y": 0
//             },
//             {
//                 "x": "skateboard",
//                 "y": 124
//             },
//             {
//                 "x": "others",
//                 "y": 246
//             }
//         ]
//     },
//     {
//         "id": "france",
//         "color": "hsl(127, 70%, 50%)",
//         "data": [
//             {
//                 "x": "plane",
//                 "y": 211
//             },
//             {
//                 "x": "helicopter",
//                 "y": 108
//             },
//             {
//                 "x": "boat",
//                 "y": 46
//             },
//             {
//                 "x": "train",
//                 "y": 129
//             },
//             {
//                 "x": "subway",
//                 "y": 245
//             },
//             {
//                 "x": "bus",
//                 "y": 189
//             },
//             {
//                 "x": "car",
//                 "y": 154
//             },
//             {
//                 "x": "moto",
//                 "y": 289
//             },
//             {
//                 "x": "bicycle",
//                 "y": 13
//             },
//             {
//                 "x": "horse",
//                 "y": 202
//             },
//             {
//                 "x": "skateboard",
//                 "y": 55
//             },
//             {
//                 "x": "others",
//                 "y": 294
//             }
//         ]
//     },
//     {
//         "id": "us",
//         "color": "hsl(250, 70%, 50%)",
//         "data": [
//             {
//                 "x": "plane",
//                 "y": 229
//             },
//             {
//                 "x": "helicopter",
//                 "y": 147
//             },
//             {
//                 "x": "boat",
//                 "y": 117
//             },
//             {
//                 "x": "train",
//                 "y": 208
//             },
//             {
//                 "x": "subway",
//                 "y": 139
//             },
//             {
//                 "x": "bus",
//                 "y": 87
//             },
//             {
//                 "x": "car",
//                 "y": 174
//             },
//             {
//                 "x": "moto",
//                 "y": 199
//             },
//             {
//                 "x": "bicycle",
//                 "y": 262
//             },
//             {
//                 "x": "horse",
//                 "y": 57
//             },
//             {
//                 "x": "skateboard",
//                 "y": 264
//             },
//             {
//                 "x": "others",
//                 "y": 279
//             }
//         ]
//     },
//     {
//         "id": "germany",
//         "color": "hsl(289, 70%, 50%)",
//         "data": [
//             {
//                 "x": "plane",
//                 "y": 91
//             },
//             {
//                 "x": "helicopter",
//                 "y": 212
//             },
//             {
//                 "x": "boat",
//                 "y": 88
//             },
//             {
//                 "x": "train",
//                 "y": 108
//             },
//             {
//                 "x": "subway",
//                 "y": 119
//             },
//             {
//                 "x": "bus",
//                 "y": 244
//             },
//             {
//                 "x": "car",
//                 "y": 271
//             },
//             {
//                 "x": "moto",
//                 "y": 200
//             },
//             {
//                 "x": "bicycle",
//                 "y": 67
//             },
//             {
//                 "x": "horse",
//                 "y": 70
//             },
//             {
//                 "x": "skateboard",
//                 "y": 279
//             },
//             {
//                 "x": "others",
//                 "y": 95
//             }
//         ]
//     },
//     {
//         "id": "norway",
//         "color": "hsl(304, 70%, 50%)",
//         "data": [
//             {
//                 "x": "plane",
//                 "y": 31
//             },
//             {
//                 "x": "helicopter",
//                 "y": 17
//             },
//             {
//                 "x": "boat",
//                 "y": 117
//             },
//             {
//                 "x": "train",
//                 "y": 158
//             },
//             {
//                 "x": "subway",
//                 "y": 286
//             },
//             {
//                 "x": "bus",
//                 "y": 45
//             },
//             {
//                 "x": "car",
//                 "y": 41
//             },
//             {
//                 "x": "moto",
//                 "y": 245
//             },
//             {
//                 "x": "bicycle",
//                 "y": 230
//             },
//             {
//                 "x": "horse",
//                 "y": 262
//             },
//             {
//                 "x": "skateboard",
//                 "y": 52
//             },
//             {
//                 "x": "others",
//                 "y": 213
//             }
//         ]
//     }
// ]
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MultipleLinesChart = ({data}: any) => {
    const getTickValues = (data: any) => {
        const totalPoints = data[0].data.length;
        const step = Math.ceil(totalPoints / 5); // Змінюйте 10 на бажану кількість позначок
        return data[0].data.filter((_: any, index: number) => index % step === 0).map((d: any) => d.x);
    };
    const CustomTooltip: React.FC<PointTooltipProps> = ({ point }) => (
        <div
            style={{
                padding: '2px',
                background: '#52616B',
                borderRadius: '2px',
                fontSize: '12px',
                color:'#DBE8F1',
                fontFamily:'Verdana',
                zIndex:99,
                position:'relative'
            }}
        >
            <strong>дата/час</strong>:<span style={{color: 'rgba(227, 197, 101, 1)'}}>{point.data.xFormatted}</span>
            <br/>
            <strong>{point.serieId}</strong>: <span style={{color: 'rgba(227, 197, 101, 1)'}}>{point.data.yFormatted}</span>
        </div>
    );
    // const CustomTooltip: React.FC<PointTooltipProps> = ({ point }) => (
    //     <div
    //         style={{
    //             padding: '2px',
    //             background: '#52616B',
    //             borderRadius: '2px',
    //             fontSize: '12px',
    //             color:'#DBE8F1',
    //             fontFamily: 'Verdana',
    //         }}
    //     >
    //         <strong>дата/час</strong>:<span style={{color: 'rgba(227, 197, 101, 1)'}}>{point.data.xFormatted}</span>
    //         <br/>
    //         <strong>{point.serieId}</strong>: <span style={{color: 'rgba(227, 197, 101, 1)'}}>{point.data.yFormatted}</span>
    //     </div>
    // );
    return (<ResponsiveLine
        data={data}
        theme={theme}
        margin={{top: 33, right: 35, bottom: 46, left: 50}}
        pointSize={3}
        yScale={{
            type: 'linear',
            min: 0,
            max: 14,
            stacked: false,
            reverse: false
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        xScale={{
            type: "point",
        }}
        axisBottom={{
            tickValues: getTickValues(data)
        }}
        curve={'monotoneX'}
        colors={({ id }) => LegendsEventForCharts[id as string] || '#000'}
        pointColor={{theme: 'background'}}
        pointBorderWidth={2}
        pointBorderColor={{from: 'serieColor', modifiers: []}}
        pointLabelYOffset={-2}
        areaOpacity={0.15}
        useMesh={true}
        // tooltip={CustomTooltip}
        tooltip={CustomTooltip}
        // sliceTooltip={({ slice }) => (
        //     <div>
        //         {slice.points.map((point) => (
        //             <div key={point.id}>
        //                 <strong>{point.serieId}:</strong> {point.data.yFormatted}
        //             </div>
        //         ))}
        //     </div>
        // )}
        // isInteractive={true}
        // onMouseEnter={(point: any) => setHighlightedLineId(point.serieId)}
        // onMouseLeave={() => setHighlightedLineId(null)}
    />)
}
