import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1E2022;
  border: 1px solid rgba(82, 97, 107, 1);
  border-radius: 5px;
  position: absolute;
  width: 35vw;
  min-height: 30vh;
  z-index: 3;
  cursor: auto;
  top: 20%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  height: 30%;
`
export const Block = styled.div<{empty:boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  height: 45px;
  margin: 2px;
  position: relative;
  background-color: ${props => props.empty ? 'rgba(82, 97, 107, 0.5)' : 'transparent' };
  border: 1px solid rgba(82, 97, 107, 1);
  box-shadow: ${props => props.empty ? 'inset 0 10px 10px -10px rgba(255, 255, 255, 0.15)' : 'none' };
  font-size: 14px;
  color: rgba(219, 232, 241, 1);
  cursor: grab;

    & svg {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%); /* Зміщення на 50% відносно правого та верхнього країв */
        fill: rgba(219, 232, 241, 1);
        width: 15px;
        height: 15px;
        opacity: 0.7;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 1;
        }
    }
`

export const BtnTab = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`
export const ErrorMsg = styled.div`
    font-size: 12px;
    color: rgba(222, 108, 108, 1);
`
export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px;
  border: 1px solid rgba(82, 97, 107, 1);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(227, 197, 101, 0.7);
  }

  &:active {
    background-color: rgba(227, 197, 101, 1);
  }

  &:focus {
    outline: 0;
  }
`
export const ListChosenQuestion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px;
  padding: 3px;
`
export const BlockChoseCalculationCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 5px;
  font-size: 14px;
`
export const MethodCalculationBlock = styled.div<{format:boolean,border:boolean,marginLeft?:string}>`
  display: flex;
  justify-content: center;
  background-color: ${props => props.format ? 'rgba(227, 197, 101, 1)' : 'none'};
  color: ${props => props.format ? 'rgba(14, 15, 18, 1)' : 'none'};
  border-top-left-radius: ${props => props.border ? "5px" : '0'};
  border-bottom-left-radius: ${props => props.border ? "5px" : '0'};
  border-top-right-radius: ${props => props.border ? "0" : '5px'};
  border-bottom-right-radius: ${props => props.border ? "0" : '5px'};
  width: 27%;
  padding: 3px;
  font-size: 14px;
  cursor: pointer;
  margin-left:${props=>props.marginLeft}
`