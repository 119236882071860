import React, {DragEvent} from 'react';
import {Block} from "./CustomMethod.styled";
import {ReactComponent as Delete} from "../../../assets/pictures/DeleteIcon.svg";


interface BlockProps {
    elem: string
    id?: number;
    onDragStart?: (event: DragEvent<HTMLDivElement>) => void;
    handleRemoveBlock?: (event: number | undefined) => void;
    noneDelete?: boolean
}

export const BlockForCustomsMethod: React.FC<BlockProps> = ({noneDelete,elem, onDragStart, handleRemoveBlock, id}) => {
    return (
        <Block
            empty={(elem.length > 0)}
            className="block"
            draggable
            onDragStart={onDragStart}
        >
            {`${elem}`}
            {noneDelete && <Delete onClick={() => handleRemoveBlock ? handleRemoveBlock(id) : ''} />}

        </Block>
    );
};