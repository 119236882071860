export const enum blockKeys {
    category = 'category',
    conclusion = 'conclusion'
}

export const enum formatTest {
    test = 'test',
    form = 'form',
    testName = 'Тест',
    formName = 'Анкета'
}

export const calculateMethod: string[] = [
    'сума категорій',
    'середнє значення',
    // 'кількість категорій',
    'власна формула',
]

export const conclusionsRoaster: string[] = [
    'загальний',
    'загальний + по категоріям',
    'по категоріям',
]

export const TypeQuestion: { text: string, value: string }[] = [
    {text: 'Відкрита відповідь', value: 'text'},
    {text: 'Декілька відповідей', value: 'checkbox'},
    {text: 'Шкала', value: 'range'},
    {text: 'Одна відповідь', value: 'radio'},
]

export const Rating: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const enum calculationCategoryMethod {
    sum = 'sum',
    average = 'average'
}

export const enum calculationCategoryMethodName {
    sum = 'Сума',
    average = 'Середнє значення'
}

export const NameBtnChoseCalculationCategoryMethod = 'Тип обрахунку категорій:'
export const CheckTestHeader = {
    TotalSettings: 'Перевірка введених даних',
    conclusion: 'Висновки',
    category: 'Категорії',
    calculationCategoryMethod: 'Cпосіб обрахунку',
    questionAndAnswers: 'Питання/відповіді'
}

export const enum NameField {
    name = 'Назва',
    topic = 'Тема',
    shortDescription = 'Короткий опис',
    description = 'Опис',
    subject = 'Предмет',
    department = 'Відділ',
    type = 'Тип',
}

export const ConclusionFieldName = {
    name: 'Назва категорії',
    range: 'Діапазон',
    description: 'Опис',
    customMethod: 'Обрахунки категорій',
    formula: 'Формула'
}
export const QuestionAnswersHeader = {
    question: 'Питання',
    answers: 'Відповіді',
    values: 'Оцінка',
    category: 'Категорія',
    typeAnswers: 'Тип відповіді'
}
export const ConfirmBtn = {
    confirm: 'Додати',
    back: 'Назад'
}

export const enum ETitleName {
    SETTINGSTEST='Налаштування тесту',
    HEADERTITLE = 'Додати новий тест',
    TESTNAME = 'Назва:',
    TESTTHEME = 'Тема:',
    TESTSHORTDESCRIPTION = 'Короткий опис:',
    TESTDESCRIPTION = 'Детальний опис:',
    TESTSUBJECT = 'Предмет:',
    TESTDEPATMENT = 'Відділ:',
    TESTTYPE = 'Тип:',
    AMOUNTQUESTIONS = 'Кількість запитань:',
    EVALUATIONQUESTIONS = 'Оцінка відповідей:',
    REVERSEQUESTIONS = 'Реверсивні запитання:',
    IDENTICALTYPEQUESTIONS = 'Однаковий тип відповідей:',
    DUPLICATEANSWERS = 'Однакові відповіді:',
    ANSWERS='Відповіді:'
}

export const enum EErrorMsg {
    ERROR = 'Заповніть всі поля'
}

export const enum EButtonNextStep {
    ADD = 'Додати'
}