import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
    color: rgba(219, 232, 241, 1);
    margin: 5%;
    padding: 15px;
    border: 1px solid rgba(227, 197, 101, 1);
    border-radius: 5px;

    & span {
        padding: 10px 0;
    }

    & p {
        font-size: 14px;
    }

    
`