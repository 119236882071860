import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const BlockLoginStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.background};
`
export const BlockForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.background};
`

export const Forms = styled.div`
  width: 23vw;
  height: 50vh;
  padding: ${props => (props.slot === '40' ? '40' : '60')}px;
  background: ${props => props.theme.background};
  border: 1px solid #52616b;
  & form {
    padding-top: 2vh;
    height: 20vh;
  }
  & h2 {
    font-family: 'Verdana',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    margin: 10px 0 30px;
    padding: 0;
    color: ${props => props.theme.hJoin};
    text-align: center;
  }
`
export const BoxUserName = styled.div`
  height: 50%;
  & input {
    display: block;
    width: 100%;
    padding-top: 20px;
    font-size: 16px;
    color: #c9d6df;
    /* margin-bottom: 30px; */
    border: none;
    border-bottom: 1px solid #52616b;
    outline: none;
    background: transparent;
    &:focus {
      border-bottom: 1px solid #e3c565;
    }
  }
  & label {
    font-family: 'Verdana',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    left: 0;
    top: -50px;
    padding: 10px 0;
    color: #52616b;
  }
`
export const BlockButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const BoxSubmit = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  width: 100%;
  height: 10%;
  justify-content: center;
`
export const BoxLogin = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
  & div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    & p {
      margin-top: 30%;
      font-family: 'Verdana';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      color: red;
    }
  }
`
export const Bth = styled.button`
  position: absolute;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  & span {
    font-family: 'Verdana';
    font-style: normal;
    font-size: 18px;
    padding: 10px 20px;
    color: ${props => props.theme.txtLogin};
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    border: 1px solid ${props => props.theme.hoverJoin};
    margin: auto;
  }

`
export const Linked = styled(Link)`
  text-decoration: none;
  & span {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 20px;
    color: #fadd4b;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    border: 1px solid #fadd4b;
    margin: auto;
  }
  & span:hover {
    background-color: rgba(250, 221, 75, 0.9);
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #fadd4b, 0 0 12px #fadd4b, 0 0 12px #fadd4b, 0 0 12px #fadd4b;
  }
`
export const Links = styled(Link)`
  text-decoration-line: ${props => props.theme.input} underline;
  cursor: pointer;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: auto;
  color: ${props => props.theme.input};
  &:hover {
    color: #e3c565;
    text-decoration: #e3c565 underline;
  }
`
export const BlockRegister = styled.div`
  width: 100%;
  margin-top: 6%;
  margin-left: 10%;
  & p {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: auto;
    color: ${props => props.theme.dontHaveAccount};
  }
`
