import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 1em;
  overflow: scroll;
  margin-right: 25px;
`
export const TableStyled = styled.table`
    width: 50%;
`
export const RowInTable = styled.tr<{ select?: boolean }>`
  display: flex;
  flex-direction: row;
  color: #C9D6DF;
  background-color: ${props => props.select ? '#52616B' : 'transparent'};

  & td {
    text-align: center;
    white-space: nowrap; /* Запобігає перенесенню тексту на новий рядок */
    overflow: hidden; /* Ховає вміст, який виходить за межі комірки */
    text-overflow: ellipsis; /* Замінює видимий вміст символами '...' при переповненні */
    padding: 1em 0.35em;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75em;
    color: rgb(255, 255, 255, 0.9);
    width: 6.8vw;
    border: 1px solid #C9D6DF;

    & span {
      display: none;
      position: absolute;
      background-color: #1e1f23;
      z-index: 2;
    }

    &:hover {
      cursor: pointer;
      & span {
        display: block;
        
        padding: 3px;
        border: 0.5px solid #C9D6DF;
      }
    }

  }
`