import {Container, QuestionBlock, StyledPhoneInput} from "./Questions.styled";
import React, {FC, useState} from "react";
import "react-phone-input-2/lib/style.css";

interface Props {
    name: string
    setAnswers: React.Dispatch<React.SetStateAction<any>>
    indexField: number
    fieldsForUpdate: string
    field: string
}

export const QuestionPhoneNumber: FC<Props> = ({name, setAnswers, indexField, field, fieldsForUpdate}: Props) => {
    const [text, setText] = useState<string>("");
    const handleChange = () => {
        setAnswers((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item: { field: string }) => item.field === field);
            if (itemsWithField[indexField]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[indexField]);
                updatedItems[globalIndex] = {
                    ...updatedItems[globalIndex],
                    [fieldsForUpdate ? fieldsForUpdate : 'answer']: text
                };

                return updatedItems;
            } else {
                return [...prevItems, {field, [fieldsForUpdate ? fieldsForUpdate : 'answer']: text}];
            }
        });
    }
    return (
        <Container>
            <QuestionBlock>{name}</QuestionBlock>
            <div>
                <StyledPhoneInput
                    dropdownStyle={{
                        backgroundColor: 'rgba(82, 97, 107, 1)',
                        color: 'rgb(227, 197, 101)',
                        maxWidth: '13.5vw',
                        maxHeight: '10vh'
                    }}
                    country={"ua"}
                    onChange={phone => setText(phone)}
                    onBlur={handleChange}
                />
            </div>
        </Container>
    )
}
