import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const HeaderEduBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`
export const LineBlock = styled.div<{right?:boolean,left?:boolean,top?:boolean}>`
    margin-top: ${props=>props.top ? '10px' : 0};
    margin-right: ${props=>props.right ? '10px' : 0};
    margin-left: ${props=>props.left ? '10px' : 0};
    background-color: rgba(82, 97, 107, 0.5);
    height: 2px;
    width: 100%;
`
export const QuestionBlock = styled.div<{disabledOpacity?:boolean}>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0;
    opacity: ${props =>props.disabledOpacity ? 0.3 : 1};
`;
export const InputContainer = styled.div`
    position: relative;
    width: 40%; /* Встановлюємо ширину контейнера */
`;

export const StyledInput = styled.input`
    width: 95%; /* Встановлюємо ширину input відповідно до контейнера */
    background-color: rgba(82, 97, 107, 0.5);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);
    accent-color: rgba(227, 197, 101, 1);

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }
`;

export const BlockListOption = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: absolute;
    top: calc(100% + 5px); /* З'являється під input */
    left: 0;
    width: 102%; /* Встановлюємо ширину списку відповідно до контейнера */
    max-height: 40vh;
    background-color: rgba(82, 97, 107, 0.9);
    border: 1px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    z-index: 10;
    overflow: scroll;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &::placeholder {
        color: rgba(219, 232, 241, 1);
    }

    & div {
        padding: 2px;
        color: rgba(219, 232, 241, 1);
        cursor: pointer;
        &:hover {
            background-color: rgba(227, 197, 101, 0.5);
        }
    }
`;