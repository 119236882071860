import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const MainUnitStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ContentBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const BlockCardWithMapStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 97%;
  height: 40vh;
  margin:20px;
  @media(min-width: 1920px) {
    width: 98.5%;
  }
`
export  const TestMapBlock = styled.div`
  width: 43%;
  height: 33.2vh;
  padding: 25px;
  border: 1px solid #C9D6DF;
`
export const TestBlock = styled.div`
  width: 45%;
  height: 30vh;
  border: 1px solid #C9D6DF;
  padding: 40px 10px;
  margin-top: 20px;
  @media (min-width: 1920px) {
    padding: 40px 0;
    height: 45vh;
  }
`

