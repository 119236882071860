export const lightTheme = {
    background: '#FFFFE0',
    logoColor: '#1E2022',
    buttonLogin: '#E3C565',
    txtLogin: '#1E2022',
    btnGoToReadBorder: '#e3c565',
    bgBtnGoToRead: '#e3c565',
    strokeSvg: '#1E2022',
    bulbsize: '40px',
    retreatTop: '100',
    retreatLeft: '302',
    bulbsizeMediaScreen: '50px',
    retreatTopMediaScreen: '110',
    retreatLeftMediaScreen: '307',
    stokeAnimated: '#1E2022',
    fonLogin1: 'red',
    fonLogin2: '#1E2022',
    hJoin: '#1E2022',
    hoverJoin: '#E3C565',
    input: '#52616B',
    dontHaveAccount: '#1E2022',
    descriptionTrue: '#1E2022'
}
export const darkTheme = {
    background: 'rgba(17, 18, 22, 1)',
    logoColor: '#C9D6DF',
    buttonLogin: 'none',
    txtLogin: '#E3C565',
    btnGoToReadBorder: '#e3c565',
    bgBtnGoToRead: 'none',
    strokeSvg: '#e3c565',
    bulbsize: '35px',
    retreatTop: '107',
    retreatLeft: '304',
    bulbsizeMediaScreen: '45px',
    retreatTopMediaScreen: '110',
    retreatLeftMediaScreen: '309',
    stokeAnimated: '#1E2022',
    fonLogin1: '#1E2022',
    fonLogin2: '#E3C565',
    hoverJoin: '#E3C565',
    hJoin: '#C9D6DF',
    input: '#52616B',
    dontHaveAccount: '#C9D6DF',
    descriptionTrue: '#1E2022'
}
