import styled from "styled-components";

export const ButtonStyled = styled.div<{ active?: boolean,sideMarkingRight?: string,sideMarkingLeft?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9%;
  border: 1px solid #52616B;
  border-radius: 15px;
  // border-right: ${props => props.sideMarkingRight};
  // border-left: ${props => props.sideMarkingLeft};
  cursor: pointer;
  & div {
    background-color:  ${props=>props.active ? '#0CC645' : '#C60C30'};
    border-radius: 50%;
    width: 60%;
    height: 60%;
  }
  & svg {
    stroke: #C9D6DF;
    width:60%;
    height:60%;
    fill: #C9D6DF;
    stroke-width: 0.1px;
  }
`


const res = [
    [
        {
            category: 'learn',
            eventStartTime: '2023-10-18T12:02:49.821+00:00'
        },
        {
            category: 'clear',
            eventStartTime: '2023-10-18T12:01:42.450+00:00'
        }
    ],
    [
        {
            category: 'eat',
            eventStartTime: '2023-10-20T12:02:49.821+00:00'
        },
        {
            category: 'sleep',
            eventStartTime: '2023-10-20T12:01:42.450+00:00'
        }
    ]
]