import React, {ReactNode} from "react";
import {ContainerBlockQuestions, HeaderGeneralBlock, LineBlock} from "./ContainerForBlockQuestions.styled";

type Props = {
    name: string
    children: ReactNode;
}

export const ContainerForBlockQuestions = ({name, children}: Props) => {
    return (<ContainerBlockQuestions>
        <HeaderGeneralBlock>
            <LineBlock right={true}/>
            <div>{name}</div>
            <LineBlock left={true}/>
        </HeaderGeneralBlock>
        {children}
    </ContainerBlockQuestions>)
}