import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const SelectorStyled = styled.div<{ borderRight?: string, size?: string,borderRadius?:string,border?:string,margin?:string,padding?:string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.size};
  font-family: Verdana, sans-serif;
  font-size: 1em;
  text-align: center;
  margin: ${props=>props.margin};
  padding: ${props=>props.padding};
  border-right: ${props => props.borderRight ? props.borderRight : '1px solid rgba(227, 197, 101, 1)'};
  border: ${props=>props.border};
  border-radius: ${props=>props.borderRadius}; 
  background-color: transparent;
  color: rgba(219, 232, 241, 1);
  cursor: pointer;
`
export const NameSelector = styled.div<{content:boolean}>`
  display: flex;
  justify-content: ${props=>props.content ? 'flex-start' : 'center'};
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis; /* Додає мітку (...) для відображення, що текст обрізаний */
  white-space: nowrap; /* Заборонити перенесення тексту на новий рядок */
`
export const ArrowBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 10%;

  & svg {
    width: 17px;
    height: 17px;
  }
`

export const BlockList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 3;
`
export const VariantChoose = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
  cursor: pointer;
  background-color: rgba(17, 18, 22, 1);
  border: 0.5px solid rgba(82, 97, 107, 1);
  color: rgba(219, 232, 241, 1);
  font-family: Verdana, sans-serif;

  & input {
    appearance: none;
    border: 1px solid transparent;
    outline: none;
    height: 17px;
    width: 17px;
    border-radius: 20px;
    margin-right: 5px;
    position: relative;

    &:not(:checked) {
      border-color: rgba(227, 197, 101, 0.5);
    }

    &:checked {
      border-color: rgba(227, 197, 101, 1);
    }

    &:checked::after {
      content: '✔'; /* Позначка в середині */
      color: rgba(227, 197, 101, 1);
      font-size: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
export const BurmanRadio = styled.div`
  margin-bottom: 10px;
  & input {
    display: none;

    &:checked ~ label::after {
      opacity: 1;
      transform: scale(1);
    }
  }
  & label {
    cursor: pointer;
    line-height: 30px;
    position: relative;
    margin-left: 35px;
    &::before, &::after {
      border-radius: 50%;
      position: absolute;
      top: -1.5px; left: -35px;
      transition: all .3s ease-out;
      z-index: 2;
    }
    &::before {
      content: "";
      border: 1.5px solid red;
      width: 20px; height: 20px;
    }

    &::after {
      content: "\\f26b";
      background: yellow;
      border: 1.5px solid greenyellow;
      color: #FFF;
      font-family: Verdana,sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      width: 20px;
      height: 20px;
      transform: scale(0);
    }
    &:hover {
      &::before { border-color: rebeccapurple; 
      }
  }
`
// export const OptionStyled = styled.option`
//   font-size: 1em;
//   background-color: #111216;
//   color: #c9d6df;
//
//   &:hover,
//   &:focus {
//     background-color: #404040;
//     outline: none;
//     color: #c9d6df;
//   }
//   &:checked {
//     box-shadow: 10px 5px 5px red;
//   }
// `