import {
    ButtonBlockConfirm, ConfirmBtnBackStyled,
    ConfirmBtnStyled,
    Container,
    OverallSettingsTestContainer,
    RowInformation, TypeAnswersStyled
} from "./CheckEnteredData.styled";
import {
    CheckTestHeader,
    ConclusionFieldName,
    ConfirmBtn, formatTest,
    NameField,
    QuestionAnswersHeader,
    TypeQuestion
} from "./constants";
import {ICategory, IConclusion, IQuestion, ISpecification} from "./interface";
import React from "react";

interface Props {
    specification: ISpecification;
    conclusion: IConclusion[]
    category: ICategory[]
    list: IQuestion[]
    customMethod: string[]
    calculationCategoryOnCustomMethod: string
    calculationMethod: string
    setCheckData: React.Dispatch<React.SetStateAction<boolean>>
    confirmFn?: () => void
}

export const CheckEnteredData: React.FC<Props> = ({
                                                      specification,
                                                      conclusion,
                                                      category,
                                                      list,
                                                      customMethod,
                                                      calculationCategoryOnCustomMethod,
                                                      calculationMethod,
                                                      setCheckData,
                                                      confirmFn
                                                  }) => {

    return <Container>
        <div>{CheckTestHeader.TotalSettings}</div>
        <OverallSettingsTestContainer>
            <RowInformation>
                <div>{NameField.name}</div>
                <div>{specification.name ? specification.name : ''}</div>
            </RowInformation>
            {(specification.format !== formatTest.form) && <RowInformation>
                <div>{NameField.topic}</div>
                <div>{specification.topic ? specification.topic : ''}</div>
            </RowInformation>}
            <RowInformation>
                <div>{NameField.shortDescription}</div>
                <div>{specification.shortDescription ? specification.shortDescription : ''}</div>
            </RowInformation>
            <RowInformation>
                <div>{NameField.description}</div>
                <div>{specification.description ? specification.description : ''}</div>
            </RowInformation>
            {(specification.format !== formatTest.form) && <RowInformation>
                <div>{NameField.subject}</div>
                <div>{specification.subject ? specification.subject : ''}</div>
            </RowInformation>}
            <RowInformation>
                <div>{NameField.department}</div>
                <div>{specification.stage ? specification.stage : ''}</div>
            </RowInformation>
            {(specification.format !== formatTest.form) && <RowInformation>
                <div>{NameField.type}</div>
                <div>{specification.type ? specification.type : ''}</div>
            </RowInformation>}
        </OverallSettingsTestContainer>
        {(specification.format !== formatTest.form) && <>
            <div>{CheckTestHeader.category}</div>
            <OverallSettingsTestContainer>
                <RowInformation border={true} fontSize={'14px'} fontWeight={'bold'}>
                    <div>{ConclusionFieldName.name}</div>
                    <div>{ConclusionFieldName.range}</div>
                </RowInformation>
                {
                    category.map((item, index) => (
                        <RowInformation key={index}>
                            <div>{item.name}</div>
                            <div>від {item.value[0]} до {item.value[1]}</div>
                        </RowInformation>
                    ))
                }
            </OverallSettingsTestContainer>
            <div>{CheckTestHeader.conclusion}</div>
            <OverallSettingsTestContainer>
                <RowInformation border={true} fontSize={'14px'} fontWeight={'bold'}>
                    <div>{ConclusionFieldName.name}</div>
                    <div>{ConclusionFieldName.range}</div>
                    <div>{ConclusionFieldName.description}</div>
                </RowInformation>
                {conclusion.map((item, index) => (
                    <RowInformation key={index}>
                        <div>{(item.name === 'Total' ? 'Загальний' : item.name)}</div>
                        <div>від {item.range[0]} до {item.range[1]}</div>
                        <div>{item.description}</div>
                    </RowInformation>
                ))}
            </OverallSettingsTestContainer>
            <div>{CheckTestHeader.calculationCategoryMethod}</div>
            <OverallSettingsTestContainer>
                <RowInformation border={true} fontSize={'14px'} fontWeight={'bold'}
                                justifyContent={(customMethod.length > 0 && calculationCategoryOnCustomMethod) ? 'space-around' : 'flex-start'}>
                    <div>{ConclusionFieldName.name}</div>
                    {customMethod.length > 0 && <div>{ConclusionFieldName.customMethod}</div>}
                    {customMethod.length > 0 && calculationCategoryOnCustomMethod &&
                        <div>{ConclusionFieldName.formula}</div>}
                </RowInformation>
                <RowInformation>
                    <div>{calculationMethod}</div>
                    {(customMethod.length > 0 && calculationCategoryOnCustomMethod) ?
                        <div>{calculationCategoryOnCustomMethod === 'sum' ? 'сума' : 'середнє значення'}</div> :
                        <div></div>}
                    {(customMethod.length > 0 && calculationCategoryOnCustomMethod) ?
                        <div>{customMethod.join('')}</div> :
                        <div></div>}
                </RowInformation>
            </OverallSettingsTestContainer></>
        }
        <div>{CheckTestHeader.questionAndAnswers}</div>
        <OverallSettingsTestContainer>
            <RowInformation border={true} fontSize={'14px'} fontWeight={'bold'}>
                <div>{QuestionAnswersHeader.question}</div>
                <div>{QuestionAnswersHeader.answers}</div>
                {(specification.format !== formatTest.form) && <div>{QuestionAnswersHeader.values}</div>}
                {(specification.format !== formatTest.form) && <div>{QuestionAnswersHeader.category}</div>}
                <TypeAnswersStyled>{QuestionAnswersHeader.typeAnswers}</TypeAnswersStyled>
            </RowInformation>
            {list.map((item, index) => (
                <RowInformation key={index}>
                    <div>{(index + 1) + '. ' + item.question}</div>
                    <div>{Array.isArray(item.answers) ? (
                        item.answers?.map((answer, index) => (
                            <div key={index} style={{borderBottom: 'none', padding: '0px 5px 5px 5px'}}>{answer}</div>
                        ))
                    ) : (
                        item.answers
                    )}</div>
                    {(specification.format !== formatTest.form) && <div>{Array.isArray(item.value) ? (
                        item.value?.map((answer, index) => (
                            <div key={index} style={{borderBottom: 'none', padding: '0px 5px 5px 5px'}}>{answer}</div>
                        ))
                    ) : (
                        item.value
                    )}</div>}
                    {(specification.format !== formatTest.form) && <div>{item.category}</div>}
                    <div>{TypeQuestion.find(type => type.value === item.type)?.text}</div>
                </RowInformation>
            ))}
        </OverallSettingsTestContainer>
        <ButtonBlockConfirm>
            <ConfirmBtnStyled style={{border: '1px solid rgba(51, 131, 44, 1)', color: 'rgba(51, 131, 44, 1)'}}
                 onClick={confirmFn}>{ConfirmBtn.confirm}</ConfirmBtnStyled>
            <ConfirmBtnBackStyled style={{border: '1px solid rgba(222, 108, 108, 1)', color: 'rgba(222, 108, 108, 1)'}}
                 onClick={() => setCheckData(false)}>{ConfirmBtn.back}</ConfirmBtnBackStyled>
        </ButtonBlockConfirm>
    </Container>
}