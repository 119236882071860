import {
    CloseButton,
    BtnTab,
    Container,
    Row,
    BlockChoseCalculationCategory,
    MethodCalculationBlock, ErrorMsg
} from "./CustomMethod.styled";
import React, {useState} from "react";
import {BlockForCustomsMethod} from "./BlockForCustomsMethod";
import {
    calculationCategoryMethod,
    calculationCategoryMethodName,
    NameBtnChoseCalculationCategoryMethod
} from "./constants";

export interface IListCategory {
    name: string
}

type Props = {
    setCustomMethodSettings: React.Dispatch<React.SetStateAction<boolean>>
    listCategory: IListCategory[]
    lowerRow: string[]
    setLowerRow: React.Dispatch<React.SetStateAction<string[]>>
    calculationCategoryOnCustomMethod: string
    setCalculationCategoryOnCustomMethod: React.Dispatch<React.SetStateAction<string>>
}

export const CustomMethod: React.FC<Props> = ({
                                                  setCustomMethodSettings,
                                                  listCategory,
                                                  lowerRow,
                                                  setLowerRow,
                                                  calculationCategoryOnCustomMethod,
                                                  setCalculationCategoryOnCustomMethod
                                              }) => {
    const newList = listCategory.map(category => category.name)
    newList.unshift('+', '-', '*', '/', '=', '(', ')', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0')
    const [upperRow, setUpperRow] = useState<string[]>(newList);
    const [errorMessage, setErrorMessage] = useState<boolean>(false)

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, elem: (string | IListCategory)): void => {
        if (typeof elem === "string") {
            event.dataTransfer.setData('text/plain', elem);
        } else {
            event.dataTransfer.setData('text/plain', elem.name);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        const draggedId = event.dataTransfer.getData('text/plain');
        if (event.currentTarget.classList.contains('lower-row')) {
            setLowerRow([...lowerRow, draggedId]);
        }

    };

    const handleRemoveBlock = (id: number | undefined): void => {
        const updatedLowerRow = lowerRow.filter((_, index) => index !== id);
        setLowerRow(updatedLowerRow);
    };

    const allowDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
    };
    const handleCategoryCalculationMethod = (method: string) => {
        setCalculationCategoryOnCustomMethod(method)
    }
    console.log(calculationCategoryOnCustomMethod)
    return (
        <Container onClick={(e: { stopPropagation: () => void; }) => e.stopPropagation()}>
            <BtnTab>
                <CloseButton onClick={() => {
                    setCustomMethodSettings(false)
                    setLowerRow([])
                }}>X</CloseButton>
            </BtnTab>
            <Row onDragOver={allowDrop}>
                {upperRow.map((elem: string, id: number) => (
                    <BlockForCustomsMethod noneDelete={false} elem={elem} key={id} id={id}
                                           onDragStart={(e) => handleDragStart(e, elem)}/>
                ))}
            </Row>

            <Row onDrop={handleDrop} onDragOver={allowDrop} className="lower-row">
                {lowerRow.map((elem: string, id: number) => (
                    <BlockForCustomsMethod noneDelete={(elem.length > 0)} handleRemoveBlock={handleRemoveBlock}
                                           elem={elem} id={id} onDragStart={(e) => handleDragStart(e, elem)}/>
                ))}
                <BlockForCustomsMethod noneDelete={false} handleRemoveBlock={handleRemoveBlock}
                                       elem={''} onDragStart={(e) => handleDragStart(e, '')}/>
            </Row>
            <BtnTab>
                <BlockChoseCalculationCategory>
                    <div>{NameBtnChoseCalculationCategoryMethod}</div>
                    <MethodCalculationBlock
                        marginLeft={'10px'}
                        format={calculationCategoryOnCustomMethod === calculationCategoryMethod.sum}
                        border={true}
                        onClick={() => handleCategoryCalculationMethod(calculationCategoryMethod.sum)}>{calculationCategoryMethodName.sum}
                    </MethodCalculationBlock>
                    <MethodCalculationBlock
                        format={calculationCategoryOnCustomMethod === calculationCategoryMethod.average}
                        border={false}
                        onClick={() => handleCategoryCalculationMethod(calculationCategoryMethod.average)}>{calculationCategoryMethodName.average}
                    </MethodCalculationBlock>
                </BlockChoseCalculationCategory>
            </BtnTab>
            <BtnTab>
                {errorMessage && <ErrorMsg>Обери тип обрахунку категорій</ErrorMsg>}
                <CloseButton onClick={() => {
                    if (calculationCategoryOnCustomMethod) {
                        setErrorMessage(false)
                        setCustomMethodSettings(false)
                    } else {
                        setErrorMessage(true)
                    }
                }}>Готово</CloseButton>
            </BtnTab>
        </Container>)

}
