import {yellowDuckApi} from "./globalApi";

export const injectedJournalApi = yellowDuckApi.injectEndpoints({
    endpoints: (build) => ({
        getEventsFromUser: build.query({
            query: (body) => ({
                url: '/api/card/events',
                method: 'POST',
                body
            })
        }),
        addFavourite: build.query({
            query: (body) => ({
                url: '/api/card/addFavourites',
                method: 'POST',
                body
            })
        }),
        getAllFavourite: build.query({
            query: (body) => ({
                url: '/api/card/getFavourites',
                method: 'POST',
                body
            })
        }),
        removeFavourites: build.query({
            query: (body) => ({
                url: '/api/card/removeFavourites',
                method: 'POST',
                body
            })
        }),
        readEvents: build.query({
            query: (body) => ({
                url: '/api/journal/readEvents',
                method: 'POST',
                body
            })
        }),
    }),
});

export const {useGetAllFavouriteQuery,
    useLazyRemoveFavouritesQuery,
    useLazyAddFavouriteQuery,
    useLazyGetEventsFromUserQuery,
    useGetEventsFromUserQuery,
    useLazyReadEventsQuery,
} = injectedJournalApi;