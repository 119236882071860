import {Button, Container, TextBlock, TextContainer} from "./ExpandableText.styled";
import {ReactComponent as TriangleUp} from '../../../assets/pictures/TriangleUp.svg';
import {ReactComponent as TriangleDown} from '../../../assets/pictures/TriangleDown.svg';
import React, {useState} from "react";
import {ESpecificQuestions} from "./constants";
import {format} from "date-fns";
import {uk} from "date-fns/locale";
import {Line} from "./ProfileInformation.styled";

type Props = {
    information?: any,
    additionalInformation?: any[],
    header: string,
    visibleAdditionalBtn: boolean,
    questions: { label: string, key: string }[],
    additionalQuestions?: { label: string, key: string }[],
    formatDate?: (date: Date) => string
    personalDetailsAndOther: boolean,
    foreignLanguage?: boolean,
    lineAfterQuestions?:boolean
};

export const BlockInformation = ({
                                     information,
                                     header,
                                     visibleAdditionalBtn,
                                     questions,
                                     formatDate,
                                     additionalInformation,
                                     personalDetailsAndOther,
                                     foreignLanguage,
                                     lineAfterQuestions
                                 }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const convertArrayToString = (arr: { name: string, phoneNumber: string, personEmergency: string }[]) => {
        return arr.map(elem => `${elem.name} (${elem.personEmergency}) ${elem.phoneNumber}`)
    }

    function calculateAge(birthDateString: string): number {
        const birthDate = new Date(birthDateString);
        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    }


    const formatYears = (years: string): string => {
        if (!years || years.length !== 2) return 'N/A';
        const startYear = new Date(years[0]).getFullYear();
        const endYear = new Date(years[1]).getFullYear();
        return `${startYear}-${endYear}`;
    };

    function formatMonthPeriod(dates: string[]): string {
        const [startDate, endDate] = dates.map(date => new Date(date));

        const formattedStartDate = format(startDate, 'LLLL, yyyy', {locale: uk});
        const formattedEndDate = format(endDate, 'LLLL, yyyy', {locale: uk});

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    return (
        <Container borderTop={false} marginTop={'0'}>
            <span>{header}</span>
            <TextContainer>
                {personalDetailsAndOther ?
                    questions.map((question, index, arr) => {
                            if (information[question.key]) {
                                switch (question.label) {
                                    case ESpecificQuestions.NAME:
                                        return <TextBlock key={index}>
                                            <span>{question.label}</span>
                                            <span>{information?.familyName} {information?.firstName} {information?.paternalName}</span>
                                        </TextBlock>
                                    case ESpecificQuestions.AGE:
                                        return <TextBlock key={index}>
                                            <span>{question.label}</span>
                                            <span>{calculateAge(information?.birthDate)}</span>
                                        </TextBlock>
                                    case ESpecificQuestions.BIRTHDATE:
                                        return <TextBlock key={index}>
                                            <span>{question.label}</span>
                                            <span>{formatDate && formatDate(information?.birthDate)}</span>
                                        </TextBlock>
                                    case ESpecificQuestions.YEARSEDUCATION:
                                        return <TextBlock key={index}>
                                            <span>{question.label}</span>
                                            <span>{formatDate && formatYears(information?.yearsEducation)}</span>
                                        </TextBlock>
                                    case ESpecificQuestions.WORKINGPERIOD:
                                        return <TextBlock key={index}>
                                            <span>{question.label}</span>
                                            <span>{formatDate && formatMonthPeriod(information?.workingPeriod)}</span>
                                        </TextBlock>
                                    default:
                                        return <><TextBlock key={index}>
                                            <span>{question.label}</span>
                                            <span>{Array.isArray(information[question.key]) ? convertArrayToString(information[question.key]) : information[question.key]}</span>
                                        </TextBlock>
                                        {index !== arr.length - 1 && lineAfterQuestions && <Line></Line>}
                                            {index == arr.length - 1 && additionalInformation && !lineAfterQuestions && <Line></Line>}
                                        </>
                                }
                            }
                        }
                    ) :
                    information?.map((elem: { [x: string]: string; }, index: number, arr: {
                        [x: string]: string;
                    }[]) => {
                        if (foreignLanguage) return <><TextBlock key={index}><span>{elem.name}</span>
                            <span>({elem.levelProficiency})</span></TextBlock>{index !== arr.length - 1 &&
                            <Line></Line>}</>
                        return <>
                            {questions.map((detail, detailIndex) => {
                                    if (elem[detail.key]) {
                                        return <TextBlock key={detailIndex}><span>{detail.label}</span>
                                            <span>{elem[detail.key]}</span></TextBlock>
                                    }
                                }
                            )}
                            {index !== arr.length - 1 && <Line></Line>}
                        </>
                    })}
                {isExpanded && <><Line></Line>
                    {additionalInformation?.map((elem: { [x: string]: string | string[]; }, index: number, arr: {
                        [x: string]: string;
                    }[]) => {
                        if (foreignLanguage) return <><TextBlock key={index}><span>{elem.name}</span>
                            <span>({elem.levelProficiency})</span></TextBlock>{index !== arr.length - 1 &&
                            <Line></Line>}</>
                        return <>
                            {questions?.map((detail, detailIndex) => {
                                    if (elem[detail.key]) {
                                        return <TextBlock key={detailIndex}>
                                            <span>{detail.label}</span>
                                            <span>{detail.key === 'yearsEducation' ? formatYears(elem[detail.key] as string) : detail.key === 'workingPeriod' && Array.isArray(elem.workingPeriod) ? formatMonthPeriod(elem.workingPeriod) : elem[detail.key] || 'N/A'}</span></TextBlock>
                                    }
                                }
                            )}
                            {index !== arr.length - 1 && <Line></Line>}
                        </>
                    })}</>}
                {visibleAdditionalBtn && (
                    <Button>
                        {isExpanded ?
                            <div onClick={toggleExpand}><TriangleUp/></div> :
                            <div onClick={toggleExpand}><TriangleDown/></div>
                        }
                    </Button>
                )}
            </TextContainer>
        </Container>
    )
}