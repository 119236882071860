import { ResponsivePie } from '@nivo/pie'
import {LegendsEventForCharts} from "../constants";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no areaBumpChart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

type Props = {
    data: any
    innerRadius: number
}
const customColors = ['rgba(227, 197, 101, 0.1)', 'rgba(227, 197, 101, 0.3)', 'rgba(227, 197, 101, 0.5)', 'rgba(227, 197, 101, 0.7)','rgba(227, 197, 101, 1)'];
export const PieChart = ({ data,innerRadius /* see data tab */ }:Props) => (
    <ResponsivePie
        data={data}
        margin={{ top: 1, right: 50, bottom: 1, left: 50 }}
        sortByValue={true}
        fit={false}
        innerRadius = {innerRadius}
        borderColor={{ theme: 'background' }}
        enableArcLinkLabels={false}
        arcLinkLabelsTextColor="#c9d6df"
        arcLinkLabelsStraightLength={0}
        arcLinkLabelsColor="#c9d6df"
        arcLabelsRadiusOffset={0.65}
        arcLabel={e=>e.id+"("+e.value+")"}
        arcLabelsSkipAngle={16}
        arcLabelsTextColor={{ theme: 'grid.line.stroke' }}
        colors={({ id }) => LegendsEventForCharts[id as string] || '#000'}
        // defs={[
        //     {
        //         id: 'dots',
        //         type: 'normal',
        //         background: 'inherit',
        //         color: 'rgba(255, 255, 255, 0.3)',
        //         size: 4,
        //         padding: 1,
        //         stagger: true
        //     },
        //     {
        //         id: 'lines',
        //         type: 'normal',
        //         background: 'inherit',
        //         color: 'rgba(255, 255, 255, 0.3)',
        //         rotation: -55,
        //         lineWidth: 6,
        //         spacing: 10
        //     }
        // ]}
        fill={[
            {
                match: {
                    id: 'ruby'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'c'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'go'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'python'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'scala'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'lisp'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'elixir'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'javascript'
                },
                id: 'lines'
            }
        ]}

    />
)