export const departmentList = [
    {value:'',name:'Відділ'},
    {value:'Draft',name:'Draft'},
    {value:'Games',name:'Games'},
    {value:'Workshop',name:'Workshop'},
    {value:'Factory',name:'Factory'},
]

export const typeList = [
    {value:'',name:'Тип'},
    {value:'softSkills',name:'SoftSkills'},
    {value:'hardSkills',name:'HardSkills'},
]

export const subjectList = [
    {value:'',name:'Предмет'},
    {value:'Computer Science',name:'Computer Science'},
    {value:'English',name:'English'},
    {value:'Psycho',name:'Psycho'},
]