import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {yellowDuckApi} from "./api/globalApi";
import {setupListeners} from "@reduxjs/toolkit/query";
import {authReducer} from "./auth.slice";

export const rootReducer:any = combineReducers({
    [yellowDuckApi.reducerPath]: yellowDuckApi.reducer,
    auth: authReducer,

})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(yellowDuckApi.middleware)
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;



// export const store = configureStore({
//     reducer: {
//         [testApi.reducerPath]: testApi.reducer,
//         auth: authReducer,
//     },
//     middleware: getDefaultMiddleware => getDefaultMiddleware().concat(testApi.middleware)
// })