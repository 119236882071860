import {
    AmountCategory,
    AmountCategoryInput,
    ArrowBlock,
    BlockChosenMethod,
    BlockNameCategoryWithRange,
    BlockRange,
    ConclusionBlock,
    Container,
    List,
    Method,
    NameMethod,
    NameWithInput,
    TableCategory,
    Variant
} from "./KeysSettings.styled";
import React, {ChangeEvent, useRef, useState} from "react";
import {ReactComponent as ArrowOpen} from "../../../assets/pictures/ArrowForSelectorOpen.svg";
import {ReactComponent as ArrowClose} from "../../../assets/pictures/ArrowForSelectorClose.svg";
import {blockKeys, calculateMethod, conclusionsRoaster} from "./constants";
import {CustomMethod} from "./CustomMethod";
import {ICategory, IConclusion} from "./interface";

interface Props {
    category: ICategory[]
    setCategory: React.Dispatch<React.SetStateAction<ICategory[]>>
    conclusion: IConclusion[][]
    setConclusion: React.Dispatch<React.SetStateAction<IConclusion[][]>>
    customMethod: string[]
    setCustomMethod: React.Dispatch<React.SetStateAction<string[]>>
    conclusionText: string
    setConclusionText: React.Dispatch<React.SetStateAction<string>>
    calculationMethod: string
    setCalculationMethod: React.Dispatch<React.SetStateAction<string>>
    calculationCategoryOnCustomMethod: string
    setCalculationCategoryOnCustomMethod: React.Dispatch<React.SetStateAction<string>>
    conclusionAmount: number[]
    setConclusionAmount: React.Dispatch<React.SetStateAction<number[]>>
    conclusionDescription: string[][]
    setConclusionDescription: React.Dispatch<React.SetStateAction<string[][]>>
    minRange: number [][]
    setMinRange: React.Dispatch<React.SetStateAction<number[][]>>
    maxRange: number [][]
    setMaxRange: React.Dispatch<React.SetStateAction<number[][]>>
    conclusionTotalAmount: number
    setConclusionTotalAmount: React.Dispatch<React.SetStateAction<number>>
    conclusionTotalDescription: string[]
    setConclusionTotalDescription: React.Dispatch<React.SetStateAction<string[]>>
    minTotalRange: number[]
    setMinTotalRange: React.Dispatch<React.SetStateAction<number[]>>
    maxTotalRange: number[]
    setMaxTotalRange: React.Dispatch<React.SetStateAction<number[]>>
    setCheckData: React.Dispatch<React.SetStateAction<boolean>>
}

export const KeysSettings: React.FC<Props> = ({
                                                  category,
                                                  setCategory,
                                                  conclusion,
                                                  setConclusion,
                                                  customMethod,
                                                  setCustomMethod,
                                                  conclusionText,
                                                  setConclusionText,
                                                  calculationMethod,
                                                  setCalculationMethod,
                                                  calculationCategoryOnCustomMethod,
                                                  setCalculationCategoryOnCustomMethod,
                                                  conclusionAmount,
                                                  setConclusionAmount,
                                                  conclusionDescription,
                                                  setConclusionDescription,
                                                  minRange,
                                                  setMinRange,
                                                  maxRange,
                                                  setMaxRange,
                                                  conclusionTotalAmount,
                                                  setConclusionTotalAmount,
                                                  conclusionTotalDescription,
                                                  setConclusionTotalDescription,
                                                  minTotalRange,
                                                  setMinTotalRange,
                                                  maxTotalRange,
                                                  setMaxTotalRange,
                                                  setCheckData
                                              }: Props) => {
    const [amountCategory, setAmountCategory] = useState<number>(0)
    const [keyInput, setKeyInput] = useState<string[]>([]);
    const [maxValue, setMaxValue] = useState<number[]>([])

    //conclusion
    const [conclusionList, setConclusionList] = useState<boolean>(false)


    //calculation
    const [calculationMethodList, setCalculationMethodList] = useState<boolean>(false)
    const [customMethodSettings, setCustomMethodSettings] = useState<boolean>(false)


    const handleAmountCategory = (event: ChangeEvent<HTMLInputElement>, block: string) => {
        if (event.target.valueAsNumber && event.target.valueAsNumber >= 0 && block === blockKeys.category) {
            setAmountCategory(event.target.valueAsNumber)
            setCategory(Array(event.target.valueAsNumber).fill({
                name: '',
                value: [],
            }))
            setKeyInput(Array(event.target.valueAsNumber).fill(''))
            setMaxValue(Array(event.target.valueAsNumber).fill(''))
        }
        if (event.target.valueAsNumber && event.target.valueAsNumber >= 0 && block === blockKeys.conclusion) {
            setConclusion(Array(event.target.valueAsNumber).fill({}))
        }
    }

    const handleCategoryConclusionAmount = (event: ChangeEvent<HTMLInputElement>, name: string, index: number): void => {
        setConclusionAmount(prevState => {
            const newArr = [...prevState]
            newArr[index] = event.target.valueAsNumber
            return newArr
        })

        if (event.target.valueAsNumber !== 0 && event.target.valueAsNumber > 0) {

            setConclusion(prevState => {
                const currentDescription = [...prevState];
                const existingRow = currentDescription[index];

                if (existingRow && existingRow[0]?.name === name) {
                    // Оновити кількість об'єктів в існуючому масиві
                    currentDescription[index] = Array(event.target.valueAsNumber).fill({
                        name: name,
                        range: [0, 0],
                        description: ''
                    });
                } else {
                    // Додати новий масив з вказаною кількістю об'єктів
                    currentDescription.splice(index, 0, Array(event.target.valueAsNumber).fill({
                        name: name,
                        range: [0, 0],
                        description: ''
                    }));
                }

                return currentDescription;
            });

            const newArrayDescription: string[] = Array(event.target.valueAsNumber).fill('');
            const newArrayMinRange: number[] = Array(event.target.valueAsNumber).fill(0);
            const newArrayMaxRange: number[] = Array(event.target.valueAsNumber).fill(0);

            const currentMinRange = [...minRange]
            const currentMaxRange = [...maxRange]
            const currentDescription = [...conclusionDescription];

            setConclusionDescription([...currentDescription, newArrayDescription]);
            setMaxRange([...currentMaxRange, newArrayMaxRange]);
            setMinRange([...currentMinRange, newArrayMinRange]);

            // if (event.target.valueAsNumber > currentDescription.length) {
            //     const difference = event.target.valueAsNumber - currentDescription.length;
            //     const test: string[] = Array(difference).fill('');
            //     setConclusionDescription([...currentDescription, test]);
            // } else if (event.target.valueAsNumber < currentDescription.length) {
            //     const difference = currentDescription.length - event.target.valueAsNumber;
            //     setConclusionDescription(currentDescription.slice(0, -difference));
            // }
            // else {
            //     currentDescription.splice(index, 0, newArrayDescription);
            //     setConclusionDescription(currentDescription);
            // }

            // if (event.target.valueAsNumber > minRange.length) {
            //     const difference: number = event.target.valueAsNumber - minRange.length;
            //     const test: number[] = Array(difference).fill(0);
            //     setMinRange([...currentMinRange, test]);
            // } else if (event.target.valueAsNumber < minRange.length) {
            //     const difference = minRange.length - event.target.valueAsNumber;
            //     setMinRange(currentMinRange.slice(0, -difference));
            // } else {
            //     currentMinRange.splice(index, 0, newArrayMinRange);
            //     setMinRange(currentMinRange);
            // }
            //
            // if (event.target.valueAsNumber > maxRange.length) {
            //     const difference: number = event.target.valueAsNumber - maxRange.length;
            //     const test: number[] = Array(difference).fill(0);
            //     setMaxRange([...currentMaxRange, test]);
            // } else if (event.target.valueAsNumber < maxRange.length) {
            //     const difference = maxRange.length - event.target.valueAsNumber;
            //     setMaxRange(currentMaxRange.slice(0, -difference));
            // } else {
            //     currentMaxRange.splice(index, 0, newArrayMaxRange);
            //     setMaxRange(currentMaxRange);
            // }


            // Total
            //     if (event.target.valueAsNumber > currentTotalDescription.length) {
            //         const difference = event.target.valueAsNumber - currentTotalDescription.length;
            //         const test: string[] = Array(difference).fill('');
            //         setConclusionTotalDescription([...currentTotalDescription, test]);
            //     } else if (event.target.valueAsNumber < currentTotalDescription.length) {
            //         const difference = currentTotalDescription.length - event.target.valueAsNumber;
            //         setConclusionTotalDescription(currentTotalDescription.slice(0, -difference));
            //     } else {
            //         currentTotalDescription.splice(index, 0, newArrayTotalDescription);
            //         setConclusionTotalDescription(currentTotalDescription);
            //     }
            //
            //     if (event.target.valueAsNumber > minTotalRange.length) {
            //         const difference: number = event.target.valueAsNumber - minTotalRange.length;
            //         const test: number[] = Array(difference).fill(0);
            //         setMinTotalRange([...currentMinRange, test]);
            //     } else if (event.target.valueAsNumber < minTotalRange.length) {
            //         const difference = minTotalRange.length - event.target.valueAsNumber;
            //         setMinTotalRange(currentMinRange.slice(0, -difference));
            //     } else {
            //         currentMinTotalRange.splice(index, 0, newArrayTotalMinRange);
            //         setMinTotalRange(currentMinRange);
            //     }
            //
            //     if (event.target.valueAsNumber > maxTotalRange.length) {
            //         const difference: number = event.target.valueAsNumber - maxTotalRange.length;
            //         const test: number[] = Array(difference).fill(0);
            //         setMaxTotalRange([...currentMaxRange, test]);
            //     } else if (event.target.valueAsNumber < maxTotalRange.length) {
            //         const difference = maxTotalRange.length - event.target.valueAsNumber;
            //         setMaxTotalRange(currentMaxRange.slice(0, -difference));
            //     } else {
            //         currentMaxTotalRange.splice(index, 0, newArrayTotalMaxRange);
            //         setMaxTotalRange(currentMaxRange);
            //     }
        }
    }

    const handleConclusionType = (elem: string): void => {
        setConclusionText(elem);
        setConclusionList(false)
        setCheckData(false)
        // if (conclusion) {
        //     switch (elem) {
        //         case conclusionsRoaster[0]:
        //             setConclusion(prevState => {
        //                 return prevState.filter(row => row.every(item => item.name === 'Total'))
        //             })
        //             break
        //         case conclusionsRoaster[1]:
        //                     conclusionAmount.forEach((categoryAmount: number,index:number) => {
        //                         if (categoryAmount) {
        //                             setConclusion(prevState => {
        //                                 const newArr = [...prevState]
        //                                 newArr[index] = Array(categoryAmount).fill({
        //                                     name: category[index].name,
        //                                     range: [0, 0],
        //                                     description: ''
        //                                 })
        //                                 return newArr
        //                             })
        //                         }
        //                     })
        //                     setConclusion(prevState => {
        //                         if (conclusionTotalAmount) {
        //                             return [...prevState,Array(conclusionTotalAmount).fill({
        //                                 name: 'Total',
        //                                 range: [0, 0],
        //                                 description: ''
        //                             })]
        //                         } else {
        //                             return prevState
        //                         }
        //                     })
        //                     break
        //         case conclusionsRoaster[2]:
        //             setConclusion(prevState => {
        //                 return prevState.filter(row => row.every(item => item.name !== 'Total'))
        //             })
        //             break
        //     }
        // }
        // switch (elem) {
        //     case conclusionsRoaster[0]:
        //         setConclusion(prevState => {
        //             if (conclusionTotalAmount) {
        //                 return Array(conclusionTotalAmount).fill({
        //                     name: 'Total',
        //                     range: [0, 0],
        //                     description: ''
        //                 })
        //             } else {
        //                 return prevState
        //             }
        //         })
        //         break
        //     case conclusionsRoaster[1]:
        //         conclusionAmount.forEach((categoryAmount: number) => {
        //             if (categoryAmount) {
        //                 setConclusion(Array(conclusionTotalAmount).fill({
        //                     name: '',
        //                     range: [0, 0],
        //                     description: ''
        //                 }))
        //             }
        //         })
        //         setConclusion(prevState => {
        //             if (conclusionTotalAmount) {
        //                 return Array(conclusionTotalAmount).fill({
        //                     name: 'Total',
        //                     range: [0, 0],
        //                     description: ''
        //                 })
        //             } else {
        //                 return prevState
        //             }
        //         })
        //         break
        //     case conclusionsRoaster[2]:
        //         conclusionAmount.forEach((categoryAmount: number) => {
        //             if (categoryAmount) {
        //                 setConclusion(Array(categoryAmount).fill({
        //                     name: '',
        //                     range: [0, 0],
        //                     description: ''
        //                 }))
        //             }
        //         })
        //         break
        // }
        // setConclusion(prevState => {
        //     const newArr = [...prevState]
        //     return newArr.map((elem:IConclusion[],index:number) => {
        //         if (conclusionAmount[index]) {
        //             return Array(conclusionAmount[index]).fill({
        //                 name:'',
        //                 range:[],
        //                 description:''
        //             })
        //         } else if (conclusionTotalAmount) {
        //             return Array(conclusionTotalAmount).fill({
        //                 name:'',
        //                 range:[],
        //                 description:''
        //             })
        //         } else {
        //             return elem
        //         }
        //     })
        // if (elem === conclusionsRoaster[0]) {
        //     return prevState.filter(row => row.every(item => item.name === 'Total'))
        // } else if (elem === conclusionsRoaster[2]) {
        //     return prevState.filter(row => row.every(item => item.name !== 'Total'))
        // } else {
        //     return prevState
        // }
        // })
    }

    const handleAddCategory = (indexToUpdate: number): void => {
        setCategory((prevCategory) => {
            const updatedCategory = [...prevCategory];
            updatedCategory[indexToUpdate] = {
                ...updatedCategory[indexToUpdate],
                name: keyInput[indexToUpdate],
            };
            return updatedCategory;
        });
    };
    const handleAddCategoryValue = (indexToUpdate: number) => {
        setCategory((prevCategory) => {
            const updatedCategory = [...prevCategory];
            updatedCategory[indexToUpdate].value = [0, maxValue[indexToUpdate]];
            return updatedCategory; // Повертаємо оновлений стан
        });
    }

    const handleKeyInputChange = (event: ChangeEvent<HTMLInputElement>, index: number, setState: React.Dispatch<React.SetStateAction<string[]>>): void => {
        setState(prev => {
            const newArr = [...prev]
            newArr[index] = event.target.value
            return newArr
        })
    };

    const handleAddValue = (event: ChangeEvent<HTMLInputElement>, index: number, setState: React.Dispatch<React.SetStateAction<number[]>>): void => {
        setState(prev => {
            const newArr = [...prev]
            newArr[index] = event.target.valueAsNumber
            return newArr
        })

    };

    const handleAddConclusionRange = (indexCategory: number, MinMaxValue: number, indexConclusion: number, value: number[][]): void => {

        setConclusion((prevConclusion) => {
            const updatedConclusion = [...prevConclusion];
            const updatedInnerArray = [...updatedConclusion[indexCategory]];
            updatedInnerArray[indexConclusion] = {
                ...updatedInnerArray[indexConclusion],
                range: [...updatedInnerArray[indexConclusion].range],
            };
            updatedInnerArray[indexConclusion].range[MinMaxValue] = value[indexCategory][indexConclusion];
            updatedConclusion[indexCategory] = updatedInnerArray;
            return updatedConclusion;
        });
    }


    const handleAddMinValueConclusion = (e: ChangeEvent<HTMLInputElement>, indexCategory: number, indexConclusion: number) => {
        setMinRange(prev => {
            const newArrValue = [...prev]
            newArrValue[indexCategory][indexConclusion] = e.target.valueAsNumber
            return newArrValue
        })
    }
    const handleAddMaxValueConclusion = (e: ChangeEvent<HTMLInputElement>, indexCategory: number, indexConclusion: number) => {
        setMaxRange(prev => {
            const newArrValue = [...prev]
            newArrValue[indexCategory][indexConclusion] = e.target.valueAsNumber
            return newArrValue
        })
    }
    const handleAddDescriptionConclusion = (e: React.ChangeEvent<HTMLTextAreaElement>, indexCategory: number, indexConclusion: number) => {
        setConclusionDescription(prev => {
            const newArrValue = [...prev]
            newArrValue[indexCategory][indexConclusion] = e.target.value
            return newArrValue
        })
    }

    const handleDescriptionConclusion = (indexCategory: number, indexConclusion: number): void => {
        setConclusion((prevConclusion) => {
            const updatedConclusion = [...prevConclusion];
            const updatedInnerArray = [...updatedConclusion[indexCategory]];
            updatedInnerArray[indexConclusion] = {
                ...updatedInnerArray[indexConclusion],
                description: conclusionDescription[indexCategory][indexConclusion],
            };
            updatedConclusion[indexCategory] = updatedInnerArray;
            return updatedConclusion;
        });
    }

    const handleAddAmountTotalConclusion = (event: ChangeEvent<HTMLInputElement>): void => {
        setConclusionTotalAmount(event.target.valueAsNumber)
        if (isNaN(event.target.valueAsNumber)) return
        setConclusion(prevConclusion => {
            let newArr: IConclusion[][] = [...prevConclusion]
            if ((isNaN(event.target.valueAsNumber)) || event.target.valueAsNumber <= 0) {
                return newArr.filter(row => row.every(item => item.name !== 'Total'))
            } else {
                return [...newArr.filter(row => row.every(item => item.name !== 'Total')), Array(event.target.valueAsNumber).fill({
                    name: 'Total',
                    range: [0, 0],
                    description: ''
                })]
            }
        });
    }

    const listTotalConclusion = () => {
        return conclusion.flat().filter(elem => elem.name === 'Total')
    }

    const handleTotalConclusion = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number): void => {
        setConclusionTotalDescription(prevDescription => {
            const updatedDescription = [...prevDescription];
            updatedDescription[index] = e.target.value;
            return updatedDescription;
        });
    }

    const handleTotalRangeConclusion = (e: ChangeEvent<HTMLInputElement>, index: number, setState: React.Dispatch<React.SetStateAction<number[]>>): void => {
        setState(prev => {
            const updatedRange = [...prev];
            updatedRange[index] = e.target.valueAsNumber;
            return updatedRange;
        });
    }

    const handleAddTotalDescriptionConclusion = (index: number): void => {
        setConclusion(prevConclusion => {
            const newArray = [...prevConclusion];
            const totalArrayIndex = newArray.findIndex(row => row.some(item => item.name === 'Total'));
            if (totalArrayIndex !== -1 && index >= 0 && index < newArray[totalArrayIndex].length) {
                newArray[totalArrayIndex][index] = {
                    ...newArray[totalArrayIndex][index],
                    description: conclusionTotalDescription[index],
                };
            }
            return newArray;
        });
    }

    const handleAddTotalRangeConclusion = (index: number, indexForRange: number, value: number[]): void => {
        setConclusion(prevConclusion => {
            const newArray = [...prevConclusion];

            // Шукаємо масив, в якому name === 'Total'
            const totalArrayIndex = newArray.findIndex(row => row.some(item => item.name === 'Total'));

            const updatedRange = [...newArray[totalArrayIndex][index].range];
            updatedRange[indexForRange] = value[index];
            newArray[totalArrayIndex][index] = {
                ...newArray[totalArrayIndex][index],
                range: updatedRange,
            };

            return newArray;
        });
    }


    const textarea = useRef<HTMLTextAreaElement | null>(null)
    const textareaInputHandler = () => {
        if (textarea.current) {
            textarea.current.style.height = '18px';
            textarea.current.style.height = `${Math.min(textarea.current.scrollHeight, 100)}px`;
        }
    };
    return <Container>
        <div>Ключі</div>
        <AmountCategory>
            <AmountCategoryInput top={'5%'}>
                Кількість категорій:
                <input type={"number"} onChange={(e) => handleAmountCategory(e, blockKeys.category)}/>
            </AmountCategoryInput>
        </AmountCategory>
        <TableCategory height={'20vh'}>
            {Array(amountCategory).fill(0).map((_, index) => (
                <BlockNameCategoryWithRange key={index}>
                    <NameWithInput key={index}>
                        <div>назва</div>
                        <input
                            type="text"
                            placeholder="Введи назву"
                            value={keyInput[index]}
                            onChange={(e) => handleKeyInputChange(e, index, setKeyInput)}
                            onBlur={() => handleAddCategory(index)}
                        />
                    </NameWithInput>
                    <BlockRange>
                        <div>
                            <div>max</div>
                            <input
                                type="number"
                                value={maxValue[index]}
                                onChange={(e) => handleAddValue(e, index, setMaxValue)}
                                onBlur={() => handleAddCategoryValue(index)}
                            />
                        </div>
                    </BlockRange>
                </BlockNameCategoryWithRange>
            ))}
        </TableCategory>
        {amountCategory > 0 && <ConclusionBlock>
            <AmountCategory>
                <AmountCategoryInput top={'2%'}>
                    Висновок:
                    <BlockChosenMethod onClick={() => setConclusionList(!conclusionList)}>
                        <NameMethod>{conclusionText}</NameMethod>
                        <ArrowBlock>
                            {conclusionList ? <ArrowClose/> : <ArrowOpen/>}
                        </ArrowBlock>
                        {conclusionList && <List>
                            {conclusionsRoaster.map((elem: string, index: number) => (
                                <Variant key={index} onClick={() => handleConclusionType(elem)}>{elem}</Variant>
                            ))}
                        </List>}
                    </BlockChosenMethod>
                </AmountCategoryInput>
            </AmountCategory>
            <TableCategory height={'43vh'}>
                {(conclusionText !== conclusionsRoaster[0] && conclusionText !== '') && category.map((elem: {
                    name: string
                }, index: number) => (
                    <>
                        {elem.name && <AmountCategory>
                            <AmountCategoryInput top={'0.5%'} widthInput={'10%'}>
                                {elem.name}
                                <input type={"number"}
                                       value={conclusionAmount[index]}
                                       onChange={(e) => handleCategoryConclusionAmount(e, elem.name, index)}/>
                            </AmountCategoryInput>
                        </AmountCategory>}
                        {Array.isArray(conclusion[index]) && conclusion[index].filter(item => item.name !== 'Total').map((_, indexItem: number) => (
                            <BlockNameCategoryWithRange key={indexItem}>
                                <NameWithInput>
                                    <div>назва</div>
                                    <textarea
                                        placeholder="Введи назву"
                                        ref={textarea}
                                        onInput={textareaInputHandler}
                                        value={conclusionDescription[index][indexItem]}
                                        onChange={(e) => handleAddDescriptionConclusion(e, index, indexItem)}
                                        onBlur={() => handleDescriptionConclusion(index, indexItem)}
                                    />
                                </NameWithInput>
                                <BlockRange>
                                    <div>
                                        <div>min</div>
                                        <input
                                            value={minRange[index][indexItem]}
                                            type="number"
                                            onChange={(e) => handleAddMinValueConclusion(e, index, indexItem)}
                                            onBlur={() => handleAddConclusionRange(index, 0, indexItem, minRange)}
                                        />
                                    </div>
                                    <div>
                                        <div>max</div>
                                        <input
                                            value={maxRange[index][indexItem]}
                                            type="number"
                                            onChange={(e) => handleAddMaxValueConclusion(e, index, indexItem)}
                                            onBlur={() => handleAddConclusionRange(index, 1, indexItem, maxRange)}
                                        />
                                    </div>
                                </BlockRange>
                            </BlockNameCategoryWithRange>
                        ))}
                    </>
                ))}
                {(conclusionText === conclusionsRoaster[0] || conclusionText === conclusionsRoaster[1]) && <>
                    <AmountCategory>
                        <AmountCategoryInput top={'0.5%'}>
                            Загальний:
                            <input type={"number"} value={conclusionTotalAmount} min={0}
                                   onChange={(e) => handleAddAmountTotalConclusion(e)}/>
                        </AmountCategoryInput>
                    </AmountCategory>

                    {listTotalConclusion()?.map((_, indexTotalItem: number) => (
                        <BlockNameCategoryWithRange key={indexTotalItem}>
                            <NameWithInput>
                                <div>назва</div>
                                <textarea
                                    ref={textarea}
                                    onInput={textareaInputHandler}
                                    placeholder="Введи назву"
                                    value={conclusionTotalDescription[indexTotalItem]}
                                    onChange={(e) => handleTotalConclusion(e, indexTotalItem)}
                                    onBlur={() => handleAddTotalDescriptionConclusion(indexTotalItem)}
                                />
                            </NameWithInput>
                            <BlockRange>
                                <div>
                                    <div>min</div>
                                    <input
                                        value={minTotalRange[indexTotalItem]}
                                        type="number"
                                        onChange={(e) => handleTotalRangeConclusion(e, indexTotalItem, setMinTotalRange)}
                                        onBlur={() => handleAddTotalRangeConclusion(indexTotalItem, 0, minTotalRange)}
                                    />
                                </div>
                                <div>
                                    <div>max</div>
                                    <input
                                        value={maxTotalRange[indexTotalItem]}
                                        type="number"
                                        onChange={(e) => handleTotalRangeConclusion(e, indexTotalItem, setMaxTotalRange)}
                                        onBlur={() => handleAddTotalRangeConclusion(indexTotalItem, 1, maxTotalRange)}
                                    />
                                </div>
                            </BlockRange>
                        </BlockNameCategoryWithRange>
                    ))}</>}
            </TableCategory>
        </ConclusionBlock>}

        {conclusionText && <Method>
            <NameMethod>Спосіб обрахунку:</NameMethod>
            <BlockChosenMethod onClick={() => setCalculationMethodList(!calculationMethodList)}>
                <NameMethod>{calculationMethod}</NameMethod>
                <ArrowBlock>
                    {calculationMethodList ? <ArrowClose/> : <ArrowOpen/>}
                </ArrowBlock>
                {calculationMethodList && <List>
                    {calculateMethod.map((elem: string, index: number) => (
                        <Variant key={index} onClick={(): void => {
                            setCalculationMethod(elem);
                            setCalculationMethodList(false)
                            if (elem === calculateMethod[calculateMethod.length - 1]) {
                                setCustomMethodSettings(true)
                            } else{
                                setCalculationCategoryOnCustomMethod('')
                                setCustomMethod([])
                            }
                        }}>{elem}</Variant>
                    ))}
                </List>}

            </BlockChosenMethod>
            {customMethodSettings && <CustomMethod lowerRow={customMethod} setLowerRow={setCustomMethod}
                                                   setCustomMethodSettings={setCustomMethodSettings}
                                                   listCategory={category.map(({name}) => ({name}))}
                                                   calculationCategoryOnCustomMethod={calculationCategoryOnCustomMethod}
                                                   setCalculationCategoryOnCustomMethod={setCalculationCategoryOnCustomMethod}/>}
        </Method>}
        {/*<button onClick={() => console.log(conclusion)}>qq</button>*/}
        {/*<button onClick={() => handleTestConclusion()}>qqq</button>*/}
        {/*<button onClick={() => console.log(conclusionTotalDescription)}>c</button>*/}
    </Container>
}