import {AvatarAndLogOut, HeaderStyled, SnowBlock} from "./Header.styled";
import React from "react";
import {ReactComponent as Avatar} from "../assets/pictures/Avatar.svg";
import {ReactComponent as OFF} from "../assets/pictures/Off.svg";
import {useLazyLogoutQuery} from "../store/api/authApi";
import axios from "axios";
import {NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import {path} from "../../utils/path";
import Cookies from "universal-cookie";
import Snowfall from "react-snowfall";
import {SparklesPreview} from "../ui/SparklesPreview";
import {EHeaders} from "./constants";

axios.defaults.withCredentials = true



export const Header: React.FC = () => {
    const location = useLocation()
    const cookies: Cookies = new Cookies()
    const navigate: NavigateFunction = useNavigate()
    const [fetch] = useLazyLogoutQuery()
    const nameHeader = () => {
        switch (location.pathname) {
            case path.journal:
                return <div>
                    <span>{EHeaders.JOURNAL}</span>
                </div>
            case path.admin:
                return <div>
                    <span>{EHeaders.ADMIN}</span>
                </div>
            case path.knowledgeTesting:
                return <div>
                    <span>{EHeaders.KNOWLEDGETESTING}</span>
                </div>
            case path.statistics:
                return <div>
                    <span>{EHeaders.STATISTICS}</span>
                </div>
            case path.adminPage:
                return <div>
                    <span>{EHeaders.ADMINPAGE}</span>
                </div>
            case path.favourite:
                return <div>
                    <span>{EHeaders.FAVOURITE}</span>
                </div>
            case path.profile:
                return <div>
                    <span>{EHeaders.PROFILE}</span>
                </div>
        }
    }

    return (<HeaderStyled>
        <SnowBlock>
            <SparklesPreview />
                {/*<Snowfall snowflakeCount={40}/>*/}
        </SnowBlock>
        {nameHeader()}
        <AvatarAndLogOut>
            <div>
                <Avatar/>
            </div>
            <div onClick={() => {
                fetch('').then(() => {
                    cookies.remove('EMSTOKEN')
                    cookies.remove('FBTOKEN')
                    navigate(`${path.home}`)
                })}
            }>
                <OFF/>
            </div>
        </AvatarAndLogOut>
    </HeaderStyled>)
}