import {  useEffect, useMemo, useState } from 'react'

export default function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref])

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [ref.current])

    return isIntersecting
}