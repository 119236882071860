import {
    BlockInfo,
    Container,
    QuestionsBlock,
    MainBlockStyled,
    PanelChoseTests,
    ContainerTests,
    NameBlockTest,
    HeaderStyled,
    ContainerQuestions,
    ContainerBtn,
    BtnSubmit,
    SvgNearNameTest, AdditionalTestsBlock, NameBlockMoreTest, SortBlock, ListTest, DescriptionBlock
} from "./Testing.styled";
import React, {useEffect, useState} from "react";
import {QuestionAndAnswer} from "./QuestionAndAnswer";
import {Answers} from "./Answers";
import {ReactComponent as Success} from "../../assets/pictures/PassingTestMark.svg";
import {ReactComponent as OpenAccessLock} from "../../assets/pictures/OpenAccessLockForTest.svg";
import {toast, ToastContainer} from "react-toastify";
import {AddNewTest} from "./createNewTest/AddNewTest";
import {ReactComponent as ListSettings} from "../../assets/pictures/ListSettings.svg";
import {DragAndDrop} from "./DragAndDrop";
import {IQuestion} from "./createNewTest/interface";
import {ReactComponent as Info} from "../../assets/pictures/InfoIcon.svg";
import {Acquaintance} from "../formAcquaintance/Acquaintance";
import {useAppSelector} from "../../store/auth.slice";
import {useGetUserQuery} from "../../store/api/authApi";
import {useGetTestsQuery, useLazyGetTestsQuery, useLazySendTestResultsQuery} from "../../store/api/testsApi";

interface ITest {
    question: string;
    type: string
    answers?: string[]
    value: number[]
    category: string
}

export const Testing = () => {
    // const auth = useAppSelector((state) => state.auth);
    const [activeTab, setActiveTab] = useState<boolean>(false);
    const [selectedTest, setSelectedTest] = useState<string>('')
    const [additionalTests, setAdditionalTests] = useState(false)
    const [test, setTest] = useState<any>({})
    const {data: user} = useGetUserQuery('');
    const {data, refetch} = useGetTestsQuery({id: user?._id})
    const [fetch] = useLazyGetTestsQuery()
    const [sendResult, isSuccess] = useLazySendTestResultsQuery()
    const [answersFromUser, setAnswersFromUser] = useState<IQuestion[]>([])
    const [active, setActive] = useState<boolean>(false)
    const [sortTests, setSortTests] = useState(false)
    const handleChoseTest = (test: { _id: string, name: string, usersWithAccessRoster: string[] }) => {
        setAdditionalTests(false)
        if (test.usersWithAccessRoster.includes(user?.employeeId)) {
            setSelectedTest(test.name)
            fetch({id: user?._id, testId: test._id}).then((res) => {
                setTest(res?.data?.currentTest)
                setAnswersFromUser(Array(res?.data?.currentTest?.content?.length).fill({}))
            })
        }
    }

    // function splitResponse(arr: ITest[]): [ITest[], ITest[]] {
    //     const middleIndex = Math.ceil(arr?.length / 2);
    //     const firstHalf = arr?.slice(0, middleIndex);
    //     const secondHalf = arr?.slice(middleIndex);
    //
    //     return [firstHalf, secondHalf];
    // }
    //
    // const [firstHalf, secondHalf] = splitResponse(test?.content);

    const notifySuccess = () => toast.success('Результати успішно збережені', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });


    const notifyError = () => toast.error('Сталась помилка', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const handleCheck = () => {
        const allNonEmpty = answersFromUser.every(obj => Object.keys(obj).length !== 0);
        if (allNonEmpty) {
            sendResult({
                testId: test._id,
                employeeId: user?.employeeId,
                userId: user?._id,
                result: answersFromUser,
                subject: test.subject,
                topic: test.topic,
                testCategory: test.type,
                passageTime: new Date().toISOString(),
                format: test.format,
            }).then((r) => {
                if (r.data) {
                    notifySuccess()
                    refetch()
                } else {
                    notifyError()
                }
            })
        }
    }
    useEffect(() => {
        if (data) {
            setSelectedTest(data?.tests[0]?.name)
            setTest(data?.tests[0])
            setAnswersFromUser(Array(data?.tests[0]?.content?.length).fill({}))
        }
    }, [data, sendResult]);

    const addTest = () => {
        setSelectedTest('')
        setActive(true)

    }
    return <Container>
        <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
        <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
        <BlockInfo>
            <MainBlockStyled>
                {/*<button onClick={() => console.log(auth)}>Acquaintance</button>*/}
                <Acquaintance activeTab={activeTab} setActiveTab={setActiveTab}/>
                <PanelChoseTests>
                    <ListTest>
                        <NameBlockTest hover={true}
                                       onClick={() => setActiveTab(true)} active={activeTab}>
                            Форма знайомства
                        </NameBlockTest>
                        {data?.allTests?.slice(0, 5).map((item: {
                            name: string,
                            _id: string,
                            usersWithAccessRoster: string[],
                            passedUsersRoster: string[]
                        }, index: number) => (
                            <NameBlockTest key={index} hover={item?.usersWithAccessRoster?.includes(user?.employeeId)}
                                           onClick={() => handleChoseTest(item)} active={selectedTest === item.name}>
                                {/*<DetailNameTest>{item?.name}</DetailNameTest>*/}
                                {item?.passedUsersRoster?.includes(user?.employeeId) ?
                                    <SvgNearNameTest access={'rgba(51, 131, 44, 1)'}><Success/> {item?.name}
                                    </SvgNearNameTest> : item?.usersWithAccessRoster?.includes(user?.employeeId) ? item?.name :
                                        <SvgNearNameTest access={'rgba(222, 108, 108, 1)'}><OpenAccessLock/>{item?.name}
                                        </SvgNearNameTest>}
                            </NameBlockTest>
                        ))}
                        {data?.allTests.length > 6 && <NameBlockTest hover={true} onClick={() => {
                            setSelectedTest('More>>');
                            setAdditionalTests(!additionalTests)
                        }}>
                            More{">>"}
                        </NameBlockTest>}
                        {additionalTests && <AdditionalTestsBlock>
                            {data?.allTests?.slice(5) && additionalTests && data?.allTests?.slice(5).map((item: {
                                name: string,
                                _id: string,
                                usersWithAccessRoster: string[],
                                passedUsersRoster: string[]
                            }) => (
                                <NameBlockMoreTest onClick={() => handleChoseTest(item)}
                                                   active={selectedTest === item.name}>
                                    {item?.passedUsersRoster?.includes(user?.employeeId) ?
                                        <SvgNearNameTest access={'rgba(51, 131, 44, 1)'}><Success/> {item?.name}
                                        </SvgNearNameTest> : item?.usersWithAccessRoster?.includes(user?._id) ? item?.name :
                                            <SvgNearNameTest
                                                access={'rgba(222, 108, 108, 1)'}><OpenAccessLock/>{item?.name}
                                            </SvgNearNameTest>}
                                </NameBlockMoreTest>
                            ))}
                        </AdditionalTestsBlock>}
                        {user?.isAdmin && <NameBlockTest hover={true} onClick={() => addTest()}>
                            +
                        </NameBlockTest>}
                        <AddNewTest active={active} setActive={setActive}/>
                    </ListTest>
                    {user?.isAdmin && <SortBlock onClick={() => {
                        setSortTests(!sortTests)
                    }}>
                        <ListSettings/>
                    </SortBlock>}
                </PanelChoseTests>
                <ContainerTests>
                    {sortTests &&
                        <DragAndDrop setSortTests={setSortTests} tests={data?.allTests} refetchFunction={refetch}/>}
                    <QuestionsBlock bordertop={'0'}>
                        <HeaderStyled marginLeft={'10px'}>
                            <span>{test?.name}</span>
                            {test?.name && <div>
                                <DescriptionBlock>{test?.shortDescription}</DescriptionBlock>
                                <Info/>
                            </div>}
                        </HeaderStyled>

                        <ContainerQuestions>
                            {/*<ColumnQuestion>*/}
                            {test?.content && test?.content.map((item: ITest, index: number) => {
                                return <QuestionAndAnswer
                                    question={item}
                                    indexQuestion={index}
                                    key={index}
                                    setAnswersFromUser={setAnswersFromUser}
                                />
                            })}
                            {/*</ColumnQuestion>*/}
                            {/*<ColumnQuestion>*/}
                            {/*    {secondHalf?.map((item: ITest, index: number) => {*/}
                            {/*        return <QuestionAndAnswer*/}
                            {/*            question={item}*/}
                            {/*            indexQuestion={firstHalf.length + index}*/}
                            {/*            key={index}*/}
                            {/*            setAnswersFromUser={setAnswersFromUser}*/}
                            {/*        />*/}
                            {/*    })}*/}
                            {/*</ColumnQuestion>*/}
                        </ContainerQuestions>
                        {test?.content && <ContainerBtn>
                            <BtnSubmit
                                submit={answersFromUser.every(obj => Object.keys(obj).length !== 0)}
                                onClick={() => handleCheck()}
                            >
                                Надіслати</BtnSubmit>
                        </ContainerBtn>}
                    </QuestionsBlock>
                    <QuestionsBlock bordertop={'15px'}>
                        {test?.content && <HeaderStyled>Результат</HeaderStyled>}
                        <ContainerQuestions>
                            {test?.content && test?.content?.map((item: IQuestion, index: number) => {
                                return <Answers
                                    answersFromUser={answersFromUser[index]}
                                    question={item.question}
                                    questionNumber={index + 1} key={index}
                                />
                            })}
                        </ContainerQuestions>
                    </QuestionsBlock>
                </ContainerTests>
            </MainBlockStyled>
        </BlockInfo>
    </Container>
}