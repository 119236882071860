import styled from "styled-components";

export const CustomTooltipBlockStyled = styled.div`
    background-color: #52616B;
    padding: 5px;
    color: #DBE8F1;
    & strong {
        font-family: Verdana, sans-serif;
        font-Size: 12px;
    }
`
export const ContainerChartStyled = styled.div<{ graph: boolean, chartWidth: number }>`
    display: flex;
    flex-direction: row;
    position: relative;
    min-width: ${({graph, chartWidth}) =>
            graph ? '15px' : chartWidth >= 600 ? `${chartWidth}px` : '100%'};
    height: 88%;
    margin-top: 3%;
`

export const HiddenChartBlockStyled = styled.div`
display: flex;
    position: absolute;
    min-width: 17px;
    height: 100%;
    overflow: hidden;
    padding-left: 23px;
    z-index: 2;
    background-color: rgba(17, 18, 22, 1);
`