import styled from "styled-components";


export  const PaginationNav = styled.div<{marginTop?:string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${props => props.marginTop}
`

export const PaginationList = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
`
export const PaginationItem = styled.li<{active?: boolean}>`
  margin: 0 5px;
  padding: 0 3px;
  color: ${(props) => (props.active ? '#E3C565' : '#52616B')};
  border-radius: 5px;
  cursor: pointer;
  font-size: ${(props) => (props.active ? '0.8em' : '0.5em')};
  & a {
    color: #52616B;
    text-decoration: none;
  }
`
export const PaginationArrow = styled.li`
  margin: 0 5px;
  padding: 0 5px;
  cursor: pointer;
  font-size: 20px;
  & a {
    color: #52616B;
    text-decoration: none;
  }
  //@media (min-width: 2000px) {
  //  font-size: 0.7em;
  //  padding: 10px;
  //}
`