import styled from "styled-components";


export const ContainerBlockQuestions = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const HeaderGeneralBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    white-space: nowrap;
`

export const LineBlock = styled.div<{right?:boolean,left?:boolean,top?:boolean,}>`
    margin-top: ${props=>props.top ? '10px' : 0};
    margin-right: ${props=>props.right ? '10px' : 0};
    margin-left: ${props=>props.left ? '10px' : 0};
    background-color: rgba(82, 97, 107, 0.5);
    height: 2px;
    width: 100%;
`