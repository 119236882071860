import styled from 'styled-components';

export const Container = styled.div<{ borderTop: boolean, marginTop: string }>`
    max-width: 100%; /* Можете змінити розміри за потребою */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-top: ${props => props.borderTop ? '1px solid red' : 'none'};
    margin-top: ${props => props.marginTop};
    padding-top: 10px;
    //@media (min-width: 1920px) {
    //  margin-top: 22%;
    //}

    & span {
        margin-bottom: 10px;
    }
`;

export const TextContainer = styled.div<{ isExpanded?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #C9D6DF;
    font-family: Verdana, serif;
    white-space: pre-wrap;
    text-align: left;
    margin-top: ${({isExpanded}) => (isExpanded ? '5px' : '0')};
    border: 1px solid #52616B;
    border-radius: 10px;
`;

export const TextBlock = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    & span:first-child {
        display: flex;
        font-size: 0.8em;
        min-width: 25%;
        max-width: 45%;
        padding-right: 12px;
    }
    & span:last-child {
        display: flex;
        font-size: 0.8em;
        width: 50%;
        padding-left: 12px;
    }
`
export const Button = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: end;
    fill: #52616B;
    opacity: 1;
    cursor: pointer;

    & svg {
        width: 20px;
        height: 20px;
        fill: #52616B;
    }
`;