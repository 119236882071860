import styled from "styled-components";

export const DatePickerStatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input {
        text-align: center;
        width: 155px;
        font-size: 14px;
        background: #ffffff22;
        color: rgba(219, 232, 241, 1);
        padding: 3px;
        background: rgba(82, 97, 107, 0.3);
        border: none;
        //border: 0.1px solid #64748b;
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
    }

    .react-datepicker {
        background: #3c434c;
    }

    .react-datepicker__day,
    .react-datepicker__current-month,
    .react-datepicker-time__input,
    .react-datepicker__month-container,
    .react-datepicker__input-time-container,
    .react-datepicker-time__caption,
    .react-datepicker__time-list-item,
    .react-datepicker__time-list {
        background: #3c434c;
        color: #fff;
        border: none;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
    .react-datepicker__week-number--selected,
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__close-icon::after {
        background: rgb(41, 48, 56) !important;
        color: rgb(240, 185, 11) !important;
    }

    .react-datepicker__day--outside-month {
        color: #ffffff40;
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected,
    .react-datepicker-time__input,
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker__header {
        background: rgb(41, 48, 56) !important;
        color: rgb(240, 185, 11) !important;
    }

    .react-datepicker__day-name {
        background: rgb(41, 48, 56) !important;
        color: #fff !important;
    }

    .react-datepicker__time-list-item:hover {
        background: rgb(240, 185, 11) !important;
        color: #000;
    }
`