import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(219, 232, 241, 1);
`
export const AmountCategory = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
export const AmountCategoryInput = styled.div<{ top: string,widthInput?:string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.top};
  color: rgba(219, 232, 241, 1);

  & input {
    margin-left: 2px;
    width: ${props=>props.widthInput ? props.widthInput : '20%'};
    background-color: rgba(82, 97, 107, 0.5);
    border: 0.5px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);

    &::placeholder {
      color: rgba(82, 97, 107, 1);
    }
  }
`
export const BlockNameCategoryWithRange = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around; 
  align-items: center;
`
export const TableCategory = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  max-height: ${props => props.height};
  overflow: scroll;
  margin-top: 10px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
export const NameForConclusion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
padding: 3px;
`
export const BlockChosenMethod = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10vw;
  font-family: Verdana, sans-serif;
  font-size: 12px;
  text-align: center;
  margin: 10px;
  padding: 3px 5px;
  background-color: rgba(82, 97, 107, 0.5);
  border: 0.5px solid rgba(82, 97, 107, 1);
  border-radius: 5px;
  color: rgba(219, 232, 241, 1);
  cursor: pointer;
`
export const NameMethod = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis; /* Додає мітку (...) для відображення, що текст обрізаний */
  white-space: nowrap; /* Заборонити перенесення тексту на новий рядок */
`
export const ArrowBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 10%;

  & svg {
    width: 17px;
    height: 17px;
  }
`
export const List = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 2;
`
export const Variant = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px 5px;
  width: 88%;
  background-color: #1E2022;
  border: 0.5px solid rgba(82, 97, 107, 1);
  color: rgba(219, 232, 241, 1);
  font-family: Verdana, sans-serif;
  &:hover {
    overflow: visible;
  }
`

export const Method = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`
export const EvaluationMethod = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const NameForEvaluationMethod = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`
export const StyledLabel = styled.label`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  padding: 5px 0;
`
export const NameWithInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin: 5px 0;
  & div {
    margin-right: 10px;
  }

  & input {
    margin-left: 2px;
    width: 50%;
    background-color: rgba(82, 97, 107, 0.5);
    border: 0.5px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);

    &::placeholder {
      color: rgba(82, 97, 107, 1);
    }
  }

  & textarea {
    margin-left: 2px;
    width: 50%;
    background-color: rgba(82, 97, 107, 0.5);
    border: 0.5px solid rgba(82, 97, 107, 1);
    border-radius: 5px;
    padding: 3px;
    color: rgba(219, 232, 241, 1);
    resize: none;

    &::placeholder {
      color: rgba(82, 97, 107, 1);
    }
  }
`
export const BlockRange = styled.div`
  display: flex;
  flex-direction: row;
  width: 38%;

  & div {
    display: flex;
    flex-direction: row;

    & div {
      margin: 5px;
    }

    & input {
      margin: 5px 0;
      width: 75%;
      background-color: rgba(82, 97, 107, 0.5);
      border: 0.5px solid rgba(82, 97, 107, 1);
      border-radius: 5px;
      padding: 3px;
      color: rgba(219, 232, 241, 1);

      &::placeholder {
        color: rgba(82, 97, 107, 1);
      }
    }
    & textarea {
      margin: 5px 0;
      width: 75%;
      background-color: rgba(82, 97, 107, 0.5);
      border: 0.5px solid rgba(82, 97, 107, 1);
      border-radius: 5px;
      padding: 3px;
      color: rgba(219, 232, 241, 1);

      &::placeholder {
        color: rgba(82, 97, 107, 1);
      }
    }
  }
`
export const ConclusionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
