import React, {useState} from "react";
import {ReactComponent as ArrowForSelectorOpen} from "../../assets/pictures/ArrowForSelectorOpen.svg";
import {ReactComponent as ArrowForSelectorClose} from "../../assets/pictures/ArrowForSelectorClose.svg";
import {
    ArrowBlock,
    BlockList,
    Container,
    NameSelector,
    SelectorStyled,
    VariantChoose
} from "./Selector.styled";

type Props = {
    optionsCategory: Array<{ value: string, name: string, field: string }>
    borderRight?: string
    borderRadius?: string
    border?: string
    margin?:string
    padding?: string
    setSelectedFilters: React.Dispatch<React.SetStateAction<{
        value: string;
        name: string;
        field: string;
    }[]>>
    selectedFilters: any
    size: string
    header: string

}
export const Selector = ({
                             optionsCategory,
                             borderRight,
                             setSelectedFilters,
                             selectedFilters,
                             size,
                             header,
                             borderRadius,
                             border,
                             margin,
                             padding
                         }: Props) => {
    const [openList, setOpenList] = useState(false)
    const [checked, setChecked] = useState<string[]>([]);
    const [chosenOption, setChosenOption] = useState<string[]>([])
    function changeCheckbox(obj: {
        value: string;
        name: string;
        field: string;
    }) {
        const has = selectedFilters.some((item: any) => item.value === obj.value)
        if (checked.includes(obj.value) || has) {
            setChecked(prev => prev.filter(item => item !== obj.value))
            setSelectedFilters(selectedFilters.filter((item: any) => item.value !== obj.value))
        } else {
            setSelectedFilters(prev => [...prev, obj])
            setChecked(prev => [...prev, obj.value])
        }
        setChosenOption(prev => {
            return chosenOption?.includes(obj.name) ? prev.filter(element => element !== obj.name) : [...prev, obj.name];})
    }
    const offWatchBlock = () => {
        setOpenList(false)
    }
    return (
        <Container>
            <SelectorStyled padding={padding} margin={margin} border={border} borderRadius={borderRadius} size={size} borderRight={borderRight} onClick={() => setOpenList(!openList)} >
                <NameSelector content={chosenOption.length > 0}>
                    {chosenOption.length ? chosenOption.join(',') : header}
                </NameSelector>
                <ArrowBlock>
                    {openList ? <ArrowForSelectorClose/> : <ArrowForSelectorOpen/>}
                </ArrowBlock>
            </SelectorStyled>
            {openList &&
                <BlockList  onMouseLeave={()=>offWatchBlock()}>
                    {optionsCategory.map((elem, index) => (
                        <VariantChoose key={index}>
                            {elem.name}
                            <input type={'checkbox'}
                                   checked={checked.includes(elem.value)}
                                   name={elem.value}
                                   id={elem.value}
                                   value={elem.value}
                                   onChange={() => changeCheckbox(elem)}
                            />
                        </VariantChoose>
                    ))}
                </BlockList>}
        </Container>
    )
}

