import styled from "styled-components";

export const ProfileInformationBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 1vw 1vw 0 1vw;

    & span {
        font-size: 22px;
        color: #C9D6DF;
    }
`
export const BlockBtnWithAvatar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 7vh;
    box-sizing: border-box;
    padding-left: 2vw;
`
export const BlockAvatarStyled = styled.div`
    display: flex;
    width: 34%;
    height: 34.6vh;
    border: 1px solid #52616B;
    border-radius: 25px;
    background-color: #1E2022;
    z-index: 1;

    & img {
        border-radius: 25px;
        width: 100%;
        height: 100%;
        aspect-ratio: 3 / 2;
        object-fit: cover;
    }

    & svg {
        fill: #52616B;
    }
`


export const AvatarInfoBlock = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 1;
`
export const SetOfButtonsNearAvatarStyled = styled.div`
    display: flex;
    flex-direction: column;
`

// export const ButtonStyled = styled.div<{ borderRight?: string,borderLeft?: string }>`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 1.8vw;
//   height: 2vw;
//   margin-top: 20px;
//   border: 1px solid #52616B;
//   border-right: ${props => props.borderRight};
//   border-left: ${props => props.borderLeft};
//   & div {
//     background-color: yellow;
//     border: 1px solid red;
//     border-radius: 50%;
//     width: 16px;
//     height: 16px;
//     margin-bottom: 2px;
//   }
// `
export const DetailInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    border: 1px solid #52616B;
    border-radius: 15px;
    margin-top: 1.3vw;
`
export const PersonalInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    border-top: 1px solid #52616B;
    margin-top: 2vw;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
export const InfoAboutUser = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 40%;
    margin-top: 30px;

    & div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    & span {
        padding: 5px 0;
        font-size: 1em;
    }
`

export const ContainerAnswersForm = styled.div`
    max-width: 100%; /* Можете змінити розміри за потребою */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 10px;

    & span {
        margin-bottom: 10px;
    }
`
export const ListForms = styled.div<{ isExpanded?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px;
    overflow: hidden;
    overflow-wrap: break-word;
    color: #C9D6DF;
    font-family: Verdana, serif;
    white-space: pre-wrap;
    margin-top: ${({isExpanded}) => (isExpanded ? '5px' : '0')};
    margin-bottom: 20px;
    border: 1px solid #52616B;
    border-radius: 10px;

    & div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 5px 0;

        & div {
            display: flex;
            width: fit-content;
            padding: 5px;
            color: #C9D6DF;
            font-family: Verdana, sans-serif;
            border: 1px solid #52616B;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    & span {
        font-size: 1em;
    }
`;

export const BlockAnswersForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 40vw;
    font-family: Verdana, serif;
    max-height: 80vh;
    overflow: scroll;

    & div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:last-child {
            display: flex;
            justify-content: flex-end;
        }
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`

export const TableQuestionsAndAnswersForm = styled.table`
    width: 100%;
    border: 2px solid #52616B;
    border-collapse: collapse;
    margin-top: 10px;

    th, td {
        padding: 3px;
        border: 2px solid #52616B;
    }

    td:first-of-type {
        width: 40%;
    }

    td:nth-of-type(2) {
        width: 60%;
    }

`

export const Line = styled.div`
    display: flex;
    width: 100%;
    height: 1px;
    margin: 9px 15px 12px 0;
    background-color: #52616B;
`
