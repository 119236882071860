import {Container, QuestionBlock, StyledInput, StyledTextarea} from "./Questions.styled";
import React, {FC, useRef, useState} from "react";
import {additionalQuestions} from "../constants";

interface Props {
    name: string
    require: boolean
    type: string
    field: string
    indexField:number
    setAnswers: React.Dispatch<React.SetStateAction<any>>
    fieldsForUpdate:string,
    previosWorkAnswers?:any[]
}

export const QuestionText: FC<Props> = ({name, require, type, setAnswers, field,indexField,fieldsForUpdate,previosWorkAnswers}) => {
    const [text, setText] = useState('')
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    };

    const handleAddAnswer = () => {
        setAnswers((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item:{field:string} )=> item.field === field);
            if (itemsWithField[indexField]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[indexField]);
                updatedItems[globalIndex] = { ...updatedItems[globalIndex], [fieldsForUpdate ? fieldsForUpdate : 'answer']: text };

                return updatedItems;
            } else {
                return [...prevItems, { field, [fieldsForUpdate ? fieldsForUpdate : 'answer']: text }];
            }
        });
    };


    return (
        <Container disabledOpacity={previosWorkAnswers && previosWorkAnswers?.length < 1 && (name === additionalQuestions[2].question || name === additionalQuestions[4].question)}>
            <QuestionBlock>{name}</QuestionBlock>
            {type === 'text' ? <StyledInput type={'text'} required={require} onChange={(e) => setText(e.target.value)}
                                            onBlur={handleAddAnswer}/> :
                <StyledTextarea ref={textareaRef} onInput={adjustTextareaHeight} required={require} disabled={previosWorkAnswers && previosWorkAnswers?.length < 1 && (name === additionalQuestions[2].question || name === additionalQuestions[4].question)}
                                onChange={(e) => setText(e.target.value)} onBlur={handleAddAnswer}/>}
        </Container>
    )
}