import styled from 'styled-components'

type Props = { event: any }

export const Planning = ({event}: Props) => {
    const lastIndex = event?.eventPlan?.findIndex((item: {
        name: string
    }) => item.name === 'Чи виділяє головне (Що саме/Чому)?');

    const firstArray = event?.eventPlan?.slice(0, lastIndex + 1);
    const secondArray = event?.eventPlan?.slice(lastIndex + 1);
    return <>
        {event.category === 'План 📋' ? <StyledTable>
            <thead>
            <tr>
                <th colSpan={3}>
                    План
                </th>
            </tr>
            </thead>
            <tbody>
            {firstArray?.map((el: { name: string, description: string, chosen: string }, index: number) => (
                <tr key={index}>
                    <StyledTd>{el.name}</StyledTd>
                    {el.chosen ? <StyledTd>{el.chosen}</StyledTd> : <StyledTd>-</StyledTd>}
                    {el.description ? <StyledTd>{el.description}</StyledTd> : <StyledTd>-</StyledTd>}
                </tr>
            ))}
            {event?.thoughts?.length > 0 && event.thoughts.length > 1 ?
                <tr>
                    <StyledTd>Думки</StyledTd>
                    <StyledTd>-</StyledTd>
                    <StyledTd>{event?.thoughts?.map((thought:string,indexThought:number) => (
                        <>{indexThought + 1}. {thought}<br/></>
                    ))}</StyledTd>
                </tr>
                //     <tr>
                //         <StyledTd>Думки</StyledTd>
                //         <StyledTd>-</StyledTd>
                //         {event.thoughts.length > 1 ? event?.thoughts?.map((thought: string, indexThought: number) => (
                //         <StyledTd>{indexThought + 1}. {thought}</StyledTd>
                // )}</tr>
                :
                <tr><StyledTd>Думки</StyledTd><StyledTd>-</StyledTd><StyledTd>{event.thoughts.join('')}</StyledTd></tr>}
            {secondArray?.map((el: { name: string, description: string, chosen: string }, index: number) => (
                <tr key={index}>
                    <StyledTd>{el.name}</StyledTd>
                    {el.chosen ? <StyledTd>{el.chosen}</StyledTd> : <StyledTd>-</StyledTd>}
                    {el.description ? <StyledTd>{el.description}</StyledTd> : <StyledTd>-</StyledTd>}
                </tr>
            ))}
            </tbody>
        </StyledTable> : <div>{event.doing}</div>}
    </>
}

const StyledTd = styled.td`
  border: 1px solid grey;
`

const StyledTable = styled.table`
  border-collapse: collapse;
  font-weight: 400;
  width: 90%;
  font-family: Verdana, sans-serif;

  th {
    text-align: center;
    color: #E3C565;
    padding: 5px;
    @media (min-width: 1920px) {
      padding: 25px;
      font-size: 1.2em;
    }
  }

  td {
    color: #C9D6DF;
    font-size: 12px;
    padding: 5px 10px;
    @media (min-width: 1920px) {
      padding: 5px 20px;
      font-size: 1em;
    }
  }

  td:first-child {
    width: 25%;
  }

  td:last-child {
    width: 70%;
  }

`
