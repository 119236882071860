import { ResponsiveLine, PointTooltipProps } from '@nivo/line';
import React from "react";

type Props = {
    data?: any
}

const customColors = ['rgba(227, 197, 101, 1)', 'rgba(227, 197, 101, 0.5)', 'rgba(227, 197, 101, 0.7)', 'rgba(227, 197, 101, 0.3)']; // Замініть ці кольори на ті, які вам подобаються

export const LineChart = ({ data }: Props) => {

    const getTickValues = (data: any) => {
        const totalPoints = data[0].data.length;
        const step = Math.ceil(totalPoints / 5); // Змінюйте 10 на бажану кількість позначок
        return data[0].data.filter((_: any, index: number) => index % step === 0).map((d: any) => d.x);
    };

    const theme = {
        text: {
            fontSize: 11,
            fill: "#333333",
            outlineWidth: 0,
            outlineColor: "transparent"
        },
        axis: {
            domain: {
                line: {
                    stroke: "rgba(82, 97, 107, 1)",
                    strokeWidth: 1
                }
            },
            legend: {
                text: {
                    fontSize: 16,
                    fill: "#C9D6DF",
                    outlineWidth: 1,
                }
            },
            ticks: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 0
                },
                text: {
                    fontSize: 12,
                    fill: "#C9D6DF",
                    outlineWidth: 0,
                    outlineColor: "transparent"
                }
            }
        },
        grid: {
            line: {
                stroke: "rgba(82, 97, 107, 0.5)",
                strokeWidth: 1
            }
        },
        legends: {
            title: {
                text: {
                    fontSize: 14,
                    fill: "#C9D6DF",
                    outlineWidth: 0,
                    outlineColor: "transparent"
                }
            },
            text: {
                fontSize: 20,
                fill: "#C9D6DF",
                outlineWidth: 0,
                outlineColor: "transparent"
            },
            ticks: {
                line: {},
                text: {
                    fontSize: 10,
                    fill: "#C9D6DF",
                    outlineWidth: 0,
                    outlineColor: "transparent"
                }
            }
        },
        annotations: {
            text: {
                fontSize: 13,
                fill: "#C9D6DF",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1
            },
            link: {
                stroke: "#000000",
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1
            },
            outline: {
                stroke: "#000000",
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1
            },
            symbol: {
                fill: "#000000",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1
            }
        },
        tooltip: {
            container: {
                background: "#ffffff",
                fontSize: 12,
                height: 10, // Додайте висоту для контейнера
                // className: Container,
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {}
        }
    }
    const CustomTooltip: React.FC<PointTooltipProps> = ({ point }) => (
        <div
            style={{
                padding: '2px',
                background: '#52616B',
                borderRadius: '2px',
                fontSize: '12px'
            }}
        >
            <strong>дата/час</strong>:<span style={{color: 'rgba(227, 197, 101, 1)'}}>{point.data.xFormatted}</span>
            <br/>
            <strong>{point.serieId}</strong>: <span style={{color: 'rgba(227, 197, 101, 1)'}}>{point.data.yFormatted}</span>
        </div>
    );

    return (
        <ResponsiveLine
            data={data}
            theme={theme}
            margin={{ top: 29, right: 35, bottom: 50, left: 50 }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 14,
                stacked: false,
                reverse: false
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            xScale={{
                type: "point",
            }}
            axisBottom={{
                tickValues: getTickValues(data)
            }}
            curve={'monotoneX'}
            colors={'rgba(222,198,115,1)'}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor', modifiers: [] }}
            pointLabelYOffset={-2}
            areaOpacity={0.15}
            useMesh={true}
            tooltip={CustomTooltip}
        />
    );
}
