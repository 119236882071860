import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`
export const MainUnitStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContentBlockStyled = styled.div`
  display: flex;
  flex-direction: row;
`
export const ContentTestStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 84.5vh;
    margin: 1vw 1vw 0 1vw;
    //border: 1px solid #52616B;
    border-radius: 25px;
    & span {
        margin: 0 0 5px 0;
        font-size: 1.2em;
        color: #C9D6DF;
    }
`
export const ProfileInformationBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 1vw 1vw 0 1vw;

    & span {
        font-size: 22px;
        color: #C9D6DF;
    }
`
export const BlockBtnWithAvatar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 7vh;
    box-sizing: border-box;
    padding-left: 2vw;
`
export const BlockAvatarStyled = styled.div`
    display: flex;
    width: 34%;
    height: 34.6vh;
    border: 1px solid #52616B;
    border-radius: 25px;
    background-color: #1E2022;
    z-index: 1;
    position: relative;
    overflow: hidden;

    & img {
        border-radius: 25px;
        width: 100%;
        height: 100%;
        aspect-ratio: 3 / 2;
        object-fit: cover;
    }

    & svg {
        fill: #52616B;
    }
`
export const BtnDeletePhoto = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(82, 97, 107, 0.2);
    border-left: 1px solid #52616B;
    border-bottom: 1px solid #52616B;
    position: absolute;
    width: 25px;
    height: 25px;
    right: 0;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    color: #C9D6DF;
    font-size: 10px;
`
export const BtnAddPhoto = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #C9D6DF;
    cursor: pointer;

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid #52616B;
    }
`
export const DetailInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    border: 1px solid #52616B;
    border-radius: 15px;
    margin-top: 1.3vw;
`
export const InfoAboutUser = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 40%;
    margin-top: 30px;

    & div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    & span {
        padding: 5px 0;
        font-size: 1em;
    }
`