import styled from 'styled-components';

export const Modal = styled.div<{test?: boolean}>`
  display: flex;
  justify-content: ${props => props.test ? 'space-around' : 'center'};
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  &.active {
    opacity: 1;
    pointer-events: all;
    z-index: 1;
  }
`;

export const ModalContent = styled.div`
    padding: 20px;
    border-radius: 12px;
    background-color: #1E2022;
    border: 1px solid #52616B;
    transform: scale(0.5);
    transition: 0.4s all;
    max-width: 50%;
    color: white;
    &.active {
        transform: scale(1);
    }
`;
export const ModalSecondContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  height: 50vh;
  font-family: Verdana, sans-serif;
  padding: 20px;
  border-radius: 12px;
  background-color: #1E2022;
  border: 1px solid #52616B;
  transform: scale(0.5);
  transition: 0.4s all;
  max-width: 2500px;
  color: white;
  margin-left: 20px;
  z-index: -1;

  &.active {
    transform: scale(1);
  }
`;