import React from "react"
import {ButtonStyled} from "./Btn.styled";

type Props = {
    children: React.ReactNode
    marginTop?: string
    onclick?:any
}

export const Btn = ({children,marginTop,onclick}: Props) => {
    return (
        <ButtonStyled  onClick={()=>onclick()} marginTop={marginTop}>
            {children}
        </ButtonStyled>
    )
}