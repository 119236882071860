import {Container, DatePickerStatsWrapper, QuestionBlock} from "./Questions.styled";
import React, {FC, useState} from "react";
import DatePicker from "react-datepicker";
import {
    IAdditionalQuestions,
    IAnswersFromUser, IContactDetails, IContactPersonEmergency,
    IEducation,
    IForeignLanguage,
    IPreviousWorkExperience
} from "../interface";

interface Props  {
    name:string
    require:boolean
    selectsRange?:boolean
    showMonthYearPicker?:boolean
    showYearPicker?:boolean
    setAnswers:React.Dispatch<React.SetStateAction<(IAnswersFromUser | IEducation | IForeignLanguage | IPreviousWorkExperience | IAdditionalQuestions | IContactDetails | IContactPersonEmergency)[]>>
    field:string
    formatDate?:string
    indexField:number
    fieldsForUpdate:string
}

export const QuestionDatePicker:FC<Props> = ({name,require,selectsRange,showMonthYearPicker,showYearPicker,setAnswers,field,formatDate,indexField,fieldsForUpdate}:Props) => {
    const [startDate, setStartDate] = useState<(Date | null) | (Date | null)[]>();
    const handleChangeDate = (date: (Date | null)[] | (Date | null)) => {
        setStartDate(date)
        if (Array.isArray(date)) {
            const [start, end] = date;
            if (start !== null && end !== null) {
                setAnswers((prevItems: any[]) => {
                    const itemsWithField = prevItems.filter((item:{field:string} )=> item.field === field);
                    if (itemsWithField[indexField]) {
                        const updatedItems = [...prevItems];
                        const globalIndex = prevItems.indexOf(itemsWithField[indexField]);
                        updatedItems[globalIndex] = { ...updatedItems[globalIndex], [fieldsForUpdate ? fieldsForUpdate : 'answer']: [start,end] };

                        return updatedItems;
                    } else {
                        return [...prevItems, { field, [fieldsForUpdate ? fieldsForUpdate : 'answer']: [start,end] }];
                    }
                });
            }
        } else {
            setAnswers((prevItems: any[]) => {
                const itemsWithField = prevItems.filter((item:{field:string} )=> item.field === field);
                if (itemsWithField[indexField]) {
                    const updatedItems = [...prevItems];
                    const globalIndex = prevItems.indexOf(itemsWithField[indexField]);
                    updatedItems[globalIndex] = { ...updatedItems[globalIndex], [fieldsForUpdate ? fieldsForUpdate : 'answer']: date };
                    return updatedItems;
                } else {
                    return [...prevItems, { field, [fieldsForUpdate ? fieldsForUpdate : 'answer']: date }];
                }
            });
            // setAnswers((prevItems) => {
            //     const itemIndex = prevItems.findIndex(item => item.field === field);
            //     if (itemIndex !== -1) {
            //         const updatedItems = [...prevItems];
            //         updatedItems[itemIndex] = {...updatedItems[itemIndex], answers: date as Date};
            //         return updatedItems;
            //     } else {
            //         return [...prevItems, {field, answers: date as Date}];
            //     }
            // });
        }
    }
    return(<Container>
        <QuestionBlock>{name}</QuestionBlock>
        <DatePickerStatsWrapper>
        <DatePicker
            dateFormat={formatDate ? formatDate : "dd.MM.yyyy"}
            selected={Array.isArray(startDate) ? startDate[0] : startDate}
            startDate={Array.isArray(startDate) ? startDate[0] : startDate}
            endDate={Array.isArray(startDate) ? startDate[1] : startDate}
            onChange={(date:(Date | null) | (Date | null)[]) => handleChangeDate(date)}
            maxDate={new Date()}
            required={require}
            selectsRange={selectsRange}
            showMonthYearPicker={showMonthYearPicker}
            showYearPicker={showYearPicker}
        />
        </DatePickerStatsWrapper>
    </Container>)
}