import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 33vw;
    font-weight: bold;
    font-size: 14px;
`
export const OverallSettingsTestContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    justify-content: space-around;
    width: 100%;
    font-weight: normal;
    font-size: 12px;
`
export const RowInformation = styled.div<{border?:boolean,fontSize?:string,fontWeight?:string,justifyContent?:string,width?:string}>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-around'};
    
    & div {
        border-bottom: ${props=>props.border ? 'none' : '1px solid rgba(227, 197, 101, 1)'};
        width: ${props=>props.width ? props.width : '50%'};
        padding: 5px;
        white-space: pre-wrap;
        font-size: ${props=>props.fontSize ? props.fontSize : ''};
        font-weight: ${props=>props.fontWeight ? props.fontWeight : ''};
    }
`
export const TypeAnswersStyled = styled.div`
    width: 68%
`
export const ConfirmBtnStyled = styled.div`
    border: 1px solid rgba(51, 131, 44, 1);
    color: rgba(51, 131, 44, 1)
`
export const ConfirmBtnBackStyled = styled.div`
    border: 1px solid rgba(222, 108, 108, 1);
    color: rgba(222, 108, 108, 1)
`
export const ButtonBlockConfirm = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    & div {
        border-radius: 5px;
        cursor: pointer;
        font-size: 12px;
        padding: 5px;
        margin: 0 5px;
    }
`