import React, {useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {darkTheme, lightTheme} from './components/globalStyled/ThemeSwitch.styled';
import {AppStyled, Global, HeaderStyled, MenuStyled} from './components/globalStyled/Global.styled';
import {path} from './utils/path';
import {Menu} from "./components/menu/Menu";
import {Header} from "./components/header/Header";
import {Router} from "./components/routes/Router";
import {useGetUserQuery} from "./components/store/api/authApi";
import {useLocation} from "react-router-dom";
import {AdminContext} from "./components/routes/adminContext";

function App() {
    const {data} = useGetUserQuery('');
    const [theme, setTheme] = useState('dark');
    const location = useLocation();
    const isDarkTheme = theme === 'dark';
    const toggleTheme = () => {
        setTheme(isDarkTheme ? 'light' : 'dark');
    };

    return (
        // <AdminContext.Provider value={data}>
            <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
                <AppStyled>
                    {/*{!!data?.employeeId && location.pathname !== path.home && location.pathname !== path.login && location.pathname !== path.registration && (*/}
                    {location.pathname !== path.home && location.pathname !== path.login && location.pathname !== path.registration && (
                        <MenuStyled>
                            <Menu/>
                        </MenuStyled>
                    )}
                    <HeaderStyled>
                        {/*{!!data?.employeeId && location.pathname !== path.home && location.pathname !== path.login && location.pathname !== path.registration && (*/}
                        {location.pathname !== path.home && location.pathname !== path.login && location.pathname !== path.registration && (
                            <header>
                                <Header/>
                            </header>
                        )}
                        {/*<div style={{flex: '1', overflowY: 'auto'}}>*/}
                            <Router/>
                        </HeaderStyled>
                    {/*</div>*/}
                </AppStyled>
                <Global/>
            </ThemeProvider>
        // </AdminContext.Provider>
    );
}

export default App;
