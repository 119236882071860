import {IDataForBarChart, IEventForBarChart} from "../computations/interface";
import {consistentTimePeriodsForCharts} from "../Selector/contants";

function normalizeDate(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
}


function getDaysToEndOfWeekAndFromMonday(date1: Date | null, date2: Date | null): {
    daysToEndOfWeek: number,
    daysFromMonday: number,
    mondayDate: Date,
    sundayDate: Date
} {
    // Якщо будь-який з аргументів null, повернути 1
    if (date1 === null || date2 === null) {
        return {
            daysToEndOfWeek: 1,
            daysFromMonday: 1,
            mondayDate: new Date(),
            sundayDate: new Date()
        };
    }
    const firstDay = new Date(date1);
    const secondDay = new Date(date2);

    // Перевірка на валідність дат
    if (isNaN(firstDay.getTime()) || isNaN(secondDay.getTime())) {
        throw new Error('Invalid date');
    }

    // Функція для обчислення дати понеділка цього тижня
    const getMonday = (date: Date): Date => {
        const dayOfWeek = date.getDay();
        const monday = new Date(date);
        monday.setDate(date.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
        monday.setHours(0, 0, 0, 0); // Задаємо час 00:00:00
        return monday;
    };

    // Функція для обчислення дати неділі цього тижня
    const getSunday = (date: Date): Date => {
        const monday = getMonday(date);
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        sunday.setHours(23, 59, 59, 999); // Задаємо час 23:59:59
        return sunday;
    };

    // Обчислення дати неділі для першої дати
    const sunday = getSunday(firstDay);
    const daysToEndOfWeek = Math.floor((sunday.getTime() - firstDay.getTime()) / (1000 * 60 * 60 * 24));

    // Обчислення дати понеділка для другої дати
    const monday = getMonday(secondDay);
    const daysFromMonday = Math.floor((secondDay.getTime() - monday.getTime()) / (1000 * 60 * 60 * 24));


    return {
        daysToEndOfWeek,
        daysFromMonday,
        mondayDate: monday,
        sundayDate: sunday
    };
}

function getDaysToEndOfMonthAndFromStart(date1: Date | null, date2: Date | null): {
    daysToEndOfMonth: number,
    daysFromStartOfMonth: number
} {
    // Якщо будь-який з аргументів null, повернути 1
    if (date1 === null || date2 === null) {
        return {
            daysToEndOfMonth: 1,
            daysFromStartOfMonth: 1
        };
    }

    // Перевірка на валідність дат
    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
        throw new Error('Invalid date');
    }

    const firstDay = new Date(date1);
    const secondDay = new Date(date2);

    // Функція для обчислення дати останнього дня місяця
    const getLastDayOfMonth = (date: Date): Date => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    // Обчислення дати останнього дня місяця для першої дати
    const lastDayOfMonth = getLastDayOfMonth(firstDay);
    const daysToEndOfMonth = Math.floor((lastDayOfMonth.getTime() - firstDay.getTime()) / (1000 * 60 * 60 * 24));

    // Обчислення кількості днів від початку місяця до другої дати
    const firstDayOfMonth = new Date(secondDay.getFullYear(), secondDay.getMonth(), 1);
    const daysFromStartOfMonth = Math.floor((secondDay.getTime() - firstDayOfMonth.getTime()) / (1000 * 60 * 60 * 24));

    return {
        daysToEndOfMonth,
        daysFromStartOfMonth
    };
}


function getDifferenceInDays(date1: Date | null, date2: Date | null): number {
    if (date1 === null || date2 === null) {
        return 1;
    }
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    // Отримання часу в мілісекундах для обох дат
    const time1 = firstDate.getTime();
    const time2 = secondDate.getTime();

    // Обчислення різниці в мілісекундах
    const differenceInMillis = Math.abs(time2 - time1);

    // Кількість мілісекунд в одному дні
    const millisInDay = 1000 * 60 * 60 * 24;

    // Обчислення різниці в днях
    return Math.floor(differenceInMillis / millisInDay);
}

function getDifferenceInMonths(date1: Date | null, date2: Date | null): number {
    if (date1 === null || date2 === null) {
        return 1;
    }

    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    const year1 = firstDate.getFullYear();
    const month1 = firstDate.getMonth();
    const year2 = secondDate.getFullYear();
    const month2 = secondDate.getMonth();

    // Обчислення різниці в місяцях
    const yearDifference = year2 - year1;
    const monthDifference = month2 - month1;

    return yearDifference * 12 + monthDifference;
}
function getDifferenceInQuarters(date1: Date | null, date2: Date | null): number {
    if (date1 === null || date2 === null) {
        return 1;
    }

    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    const year1 = firstDate.getFullYear();
    const month1 = firstDate.getMonth();
    const year2 = secondDate.getFullYear();
    const month2 = secondDate.getMonth();

    // Обчислення різниці в кварталах
    const quarter1 = Math.floor(month1 / 3) + 1;
    const quarter2 = Math.floor(month2 / 3) + 1;
    const yearDifference = year2 - year1;

    return (yearDifference * 4) + (quarter2 - quarter1);
}


function calculateColumns(
    data: IEventForBarChart[],
    chosenTimePeriod: Date | null,
    daysInWeek: number,
    result: IDataForBarChart[],
    days: number
) {
    const now = new Date();
    const startDate = new Date(chosenTimePeriod as Date);
    startDate.setDate(startDate.getDate() - days);

    for (let i = 0; i <= days; i++) {
        const currentDate = new Date(startDate.getTime());
        currentDate.setDate(startDate.getDate() + i);

        const startOfDayTimestamp = normalizeDate(currentDate);
        const endOfDayTimestamp = startOfDayTimestamp + (1000 * 60 * 60 * 24);

        const dayString = currentDate.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' });

        let resultObject: IDataForBarChart = { timePeriod: dayString, Думки: 0 };

        data.forEach(event => {
            const eventDate = new Date(event.eventStartTime);
            const eventTimestamp = eventDate.getTime();

            if (eventTimestamp >= startOfDayTimestamp && eventTimestamp < endOfDayTimestamp) {
                const categoryKey = event.event;
                if (categoryKey === 'Думки 💭') {
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + 1;
                } else {
                    resultObject[categoryKey] = (resultObject[categoryKey] as number || 0) + 1;
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + event.thoughts.length;
                }
            }
        });

        result.push(resultObject);
    }
}
function calculateColumnFromStartToEndDate(
    data: IEventForBarChart[],
    startDate: Date | null,
    endDate: Date | null,
    result: IDataForBarChart[]
) {
    // Якщо startDate дорівнює null, встановлюємо її як поточну дату
    if (!startDate) {
        startDate = new Date();
    }

    // Якщо endDate дорівнює null, встановлюємо її як наступний день після startDate
    if (!endDate) {
        endDate = new Date(startDate.getTime());
        endDate.setDate(endDate.getDate() + 1);
    }

    // Встановлення початку дня для startDate
    const startOfDayTimestamp = new Date(startDate);
    startOfDayTimestamp.setHours(0, 0, 0, 0);

    // Встановлення кінця дня для endDate
    const endOfDayTimestamp = new Date(endDate);
    endOfDayTimestamp.setHours(23, 59, 59, 999);

    // Формування рядка періоду у форматі 'dd.mm-dd.mm'
    const startOfPeriodString = startOfDayTimestamp.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' });
    const endOfPeriodString = endOfDayTimestamp.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' });
    const periodString = `${startOfPeriodString}-${endOfPeriodString}`;

    let resultObject: IDataForBarChart = { timePeriod: periodString, Думки: 0 };

    data.forEach(event => {
        const eventDate = new Date(event.eventStartTime);
        const eventTimestamp = eventDate.getTime();

        if (eventTimestamp >= startOfDayTimestamp.getTime() && eventTimestamp <= endOfDayTimestamp.getTime()) {
            const categoryKey = event.event;
            if (categoryKey === 'Думки 💭') {
                resultObject['Думки'] = (resultObject['Думки'] as number || 0) + 1;
            } else {
                resultObject[categoryKey] = (resultObject[categoryKey] as number || 0) + 1;
                resultObject['Думки'] = (resultObject['Думки'] as number || 0) + event.thoughts.length;
            }
        }
    });

    result.push(resultObject);
}

function calculateWeeklyColumns(
    data: IEventForBarChart[],
    startDate: Date | null,
    endDate: Date | null,
    result: IDataForBarChart[]
) {
    if (!startDate) {
        startDate = new Date();
    }

    if (!endDate) {
        endDate = new Date(startDate.getTime());
        endDate.setDate(endDate.getDate() + 1);
    }

    const daysInWeek = 7;
    const currentStartDate = new Date(startDate.getTime());
    currentStartDate.setHours(0, 0, 0, 0);

    while (currentStartDate <= endDate) {
        const currentEndDate = new Date(currentStartDate.getTime());
        currentEndDate.setDate(currentEndDate.getDate() + daysInWeek - 1);
        currentEndDate.setHours(23, 59, 59, 999);

        // Формування рядка періоду у форматі 'dd.mm-dd.mm'
        const startOfWeekString = currentStartDate.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' });
        const endOfWeekString = currentEndDate.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' });
        const weekString = `${startOfWeekString}-${endOfWeekString}`;

        let resultObject: IDataForBarChart = { timePeriod: weekString, Думки: 0 };

        data.forEach(event => {
            const eventDate = new Date(event.eventStartTime);
            const eventTimestamp = eventDate.getTime();

            if (eventTimestamp >= currentStartDate.getTime() && eventTimestamp <= currentEndDate.getTime()) {
                const categoryKey = event.event;
                if (categoryKey === 'Думки 💭') {
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + 1;
                } else {
                    resultObject[categoryKey] = (resultObject[categoryKey] as number || 0) + 1;
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + event.thoughts.length;
                }
            }
        });

        result.push(resultObject);

        // Переходимо до наступного тижня
        currentStartDate.setDate(currentStartDate.getDate() + daysInWeek);
    }
}





function getDaysInMonth(date: Date): number {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Створюємо нову дату з наступного місяця і дня 0, що дає нам останній день поточного місяця
    const lastDayOfMonth = new Date(year, month + 1, 0);

    // Повертаємо число, яке представляє день останнього дня місяця
    return lastDayOfMonth.getDate();
}

function calculateMonthlyColumns(
    data: IEventForBarChart[],
    startDate: Date | null,
    endDate: Date | null,
    result: IDataForBarChart[]
) {
    if (!startDate) {
        startDate = new Date();
    }

    if (!endDate) {
        endDate = new Date(startDate.getTime());
        endDate.setDate(endDate.getDate() + 1);
    }

    const currentStartDate = new Date(startDate.getTime());
    currentStartDate.setHours(0, 0, 0, 0);

    while (currentStartDate <= endDate) {
        const startOfMonth = new Date(currentStartDate.getFullYear(), currentStartDate.getMonth(), 1);
        const endOfMonth = new Date(currentStartDate.getFullYear(), currentStartDate.getMonth() + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);

        // Формування рядка періоду у форматі 'dd.mm-dd.mm'
        const startOfMonthString = startOfMonth.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' });
        const endOfMonthString = endOfMonth.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' });
        const monthString = `${startOfMonthString}-${endOfMonthString}`;

        let resultObject: IDataForBarChart = { timePeriod: monthString, Думки: 0 };

        data.forEach(event => {
            const eventDate = new Date(event.eventStartTime);
            const eventTimestamp = eventDate.getTime();

            if (eventTimestamp >= startOfMonth.getTime() && eventTimestamp <= endOfMonth.getTime()) {
                const categoryKey = event.event;
                if (categoryKey === 'Думки 💭') {
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + 1;
                } else {
                    resultObject[categoryKey] = (resultObject[categoryKey] as number || 0) + 1;
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + event.thoughts.length;
                }
            }
        });

        result.push(resultObject);

        // Переходимо до наступного місяця
        currentStartDate.setMonth(currentStartDate.getMonth() + 1);
        currentStartDate.setDate(1); // Встановлюємо перший день місяця
    }
}

function calculateQuarterlyColumns(data: IEventForBarChart[], chosenTimePeriod: Date | null, result: IDataForBarChart[], quarters: number) {
    // Ініціалізація змінних
    let currentDate = chosenTimePeriod ? new Date(chosenTimePeriod) : new Date();

    for (let i = 0; i < quarters; i++) {
        // Обчислення початку тримісячного періоду
        let startOfQuarter = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3 - i * 3, 1);
        startOfQuarter.setHours(0, 0, 0, 0);

        // Обчислення кінця тримісячного періоду
        let endOfQuarter = new Date(startOfQuarter.getFullYear(), startOfQuarter.getMonth() + 3, 0);
        endOfQuarter.setHours(23, 59, 59, 999);

        // Формування часових міток
        let columnName = `${startOfQuarter.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' })} - ${endOfQuarter.toLocaleDateString('uk-UA', { month: '2-digit', day: 'numeric' })}`;

        // Ініціалізація об'єкту результату
        let resultObject: IDataForBarChart = { timePeriod: columnName, thoughts: 0 };

        // Обробка подій
        data.forEach(event => {
            const eventDate = new Date(event.eventStartTime);
            const eventTimestamp = eventDate.getTime();
            if (eventTimestamp >= startOfQuarter.getTime() && eventTimestamp <= endOfQuarter.getTime()) {
                const categoryKey = event.event;
                if (categoryKey === 'Думки 💭') {
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + 1;
                } else {
                    resultObject[categoryKey] = (resultObject[categoryKey] as number || 0) + 1;
                    resultObject['Думки'] = (resultObject['Думки'] as number || 0) + (event.thoughts.length || 0);
                }
            }
        });

        // Додавання результату
        result.push(resultObject);
    }
}

// Функція для знаходження останнього дня тижня, в якому знаходиться дата
function getLastDayOfWeek(date: Date | null): Date {
    if (date === null) {
        return new Date(); // Повертаємо поточну дату, якщо вхідний параметр null
    }

    const day = date.getDay(); // День тижня (нумерація з 0 для неділі)
    const diff = 7 - day; // Різниця між поточним днем тижня і неділею
     // Додаємо дні
    return new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
}

// Функція для знаходження наступного дня тижня, в якому знаходиться дата
function getNextDayOfWeek(date: Date | null): Date {
    if (date === null) {
        return new Date(); // Повертаємо поточну дату, якщо вхідний параметр null
    }

    const day = date.getDay(); // День тижня (нумерація з 0 для неділі)
    const diff = day === 0 ? 1 : 8 - day; // Якщо неділя, то наступний день - понеділок (diff = 1)
     // Додаємо дні
    return new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
}


// Функція для знаходження першого дня тижня, в якому знаходиться дата
function getFirstDayOfWeek(date: Date|null): Date {
    if (date === null) {
        return new Date()
    }
    const day = date.getDay(); // День тижня (нумерація з 0 для неділі)
    const diff = day === 0 ? -6 : 1 - day; // Якщо неділя, то перший день - понеділок (diff = -6)
     // Додаємо дні
    return new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
}

// Функція для знаходження останнього дня тижня, який був перед датою
function getLastDayOfLastWeek(date: Date | null): Date {
    if (date === null) {
        return new Date(); // Повертаємо поточну дату, якщо вхідний параметр null
    }

    const day = date.getDay(); // День тижня (нумерація з 0 для неділі)
    const diff = day === 0 ? 7 : day; // Якщо неділя, то diff = 7, інакше diff = day
     // Віднімаємо дні
    return new Date(date.getTime() - diff * 24 * 60 * 60 * 1000);
}

function getStartOfMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getEndOfMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function getStartOfNextMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

function getLastDayOfPreviousMonth(date: Date | null): Date {
    if (date === null) {
        date = new Date();
    }
    return new Date(date.getFullYear(), date.getMonth(), 0);
}





export const computationsDataForBarChart = (data: IEventForBarChart[], chosenTimePeriod: (Date | null)[], interval: string) => {
    let result: IDataForBarChart[] = [];
    if (interval === consistentTimePeriodsForCharts[1]) {
        const differenceDay = getDifferenceInDays(chosenTimePeriod[0], chosenTimePeriod[1])
        calculateColumns(data, chosenTimePeriod[1], 1, result, differenceDay)
        result.reverse()
    }

    if (interval === consistentTimePeriodsForCharts[2]) {
        getLastDayOfWeek(chosenTimePeriod[0]).getTime() < new Date(chosenTimePeriod[1] as Date).getTime() &&  calculateColumnFromStartToEndDate(data,chosenTimePeriod[0],getLastDayOfWeek(chosenTimePeriod[0]),result)
        calculateWeeklyColumns(data,getNextDayOfWeek(chosenTimePeriod[0]),getLastDayOfLastWeek(chosenTimePeriod[1]),result)
        calculateColumnFromStartToEndDate(data,getFirstDayOfWeek(chosenTimePeriod[1]),chosenTimePeriod[1],result)
        result.reverse()
    }
    if (interval === consistentTimePeriodsForCharts[3]) {
        getEndOfMonth(chosenTimePeriod[0]).getTime() !== new Date(chosenTimePeriod[0] as Date).getTime() && calculateColumnFromStartToEndDate(data,chosenTimePeriod[0], getEndOfMonth(chosenTimePeriod[0]),result)
        calculateMonthlyColumns(data, getStartOfNextMonth(chosenTimePeriod[0]),getLastDayOfPreviousMonth(chosenTimePeriod[1]),result)
        getStartOfMonth(chosenTimePeriod[1]).getTime() !== new Date(chosenTimePeriod[1] as Date).getTime() && calculateColumnFromStartToEndDate(data,getStartOfMonth(chosenTimePeriod[1]), chosenTimePeriod[1],result)
        result.reverse()
    }

    if (interval === consistentTimePeriodsForCharts[4]) {
        const differenceDay = getDifferenceInQuarters(chosenTimePeriod[0], chosenTimePeriod[1])
        calculateQuarterlyColumns(data, chosenTimePeriod[1], result, differenceDay+1)
    }

    return result.reverse();
};


export const maxValueForBarChart = (arr: IDataForBarChart[]): number => {
    return arr.reduce((max, item) => {
        // Витягуємо всі числові значення з об'єкта, крім timePeriod
        const values = Object.keys(item)
            .filter(key => key !== 'timePeriod')
            .map(key => item[key] as number);

        // Знаходимо максимальну суму значень для поточного об'єкта
        const sum = values.reduce((a, b) => a + b, 5);

        // Порівнюємо з поточним максимальним значенням
        return sum > max ? sum : max;
    }, 0);
}