import styled from 'styled-components'
import { Link } from 'react-router-dom'
export const BlockForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.background};
`

export const Forms = styled.div`
  width: 23vw;
  height: 50vh;
  padding-top: ${props => (props.slot === '40' ? '80' : '60')}px;
  padding-left: 40px;
  padding-right: 40px;
  background: ${props => props.theme.background};
  border: 1px solid #52616b;
  & form {
    padding-top: 2vh;
    height: 20vh;
  }
  & h2 {
    font-family: Verdana,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    margin: -30px 0 30px;
    padding: 0;
    color: ${props => props.theme.hJoin};
    text-align: center;
  }
`
export const BoxUserName = styled.div`
  height: 50%;
  & input {
    width: 100%;
    padding-top: 25px;
    font-size: 16px;
    color: #c9d6df;
    border: none;
    border-bottom: 1px solid #52616b;
    outline: none;
    background: transparent;
    &:focus {
      border-bottom: 1px solid #e3c565;
    }
  }
  & label {
    font-family: 'Verdana',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    left: 0;
    top: -50px;
    padding: 10px 0;
    color: #52616b;
    pointer-events: none;
  }
  /* input:focus ~ label,
  input:valid ~ label {
    color: #e3c565;
  } */
  & p {
    font-family: 'Verdana';
    font-weight: 400;
    font-size: 12px;
    color: crimson;
    position: relative;
    left: 0;
    top: -40px;
    padding: 10px 0;
  }
`
export const Linked = styled(Link)`
  text-decoration: none;
  & span {
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding: 10px 20px;
    color: ${props => props.theme.txtLogin};
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    border: 1px solid ${props => props.theme.txtLogin};
    margin: auto;
  }
`

export const BoxSubmit = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  width: 100%;
  height: 10%;
  justify-content: center;
  & p {
    margin: auto;

    font-family: 'Verdana',sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: crimson;
    padding: 10px 0;
  }
`
export const BoxSubmitSuccess = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8%;
  width: 100%;
  height: 10%;
  justify-content: center;
`

export const BlockRegisterStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000000;
`

export const BlockSuccess = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 38vh;
  padding: ${props => (props.slot === '40' ? '40' : '60')}px;
  transform: translate(-50% -50%);
  background: ${props => props.theme.background};
  border: 1px solid #52616b;
  & img {
    width: 10vw;
    height: 10vh;
  }
  & form {
    height: 20vh;
  }
  & p {
    font-family: 'Verdana';
    font-weight: 100;
    font-size: 36px;
    margin: 15px 0 0 0;
    padding: 0;
    color: ${props => props.theme.dontHaveAccount};
    text-align: center;
  }
`

export const Bth = styled.button`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  & span {
    font-family: 'Verdana';
    font-size: 18px;
    padding: 10px 20px;
    color: ${props => props.theme.txtLogin};
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    border: 1px solid #fadd4b;
    margin: auto;
  }
  /* & span:hover {
    background-color: rgba(250, 221, 75, 0.9);
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e3c565, 0 0 12px #e3c565, 0 0 12px #e3c565, 0 0 12px #e3c565;
  } */
`

export const Links = styled(Link)`
  text-decoration: none;
  & span {
    cursor: pointer;
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: auto;
    color: #fadd4b;
    text-decoration: none;
  }
`
