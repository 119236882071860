import React, {useState} from "react";
import {
    BlockAnswersForm, BlockAvatarStyled, BlockBtnWithAvatar, ContainerAnswersForm,
    DetailInformation, InfoAboutUser, ListForms, PersonalInfoBlock,
    ProfileInformationBlock, TableQuestionsAndAnswersForm,
} from "./ProfileInformation.styled";
import {Btn} from "./Btn";
import {ReactComponent as Journal} from '../../../assets/pictures/Journal.svg'
import {ReactComponent as ChartBtn} from '../../../assets/pictures/ChartBtn.svg'
import {ReactComponent as History} from '../../../assets/pictures/HistoryUser.svg'
import {ReactComponent as Avatar} from '../../../assets/pictures/Avatar.svg'
import {PopUp} from "../../../pop-up/Pop-Up";
import {
    additionalEducationDetails,
    additionalInfoDetails,
    contactDetails,
    educationDetails,
    EGeneralProfileInformation,
    EHeadersBlock, foreignLanguageDetails,

    months, otherInfoDetails, personalDetails, previousJobDetails, socialDetails
} from "./constants";
import {BlockInformation} from "./BlockInformation";
import {useLazyChangeActiveUserQuery} from "../../../store/api/cardsApi";
import {useGetAvatarQuery} from "../../../store/api/fireBaseApi";
import {IProfileInformation, ITest} from "../intefrace";

type Props = {
    data: IProfileInformation
}
export const ProfileInformation = ({data}: Props) => {
    const {data:avatar,error:avatarError} = useGetAvatarQuery(data?.employeeId)
    const [temporaryColorActiveBtn, setTemporaryColorActiveBtn] = useState(true)
    const [fetchRepos] = useLazyChangeActiveUserQuery()
    const [active, setActive] = useState(false)
    const [openAnswers, setOpenAnswers] = useState<boolean>(false)
    const [indexChosenTest, setIndexChosenTest] = useState<number>(0)

    function openAnswersFromTest(numberAnswers: number): void {
        setOpenAnswers(true)
        setIndexChosenTest(numberAnswers)
    }

    function changeActive() {
        fetchRepos(data?.employeeId).then((res) => {
            if (res.isSuccess) setTemporaryColorActiveBtn(!temporaryColorActiveBtn)
        })
    }

    function openPopUp() {
        setActive(true)
    }

    function differentTime(arg: Date): string | number {
        const now = new Date()
        const different = Math.ceil((now.getTime() - new Date(arg).getTime()) / (1000 * 3600 * 24))
        if (different > 30) {
            return (
                `${Math.floor(different / 30)} міс. ${different % 30} дн.`
            )
        } else {
            return different
        }
    }

    function formatDate(inputDate: Date): string {
        const date: Date = new Date(inputDate);
        const day: number = date.getDate();
        const month: string = months[date.getMonth()];
        const year: number = date.getFullYear();

        return `${day} ${month}, ${year}`;
    }

    return (
        <ProfileInformationBlock>
            <BlockBtnWithAvatar>
                <BlockAvatarStyled>
                    {!avatarError && avatar ? <img src={avatar.downloadUrl} alt={avatar.downloadUrl} /> :
                        <Avatar/>}
                </BlockAvatarStyled>
                <Btn active={temporaryColorActiveBtn} sideMarkingRight={'none'} changeActive={changeActive}>
                    <div></div>
                </Btn>
                <Btn sideMarkingRight={'none'}>
                    <History/>
                </Btn>
                <Btn sideMarkingLeft={'none'}>
                    <Journal/>
                </Btn>
                <Btn sideMarkingLeft={'none'}>
                    <ChartBtn/>
                </Btn>
                <Btn sideMarkingRight={'none'}>
                    <History/>
                </Btn>
            </BlockBtnWithAvatar>
            <DetailInformation>
                <InfoAboutUser>
                    <div>
                        {data && <span>{EGeneralProfileInformation.NAME} {data.nickName}</span>}
                    </div>
                    <div>
                        {data && <span>{EGeneralProfileInformation.STAGE} {data.stage}</span>}
                    </div>
                    <div>
                        {data && <span>{EGeneralProfileInformation.DEPARTMENT} {data.department}</span>}
                    </div>
                    <div>
                        {data && <span>{EGeneralProfileInformation.DAYINCOMPANY} {differentTime(data.createdAt)}</span>}
                    </div>
                    <div>
                        {data &&
                            <span>{EGeneralProfileInformation.DAYINCOMPANYFROM} {formatDate(data.createdAt)}</span>}
                    </div>
                </InfoAboutUser>
                <PersonalInfoBlock>
                    {data && <BlockInformation questions={contactDetails} information={data}
                                       header={EHeadersBlock.CONTACTDETAILS} visibleAdditionalBtn={false}
                                       personalDetailsAndOther={true}/>}
                    {data && <BlockInformation questions={personalDetails} information={data}
                                       header={EHeadersBlock.PERSONALDETAILS} visibleAdditionalBtn={false}
                                       personalDetailsAndOther={true} formatDate={formatDate}/>}
                    {data?.education.length && <BlockInformation questions={educationDetails} information={data.education[0]}
                                       header={EHeadersBlock.EDUCATION} visibleAdditionalBtn={data.education.length > 1}
                                       personalDetailsAndOther={true} formatDate={formatDate}
                                       additionalInformation={data?.education.slice(1)}/>}
                    {data?.courses.length && <BlockInformation questions={additionalEducationDetails} information={data.courses[0]}
                                       header={EHeadersBlock.ADDITIONALEDUCATIONS}
                                       visibleAdditionalBtn={data.courses.length > 1} personalDetailsAndOther={true}
                                       formatDate={formatDate} additionalInformation={data?.courses.slice(1)}/>}
                    {data.workExperience.length && <BlockInformation questions={previousJobDetails} information={data?.workExperience[0]}
                                                                     header={EHeadersBlock.PREVIOUSJOB}
                                                                     visibleAdditionalBtn={data?.workExperience.length > 1}
                                                                     personalDetailsAndOther={true} formatDate={formatDate}
                                                                     additionalInformation={data?.workExperience.slice(1)}/>}
                    {data?.foreignLanguage.length && <BlockInformation questions={foreignLanguageDetails} information={data.foreignLanguage.slice(0, 3)}
                                       header={EHeadersBlock.FOREIGNLANGUAGE}
                                       visibleAdditionalBtn={data.foreignLanguage.length > 3}
                                       personalDetailsAndOther={false} formatDate={formatDate}
                                       additionalInformation={data?.foreignLanguage.slice(3)} foreignLanguage={true}/>}
                    {data && <BlockInformation questions={additionalInfoDetails} information={data}
                                       header={EHeadersBlock.ADDITIONALINFO} visibleAdditionalBtn={false}
                                       personalDetailsAndOther={true} formatDate={formatDate}/>}
                    {data && <BlockInformation questions={otherInfoDetails} information={data} lineAfterQuestions={true}
                                       header={EHeadersBlock.OTHERQUESTIONS} visibleAdditionalBtn={false}
                                       personalDetailsAndOther={true} formatDate={formatDate}/>}
                    {data?.social.length && <BlockInformation questions={socialDetails} information={data?.social.slice(0, 2)}
                                       header={EHeadersBlock.SOCIAL} visibleAdditionalBtn={data.social.length > 2}
                                       personalDetailsAndOther={false} formatDate={formatDate}
                                       additionalInformation={data?.social.slice(2)}/>}
                    {data?.answersQuestionnaire.length > 0 && <ContainerAnswersForm>
                        <span>Анкети</span>
                        <ListForms>
                            {data && data?.answersQuestionnaire.map((form: ITest, index: number) => (
                                <div key={index}>
                                    <span>{form.name}</span>
                                    <div onClick={() => {
                                        openAnswersFromTest(index)
                                    }}>
                                        результати
                                    </div>

                                </div>
                            ))}
                        </ListForms>
                        <PopUp active={openAnswers} setActive={setOpenAnswers} testing={false}>
                            <BlockAnswersForm>
                                <div>
                                    <div>Час проходження</div>
                                    <div>{new Date(data?.answersQuestionnaire[indexChosenTest]?.passageTime).toLocaleDateString()} {new Date(data?.answersQuestionnaire[indexChosenTest]?.passageTime).toLocaleTimeString()}</div>
                                </div>
                                <div>
                                    <div>Відділ</div>
                                    <div>{data?.answersQuestionnaire[indexChosenTest]?.stage}</div>
                                </div>
                                <div>
                                    <div>Опис</div>
                                    <div>{data?.answersQuestionnaire[indexChosenTest]?.testDescription}</div>
                                </div>
                                <TableQuestionsAndAnswersForm>
                                    <tr>
                                        <td>Питання</td>
                                        <td>Відповіді</td>
                                    </tr>
                                    {data?.answersQuestionnaire[indexChosenTest]?.questions.map((question: string, index: number) => {
                                        const result = data?.answersQuestionnaire[indexChosenTest].result?.[index];
                                        const formattedResult = Array.isArray(result) ? result.join(',') : result;
                                        return (
                                            <tr key={index}>
                                                <td>{question}</td>
                                                <td>{formattedResult}</td>
                                            </tr>
                                        );
                                    })}
                                </TableQuestionsAndAnswersForm>
                            </BlockAnswersForm>
                        </PopUp>
                    </ContainerAnswersForm>}
                </PersonalInfoBlock>
            </DetailInformation>
        </ProfileInformationBlock>
    )
}