import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  color: rgba(219, 232, 241, 1);
  margin: 15px;

  & span {
    padding: 10px 0;
  }
`
export const TextInputStyled = styled.input`
  background-color: rgba(82, 97, 107, 0.5);
  border: 0.5px solid rgba(82, 97, 107, 1);
  border-radius: 3px;
  padding: 3px 3px;
  color: rgba(219, 232, 241, 1);

  &::placeholder {
    color: rgba(82, 97, 107, 1);
  }
`
export const BlockRangeInputStyled = styled.div`
  display: flex;
  flex-direction: column;

  & div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2px;

    & div {
      background-color: #52616B;
      border-radius: 5px;
      padding: 5px;
      font-size: 10px;
    }
  }
`
export const RangeStyled = styled.input`
  accent-color: rgba(227, 197, 101, 1);
  height: 5px;
  margin: 10px 0;
`
export const CheckBoxStyled = styled.input`
  accent-color: rgba(227, 197, 101, 1);
`
export const StyledLabel = styled.label`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  padding: 5px 0;
`
export const AnswersBlock = styled.div`
  padding: 15px;
  border: 1px solid rgba(227, 197, 101, 1);
  border-radius: 5px;
`
export const RadioStyled = styled.input`
  margin-right: 5px;
  accent-color: rgba(227, 197, 101, 1);
`