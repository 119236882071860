import {BtnApply, Container, ListTestsBlock, TestBlock} from "./DragAndDrop.styled";
import React, {Dispatch, SetStateAction, useState} from "react";
import {ReactComponent as Dump} from "../../assets/pictures/Dumpster.svg";
import {useLazyChangeCounterTestsQuery, useLazyDeleteTestQuery} from "../../store/api/testsApi";

interface Test {
    _id:string
    counterOfAccess: number;
    name: string;
}

interface Props {
    setSortTests: Dispatch<SetStateAction<boolean>>;
    tests: Test[];
    refetchFunction: ()=>void
}

export const DragAndDrop: React.FC<Props> = ({ setSortTests, tests,refetchFunction }) => {
    const [cardList, setCardList] = useState<Test[]>(tests);
    const [currentCard, setCurrentCard] = useState<Test | null>(null);
    const [fetch] = useLazyChangeCounterTestsQuery()
    const [fetchDelete] = useLazyDeleteTestQuery()
    const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, card: Test) => {
        setCurrentCard(card);
    };

    const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.currentTarget.style.background = "rgba(82, 97, 107, 0.5)";
    };

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.currentTarget.style.background = "rgba(82, 97, 107, 1)";
    };

    const dropHandler = (e: React.DragEvent<HTMLDivElement>, card: Test) => {
        e.preventDefault();
        if (currentCard) {
            setCardList((prevCardList) => {
                return prevCardList.map((c) => {
                    if (c.counterOfAccess === card.counterOfAccess) {
                        return {...c, counterOfAccess: currentCard.counterOfAccess};
                    }
                    if (c.counterOfAccess === currentCard.counterOfAccess) {
                        return {...c, counterOfAccess: card.counterOfAccess};
                    }
                    return c;
                });
            });
        }
        e.currentTarget.style.background = "rgba(82, 97, 107, 0.5)";
    };

    const sortCards = (arr: Test[]): Test[] => {
        return [...arr].sort((a, b) => a.counterOfAccess - b.counterOfAccess);
    };

    const sortedArray = sortCards(cardList);

    const handleSubmit = () => {
        fetch({listChanges: cardList})
        setSortTests(false)
        refetchFunction()
    }

    const handleDelete = async (id:string) => {
        fetchDelete({id:id}).then((res)=>{
            setCardList(res?.data)
        })
    }

    return (
        <Container>
            {sortedArray?.map((card, index) => (
                <ListTestsBlock
                    key={index}
                    draggable={true}
                    onDragStart={(e: React.DragEvent<HTMLDivElement>) => dragStartHandler(e, card)}
                    onDragLeave={dragEndHandler}
                    onDragEnd={dragEndHandler}
                    onDragOver={dragOverHandler}
                    onDrop={(e: React.DragEvent<HTMLDivElement>) => dropHandler(e, card)}
                >
                    {card.name}
                    <div onClick={()=>handleDelete(card._id)}>
                        <Dump />
                    </div>
                </ListTestsBlock>
            ))}
            <TestBlock>
                <BtnApply onClick={() => handleSubmit()}>Apply</BtnApply>
            </TestBlock>
        </Container>
    );
};
