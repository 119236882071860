"use client";
import React from "react";
import { SparklesCore } from "./sparkles";

export function SparklesPreview() {
    return (

                <SparklesCore
                    id="tsparticlesfullpage"
                    background="transparent"
                    minSize={7}
                    maxSize={7}
                    particleDensity={50}
                    className="w-full h-full"
                    particleColor="#FFFFFF"
                />

    );
}
