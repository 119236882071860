type Props = { duration: number }
export const RowForDuration = ({duration}: Props) => {
    if (duration < 60) {
        return <span>{duration} сек.</span>
    } else if (duration > 60 && duration < 3600) {
        return <span>{Math.round(duration / 60)} хв.</span>
    } else if (duration > 3600) {
        return <span>{Math.floor(duration / 3600)} год. {Math.round((duration % 3600) / 60)} хв.</span>
    } else {
        return <span>-</span>
    }
}
