import styled from "styled-components";

export const Container = styled.div<{size:string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #E3C565;
  width: ${props=>props.size};
  color: rgba(219, 232, 241, 1);
  font-size: 1em;
  font-weight: 400;
  font-family: Verdana,sans-serif;
`