import {Container} from "./Answers.styled";
import React from "react";
import {IQuestion} from "./createNewTest/interface";

interface Props  {
    answersFromUser: IQuestion
    questionNumber: number
    question:string
}

export const Answers: React.FC<Props> = ({answersFromUser,question, questionNumber}) => {
    return (
        <Container>
            <span>{questionNumber + '. ' + question}</span>
            <p>{Array.isArray(answersFromUser?.answers) ? answersFromUser?.answers?.join(', ') : answersFromUser?.answers}</p>
        </Container>
    )
}