import {ButtonStyled} from "./Btn.styled";
import {ReactNode} from 'react';


type Props = {
    sideMarkingRight?: string
    sideMarkingLeft?: string
    children?: ReactNode
    changeActive?: () => void
    active?: boolean
}

export const Btn = ({sideMarkingRight, sideMarkingLeft, children, changeActive, active}: Props) => {
    return (
        <ButtonStyled active={active} onClick={changeActive} sideMarkingRight={sideMarkingRight}
                      sideMarkingLeft={sideMarkingLeft}>
            {children}
        </ButtonStyled>
    )
}