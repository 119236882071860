import {BlockInfo, Container, FilterBlock, MainBlockStyled, TableBlock,} from "../journal/Journal.styled";
import {
    useGetAllFavouriteQuery,
    useLazyReadEventsQuery
} from "../../store/api/journalApi";

import {useEffect, useState} from "react";
import {path} from "../../../utils/path";
import {useNavigate} from "react-router-dom";
import {Menu} from "../../menu/Menu";
import {Header} from "../../header/Header";
import {ReactComponent as Glass} from "../../assets/pictures/Glass.svg";
import {Btn} from "../journal/Btn";
import {Selector} from "../journal/Selector";
import {optionsEmotionLevel, optionsEvent} from "../journal/optionsTg";
import {HeaderForTable} from "../journal/HeaderForTable";
import {Table} from "../journal/Table";
import {useGetUserQuery} from "../../store/api/authApi";

export const Favourite = () => {
    const navigate = useNavigate()
    // const location = useLocation()
    const {data: user} = useGetUserQuery('');
    const [fetch] = useLazyReadEventsQuery()
    const [data, setData] = useState([])
    const [selectedRow, setSelectedRow] = useState<string | number>()
    const [description, setDescription] = useState<string[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<{
        value: string;
        name: string;
        field: string;
    }[]>([]);
    const [anchorEvent, setAnchorEvent] = useState<string[]>([])
    const [openBlock, setOpenBlock] = useState({event: false, thought: false, result: false})
    const {isError} = useGetUserQuery({isAdmin: 'admin'});
    const {data: queryData} = useGetAllFavouriteQuery({employeeId: user?.employeeId})
    if (isError) {
        navigate(`${path.login}`)
    }

    useEffect(() => {
        if (queryData) {
            setData(queryData.answers)
        }
    }, [queryData]);

    function UsersForSelector(field: string) {
        const result: { value: string, name: string, field: string }[] = []
        queryData && queryData?.users.map((elem: { chatId: string, name: string }) => {
            result.push({value: elem.chatId, name: elem.name, field: field})
        })
        return result
    }

    function result() {
        const groupedByDay = data && data.reduce((acc: any, obj: any) => {
            const date = new Date(obj.eventStartTime);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            const formattedDate =
                date.toDateString() === today.toDateString()
                    ? 'Сьогодні'
                    : date.toDateString() === yesterday.toDateString()
                        ? 'Вчора'
                        : `${date.getDate()}.${date.getMonth()}`;

            acc[formattedDate] = acc[formattedDate] || [];
            acc[formattedDate].push(obj);
            return acc;
        }, {})
        return groupedByDay && Object.entries(groupedByDay).map(([date, events]) => ({
            date,
            events
        }));
    }

    const ReadEvents = (employeeId?: string) => {
        fetch({adminId: user._id, id: employeeId}).then(r => {
            setData(r.data?.answers)
        })
    }

    return (
        <Container>
            {/*<Menu/>*/}
            <BlockInfo>
                {/*<Header>*/}
                {/*    <div>*/}
                {/*        <span>Збережені</span>*/}
                {/*        <Btn onclick={ReadEvents} marginTop={'5px'} children={<Glass/>}/>*/}
                {/*    </div>*/}
                {/*</Header>*/}
                <MainBlockStyled>
                    {/*<FilterBlock>*/}
                    {/*    <Selector*/}
                    {/*        padding={'0 5px'}*/}
                    {/*        margin={'2px 0'}*/}
                    {/*        size={'13.3vw'}*/}
                    {/*        optionsCategory={UsersForSelector('chatId')}*/}
                    {/*        selectedFilters={selectedFilters}*/}
                    {/*        setSelectedFilters={setSelectedFilters}*/}
                    {/*        header={'Користувачі'}*/}
                    {/*    />*/}
                    {/*    <Selector*/}
                    {/*        padding={'0 5px'}*/}
                    {/*        margin={'2px 0'}*/}
                    {/*        size={'18.45vw'}*/}
                    {/*        optionsCategory={optionsEvent} selectedFilters={selectedFilters}*/}
                    {/*        setSelectedFilters={setSelectedFilters}*/}
                    {/*        header={'Події'}*/}
                    {/*    />*/}
                    {/*    <HeaderForTable size={'12.55vw'} children={'Початок'}/>*/}
                    {/*    <HeaderForTable size={'12.55vw'} children={"Кінець"}/>*/}
                    {/*    /!*<HeaderForTable size={'9vw'} children={"Тривалість"}/>*!/*/}
                    {/*    <HeaderForTable size={'26vw'} children={"Локація"}/>*/}
                    {/*    <Selector*/}
                    {/*        padding={'0 5px'}*/}
                    {/*        margin={'2px 0'}*/}
                    {/*        size={'13vw'}*/}
                    {/*        optionsCategory={optionsEmotionLevel}*/}
                    {/*        selectedFilters={selectedFilters}*/}
                    {/*        setSelectedFilters={setSelectedFilters}*/}
                    {/*        borderRight={'none'}*/}
                    {/*        header={'Емоційний стан'}*/}
                    {/*    />*/}
                    {/*</FilterBlock>*/}
                    <TableBlock>
                        {result()?.map((day: any, index: number) => (
                            <Table
                                favourite={true}
                                description={description}
                                setDescription={setDescription}
                                adminsEmployeeId={user}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                readEvent={ReadEvents} key={index}
                                header={day.date}
                                event={day}
                                anchorEvent={anchorEvent}
                                setAnchorEvent={setAnchorEvent}
                                openBlock={openBlock}
                                setOpenBlock={setOpenBlock}
                            />
                        ))}
                    </TableBlock>
                </MainBlockStyled>
            </BlockInfo>
        </Container>
    )
}