import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import Cookies from "universal-cookie";
import {BaseQueryArg} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const envMode: string = process.env.REACT_APP_ENV_MODE || 'dev'
const cookies = new Cookies()

export const yellowDuckApi = createApi({
    reducerPath: 'yellowDuckApi',
    baseQuery: fetchBaseQuery({
        // baseUrl: envMode === 'stage' ? 'https://95.216.192.43:2053' : 'http://localhost:2053',
        baseUrl: envMode === 'stage' ? 'https://minds-factory.online:2053' : 'http://localhost:2053',
        prepareHeaders: (headers) => {
            const token = cookies.get('EMSTOKEN')
            headers.set('Content-Type', 'application/json');
            headers.set('X-Requested-With', 'XMLHttpRequest');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },
        credentials: 'include',
    }),
    refetchOnFocus: true,
    endpoints: build => ({
        customerTest: build.query({
            query: (body) => ({
                url: '/api/card/custom',
                method: 'POST',
                body
            })
        }),
        allInfo: build.query({
            query: () => ({
                url: '/api/card/allInfo'
            })
        }),
        // personalEmployeeId: build.query({
        //     query: () => ({
        //         url: '/api/user',
        //     })
        // }),
        analyticsData: build.query({
            query: (body) => ({
                url: '/api/analytics',
                method: 'POST',
                body
            })
        }),
        addCollections: build.query({
            query: () => ({
                url: '/api/card/custom',
                method: "POST"
            })
        }),
    }),
})

export const {
    useAddCollectionsQuery,
    useAnalyticsDataQuery,
    useAllInfoQuery,
} = yellowDuckApi

//


//розділення api
// export const inj = testApi.injectEndpoints({endpoints: build => {
//         login: build.query({
//             query: (body) => ({
//                 url: '/api/login',
//                 method: 'POST',
//                 body
//             })
//         }),
//     }})
