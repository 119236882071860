import {PopUp} from "../../pop-up/Pop-Up";
import React, {useState} from "react";
import {
    Props
} from "./interface";
import {
    ButtonSubmit,
    Container,
} from "./Acquaintance.styled";
import {
    additionalEducation, additionalEducationFields,
    additionalQuestions, contactDetails, contactPersonEmergency,
    ENameBlock,
    foreignLanguage, foreignLanguageFields, generalFields,
    generalInformation,
    NameForm, personEmergencyFields,
    previosWorkExperience, previosWorkExperienceFields, socialFields, socialNetworksQuestions
} from "./constants";
import {Education} from "./education/Education";
import {ContainerQuestions} from "./containerQuestions/ContainerQuestions";
import {ContainerForBlockQuestions} from "./containerForBlockQuestions/ContainerForBlockQuestions";
import {toast} from "react-toastify";
import {BtnSubmit} from "./constants";
import {useGetUserQuery} from "../../store/api/authApi";
import {useLazySendFormAcquaintanceAnswersQuery} from "../../store/api/testsApi";

export const Acquaintance: React.FC<Props> = ({activeTab, setActiveTab}) => {
    const {data: user} = useGetUserQuery('');
    const [answers, setAnswers] = useState([]);
    const [educationAnswers, setEducationAnswers] = useState([]);
    const [additionalEducationAnswers, setAdditionalEducationAnswers] = useState([]);
    const [foreignLanguageAnswers, setForeignLanguageAnswers] = useState([]);
    const [previosWorkAnswers, setPreviosWorkAnswers] = useState([]);
    const [additionalQuestionsAnswers, setAdditionalQuestionsAnswers] = useState([]);
    const [personEmergencyAnswers, setPersonEmergencyAnswers] = useState([]);
    const [socialAnswers, setSocialAnswers] = useState([]);
    const [fetch] = useLazySendFormAcquaintanceAnswersQuery()
    const notifySuccess = (message: string) => toast.success(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });


    const notifyError = (message: string) => toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const sendForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        fetch({
            employeeId: user?.employeeId,
            answers: answers,
            educationAnswers,
            additionalEducationAnswers,
            foreignLanguageAnswers,
            previosWorkAnswers,
            additionalQuestionsAnswers,
            personEmergencyAnswers,
            socialAnswers
        }).then((r) => {
            if (r.isSuccess) {
                notifySuccess(r?.data?.status);
                setActiveTab(false)
            } else {
                notifyError(r?.data?.status);
            }
        })
    }
    return <PopUp active={activeTab} setActive={setActiveTab}>

        <Container>
            <h1>{NameForm}</h1>
            <form onSubmit={sendForm}>
                <ContainerForBlockQuestions name={ENameBlock.generalInfo}>
                    <ContainerQuestions listQuestions={generalInformation} setState={setAnswers}
                                        fieldsForUpdate={generalFields} visibleButton={false}/>
                </ContainerForBlockQuestions>
                <ContainerForBlockQuestions name={ENameBlock.education}>
                    <Education setState={setEducationAnswers} visibleButton={true} field={'education'}/>
                </ContainerForBlockQuestions>
                <ContainerForBlockQuestions name={ENameBlock.additionalEducation}>
                    <ContainerQuestions listQuestions={additionalEducation} setState={setAdditionalEducationAnswers}
                                        fieldsForUpdate={additionalEducationFields} visibleButton={true}/>
                </ContainerForBlockQuestions>
                <ContainerForBlockQuestions name={ENameBlock.foreignLanguage}>
                    <ContainerQuestions listQuestions={foreignLanguage} setState={setForeignLanguageAnswers}
                                        fieldsForUpdate={foreignLanguageFields} visibleButton={true}/>
                </ContainerForBlockQuestions>
                <ContainerForBlockQuestions name={ENameBlock.previousWorkExperience}>
                    <ContainerQuestions listQuestions={previosWorkExperience} setState={setPreviosWorkAnswers}
                                        fieldsForUpdate={previosWorkExperienceFields} visibleButton={true}/>
                </ContainerForBlockQuestions>
                <ContainerForBlockQuestions name={ENameBlock.additionalQuestions}>
                    <ContainerQuestions listQuestions={additionalQuestions} setState={setAdditionalQuestionsAnswers}
                                        fieldsForUpdate={generalFields} visibleButton={false} previosWorkAnswers={previosWorkAnswers}/>
                </ContainerForBlockQuestions>
                <ContainerForBlockQuestions name={ENameBlock.contactDetail}>
                    <ContainerQuestions listQuestions={contactDetails} setState={setAnswers}
                                        fieldsForUpdate={generalFields} visibleButton={false}/>
                    <ContainerQuestions listQuestions={socialNetworksQuestions} setState={setSocialAnswers}
                                        fieldsForUpdate={socialFields} visibleButton={false}/>
                </ContainerForBlockQuestions>
                <ContainerForBlockQuestions name={ENameBlock.contactPersonInCaseEmergency}>
                    <ContainerQuestions listQuestions={contactPersonEmergency} setState={setPersonEmergencyAnswers}
                                        fieldsForUpdate={personEmergencyFields} visibleButton={true}/>
                </ContainerForBlockQuestions>
                <ButtonSubmit>
                    <button type={'submit'}>{BtnSubmit}</button>
                </ButtonSubmit>
            </form>
        </Container>
    </PopUp>
}