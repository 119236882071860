import {
    ArrowBlock,
    BlockList, BlockFixedTimeIntervals,
    Container,
    DatePickerStatsWrapper,
    DateSelectorStyled,
    NameSelector,
    VariantChoose, BlockAllFixedTimes, SvgCalendarVariant, BlockOptionsTheTimePeriod, BlockOption, BlockDatePicker
} from "./DateSelector.styled";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as ArrowForSelectorOpen} from "../../../assets/pictures/ArrowForSelectorOpen.svg";
import {ReactComponent as ArrowForSelectorClose} from "../../../assets/pictures/ArrowForSelectorClose.svg";
import {ReactComponent as Calendar} from "../../../assets/pictures/Calendar.svg";
import {consistentTimePeriods, consistentTimePeriodsForCharts} from "./contants";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {DatePickerFormat} from "./DatePickerFormat";
import {IIntervalMarkGraph} from "../interface";


type Props = {
    borderRight?: string;
    borderRadius?: string;
    border?: string;
    margin?: string;
    padding?: string;
    size: string;
    timeInterval: (Date | null)[]
    setTimeInterval: React.Dispatch<(Date | null)[]>
    intervalMarkGraph: IIntervalMarkGraph
    setIntervalMarkGraph: React.Dispatch<IIntervalMarkGraph>
};

export const DateSelector = ({
                                 borderRight,
                                 borderRadius,
                                 border,
                                 margin,
                                 padding,
                                 size,
                                 setTimeInterval,
                                 timeInterval,
                                 intervalMarkGraph,
                                 setIntervalMarkGraph
                             }: Props) => {
    const [timeGaps, setTimeGaps] = useState<string>(consistentTimePeriods[0]);
    // const [openPicker, setOpenPicker] = useState(false);
    // const maxDate = new Date().toISOString().split('T')[0];
    //
    //
    // const convertDateToTimestamp = (dateString: string): number => {
    //     return new Date(dateString).getTime();
    // };
    //
    // const formatTimestampToDate = (timestamp: number | string): string => {
    //     // Якщо timestamp у вигляді рядка, перетворюємо його в число
    //     const date = new Date(Number(timestamp));
    //     // Перетворення timestamp в рядок з форматом "YYYY-MM-DD"
    //     const year = date.getUTCFullYear();
    //     const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    //     const day = date.getUTCDate().toString().padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    // const onNativeChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    //     const value = e.target.value;
    //     const timestamp = convertDateToTimestamp(value);
    //
    //     // @ts-ignore
    //     setTimeInterval((prevTimeInterval) => {
    //         const updatedNative = [...prevTimeInterval];
    //         updatedNative[index] = timestamp;
    //
    //         if (index === 0) {
    //             // Якщо index = 0 і значення timeInterval[1] менше або рівне timeInterval[0],
    //             // встановлюємо timeInterval[1] на наступний день після timeInterval[0]
    //             updatedNative[1] = timestamp + 24 * 60 * 60 * 1000;
    //         }
    //
    //         // Перевірка на наявність 'тиждень', 'місяць' або 'рік' в масиві
    //         if (updatedNative.includes('7 днів') || updatedNative.includes('30 днів') || updatedNative.includes('90 днів') || updatedNative.includes('180 днів') || updatedNative.includes('360 днів')) {
    //             // Якщо такі значення є, видаляємо їх
    //             return updatedNative.filter((val) => val !== '7 днів' && val !== '30 днів' && val !== '90 днів' && val !== '180 днів' && val !== '360 днів');
    //         }
    //
    //         return updatedNative;
    //     });
    //
    //     if (index === 1) {
    //         setOpenPicker(false);
    //     }
    // };


    // const handleCheckboxChange = (type: string) => {
    //     switch (type) {
    //         case '7 днів':
    //             setTimeInterval(['7 днів']);
    //             setOpenPicker(false)
    //             break;
    //         case '30 днів':
    //             setTimeInterval(['30 днів']);
    //             setOpenPicker(false)
    //             break;
    //         case '90 днів':
    //             setTimeInterval(['90 днів']);
    //             setOpenPicker(false)
    //             break;
    //         case '180 днів':
    //             setTimeInterval(['180 днів']);
    //             setOpenPicker(false)
    //             break;
    //         case '360 днів':
    //             setTimeInterval(['360 днів']);
    //             setOpenPicker(false)
    //             break;
    //         default:
    //             break;
    //     }
    // };
    // const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

    // useEffect(() => {
    //     if (typeof timeInterval[0] === 'string') {
    //         setDateRange([null, null]);
    //     }
    // }, [timeInterval, dateRange[1]]);
    // const [startDate, endDate] = dateRange;
    // const handleDateChange = (update: [Date | null, Date | null]) => {
    //     setDateRange(update);
    //
    //     if (update[0] && update[1]) {
    //         setTimeInterval([update[0].getTime(), update[1].getTime()]);
    //     }
    // };


    //для datePicker
    // const [visibleOptionIndex, setVisibleOptionIndex] = useState<number | null>(null);
    // const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
    // const hideTimeout = useRef<NodeJS.Timeout | null>(null);
    //
    // const handleMouseEnterOption = (index: number) => {
    //     if (hideTimeout.current) {
    //         clearTimeout(hideTimeout.current);
    //         hideTimeout.current = null;
    //     }
    //     setVisibleOptionIndex(index);
    //     setIsOptionsVisible(true);
    // };
    //
    // const handleMouseLeaveOption = () => {
    //     hideTimeout.current = setTimeout(() => {
    //         setVisibleOptionIndex(null);
    //         setIsOptionsVisible(false);
    //     }, 100); // Затримка для уникнення миттєвого приховування
    // };
    //
    // const handleMouseEnterPicker = () => {
    //     if (hideTimeout.current) {
    //         clearTimeout(hideTimeout.current);
    //         hideTimeout.current = null;
    //     }
    // };

    const [testTimeInterval, setTestTimeInterval] = useState<(Date | null)[]>([new Date(), new Date()]);
    const handleChangePeriod = (chosen: string): void => {
        const now = new Date();
        const todayStart = new Date();
        let numberDaysSubtracted
        setTimeGaps(chosen)
        setIntervalMarkGraph({
            ...intervalMarkGraph,
            line: consistentTimePeriodsForCharts[1],
            multipleLines: consistentTimePeriodsForCharts[1]
        })
        switch (chosen) {
            case consistentTimePeriods[0]:
                todayStart.setHours(0, 0, 0, 0);
                setTimeInterval([todayStart, now])
                setIntervalMarkGraph({
                    ...intervalMarkGraph,
                    line: consistentTimePeriodsForCharts[1],
                    multipleLines: consistentTimePeriodsForCharts[1],
                    bar: consistentTimePeriodsForCharts[1],
                })
                break;
            case consistentTimePeriods[1]:
                numberDaysSubtracted = 7
                break;
            case consistentTimePeriods[2]:
                numberDaysSubtracted = 30
                break;
            case consistentTimePeriods[3]:
                numberDaysSubtracted = 90
                setIntervalMarkGraph({
                    ...intervalMarkGraph,
                    line: consistentTimePeriodsForCharts[2],
                    multipleLines: consistentTimePeriodsForCharts[2],
                    bar: consistentTimePeriodsForCharts[2],
                })
                break;
            case consistentTimePeriods[4]:
                numberDaysSubtracted = 180
                setIntervalMarkGraph({
                    ...intervalMarkGraph,
                    line: consistentTimePeriodsForCharts[2],
                    multipleLines: consistentTimePeriodsForCharts[2],
                    bar: consistentTimePeriodsForCharts[2],
                })
                break;
            case consistentTimePeriods[5]:
                numberDaysSubtracted = 365
                setIntervalMarkGraph({
                    ...intervalMarkGraph,
                    line: consistentTimePeriodsForCharts[3],
                    multipleLines: consistentTimePeriodsForCharts[3],
                    bar: consistentTimePeriodsForCharts[3]
                })
                break;
            default:
                numberDaysSubtracted = 1
                break
        }
        if (numberDaysSubtracted) {
            setTimeInterval([new Date(now.getTime() - numberDaysSubtracted * 24 * 60 * 60 * 1000), now])

        }
    }

    return (
        <Container>
            <BlockAllFixedTimes>
                {consistentTimePeriods.map((elem: string, index: number) => (
                    <BlockFixedTimeIntervals
                        onClick={() => {
                            handleChangePeriod(elem)
                        }}
                        active={timeGaps === elem}
                        // cursor={'pointer'}
                        key={index}
                        // width={'17px'}
                        // height={'17px'}
                        // border={'rgba(227, 197, 101, 0.5)'}
                    >
                        {elem}
                        {/*<input*/}
                        {/*    checked={timeInterval.includes(type)}*/}
                        {/*    type={'checkbox'}*/}
                        {/*    onChange={() => handleCheckboxChange(type)}*/}
                        {/*/>*/}
                    </BlockFixedTimeIntervals>))}
                <DatePickerFormat
                    timeGaps={timeGaps}
                    setTimeGaps={setTimeGaps}
                    timeInterval={timeInterval}
                    setTimeInterval={setTimeInterval}
                    day={true}
                    intervalMarkGraph={intervalMarkGraph}
                    setIntervalMarkGraph={setIntervalMarkGraph}
                    // setVisibleOptionIndex={setVisibleOptionIndex}
                    // setIsOptionsVisible={setIsOptionsVisible}
                />
                <SvgCalendarVariant>
                    <Calendar/>
                    {/*<BlockOptionsTheTimePeriod visible={isOptionsVisible}>*/}
                    {/*    {optionsTheTimePeriod.map((item: string, index: number) => (*/}
                    {/*        <BlockOption*/}
                    {/*            key={index}*/}
                    {/*            onMouseEnter={() => handleMouseEnterOption(index)}*/}
                    {/*            onMouseLeave={handleMouseLeaveOption}*/}
                    {/*        >*/}
                    {/*            {item}*/}
                    {/*            <BlockDatePicker*/}
                    {/*                visible={visibleOptionIndex === index}*/}
                    {/*                onMouseEnter={handleMouseEnterPicker}>*/}
                    {/*                {getInfoComponent(item)}*/}
                    {/*            </BlockDatePicker>*/}
                    {/*        </BlockOption>*/}
                    {/*    ))}*/}
                    {/*</BlockOptionsTheTimePeriod>*/}
                </SvgCalendarVariant>
            </BlockAllFixedTimes>

            {/*<DateSelectorStyled*/}
            {/*    padding={padding}*/}
            {/*    margin={margin}*/}
            {/*    border={border}*/}
            {/*    borderRadius={borderRadius}*/}
            {/*    size={size}*/}
            {/*    borderRight={borderRight}*/}
            {/*    onClick={() => setOpenPicker(!openPicker)}*/}
            {/*>*/}
            {/*    <NameSelector>{typeof timeInterval[0] === 'string'*/}
            {/*        ? timeInterval[0]*/}
            {/*        : timeInterval.length === 2*/}
            {/*            ? `${new Date(timeInterval[0]).toLocaleDateString()} - ${new Date(timeInterval[1]).toLocaleDateString()}`*/}
            {/*            : formatTimestampToDate(timeInterval[0]) || "Дата"}</NameSelector>*/}
            {/*    <ArrowBlock>*/}
            {/*        {openPicker ? <ArrowForSelectorClose/> : <ArrowForSelectorOpen/>}*/}
            {/*    </ArrowBlock>*/}
            {/*</DateSelectorStyled>*/}
            {/*{openPicker && (*/}
            {/*    <BlockList>*/}
            {/*        <DatePickerStatsWrapper>*/}
            {/*            <DatePicker*/}
            {/*                selectsRange*/}
            {/*                startDate={startDate}*/}
            {/*                endDate={endDate}*/}
            {/*                onChange={handleDateChange}*/}

            {/*            />*/}
            {/*        </DatePickerStatsWrapper>*/}
            {/*        /!*{[0, 1].map((index:number) => (*!/*/}
            {/*        /!*    <VariantChoose cursor={'text'} key={index} width={'75%'} height={'75%'} border={'none'} top={index === 0 ? '2px' : '2px'}>*!/*/}
            {/*        /!*        {index === 0 ? 'від' : 'до'}*!/*/}
            {/*        /!*        <input*!/*/}
            {/*        /!*            type={'date'}*!/*/}
            {/*        /!*            value={timeInterval[index] && !consistentTimePeriods.includes(timeInterval[index] as string) ? formatTimestampToDate(timeInterval[index]) : ''}*!/*/}
            {/*        /!*            min="2023-01-01"*!/*/}
            {/*        /!*            max={maxDate}*!/*/}
            {/*        /!*            onChange={(e) => onNativeChange(e, index)}*!/*/}
            {/*        /!*        />*!/*/}
            {/*        /!*    </VariantChoose>*!/*/}
            {/*        /!*))}*!/*/}
            {/*        {consistentTimePeriods.map((type: string, index: number) => (*/}
            {/*            <VariantChoose*/}
            {/*                cursor={'pointer'}*/}
            {/*                key={type}*/}
            {/*                width={'17px'}*/}
            {/*                height={'17px'}*/}
            {/*                border={'rgba(227, 197, 101, 0.5)'}*/}
            {/*            >*/}
            {/*                {index === 0 ? "" : '~'} {type}*/}
            {/*                <input*/}
            {/*                    checked={timeInterval.includes(type)}*/}
            {/*                    type={'checkbox'}*/}
            {/*                    onChange={() => handleCheckboxChange(type)}*/}
            {/*                />*/}
            {/*            </VariantChoose>*/}
            {/*        ))}*/}
            {/*    </BlockList>*/}
            {/*)}*/}
        </Container>
    );
};