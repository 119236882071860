import React, { useState } from 'react'
import Ok from '../../assets/pictures/Ok.svg'
import { path } from '../../../utils/path'
import { BlockForm, BoxSubmitSuccess, Forms, BoxUserName, Linked, BoxSubmit, BlockRegisterStyled, BlockSuccess, Bth } from './Registration.styled'
import { useEffect } from 'react'
import {useLazyRegistrationQuery} from "../../store/api/authApi";
import {ToastContainer} from "react-toastify";

export const Registration = () => {
    const [fetchRepos] = useLazyRegistrationQuery()
    const [errorEmail, setErrorEmail] = useState('Поле пошти не може бути пустим')
    const [errorPassword, setErrorPassword] = useState('Поле паролю не може бути пустим')
    const [emailDirty, setEmailDirty] = useState(false)
    const [passwordDirty, setPasswordDirty] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [formValid, setFormValid] = useState(false)
    const [replayEmail, setReplayEmail] = useState(false)

    const nameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }
    const emailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(e.target.value).toLowerCase())) {
            setErrorEmail('Некоректна пошта')
        } else {
            setErrorEmail('')
        }
    }

    const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z!#$%&? "])[a-zA-Z0-9!#$%&?]{8,20}$/
        if (!re.test(String(e.target.value).toLowerCase())) {
            setErrorPassword('Пароль має містити від 8 символів, цифри та букви')
        } else {
            setErrorPassword('')
        }
    }

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'password':
                setPasswordDirty(true)
                break
            case 'email':
                setEmailDirty(true)
                break

            default:
                return
        }
    }

    useEffect(() => {
        if (errorEmail  || errorPassword) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorEmail, errorPassword])

    const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const userData = {
            name: name,
            email: email,
            password: password,
        }
        fetchRepos(userData).then((res)=> {
            if (res.isSuccess) {
                setRegistered(false)
            }
            if (res.isError) {
                setReplayEmail(true)
            }
        })
    }
    const [registered, setRegistered] = useState(true)
    return (
        <BlockRegisterStyled>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            {registered ? (
                <BlockForm>
                    <Forms slot={'40'} onSubmit={handleSubmit}>
                        <h2>Реєстрація</h2>
                        <form>
                            <BoxUserName>
                                <input name="name" onChange={e => nameHandler(e)} type={'text'} value={name} autoComplete="off" onBlur={e => blurHandler(e)} required={true} />
                                <label>Імʼя:</label>
                            </BoxUserName><BoxUserName>
                                <input name="email" onChange={e => emailHandler(e)} type={'email'} value={email} autoComplete="off" onBlur={e => blurHandler(e)} />
                                <label>Пошта:</label>
                                {errorEmail && emailDirty && <p>{errorEmail}</p>}
                            </BoxUserName>
                            <BoxUserName>
                                <input
                                    name="password"
                                    onChange={e => passwordHandler(e)}
                                    type="password"
                                    value={password}
                                    autoComplete="off"
                                    onBlur={e => blurHandler(e)}
                                />
                                <label>Пароль:</label>
                                {errorPassword && passwordDirty && <p>{errorPassword}</p>}
                            </BoxUserName>
                            <BoxSubmit>
                                <Bth disabled={!formValid} type="submit">
                                    <span>ЗАРЕЄСТРУВАТИ</span>
                                </Bth>
                                {replayEmail && <p>Така пошта вже зареєстрована</p>}
                            </BoxSubmit>
                        </form>
                    </Forms>
                </BlockForm>
            ) : (
                <BlockForm>
                    <BlockSuccess>
                        <img src={Ok} alt="photos" />
                        <p>Аккаунт успішно створено!</p>
                        <BoxSubmitSuccess >
                            <Linked to={`${path.login}`}>
                <span
                    onClick={() => {
                        setRegistered(false)
                    }}
                >
                  Увійти
                </span>
                            </Linked>
                        </BoxSubmitSuccess>
                        {/* <h2>Реєстрація</h2>
          <form>
            <BoxUserName>
              <input type="text" name="" />
              <label>Name:</label>
            </BoxUserName>
            <BoxUserName>
              <input type="text" name="" />
              <label>E-mail:</label>
            </BoxUserName>
            <BoxUserName>
              <input type="password" name="" />
              <label>Password:</label>
            </BoxUserName>
            <BoxSubmit>
              <Linked to={`${paths.main}`}>
                <span>ЗАРЕЄСТРУВАТИ</span>
              </Linked>
            </BoxSubmit>
          </form>*/}
                    </BlockSuccess>
                </BlockForm>
            )}
        </BlockRegisterStyled>
    )
}
