import {IconStyled, MenuBlockStyled} from "./Menu.styled";
import {ReactComponent as Home} from "../assets/pictures/Home.svg";
import {ReactComponent as Journal} from "../assets/pictures/Journal.svg";
import {ReactComponent as Statistics} from "../assets/pictures/Statistics.svg";
import {ReactComponent as Heart} from "../assets/pictures/Heart.svg";
import {ReactComponent as Test} from "../assets/pictures/Materials.svg";
import {ReactComponent as Profile} from "../assets/pictures/Avatar.svg";
import {path} from "../../utils/path";
import {useLocation} from "react-router-dom";
import React from "react";
import {useGetUserQuery} from "../store/api/authApi";

export const Menu: React.FC = () => {
    const location = useLocation()
    const {isError} = useGetUserQuery({isAdmin: 'admin'});

    return (
        <MenuBlockStyled>
            {!isError &&
                <IconStyled to={`${path.admin}`} activefill={location.pathname === path.admin ? '#E3C565' : '#C9D6DF'}>
                    <Home/>
                </IconStyled>}
            {!isError && <IconStyled to={`${path.journal}`}
                                     activefill={location.pathname === path.journal ? '#E3C565' : '#C9D6DF'}>
                <Journal/>
            </IconStyled>}
            {!isError && <IconStyled to={`${path.favourite}`}
                                     activestroke={location.pathname === path.favourite ? '#E3C565' : '#C9D6DF'}>
                <Heart/>
            </IconStyled>}
            {!isError && <IconStyled to={`${path.statistics}`}
                                     activestroke={location.pathname === path.statistics ? '#E3C565' : '#C9D6DF'}>
                <Statistics/>
            </IconStyled>}
            <IconStyled to={`${path.profile}`}
                        activefill={location.pathname === path.profile ? '#E3C565' : '#C9D6DF'}>
                <Profile/>
            </IconStyled>
            <IconStyled to={`${path.knowledgeTesting}`}
                        activefill={location.pathname === path.knowledgeTesting ? '#E3C565' : '#C9D6DF'}>
                <Test/>
            </IconStyled>
        </MenuBlockStyled>
    )
}