import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
export const BlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
`
export const MainBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
export const PanelChoseTests = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 10%;
  padding: 0 1vw;
`
export const ListTest = styled.div`
  display: flex;
  flex-direction: row;
`
export const SortBlock = styled.div`
  cursor: pointer;
  margin-right: 10px;
  position: relative;

  & svg {
    fill: rgba(219, 232, 241, 1);
    width: 20px;
    height: 20px;
  }
`
export const AdditionalTestsBlock = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 3px;
    border-radius: 3px;
    left: 31vw;
    top: 14vh;
    background-color: rgba(14, 15, 18, 1);
    @media (min-width: 1920px) {
        left: 25.8vw;
        top: 14vh;
    }
    z-index: 3;
`
export const DetailNameTest = styled.div`
display: none;
    background-color: rgba(14, 15, 18, 1);
    //position: absolute;
    top: 85%;
`
export const NameBlockTest = styled.div<{active?:boolean,access?: string,hover:boolean}>`
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px;
    margin-left: 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: ${props => props.active ? 'rgba(227, 197, 101, 1)' : 'rgba(14, 15, 18, 1)'};
    box-shadow: inset 0 10px 10px -10px rgba(255, 255, 255, 0.15);
    font-size: 12px;
    color: ${props => props.active ? 'rgba(14, 15, 18, 1)' : 'rgba(219, 232, 241, 1)'};
    font-family: Verdana, sans-serif;
    cursor: pointer;
    max-width: 6vw; /* Задайте максимальний розмір блоку */
    overflow: hidden; /* Ховати вміст, що перевищує розмір блоку */
    text-overflow: ellipsis; /* Додає мітку (...) для відображення, що текст обрізаний */
    white-space: nowrap; /* Заборонити перенесення тексту на новий рядок */

    &:hover {
        background-color: ${props => props.hover ? 'rgba(227, 197, 101, 1)' : 'rgba(14, 15, 18, 1)'};
        color: ${props => props.hover ? 'rgba(14, 15, 18, 1)' : 'rgba(219, 232, 241, 1)'};
    }
    &:active {
        background-color: ${props => props.hover ? 'rgba(227, 197, 101, 0.5)' : 'rgba(14, 15, 18, 1)'};
    }
`
export const NameBlockMoreTest = styled.div<{active?:boolean,access?: string}>`
  display: flex;
  align-items: center;
  position: relative;
  height: 3vh;
  padding: 0 5px;
    //border-top-left-radius: 5px;
    //border-top-right-radius: 5px;
  background-color: ${props=> props.active ? 'rgba(227, 197, 101, 1)' : 'rgba(14, 15, 18, 1)'};
  box-shadow: inset 0 10px 10px -10px rgba(255, 255, 255, 0.15);
  font-size: 12px;
  color: ${props=> props.active ? 'rgba(14, 15, 18, 1)' : 'rgba(219, 232, 241, 1)'};
  font-family: Verdana, sans-serif;
  cursor: pointer;
  max-width: 4vw; /* Задайте максимальний розмір блоку */
  overflow: hidden; /* Ховати вміст, що перевищує розмір блоку */
  text-overflow: ellipsis; /* Додає мітку (...) для відображення, що текст обрізаний */
  white-space: nowrap; /* Заборонити перенесення тексту на новий рядок */
  &:hover {
    background-color: rgba(227, 197, 101, 1);
    color: rgba(14, 15, 18, 1);
  }
  &:active {
    background-color: rgba(227, 197, 101, 1);
  }
`
export const NameMoreBlockTest = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const SvgNearNameTest = styled.div<{access?: string}>`
  display: flex;
  align-items: center;
  & svg {
    stroke: ${props=>props.access};
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
`
export const ContainerTests = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`
export const QuestionsBlock = styled.div<{bordertop: string}>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 47%;
    height: 83vh;
    background-color: rgba(30, 31, 35, 1);
    border-radius: 15px;
    border-top-left-radius: ${props => props.bordertop};
    color: rgba(219, 232, 241, 1);
    font-family: Verdana, sans-serif;
    padding: 0 5px;
`
export const ContainerQuestions = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    overflow-y: scroll;
    max-height: 65vh;
`
export const ColumnQuestion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  padding: 0 2vw;

`
export const DescriptionBlock = styled.div`
    display: none;
    position: absolute;
    max-width: 18vw;
    max-height: 15vh;
    padding: 5px;
    background-color: rgba(17, 18, 22, 1);
    border-radius: 5px;
    border: 1px solid rgba(82, 97, 107, 0.5);
    overflow: scroll;
    word-wrap: break-word;
    font-size: 12px;
    margin-left: 10px;
    
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
export const HeaderStyled = styled.div<{marginLeft?:string}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 10%;
    font-size: 22px;
    padding: 0 2%;

    & div {
        cursor: pointer;

        & svg {
            margin-left: ${props => props.marginLeft};
            width: 20px;
            height: 20px;
            stroke: #52616B
        }

        &:hover > ${DescriptionBlock} {
            display: block;
        }
    }

    @media (min-width: 1920px) {
        padding: 0 3%;
    }
`

export const ContainerBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    padding: 1vw 1.3vw;
    bottom: 1%;
    right: 1%;
`
export const BtnSubmit = styled.div<{submit?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.submit ? 'rgba(227, 197, 101, 1)' : 'rgba(82, 97, 107, 0.5)'};
  padding: 0.5vw 1vw;
  color: ${props => props.submit ? 'rgba(227, 197, 101, 1)' : 'rgba(82, 97, 107, 0.5)'};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.submit ? 'rgba(227, 197, 101, 0.2)' : ''};
  }
`
