import {IAuth} from "./interface";
import {createSlice, isAnyOf} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import {RootState} from "./store";
import Cookies from "universal-cookie";
import {injectedAuthApi} from "./api/authApi";
import {toast} from "react-toastify";
import {ELogin} from "../pages/authorization/constants";
import {injectedFirebaseApi} from "./api/fireBaseApi";

const cookies = new Cookies()

const initialState: IAuth = {
    name: '',
    isAdmin: false,
    role: '',
    employeeId: '',
    isActive: false,
    stage: '',
    chatId: null,
    token: null,
    favorites: [],
    message: '',
    isSuccess: false,
    isLoading: false,
    isError: false,
}
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(
                    injectedAuthApi.endpoints.login.matchPending,
                    injectedAuthApi.endpoints.logout.matchPending
                ),
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                injectedAuthApi.endpoints.login.matchFulfilled,
                (state, action) => {
                    state.name = action.payload.user.name;
                    state.isAdmin = action.payload.user.isAdmin;
                    state.role = action.payload.user.role;
                    state.employeeId = action.payload.user.employeeId;
                    state.chatId = action.payload.user.chatId;
                    state.isActive = action.payload.user.isActive;
                    state.stage = action.payload.user.stage;
                    state.token = action.payload.user.token;
                    state.favorites = action.payload.user.favorites;
                    state.message = action.payload.message;
                    state.isError = false;
                    state.isLoading = false;
                    state.isSuccess = true;
                    cookies.set('FBTOKEN', action.payload.fBData.user.stsTokenManager.accessToken);
                    // cookies.set('EMSTOKEN', action.payload.cookie);
                }
            )
            .addMatcher(
                injectedAuthApi.endpoints.login.matchRejected,
                (state) => {
                    state.isError = true;
                    state.isLoading = false;
                    toast.error(`${ELogin.ERROR}`)
                }
            )
            .addMatcher(
                injectedFirebaseApi.endpoints.uploadAvatar.matchRejected,
                () => {
                    toast.error(`Помилка завантаження фото`)
                }
            )
            .addMatcher(
                injectedFirebaseApi.endpoints.uploadAvatar.matchFulfilled,
                () => {
                    toast.success(`Фото успішно оновлено`)
                }
            )
            .addMatcher(
                injectedFirebaseApi.endpoints.deleteAvatar.matchRejected,
                () => {
                    toast.error(`Помилка видалення фото`)
                }
            )
            .addMatcher(
                injectedFirebaseApi.endpoints.deleteAvatar.matchFulfilled,
                () => {
                    toast.success(`Фото успішно видалено`)
                }
            )
            .addMatcher(
                injectedAuthApi.endpoints.getUser.matchFulfilled,
                (state, action) => {
                    state.name = action.payload.name;
                    state.isAdmin = action.payload.isAdmin;
                    state.role = action.payload.role;
                    state.employeeId = action.payload.employeeId;
                    state.chatId = action.payload.chatId;
                    state.isActive = action.payload.isActive;
                    state.stage = action.payload.stage;
                    state.token = action.payload.token;
                    state.favorites = action.payload.favorites;
                    state.message = action.payload.message || '';
                    state.isError = false;
                    state.isLoading = false;
                    state.isSuccess = true;
                }
            )
            .addMatcher(
            injectedAuthApi.endpoints.logout.matchFulfilled,
            (state) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = false;
                state.name = '';
                state.isAdmin = false;
                state.role = '';
                state.employeeId = '';
                state.chatId = null;
                state.isActive = false;
                state.stage = '';
                state.token = null;
                state.favorites = [];
                state.message = '';
                cookies.remove('FBTOKEN');
            }
        )
            .addMatcher(
                injectedAuthApi.endpoints.logout.matchRejected,
                (state) => {
                    state.isError = true;
                    state.isLoading = false;
                    toast.error('Помилка при виході');
                }
            );

    }
})

export const authReducer = authSlice.reducer;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;