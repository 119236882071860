import {
    AnswersBlock,
    BlockRangeInputStyled,
    CheckBoxStyled,
    Container,
    RadioStyled,
    RangeStyled,
    StyledLabel,
    TextInputStyled
} from "./QuestionAndAnswer.styled";
import {useState} from "react";
import React from "react";
import {IQuestion} from "./createNewTest/interface";
import {ETypeQuestions} from "./constants";

interface Props {
    question: IQuestion
    indexQuestion: number
    setAnswersFromUser: React.Dispatch<React.SetStateAction<IQuestion[]>>
}

export const QuestionAndAnswer: React.FC<Props> = ({
                                                       question,
                                                       setAnswersFromUser,
                                                       indexQuestion,
                                                   }) => {
    const [newItem, setNewItem] = useState<string>('');
    const [data, setData] = useState<number>(0)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setNewItem(e.target.value);
    }
    const addNewItem = (index: number): void => {
        setAnswersFromUser(prev => {
            const newArr = [...prev]
            newArr[index] = {
                ...newArr[index],
                category: question?.category,
                type: question?.type,
                answers: newItem,
            };
            return newArr
        })
    }
    const handleBlur = (index: number): void => {
        addNewItem(index);
    };

    const handleRadioChange = (index: number, value: number, answers: string): void => {
        setAnswersFromUser(prev => {
            const newArr = [...prev]
            newArr[index] = {
                ...newArr[index],
                category: question?.category,
                type: question?.type,
                answers: answers,
                value: value
            };
            return newArr
        })
    }

    const handleRangeChange = (selectedValue: number, index: number): void => {
        setData(selectedValue)
        setAnswersFromUser(prev => {
            const newArr = [...prev]
            if (Array.isArray(question?.answers) && Array.isArray(question?.value)) {
                newArr[index] = {
                    ...newArr[index],
                    category: question?.category,
                    type: question?.type,
                    answers: question?.answers[selectedValue],
                    value: question?.value[selectedValue]
                };
            }
            return newArr
        })
    }
    // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, selectedValue: string): void => {
    //     let foundObject = answersFromUser.find((item) => item.question === questionNumber + ' ' + question)
    //     if (foundObject?.answer) {
    //         if (event.target.checked) {
    //             foundObject.answer = [...foundObject.answer, event.target.value]
    //             setAnswersFromUser((prev: AnswersState[]) => {
    //                 const newArr = prev.filter((item) => item.question !== questionNumber + ' ' + question);
    //                 newArr.push({
    //                     question: questionNumber + ' ' + question,
    //                     answer: foundObject?.answer
    //                 } as AnswersState)
    //                 return newArr
    //             })
    //         } else {
    //             foundObject.answer.splice(foundObject.answer.indexOf(event.target.value), 1);
    //             setAnswersFromUser((prev: AnswersState[]) => {
    //                 const newArr = prev.filter((item) => item.question !== questionNumber + ' ' + question);
    //                 if (foundObject?.answer !== undefined && foundObject?.answer?.length > 0) {
    //                     newArr.push({
    //                         question: questionNumber + ' ' + question,
    //                         answer: foundObject?.answer
    //                     } as AnswersState)
    //                     return newArr
    //                 }
    //                 return newArr
    //
    //             })
    //         }
    //     } else {
    //         setAnswersFromUser((prev: AnswersState[]) => [...prev, {
    //             question: questionNumber + ' ' + question,
    //             answer: [selectedValue]
    //         }]);
    //     }
    // };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, selectedValue: string, value: number, index: number) => {
        setAnswersFromUser((prevAnswers) => {
            const newAnswers = [...prevAnswers];
            if (newAnswers[index]) {
                if (event.target.checked) {
                    newAnswers[index] = {
                        ...newAnswers[index],
                        category: question?.category,
                        type: question?.type,
                        answers: Array.isArray(newAnswers[index].answers) ? [...(newAnswers[index].answers as string[]), selectedValue] : [selectedValue],
                        value: Array.isArray(newAnswers[index].value) ? [...(newAnswers[index].value as number[]), value] : [value]
                    };
                } else {
                    newAnswers[index] = {
                        ...newAnswers[index],
                        category: question?.category,
                        type: question?.type,
                        answers: [...(newAnswers[index].answers as string[]).filter(elem => elem != selectedValue)],
                        value: [...(newAnswers[index].value as number[]).filter(elem => elem != value)]
                    };
                    if ((newAnswers[index].answers as string[]).length === 0 && (newAnswers[index].value as number[]).length === 0) {
                        // @ts-ignore
                        (newAnswers[index]) = ''
                    }
                }
            } else {
                newAnswers[index] = {
                    ...newAnswers[index],
                    category: question?.category,
                    type: question?.type,
                    answers: [selectedValue],
                    value: [value]
                };
            }
            return newAnswers
            // if (Array.isArray(newAnswers[index])) {
            //     if (event.target.checked) {
            //         // newAnswers[index] = [...newAnswers[index], String(indexAnswer) + selectedValue];
            //     } else {
            //         // newAnswers[index] = (newAnswers[index] as string[]).filter(item => item != String(indexAnswer) + selectedValue)
            //     }
            // } else {
            //     // newAnswers[index] = [String(indexAnswer) + selectedValue];
            // }
            // return newAnswers;
        });
    }
    return (
        <Container>
            <span>{indexQuestion + 1 + '. ' + question.question}</span>
            {question.type === ETypeQuestions.TEXT &&
                <TextInputStyled type={question.type} value={newItem} onChange={handleChange}
                                 onBlur={() => handleBlur(indexQuestion)}
                                 placeholder={'Введи відповідь'}/>}
            {question.type === ETypeQuestions.RANGE && question.answers &&
                <BlockRangeInputStyled><RangeStyled id={'One'} type={question.type}
                                                    max={question?.answers?.length - 1}
                                                    min={0}
                                                    value={data}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRangeChange(e.target.valueAsNumber, indexQuestion)}
                />
                    <div>
                        <div>{question?.answers[0]}</div>
                        <div><label htmlFor={'One'}>{question?.answers[data]}</label></div>
                        <div>{question?.answers[question?.answers.length - 1]}</div>
                    </div>
                </BlockRangeInputStyled>}
            {question.type === ETypeQuestions.CHECKBOX &&
                <AnswersBlock>
                    {Array.isArray(question.answers) && question.answers?.map((item: string, index: number) => {
                        return <StyledLabel key={index}>
                            <CheckBoxStyled
                                type={question.type} value={item}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (Array.isArray(question.value)) {
                                        handleCheckboxChange(e, item, question.value[index], indexQuestion)
                                    }
                                }}
                            />
                            {item}
                        </StyledLabel>
                    })}
                </AnswersBlock>}
            {question.type === ETypeQuestions.RADIO &&
                <AnswersBlock>
                    {Array.isArray(question.answers) && question.answers?.map((item: string, indexAnswers: number) => {
                        return <StyledLabel key={indexAnswers}>
                            <RadioStyled type={question.type} name={question.question} onChange={() => {
                                if (Array.isArray(question.value)) {
                                    handleRadioChange(indexQuestion, question.value[indexAnswers], item);
                                }
                            }}/>
                            {item}
                        </StyledLabel>
                    })}
                </AnswersBlock>
            }
        </Container>
    )
}