import {
    Container,
    ContentBlockStyled, ContentTestStyled,
    MainUnitStyled,
} from "./EmployeeCard.styled";
import {ProfileInformation} from "./ProfileInformation/ProfileInformation";
import React, {useEffect, useState} from "react";
import {Skills} from "./HardSkills/Skills";
import { IProfileInformation} from "./intefrace";
import {PaginationArrow, PaginationItem, PaginationList, PaginationNav} from "./pagination/Pagination.styled";
import {EEmployeeCardTestName, EmployeeCardSkills} from "./constants";
import {path} from "../../../utils/path";
import {useNavigate} from "react-router-dom";
import {useGetUserQuery} from "../../store/api/authApi";
import {useGetUsersQuery} from "../../store/api/cardsApi";

export const EmployeeCard: React.FC = () => {
    // const {data:qq} = useAddCollectionsQuery('')
    const {data} = useGetUsersQuery('')
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const recordsPage: number = 1
    const lastIndex: number = currentPage * recordsPage
    const firstIndex: number = lastIndex - recordsPage
    const records = data && data.employee.slice(firstIndex, lastIndex)
    const nPage = data && Math.ceil(data.employee.length / recordsPage)
    const numbers = data && [...Array(nPage + 1).keys()].slice(1)
    const {isError} = useGetUserQuery({isAdmin: 'admin'});
    useEffect(() => {
        if (isError) {
            navigate(`${path.login}`)
        }
    }, [isError, navigate]);


    function prePage():void {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function nextPage():void {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1)
        } else {
            setCurrentPage(1)
        }
    }

    function changePage(id: number):void {
        setCurrentPage(id)
    }
    return (
        <Container>
            <MainUnitStyled>
                <ContentBlockStyled>
                    {data && records.map((item: IProfileInformation) => (
                        <>
                            <>
                                <ProfileInformation data={item}/>
                            </>
                            <ContentTestStyled>
                                <Skills skills={EmployeeCardSkills.HARD} infoEmployee={item} name={EEmployeeCardTestName.HARD}/>
                                <Skills skills={EmployeeCardSkills.SOFT} infoEmployee={item} name={EEmployeeCardTestName.SOFT}/>
                            </ContentTestStyled>
                        </>
                    ))}
                </ContentBlockStyled>
                <div>
                    <PaginationNav marginTop={'10px'}>
                        <PaginationList>
                            {records && <PaginationArrow>
                                <a href='#' onClick={prePage}>❮</a>
                            </PaginationArrow>}
                            {numbers && numbers.map((n: number, i: number) => (
                                <PaginationItem active={currentPage === n} key={i}>
                                    <a href='#' className='page-link' onClick={() => changePage(n)}>{n}</a>
                                </PaginationItem>
                            ))}
                            {records && <PaginationArrow>
                                <a href='#' onClick={nextPage}>❯</a>
                            </PaginationArrow>}
                        </PaginationList>
                    </PaginationNav>
                </div>
            </MainUnitStyled>
        </Container>)
}