import {
    BlockInfo, BlockNameDay, BlockPagination, BtnDay,
    Container,
    DatePickerWrapper,
    FilterBlock,
    MainBlockStyled,
    TableBlock
} from "./Journal.styled";
import {Table} from "./Table";
import {Btn} from "./Btn";
import {Selector} from "./Selector";
import {HeaderForTable} from "./HeaderForTable";
import {ReactComponent as Glass} from "../../assets/pictures/Glass.svg";
import {useEffect, useRef, useState} from "react";
import {path} from "../../../utils/path";
import {useNavigate} from "react-router-dom";
import {optionsEmotionLevel, optionsEvent} from "./optionsTg";
import useOnScreen from "../../hook/useOnScreen";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useGetUserQuery} from "../../store/api/authApi";
import {useGetEventsFromUserQuery, useLazyGetEventsFromUserQuery} from "../../store/api/journalApi";


export const Journal = () => {
    const navigate = useNavigate()
    const {data: user} = useGetUserQuery('');
    const [fetch] = useLazyGetEventsFromUserQuery()
    const [data, setData] = useState<any[]>([])
    const [selectedRow, setSelectedRow] = useState<string | number>()
    const [description, setDescription] = useState<string[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<{
        value: string;
        name: string;
        field: string;
    }[]>([]);
    const [anchorEvent, setAnchorEvent] = useState<string[]>([])
    const [openBlock, setOpenBlock] = useState({event: false, thought: false, result: false})
    const [page, setPage] = useState<number>(0)
    const [startCurrentDate, setStartCurrentDate] = useState(() => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 7);
        return yesterday;
    });
    const [endCurrentDate, setEndCurrentDate] = useState(() => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate());
        return yesterday;
    });
    const {isError} = useGetUserQuery({isAdmin: 'admin'});

    const handleAddDay = () => {
        const newStartDate = new Date(startCurrentDate);
        const newEndDate = new Date(endCurrentDate);
        newStartDate.setDate(startCurrentDate.getDate() + 7);
        newEndDate.setDate(endCurrentDate.getDate() + 7);
        setStartCurrentDate(newStartDate);
        setEndCurrentDate(newEndDate);
        setOpenBlock({event: false, thought: false, result: false})
        setAnchorEvent([])
        setDescription([])
        setSelectedRow('')
    };

    const handleSubtractDay = () => {
        const newStartDate = new Date(startCurrentDate);
        const newEndDate = new Date(endCurrentDate);
        newStartDate.setDate(startCurrentDate.getDate() - 7);
        newEndDate.setDate(endCurrentDate.getDate() - 7);
        setStartCurrentDate(newStartDate);
        setEndCurrentDate(newEndDate);
        setOpenBlock({event: false, thought: false, result: false})
        setAnchorEvent([])
        setDescription([])
    };
    const handleToday = () => {
        setStartCurrentDate(() => {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 7);
            return yesterday;
        })
        setEndCurrentDate(() => {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate());
            return yesterday;
        })
        setOpenBlock({event: false, thought: false, result: false})
        setAnchorEvent([])
        setDescription([])
    };
    const onChangeDay = (dates: Date) => {
        // @ts-ignore
        const [start, end] = dates;
        setStartCurrentDate(start);
        setEndCurrentDate(end);
        setOpenBlock({event: false, thought: false, result: false})
        setAnchorEvent([])
    };

    const formatDate = (date: Date) => {
        const today = new Date();

        if (date?.toDateString() === today.toDateString()) {
            return 'Поточний тиждень';
        }
    };
    const {data: queryData} = useGetEventsFromUserQuery(
        {
            filterArray: selectedFilters,
            page: page,
            startDay: startCurrentDate.toISOString(),
            endDay: endCurrentDate?.toISOString()
        },
        {
            pollingInterval: 60_000,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
        }
    )
    const userOnScreenRefLast = useRef(null)
    const isVisibleLast = useOnScreen(userOnScreenRefLast)
    if (isError) {
        navigate(`${path.login}`)
    }

    useEffect(() => {
        if (queryData) {
            setData(queryData.answers)
        }
    }, [queryData]);
    useEffect(() => {
        if (isVisibleLast) {
            setPage(prev => prev + 2)
        }
    }, [isVisibleLast]);

    function UsersForSelector(field: string) {
        const result: { value: string, name: string, field: string }[] = []
        queryData && queryData?.users.map((elem: { chatId: string, name: string }) => {
            result.push({value: elem.chatId, name: elem.name, field: field})
        })
        return result
    }

    //хз чого але не видаляти, без цього не працює
    // const [groupedByDay, setGroupByDay] = useState<{ date: string, events: unknown }[]>([])
    // useEffect(() => {
    //     if (data) {
    //         setGroupByDay(
    //             Object.entries(
    //                 data?.reduce((acc: any, obj: any) => {
    //                     const date = new Date(obj.eventStartTime);
    //                     const today = new Date();
    //                     const yesterday = new Date(today);
    //                     yesterday.setDate(today.getDate() - 1);
    //
    //                     const formattedDate =
    //                         date.toDateString() === today.toDateString()
    //                             ? 'Сьогодні'
    //                             : date.toDateString() === yesterday.toDateString()
    //                                 ? 'Вчора'
    //                                 : `${date.getDate()}.${date.getMonth() + 1}`;
    //
    //                     acc[formattedDate] = acc[formattedDate] || [];
    //                     acc[formattedDate].push(obj);
    //                     return acc;
    //                 }, {})
    //             ).map(([date, events]) => ({
    //                 date,
    //                 events
    //             }))
    //         )
    //     }
    //     // console.log(groupedByDay.length)
    //
    // }, [data])
    // const groupedByDay = data &&
    // const groupData = groupedByDay &&

    const ReadEvents = (employeeId?: string) => {
        fetch({
            adminId: user?._id,
            id: employeeId,
            filterArray: selectedFilters,
            page: page,
            makeAsReadingEvent: true,
            startDay: startCurrentDate.toISOString(),
            endDay: endCurrentDate?.toISOString()
        }).then(r => {
            setData(r.data?.answers)
        })
    }

    return (
        <Container>
            <BlockInfo>
                <MainBlockStyled>
                    <BlockNameDay>{formatDate(endCurrentDate)}</BlockNameDay>
                    <BlockPagination>
                        <Btn onclick={ReadEvents}>
                            <Glass/>
                        </Btn>
                        <BtnDay onClick={handleSubtractDay}>{'<'}</BtnDay>
                        <div>
                            <DatePickerWrapper>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startCurrentDate}
                                    // @ts-ignore
                                    onChange={onChangeDay}
                                    startDate={startCurrentDate}
                                    endDate={endCurrentDate}
                                    selectsRange
                                />
                            </DatePickerWrapper>
                        </div>
                        <BtnDay onClick={handleAddDay}>{'>'}</BtnDay>
                        <BtnDay onClick={handleToday}>{'>>'}</BtnDay>
                    </BlockPagination>
                    <FilterBlock>
                        <Selector
                            padding={'0 5px'}
                            margin={'2px 0'}
                            size={'13.3vw'}
                            optionsCategory={UsersForSelector('chatId')}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            header={'Користувачі'}
                        />
                        <Selector
                            padding={'0 5px'}
                            margin={'2px 0'}
                            size={'14.7vw'}
                            optionsCategory={optionsEvent} selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            header={'Події'}
                        />
                        <HeaderForTable size={'12.55vw'} children={'Початок'}/>
                        <HeaderForTable size={'12.55vw'} children={"Кінець"}/>
                        <HeaderForTable size={'26vw'} children={"Локація"}/>
                        <Selector
                            padding={'0 5px'}
                            margin={'2px 0'}
                            size={'13vw'}
                            optionsCategory={optionsEmotionLevel}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            borderRight={'none'}
                            header={'Емоційний стан'}
                        />
                    </FilterBlock>
                    <TableBlock>
                        {data?.map((day: any, index: number, arr: any[]) => (
                            <div  {...(index === arr.length - 1 ? {ref: userOnScreenRefLast} : {})}
                            >
                                <Table
                                    favourite={false}
                                    description={description}
                                    setDescription={setDescription}
                                    adminsEmployeeId={user}
                                    selectedRow={selectedRow}
                                    setSelectedRow={setSelectedRow}
                                    readEvent={ReadEvents}
                                    key={arr[index].day}
                                    header={day.date}
                                    event={day}
                                    anchorEvent={anchorEvent}
                                    setAnchorEvent={setAnchorEvent}
                                    openBlock={openBlock}
                                    setOpenBlock={setOpenBlock}
                                />
                            </div>
                        ))}
                    </TableBlock>
                </MainBlockStyled>
            </BlockInfo>
        </Container>
    )
}