import {Container} from "./HeaderForTable.styled";
import React from "react";
type Props = {
    children: React.ReactNode
    size: string
}

export const HeaderForTable = ({children,size}:Props) => {
return(<Container size={size}>
    {children}
</Container>)
}