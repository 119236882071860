import {
    BlockButtonSettings,
    BlockPlanTable,
    ButtonClose,
    DescriptionEvent,
    Detail,
    DetailsBlock,
    MapContainer
} from "./Journal.styled";
import {Planning} from "./Plan";
import {MarkersMap} from "../homeAdmin/map/Maps";
import React from "react";
import {ReactComponent as Close} from "../../assets/pictures/ArrowCloseEvent.svg";
import {ReactComponent as Heart} from "../../assets/pictures/Heart.svg";
import {ReactComponent as Dumpster} from "../../assets/pictures/Dumpster.svg";
import {ReactComponent as AnchorEvent} from "../../assets/pictures/AnchorEvent.svg";

type Props = {
    event: any
    setOpenBlock: React.Dispatch<React.SetStateAction<{ event: boolean, thought: boolean, result: boolean }>>
    openBlock: any
    onclick: any
    addFavourite: any
    anchorOpenDescription: any
    favourite: boolean
    animate: boolean
}
export const DetailsForEvent = ({
                                    event,
                                    setOpenBlock,
                                    openBlock,
                                    onclick,
                                    addFavourite,
                                    anchorOpenDescription,
                                    favourite
                                }: Props) => {
    return (
        <DetailsBlock>
            <BlockButtonSettings>
                <ButtonClose favourite={favourite} onClick={() => onclick(event._id)}>
                    <Close/>
                </ButtonClose>
                <ButtonClose favourite={favourite} onClick={() => addFavourite(event._id)}>
                    {favourite ? <Dumpster/> : <Heart/>}
                </ButtonClose>
                <ButtonClose favourite={favourite} onClick={() => anchorOpenDescription(event._id)}>
                    <AnchorEvent/>
                </ButtonClose>
            </BlockButtonSettings>
            <DescriptionEvent>
                {event.category === 'План 📋' ?
                    <BlockPlanTable>
                        <>
                            <Planning event={event}/>
                        </>
                    </BlockPlanTable> :
                    <>
                        {!(event.doing === '-') && !openBlock.result && !openBlock.thought ? <><h1>Опис події</h1>
                            <Detail marginBottom={'0'} bottom={!openBlock.event}
                                    onClick={() => setOpenBlock(prevState => ({
                                        ...prevState,
                                        event: !prevState.event
                                    }))} pos={openBlock.event}>
                                <span>
                                    {event.doing.split(/[.!?]+/).map((sentence: string, index: number, arr: string[]) => (
                                        <React.Fragment key={index}>
                                            <span>{sentence.trim()}</span>
                                            {index < arr.length - 1 && (
                                                <>
                                                    . <br/>
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    </span>
                            </Detail></> : false}
                        {event.thoughts.length > 0 && !openBlock.result && !openBlock.event ? <><h1>Думки</h1>
                            <Detail marginBottom={'0'}
                                    bottom={(event.doing === '-') && (event.result === '-') ? false : !openBlock.thought && !openBlock.thought}
                                    onClick={() => setOpenBlock(prevState => ({
                                        ...prevState,
                                        thought: !prevState.thought
                                    }))}
                                    pos={openBlock.thought}>
                                <span>
  {event.thoughts.map((thought: string, index: number, arr: any) => (
      <React.Fragment key={index}>
          {arr.length > 1 ? (
              <>
                  <strong>{index + 1}.{" "}</strong>
                  {thought.split(/[.!?]+/).map((sentence, sentenceIndex) => (
                      <React.Fragment key={sentenceIndex}>
              <span>
                {sentence.trim()}
                  {sentenceIndex < thought.split(/[.!?]+/).length - 1 ? ". " : ""}
              </span>
                          {index < thought.length - 1 && <br/>}
                      </React.Fragment>
                  ))}
                  {index < event.thoughts.length - 1 && (
                      <>
                          <br/>
                          <br/>
                      </>
                  )}
              </>
          ) : (
              <>
                  {thought.split(/[.!?]+/).map((sentence: string, sentenceIndex: number) => (
                      <React.Fragment key={sentenceIndex}>
              <span>
                {sentence.trim()}
                  {sentenceIndex < thought.split(/[.!?]+/).length - 1 ? ". " : ""}
              </span>
                          {index < thought.length - 1 && <br/>}
                      </React.Fragment>
                  ))}
                  {index < event.thoughts.length - 1 && (
                      <>
                          <br/>
                          <br/>
                      </>
                  )}
              </>
          )}
      </React.Fragment>
  ))}
</span>

                            </Detail></> : false}
                        {!(event.result === '-') && !openBlock.thought && !openBlock.event ? <>
                            <h1>{event?.event === 'Дорога' ? 'Чи були якісь проблеми?' : 'Підсумки'}</h1>
                            <Detail marginBottom={'15px'} bottom={!openBlock.result}
                                    onClick={() => setOpenBlock(prevState => ({
                                        ...prevState,
                                        result: !prevState.result
                                    }))}
                                    pos={openBlock.result}>
                                <span>
                                    {event.result.split(/[.!?]+/).map((sentence: string, index: number, arr: string[]) => (
                                        <React.Fragment key={index}>
                                            <span>{sentence.trim()}</span>
                                            {index < arr.length - 1 && (
                                                <>
                                                    . <br/>
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    </span>
                            </Detail></> : false}

                    </>}
            </DescriptionEvent>
            {!openBlock.event && !openBlock.result && !openBlock.thought && <MapContainer key={event._id}>
                <MarkersMap key={event._id} id={event._id} coordinates={[{
                    latitude: event.latitude,
                    longitude: event.longitude,
                    userTelegramName: event.name
                }]}/>
            </MapContainer>}
        </DetailsBlock>
    )
}