import styled from "styled-components";

export const TagBlockStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 30%;
  padding: 15px;
  border: 1px solid #52616B;
  border-radius: 25px;
`
export const ShortDescriptionTest = styled.div`
    display: none;
    position: absolute;
    max-width: 18vw;
    max-height: 15vh;
    padding: 5px;
    background-color: rgba(17, 18, 22, 1);
    border-radius: 5px;
    border: 1px solid rgba(82, 97, 107, 0.5);
    overflow: scroll;
    word-wrap: break-word;
    font-size: 12px;
    color: #C9D6DF;
    cursor: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
export const HeaderWithInfoTest = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
padding-top: 15px;
    & div {
        cursor: pointer;

        & svg {
            width: 20px;
            height: 20px;
            stroke: #52616B
        }

        &:hover > ${ShortDescriptionTest} {
            display: block;
        }
    }
    
`
export const BtnResultBlock = styled.div`
    display: flex;
    padding: 5px;
    color: #C9D6DF;
    font-family: Verdana,sans-serif;
    border: 1px solid #52616B;
    border-radius: 5px;
`
export const PaginationBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    color: #C9D6DF;
    & div {
        display: flex;
        flex-direction: row;
    }
`
export const EvaluationBlockStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    color: #C9D6DF;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
export const BlockRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const ProgressBlockStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10%;
  width: 15vw;
  @media (min-width: 1920px) {
    height: 120px;
  }

`
export const ProgressStyled = styled.div<{ width?: string, color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: ${props => props.width};
  height: 25%;
  background-color: ${props => props.color};
  border-radius: 5px;
  & div {
    color: rgba(82, 97, 107, 1);
    margin-top: 3px;
  }
`
export const BlockValueStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15%;
  & div {
    padding: 5px 3px 0 3px;
    background-color: rgba(30, 31, 35, 1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

