import {IQuestions} from "./interface";
import {ReactComponent as Behance} from "../../assets/pictures/socialNetworks/Behance.svg";
import {ReactComponent as Facebook} from "../../assets/pictures/socialNetworks/Facebook.svg";
import {ReactComponent as Instagram} from "../../assets/pictures/socialNetworks/Instagram.svg";
import {ReactComponent as LinkedIn} from "../../assets/pictures/socialNetworks/LinkedIn.svg";
import {ReactComponent as Pinterest} from "../../assets/pictures/socialNetworks/Pinterest.svg";
import {ReactComponent as Reddit} from "../../assets/pictures/socialNetworks/Reddit.svg";
import {ReactComponent as TikTok} from "../../assets/pictures/socialNetworks/TikTok.svg";
import {ReactComponent as Twitter} from "../../assets/pictures/socialNetworks/Twitter.svg";
import {ReactComponent as YouTube} from "../../assets/pictures/socialNetworks/Youtube.svg";
import {ReactComponent as Telegram} from "../../assets/pictures/messenger/Telegram.svg";
import {ReactComponent as Viber} from "../../assets/pictures/messenger/Viber.svg";
import {ReactComponent as Wechat} from "../../assets/pictures/messenger/Wechat.svg";
import {ReactComponent as WhatsApp} from "../../assets/pictures/messenger/WhatsApp.svg";


export const enum ENameBlock {
    generalInfo = 'Загальна інформація',
    education = 'Освіта',
    additionalEducation = 'Додаткова освіта',
    foreignLanguage = 'Іноземні мови',
    previousWorkExperience = 'Попередній досвід роботи',
    additionalQuestions = 'Додаткові запитання',
    contactDetail = 'Особисті контактні дані',
    contactPersonInCaseEmergency = 'Контактна особа на випадок екстреної ситуації'
}

export const enum ETypesInput {
    TEXT ='text',
    DATE='Date',
    SOCIAL='social',
    MESSENGER='messenger',
    PHONE='phone'
}
export const generalFields=['answer']
export const educationFields=['educationLevel','direction','name','yearsEducation']
export const additionalEducationFields=['name','direction','yearsEducation']
export const foreignLanguageFields=['name','levelProficiency']
export const previosWorkExperienceFields=['nameCompany','position','listResponsibilities','workingPeriod']
export const contactDetailsFields=['name','phoneNumber','email','socialNetworks','messengers']
export const personEmergencyFields=['name','personEmergency','phoneNumber']
export const socialFields=['name','link']

export const enum EFieldsBD {
    FAMILYNAME = 'familyName',
    FIRSTNAME = 'firstName',
    PATERNALNAME = 'paternalName',
    BIRTHDATE = 'birthDate',
    SEX = 'sex',
    CITIZENSHIP = 'citizenship',
    MARITALSTATUS = 'maritalStatus',
    CHILDREN = 'children',
    COURSES = 'courses',
    FOREIGNLANGUAGE = 'foreignLanguage',
    WORKEXPERIENCE = 'workExperience',
    DRIVERLICENSE = 'driverLicense',
    HOBBY = 'hobby',
    REASONDISMISSALFROMPREVIOUSJOB = 'reasonDismissalFromPreviousJob',
    WHATDOIFNOTADDICTEDMONEY = 'whatDoIfNotAddictedMoney',
    WHATANNOYEDINPREVIOUSMANAGEMENT = 'whatAnnoyedInPreviousManagement',
    PROJECTINNEXT10YEARS = 'projectInNext10Years',
    PIKEORROPE = 'pikeOrRope',
    PHONE = 'phone',
    EMAIL = 'email',
    PERSONEMERGENCY = 'personEmergency'
}

export const generalInformation:IQuestions[] = [
    {
        field: 'familyName',
        question: 'Прізвище*',
        type: 'text',
        userAbilityWriteMsg: true,
        require:true
    },
    {
        field: 'firstName',
        question: 'Імʼя*',
        type: 'text',
        userAbilityWriteMsg: true,
        require:true
    },
    {
        field: 'paternalName',
        question: 'По батькові',
        type: 'text',
        userAbilityWriteMsg: true,
        require:false
    },
    {
        field: 'birthDate',
        question: 'Дата народження*',
        type: 'Date',
        selectsRange:false,
        require:true
    },
    {
        field: 'sex',
        question: 'Стать*',
        type: 'text',
        values: ['чоловіча', 'жіноча','інше'],
        userAbilityWriteMsg: false,
        require:true
    },
    {
        field: 'citizenship',
        question: 'Громадянство*',
        type: 'text',
        values: ["австралієць",
            "австрієць",
            "азербайджанець",
            "албанець",
            "алжирець",
            "анголець",
            "андоррець",
            "антигуанець",
            "аргентинець",
            "вірменин",
            "афганець",
            "багамець",
            "бангладешієць",
            "барбадосець",
            "бахрейнець",
            "білорус",
            "белізець",
            "бельгієць",
            "бенінець",
            "болгарин",
            "болівієць",
            "боснієць",
            "ботсванець",
            "бразилець",
            "брунейці",
            "буркіне-фасо",
            "бурундець",
            "бутанець",
            "вануатуєць",
            "британець",
            "угорець",
            "венесуелець",
            "тіморці",
            "в'єтнамець",
            "габонець",
            "гаїтян",
            "гаянець",
            "гамбієць",
            "ганець",
            "гватемальець",
            "гвінеєць",
            "гвінеєць-бісаєць",
            "німець",
            "гондурасець",
            "гренадин",
            "грек",
            "грузин",
            "данець",
            "джибутійці",
            "домініканець",
            "еквадорець",
            "екваторіанець",
            "еритреєць",
            "естонець",
            "свазіландець",
            "ефіоп",
            "єгиптян",
            "єменець",
            "замбієць",
            "зімбабвець",
            "ізраїльтян",
            "індієць",
            "індонезієць",
            "йорданець",
            "іракійці",
            "іранець",
            "ірландець",
            "ісландець",
            "іспанець",
            "італієць",
            "казахстанець",
            "камбоджець",
            "камерунець",
            "канадець",
            "катарець",
            "кенієць",
            "кіпріот",
            "киргизець",
            "кірібатієць",
            "китаєць",
            "кореєць",
            "колумбієць",
            "коморець",
            "конгоанець",
            "конголезець",
            "південнокореєць",
            "косовар",
            "костариканець",
            "кот-д'івуарець",
            "кубинець",
            "кувейтці",
            "лаосець",
            "латвієць",
            "лесотоць",
            "ліванець",
            "ліберієць",
            "лівієць",
            "литовець",
            "люксембуржець",
            "маврикієць",
            "мавританець",
            "мадагаскарець",
            "македонець",
            "малавієць",
            "малайзієць",
            "малиєць",
            "мальтієць",
            "марокканець",
            "маршаллець",
            "мексиканець",
            "мікронезієць",
            "мозамбікці",
            "молдовець",
            "монакієць",
            "монголець",
            "чорногорець",
            "намібієць",
            "науруанець",
            "непалець",
            "нігерієць",
            "німець",
            "нікарагуанець",
            "новозеландець",
            "норвезець",
            "арабець",
            "оманець",
            "пакистанець",
            "палавійець",
            "панамець",
            "папуасець",
            "парагвайець",
            "перуанець",
            "польський",
            "португальський",
            "південний суданець",
            "придністровський",
            "російський",
            "руандець",
            "румунець",
            "сальвадорець",
            "самоанець",
            "сан-маринець",
            "сан-томець і принсіпієць",
            "саудієць",
            "свазіландець",
            "сейшельський",
            "сенегалець",
            "сент-вінсент і гренадінці",
            "сент-кітс і невісійці",
            "сент-люсіанець",
            "серб",
            "сінгапурець",
            "сирієць",
            "словак",
            "словенець",
            "соломонець",
            "сомалійський",
            "суданець",
            "суринамець",
            "американець",
            "сьєрра-леонець",
            "таджик",
            "тайський",
            "танзанієць",
            "того",
            "тонга",
            "трінідад і тобаго",
            "тувалуанець",
            "тунісець",
            "турець",
            "туркменець",
            "угандієць",
            "узбек",
            "українець",
            "уродіжан",
            "фіджійський",
            "філіппінець",
            "фін",
            "француз",
            "хорват",
            "центральноафриканський",
            "чадець",
            "чех",
            "чилієць",
            "чорногорець",
            "швейцарець",
            "швед",
            "шрі-ланканець",
            "ямайський",
            "японець"
        ],
        userAbilityWriteMsg: true,
        require:true
    },
    {
        field: 'maritalStatus',
        question: 'Сімейний статус*',
        type: 'text',
        values: ['не перебуваю в шлюбі', 'перебуваю в шлюбі', 'перебуваю в цивільному шлюбі', 'розлучений/розлучена'],
        userAbilityWriteMsg: false,
        require:true
    },
    {
        field: 'children',
        question: 'Діти*',
        type: 'text',
        values: ['немає','1 дитина', '2 дитини', '3 і більше дітей'],
        userAbilityWriteMsg: false,
        require:true
    },
]


export const additionalEducation:IQuestions[] = [
    {
        field: 'courses',
        question: 'Назва',
        type: 'text',
        require:false
    },
    {
        field: 'courses',
        question: 'Напрямок',
        type: 'text',
        require:false
    },
    {
        field: 'courses',
        question: 'Рік навчання (рік завершення)',
        type: 'Date',
        require:false,
        formatDate:'yyyy',
        selectsRange:true,
        yearPicker:true,
    },
]

export const foreignLanguage:IQuestions[] = [
    {
        field: 'foreignLanguage',
        question: 'Оберіть мову*',
        type: 'text',
        values: ["Англійська",
            "Мандаринська китайська",
            "Іспанська",
            "Гінді",
            "Арабська",
            "Бенгальська",
            "Португальська",
            "Японська",
            "Пенджабі",
            "Німецька",
            "Яванська",
            "Ву (китайська)",
            "Малайська/Індонезійська",
            "Телугу",
            "В'єтнамська",
            "Корейська",
            "Французька",
            "Маратхі",
            "Тамільська",
            "Урду",
            "Турецька",
            "Італійська",
            "Кантонська китайська (Юе)",
            "Тайська",
            "Гуджараті",
            "Цзінь (китайська)",
            "Південномінська китайська",
            "Перська",
            "Польська",
            "Пушту",
            "Каннада",
            "Сян (китайська)",
            "Малаялам",
            "Сунданська",
            "Хауса",
            "Одія",
            "Бірманська",
            "Хакка (китайська)",
            "Українська",
            "Бходжпурі",
            "Тагальська",
            "Йоруба",
            "Майтхілі",
            "Узбецька",
            "Сіндхі",
            "Амхарська",
            "Фула",
            "Румунська",
            "Оромо",
            "Ігбо",
            "Азербайджанська",
            "Авадхі",
            "Гань (китайська)",
            "Себуанська",
            "Голландська",
            "Курдська",
            "Сербо-хорватська",
            "Малагасійська",
            "Непальська",
            "Сингальська",
            "Чіттагонгська",
            "Чжуанська",
            "Кхмерська",
            "Туркменська",
            "Ассамська",
            "Мадурська",
            "Сомалійська",
            "Марварі",
            "Магахі",
            "Хар'янві",
            "Угорська",
            "Чхаттісгархі",
            "Грецька",
            "Чева",
            "Деканська",
            "Акан",
            "Казахська",
            "Північномінська китайська",
            "Сілеті",
            "Зулуська",
            "Чеська",
            "Кіньяруанда",
            "Дхундарі",
            "Гаїтянська креольська",
            "Східномінська китайська",
            "Ілоканська",
            "Кечуа",
            "Кірунді",
            "Шведська",
            "Хмонгська",
            "Шона",
            "Уйгурська",
            "Гаїтянська креольська французька",
            "Санскрит",
            "Латинь"],
        userAbilityWriteMsg: true,
        require:true
    },
    {
        field: 'foreignLanguage',
        question: 'Рівень володіння*',
        type: 'text',
        values: ['початковий','середній','високий','вільне володіння'],
        userAbilityWriteMsg: false,
        require:true
    },
]

export const previosWorkExperience:IQuestions[] = [
    {
        field: 'workExperience',
        question: 'Назва компанії*',
        type: 'text',
        require:true
    },
    {
        field: 'workExperience',
        question: 'Посада*',
        type: 'text',
        require:true
    },
    {
        field: 'workExperience',
        question: 'Перелік обов’язків*',
        type: 'text',
        require:true
    },
    {
        field: 'workExperience',
        question: 'Період роботи*',
        type: 'Date',
        formatDate:'MM.yyyy',
        selectsRange:true,
        monthPicker:true,
        require:true
    }
]

export const additionalQuestions:IQuestions[] = [
    {
        field: 'driverLicense',
        question: 'Чи маєте ви водійське посвідчення*',
        type: 'text',
        values:['ні','так'],
        userAbilityWriteMsg: false,
        require:true
    },
    {
        field: 'hobby',
        question: 'Перерахуйте свої хобі',
        type: 'textarea',
        require:false
    },
    {
        field: 'reasonDismissalFromPreviousJob',
        question: 'Що стало причиною вашого звільнення з попереднього місця роботи?',
        type: 'textarea',
        require:false
    },
    {
        field: 'whatDoIfNotDependOnMoney',
        question: 'Якби у вас було настільки багато грошей, що незалежно від того яку суму ви витрачали б на день — їх все одно завжди вистачало б на усе, тобто якби вам взагалі не потрібно було перейматися з приводу матеріальної сторони свого життя, чим би ви тоді займалися?*',
        type: 'textarea',
        require:true
    },
    {
        field: 'whatAnnoyedInPreviousManagement',
        question: 'Що вас найбільше дратувало у попередньому керівництві?*',
        type: 'textarea',
        require:false
    },
    {
        field: 'projectInNext10Years',
        question: 'Чи є у вас проект (ідея, задум), який вам би хотілося втілити за наступні 10 років?*',
        type: 'textarea',
        require:true
    },
    {
        field: 'howYouSeeYourselfIn10Years',
        question: 'Яким ви бачите своє життя через 10 років?*',
        type: 'textarea',
        require:true
    },
    // {
    //     field: 'pikeOrRope',
    //     question: 'Щучка чи канатік?*',
    //     type: 'text',
    //     values: ['щучка','канатік'],
    //     userAbilityWriteMsg: false,
    //     require:true
    // },
]

export const contactDetails:IQuestions[] = [
    {
        field: 'phone',
        question: 'Номер телефону',
        type: 'phone',
        require:false
    },
    {
        field: 'email',
        question: 'Електронна пошта',
        type: 'text',
        require:false
    },
]

export const socialNetworksQuestions:IQuestions[] = [
    {
        field: 'messengers',
        question: 'Посилання на месенджери',
        type: 'messenger',
        require:false
    },
    {
        field: 'socialNetworks',
        question: 'Посилання на соц.мережі',
        type: 'social',
        require:false
    },
]

export const contactPersonEmergency:IQuestions[] = [
    {
        field: 'personEmergency',
        question: 'Імʼя',
        type: 'text',
        require:false
    },
    {
        field: 'personEmergency',
        question: 'Хто вона вам',
        type: 'text',
        values: ['батько/мати','чоловік/дружина','опікун','брат/сестра','інші родичі','друг/подруга'],
        userAbilityWriteMsg: false,
        require:false
    },
    {
        field: 'personEmergency',
        question: 'Номер телефону',
        type: 'phone',
        require:false
    },
]

export const socialNetworks = [
    {name:'Behance',svg:Behance},
    {name:'Facebook',svg:Facebook},
    {name:'Instagram',svg:Instagram},
    {name:'LinkedIn',svg:LinkedIn},
    // {name:'Pinterest',svg:Pinterest},
    // {name:'Reddit',svg:Reddit},
    // {name:'TikTok',svg:TikTok},
    {name:'Twitter',svg:Twitter},
    // {name:'YouTube',svg:YouTube},
]
export const messengers = [
    {name:'Telegram',svg:Telegram},
    {name:'Viber',svg:Viber},
    {name:'Wechat',svg:Wechat},
    {name:'WhatsApp',svg:WhatsApp}
]


// export const Questions: IQuestions[] = [
//     {
//         field: 'name',
//         name: 'Імʼя',
//         type: 'text',
//         values: '',
//         answer: ''
//     },
//     {
//         field: 'fullName',
//         name: 'Прізвище',
//         type: 'text',
//         values: '',
//         answer: ''
//     },
//     {
//         field: 'married',
//         name: 'Сімейний стан',
//         type: 'radio',
//         values: ['заміжний', 'не заміжний'],
//         answer: ''
//     },
//     {
//         field: 'sex',
//         name: 'Стать',
//         type: 'radio',
//         values: ['чоловіча', 'жіноча'],
//         answer: ''
//     },
//     {
//         field: 'educated',
//         name: 'Вищий навчальний заклад',
//         type: 'text',
//         answer: ''
//     },
// ]
//
// export const VNZQuestions = [
//     {
//         name: 'Вищий навчальний заклад',
//         type: 'text',
//         answer: ''
//     }
// ]
export const NameForm = 'Форма знайомства'

export const CategoryEducationalInstitution = [{
    name: 'Заклад вищої освіти',
    value: '1'
}, {
    name: 'Заклад професійної (професійно-технічної) освіти',
    value: '2'
}, {
    name: 'Заклад фахової передвищої освіти',
    value: '9'
}, {
    name: 'Наукові інститути (установи)',
    value: '8'
}, {
    name: 'Заклад післядипломної освіти',
    value: '10'
}]
export const EducationLevel = ['неповна середня','середня','вища','неповна вища']

export const EQuestionEducationLevel = 'Освітній рівень'
export const EQuestionSpecialty = 'Спеціальність'
export const EQuestionCategoryVNZ = 'Категорія закладу освіти'
export const EQuestionReginVNZ = 'Регіон'
export const EQuestionVNZ = 'Заклад'
export const EQuestionYearsOfStudy = 'Роки Навчання'

export const RegionCode = [{
    name: 'Автономна республіка Крим',
    value: '1'
}, {
    name: 'Вінницька область',
    value: '05'
}, {
    name: 'Волинська область',
    value: '07'
}, {
    name: 'Дніпропетровська область',
    value: '12'
}, {
    name: 'Донецька область',
    value: '14'
}, {
    name: 'Житомирська область',
    value: '18'
}, {
    name: 'Закарпатська область',
    value: '21'
}, {
    name: 'Запорізька область',
    value: '23'
}, {
    name: 'Івано-Франківська область',
    value: '26'
}, {
    name: 'Київська область',
    value: '32'
}, {
    name: 'Кіровоградська область',
    value: '35'
}, {
    name: 'Луганська область',
    value: '44'
}, {
    name: 'Львівська область',
    value: '46'
}, {
    name: 'Миколаївська область',
    value: '48'
}, {
    name: 'Одеська область',
    value: '51'
}, {
    name: 'Полтавська область',
    value: '53'
}, {
    name: 'Рівненська область',
    value: '56'
}, {
    name: 'Сумська область',
    value: '59'
}, {
    name: 'Тернопільська область',
    value: '61'
}, {
    name: 'Харківська область',
    value: '63'
}, {
    name: 'Херсонська область',
    value: '65'
}, {
    name: 'Хмельницька область',
    value: '68'
}, {
    name: 'Черкаська область',
    value: '71'
}, {
    name: 'Чернівецька область',
    value: '73'
}, {
    name: 'Чернігівська область',
    value: '74'
}, {
    name: 'КИЇВ',
    value: '80'
}, {
    name: 'м.Севастополь',
    value: '85'
}]

export const AddButton = '+'
export const BtnSubmit = 'Надіслати'