import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const BlockInfo = styled.div`
  display: flex;
  flex-direction: column;
`
export const MainBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: white;
`
export const FilterBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  border: 1px solid rgba(82, 97, 107, 1);
  background-color: rgba(25, 24, 24, 1);
  border-radius: 5px;
  margin: 5px 5px 0 5px;
  padding: 0;
`
export const TableBlock = styled.div`
  align-items: center;
  height: 81vh;
  overflow: scroll;
  position: relative;
  width: 99%;
  
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
export const BlockTableWithHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 94.3vw;
    & span {
        font-weight: 100;
        font-family: Verdana, sans-serif;
        font-size: 0.8em;
        color: rgba(82, 97, 107, 1);
        margin: 10px 0 3px 0;
    }
`
export const BlockTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 99%;
    border-radius: 5px;
    border: 1px solid rgba(82, 97, 107, 1);
`;
export const RowContainer = styled.div<{ unread?: boolean, active: boolean }>`
    &:first-of-type > div {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:last-of-type > div {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &:nth-of-type(even) > div:first-child {
        background-color: ${props => props.active ? 'rgba(227, 197, 101, 0.15)' : props.unread ? 'rgba(25, 24, 24, 1)' : 'rgba(25, 24, 24, 1)'};
        color: ${props => props.active ? '#DBE8F1' : props.unread ? 'rgba(150, 172, 187, 1)' : 'rgba(82, 97, 107, 1)'};

        &:hover {
            color: #DBE8F1;
            background-color: rgba(227, 197, 101, 0.15);

            & > div:nth-child(3) {
                color: rgba(51, 131, 44, 1);
            }

            & > div:nth-child(4) {
                color: rgba(222, 108, 108, 1);
            }
        }
`;

export const RowBlock = styled.div<{ unread?: boolean, active: boolean }>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background-color: ${props => props.active ? 'rgba(227, 197, 101, 0.15)' : props.unread ? 'rgba(30, 31, 35, 1)' : 'rgba(30, 31, 35, 1)'};
    color: ${props => props.active ? '#DBE8F1' : props.unread ? 'rgba(150, 172, 187, 1)' : 'rgba(82, 97, 107, 1)'};

    &:nth-of-type(odd) {
        background-color: ${props => props.active ? 'rgba(227, 197, 101, 0.15)' : props.unread ? 'rgba(30, 31, 35, 1)' : 'rgba(30, 31, 35, 1)'};
    }

    &:nth-of-type(even) {
        background-color: ${props => props.active ? 'rgba(227, 197, 101, 0.15)' : props.unread ? 'rgba(25, 24, 24, 1)' : 'rgba(25, 24, 24, 1)'};
    }

    & > div {
        display: flex;
        border-right: 1px solid rgba(227, 197, 101, 0.3);
        font-family: Verdana, sans-serif;
        font-weight: ${props => props.unread ? '700' : '100'};

        &:first-child {
            width: 13.3vw;
            padding: 0 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & svg {
                fill: #DBE8F1;
                width: 17px;
                height: 17px;
                stroke: #DBE8F1;
            }
        }

        &:nth-child(2) {
            margin-left: 2px;
            text-align: left;
            width: 15.3vw;
        }

        &:nth-child(3) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12.2vw;
            color: ${props => props.active ? 'rgba(51, 131, 44, 1)' : props.unread ? 'rgba(51, 131, 44, 1)' : 'rgba(33, 57, 31, 1)'};
        }

        &:nth-child(4) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12.4vw;
            color: ${props => props.active ? 'rgba(222, 108, 108, 1)' : props.unread ? 'rgba(222, 108, 108, 1)' : 'rgba(74, 44, 44, 1)'};

            & span {
                display: none;
                position: absolute;
                background-color: #1e1f23;
                z-index: 2;
            }

            &:hover {
                cursor: pointer;

                & span {
                    display: block;
                    padding: 3px;
                    border: 1px solid #ffffff;
                    color: #DBE8F1;
                }
            }
        }

        &:nth-last-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 25vw;
            border-right: 1px solid rgba(227, 197, 101, 0.3);
            padding-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            & span {
                display: none;
                position: absolute;
                background-color: #1e1f23;
                z-index: 2;
                color: #DBE8F1;
            }

            &:hover {
                cursor: pointer;

                & span {
                    display: block;
                    padding: 3px;
                    position: absolute;
                    border: 1px solid #C9D6DF;
                }
            }
        }

        &:last-child {
            justify-content: space-between;
            border-right: none;
            width: 13.5vw;
            margin-left: 3px;
        }
    }

    &:hover {
        color: #DBE8F1;
        background-color: rgba(227, 197, 101, 0.15);

        & > div:nth-child(3) {
            color: rgba(51, 131, 44, 1);
        }

        & > div:nth-child(4) {
            color: rgba(222, 108, 108, 1);
        }
    }
`;
export const DetailsBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 45vh;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 25px 15px 25px;
  background-color: rgba(227, 197, 101, 0.1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px; /* Враховуйте товщину лівої границі */
    right: -5px; /* Враховуйте товщину правої границі */
    z-index: -1;
    //background: #E3C565; /* Колір границі */
    border-left: 8px solid rgba(128, 114, 68, 1);
    border-right: 8px solid rgba(128, 114, 68, 1);
    filter: blur(5px); /* Розмиття */
    
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
export const MapContainer = styled.div`
  width: 26%;
  height: 85%;
  margin-left: 6.5%;
  border-radius: 10px;

  &::before {
    width: 25%;
    height: 81%;
    content: '';
    position: absolute;
    top: 5%;
    bottom: 0;
    left: 70%; /* Враховуйте товщину лівої границі */
    right: -5px; /* Враховуйте товщину правої границі */
    z-index: -1;
    //background: #E3C565; /* Колір границі */
    border: 8px solid rgba(128, 114, 68, 1);
    filter: blur(5px); /* Розмиття */
  }
`
export const DescriptionEvent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 65%;
  height: 95%;

  & h1 {
    font-size: 1.1em;
    font-family: Verdana, sans-serif;
    font-weight: 600;
    color: rgba(227, 197, 101, 0.7);
  }
`
export const BlockPlanTable = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 95%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`
export const Detail = styled.div<{ pos: boolean, bottom?: boolean, marginBottom: string }>`
  display: flex;
  flex-direction: column;
  width: ${props => props.pos ? "80vw" : "90%"};
  height: ${props => props.pos ? "90%" : "33%"};
  border-bottom: ${props => props.bottom ? '1px solid rgba(87, 78, 50, 1)' : 'none'};
  margin-bottom: ${props => props.marginBottom};
  overflow: scroll;
  cursor: pointer;
  z-index: ${props => props.pos ? "2" : "0"};
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  & span {
    font-family: Verdana, sans-serif;
    margin-top: -3px;
    font-size: 1em;
    color: rgba(82, 97, 107, 1);
 
  }

  & :hover {
    color: rgba(167, 192, 209, 0.7);
      & strong {
          color: rgba(82, 97, 107, 1);
      }
  }
`
export const BlockButtonSettings = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 92%;
`
export const ButtonClose = styled.div<{ favourite: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 5px;

  & svg {
    width: ${props => props.favourite ? '20px' : '20px'};
    height: ${props => props.favourite ? '20px' : '20px'};
    fill: ${props => props.favourite ? '#E3C565' : ''};
    stroke: ${props => props.favourite ? '' : '#E3C565'};
  }
`

export const NameChosenUser = styled.div`
  display: flex;
  width: 100%;
  height: 1vw;
  padding: 0.6vw;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.2em;
  font-family: Verdana, sans-serif;
`
export const EventInfoBlockStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  height: 25%;
`
export const InfoAboutEvent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;`
export const BlockLefForNextColumnWithMapStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`
export const BlockMapStyled = styled.div`
  width: 30%;
  height: 90%;
`
export const BlockLeftWithMapStyled = styled.div`
  display: flex;
  flex-direction: row;
`
export const NameLineStyled = styled.div`
  text-align: left;
  margin: 1vw 10px 2px 1px;
  font-size: 12px;
  background-color: #1e1f23;
  color: #ffffff;
  width: 4vw;

  & span {
    font-family: Verdana, sans-serif;
    color: #52616b;
  }

  @media (min-width: 1920px) {
    font-size: 0.6em;
  }
`
export const AnswerForNameLineStyled = styled.div`
  display: flex;
  margin: 1vw 5px 2px 10px;
  color: #ffffff;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  background-color: #1e1f23;
  width: 7vw;
  @media (min-width: 1920px) {
    width: 300px;
    font-size: 0.6em;
  }
`
export const BlockPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  font-family: Verdana,sans-serif;
  font-size: 12px;
  position: relative;
  & div {
    margin: 0 2px;
  }
`
export const BlockNameDay = styled.div`
  position: absolute;
  font-family: Verdana,sans-serif;
  font-size: 14px;
  color: #52616B;
  margin-top: 10px;
`
export const BtnDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  border: 1px solid #52616B;
  border-radius: 3px;
  font-size: 10px;
  cursor: pointer;
`
export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  input {
    width: 155px;
    font-size: 14px;
    background: #ffffff22;
    color: #fff;
    padding: 0 4px;
    background: rgba(255, 255, 255, 0.11);
    border: 0.3px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }

  .react-datepicker {
    background: #3c434c;
  }

  .react-datepicker__day,
  .react-datepicker__current-month,
  .react-datepicker-time__input,
  .react-datepicker__month-container,
  .react-datepicker__input-time-container,
  .react-datepicker-time__caption,
  .react-datepicker__time-list-item,
  .react-datepicker__time-list {
    background: #3c434c;
    color: #fff;
    border: none;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
  .react-datepicker__week-number--selected,
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  
  .react-datepicker__close-icon::after {
    background: rgb(41, 48, 56) !important;
    color: rgb(240, 185, 11) !important;
  }

  .react-datepicker__day--outside-month {
    color: #ffffff40;
  }

  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker-time__input,
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__header {
    background: rgb(41, 48, 56) !important;
    color: rgb(240, 185, 11) !important;
  }

  .react-datepicker__day-name {
    background: rgb(41, 48, 56) !important;
    color: #fff !important;
  }

  .react-datepicker__time-list-item:hover {
    background: rgb(240, 185, 11) !important;
    color: #000;
  }
`