import { BlockListOption, Container, InputContainer, QuestionBlock, StyledInputWithDropList } from "./Questions.styled";
import React, { FC, useState, useEffect, useRef } from "react";

interface Props {
    name: string;
    list: string[];
    userAbilityWriteMsg: boolean;
    require: boolean;
    setAnswers: React.Dispatch<React.SetStateAction<any>>;
    field: string;
    fieldsForUpdate: string;
    indexField: number;
}

export const QuestionTextWithDropDownList: FC<Props> = ({ name, list, userAbilityWriteMsg, require, setAnswers, field, fieldsForUpdate, indexField }: Props) => {
    const [openList, setOpenList] = useState(false);
    const [text, setText] = useState<string>('');
    const ref = useRef<HTMLDivElement>(null);

    const handleAddAnswer = (answer?: string) => {
        setAnswers((prevItems: any[]) => {
            const itemsWithField = prevItems.filter((item: { field: string }) => item.field === field);
            if (itemsWithField[indexField]) {
                const updatedItems = [...prevItems];
                const globalIndex = prevItems.indexOf(itemsWithField[indexField]);
                updatedItems[globalIndex] = { ...updatedItems[globalIndex], [fieldsForUpdate ? fieldsForUpdate : 'answer']: answer ? answer : text };

                return updatedItems;
            } else {
                return [...prevItems, { field, [fieldsForUpdate ? fieldsForUpdate : 'answer']: answer ? answer : text }];
            }
        });
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setOpenList(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Container ref={ref}>
            <QuestionBlock>{name}</QuestionBlock>
            <InputContainer>
                <StyledInputWithDropList
                    type='text'
                    onFocus={() => setOpenList(true)}
                    value={text}
                    onBlur={() => handleAddAnswer()}
                    onChange={(e) => {
                        if (userAbilityWriteMsg) {
                            setText(e.target.value);
                        }
                    }}
                    required={require}
                />
                {openList && (
                    <BlockListOption show={openList}>
                        {list.filter(elem => elem.toLowerCase().includes(text.toLowerCase())).map((elem, index) => (
                            <div key={index} onClick={() => {
                                setText(elem);
                                setOpenList(false);
                                handleAddAnswer(elem);
                            }}>{elem}</div>
                        ))}
                    </BlockListOption>
                )}
            </InputContainer>
        </Container>
    );
};