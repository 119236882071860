import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';
import Duck from "../../../assets/pictures/Duck.svg";

type Props = {
    id: string
    coordinates: any
}

export const MarkersMap = ({ id, coordinates }: Props) => {
    const mapRef = useRef(null);

    useEffect(() => {
        if (!mapRef.current) {
            // Ініціалізація карти, якщо вона ще не існує
            const map = L.map(id).setView([coordinates[0].latitude, coordinates[0].longitude], 17);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 20,
            }).addTo(map);

            // @ts-ignore
            mapRef.current = map;
        }

        // Додаємо маркери з власним зображенням та підписом
        // @ts-ignore
        coordinates.forEach(({ latitude, longitude, userTelegramName }) => {
            const icon = L.divIcon({
                className: 'custom-div-icon',
                html: `<div class="marker-content" style='display: flex;flex-direction:column;justify-content: center;align-items: center'>${userTelegramName}<br><img src=${Duck} style='width:25px;height:25px;'/></div>`,
                iconSize: [20, 40],
                iconAnchor: [10, 40],
                popupAnchor: [0, -40],
            });

            L.marker([latitude, longitude], { icon })
                // @ts-ignore
                .addTo(mapRef.current);
        });

        // Очищення попередніх маркерів при зміні координат
        return () => {
            // @ts-ignore
            mapRef.current.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    // @ts-ignore
                    mapRef.current.removeLayer(layer);
                }
            });

        };
    }, [id, coordinates]);

    return <MapWrapper id={id} />;
};

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
    z-index: 1;
`;

// Стилі для контенту маркера
const MarkerContent = styled.div`
  text-align: center;
  font-size: 12px;
`;
