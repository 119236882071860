import {
    BlockTableWithHeader,
    BlockTable,
    RowBlock, RowContainer,
} from "./Journal.styled";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    useLazyAddFavouriteQuery,
    useLazyRemoveFavouritesQuery
} from "../../store/api/journalApi";
import {ReactComponent as Heart} from "../../assets/pictures/Heart.svg";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RowForDuration} from "./DurationRow";
import {DetailsForEvent} from "./DetailsForEvent";
import {useAutoAnimate} from "@formkit/auto-animate/react";

interface IOpenBlock {
    event: boolean,
    thought: boolean,
    result: boolean
}

type Props = {
    header: string
    event: any
    readEvent: any
    selectedRow: any
    setSelectedRow: React.Dispatch<React.SetStateAction<any>>
    adminsEmployeeId: {employeeId: string,_id:string, favorites: string[]}
    description: string[]
    setDescription: Dispatch<SetStateAction<string[]>>;
    favourite: boolean
    indexBlock?: React.MutableRefObject<null>
    anchorEvent:string[],
    setAnchorEvent:React.Dispatch<React.SetStateAction<string[]>>,
    openBlock:IOpenBlock,
    setOpenBlock: React.Dispatch<React.SetStateAction<IOpenBlock>>
}
type item = {
    _id: string
    name: string
    stage: string
    unread: string[],
    event: string,
    eventStartTime: Date,
    eventEndTime: Date
    emotionalLevel: string
    doing: string
    thoughts: string[]
    result: string
    latitude: number
    longitude: number
    location: string
    eventDuration: number
    category: string
}
type ClickHandler = (id: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
export const Table = ({
                          header,
                          event,
                          readEvent,
                          selectedRow,
                          setSelectedRow,
                          adminsEmployeeId,
                          description,
                          setDescription,
                          favourite,
                          anchorEvent,
                          setAnchorEvent,
                          openBlock,
                          setOpenBlock
                      }: Props) => {
    const [fetchRepos] = useLazyAddFavouriteQuery()
    const [fetchRemove] = useLazyRemoveFavouritesQuery()
    const [block] = useAutoAnimate()

    const [animate,setAnimate] = useState<boolean>(false)
    const notifySuccess = () => toast.success(favourite ? 'Подія видалена' : 'Подія збережена', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const notifyError = () => toast.error('Сталась помилка', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const toggleDescription = (eventIndex: string): void => {

        selectedRow === eventIndex ? setSelectedRow('') : setSelectedRow(eventIndex);setAnimate(true)
        setDescription((prevBlocks: any) => {
            // Копіюємо попередній стан та додаємо/видаляємо індекс натиснутого блоку
            return prevBlocks.includes(eventIndex)
                ? [] : !(prevBlocks.includes(eventIndex)) ? [eventIndex] : []
        });
        !favourite && readEvent(eventIndex)
    };
    const anchorOpenDescription = (eventIndex: string): void => {
        selectedRow === eventIndex ? setSelectedRow('') : setSelectedRow(eventIndex)
        setAnchorEvent((prevBlocks: any) => {
            // Копіюємо попередній стан та додаємо/видаляємо індекс натиснутого блоку
            return prevBlocks.includes(eventIndex)
                ? prevBlocks.filter((index: any) => index !== eventIndex)
                : [...prevBlocks, eventIndex];
        });
        anchorEvent.includes(eventIndex) && setSelectedRow('');
        setDescription((prevBlocks: any) => {
            // Копіюємо попередній стан та додаємо/видаляємо індекс натиснутого блоку
            return prevBlocks.filter((index: any) => index !== eventIndex)
        });

    }
    const closeDescription = (eventIndex: any) => {
        setDescription((prevBlocks: any) => prevBlocks.filter((index: any) => index !== eventIndex))
        setSelectedRow(0)
    }

    const handleAddFavorite: ClickHandler = (id: string) => {
        fetchRepos({employeeId: adminsEmployeeId?.employeeId, eventId: id}).then((r) => {
                if (r.isSuccess) {
                    notifySuccess()
                } else if (!r.isSuccess) {
                    notifyError()
                }
            }
        )
    }
    const handleRemoveFavorite = (id: string) => {
        fetchRemove({employeeId: adminsEmployeeId?.employeeId, eventId: id}).then((r) => {
                if (r.isSuccess) {
                    notifySuccess()
                    setDescription([])
                    window.location.reload();
                } else if (!r.isSuccess) {
                    notifyError()
                }
            }
        )
    }
    useEffect(() => {
        setOpenBlock({...openBlock, event: false, result: false, thought: false})
    }, [description]);


    return (
        <BlockTableWithHeader>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <span>{header + ` (${event.events?.length})`}</span>
            <BlockTable>
                {event.events.map((item: any) => (
                    <RowContainer key={item._id} ref={block} unread={Array.isArray(item.unread) ? item.unread.includes(adminsEmployeeId._id) : false} active={item._id === selectedRow}>
                        <RowBlock unread={Array.isArray(item.unread) ? item.unread.includes(adminsEmployeeId._id) : false} active={item._id === selectedRow}
                                  onClick={() => toggleDescription(item._id)}>
                            <div key={Math.random()}>
                                {item.name}
                                {adminsEmployeeId.favorites.includes(item._id) && <Heart />}
                            </div>
                            <div key={Math.random()}>
                                {item.category === 'Думки 💭' ? 'Думки 💭' : item.category === 'План 📋' ? 'План 📋' : item.thoughts.length > 0 ? item.event + ` 💭` + ` (${item.thoughts.length})` : item.event}
                            </div>
                            <div key={Math.random()}>
                                {new Date(item.eventStartTime).toLocaleTimeString()}
                            </div>
                            <div key={Math.random()}>
                                {item.eventEndTime ? new Date(item.eventEndTime).toLocaleTimeString() : '-'}
                                {item.eventEndTime && <RowForDuration key={Math.random()} duration={item.eventDuration} />}
                            </div>
                            <div key={Math.random()}>
                                {item.location === '-' ? '-' : <>{item?.location}<span>{item?.location}</span></>}
                            </div>
                            <div key={Math.random()}>
                                {item.emotionalLevel?.replace(/\s\d+\s-\s/, ' ')}
                            </div>
                        </RowBlock>
                        {(anchorEvent.includes(item._id) || description.includes(item._id)) && (
                            <DetailsForEvent
                                animate={animate}
                                favourite={favourite}
                                anchorOpenDescription={anchorOpenDescription}
                                addFavourite={favourite ? handleRemoveFavorite : handleAddFavorite}
                                event={item}
                                openBlock={openBlock}
                                setOpenBlock={setOpenBlock}
                                onclick={closeDescription}
                            />
                        )}
                    </RowContainer>
                ))}
            </BlockTable>
        </BlockTableWithHeader>
    );
}
