import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`
export const BlockInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const SpinnerBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100%;
`
export const MainBlockStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`
export const FilterBlockStyled = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin: 15px 0;
`
export const SaveButtonStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & span {
        color: #C9D6DF;
        font-size: 14px;
    }

    & div {
        padding: 5px 5px 0 5px;
        border: 1px solid #52616B;
        cursor: pointer;
        border-radius: 5px;

        & svg {
            width: 20px;
            height: 20px;
            fill: #52616B;
        }
    }
`
export const DataAndChartBlockStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 95vw;
    height: 40vh;
    margin: 5px 0;
`
export const LineChartWithHeader = styled.div<{ height: string, width: string, marginheader: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 10px;
    border: 1px solid #52616B;
    position: relative;

    & h1 {
        font-family: Verdana, sans-serif;
        font-weight: 300;
        font-size: 1em;
        color: #C9D6DF;
        margin-top: ${props => props.marginheader}px;
    }

    & span {
        color: rgba(30, 32, 34, 1);
    }
`
export const ContainerIntervalMarkGraph = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 99%;
    position: absolute;
    z-index: 2;
`
export const BlockIntervalMarkGraph = styled.div`
    display: flex;
    font-family: Verdana, sans-serif;
    color: rgba(219, 232, 241, 1);
    padding: 1px;
`
export const IntervalMarkGraphBlock = styled.div<{ active: boolean }>`
    display: flex;
    padding: 2px 3px;
    border-radius: 5px;
    background-color: ${props => props.active ? 'rgba(82, 97, 107, 0.5)' : 'transparent'};
    margin: 1px;
    align-items: center;
    cursor: pointer;

    &:hover {
        scale: 1.1;
        background-color: rgba(82, 97, 107, 0.7);
    }
`

export const BarChartWithHeader = styled.div<{ height: string, width: string, marginheader: number }>`
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 10px;
    align-items: center;
    position: relative;

    & h1 {
        font-family: Verdana, sans-serif;
        font-weight: 300;
        font-size: 1em;
        color: #C9D6DF;
        margin-bottom: ${props => props.marginheader}px;
    }

    & span {
        color: rgba(30, 32, 34, 1);
    }
`
export const BarChartBlock = styled.div<{ height: string, width: string, marginheader: number }>`
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 10px;
    border: 1px solid #52616B;

    & span {
        color: rgba(30, 32, 34, 1);
    }
`
export const AreaBumpBlock = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`
export const DoubleBarChart = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-right: 35px;
`
export const BlockBarChart = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
    position: relative;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
export const LineChartWithHeaderStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    //margin: 0;
    padding: 5px;
    color: #C9D6DF;
    font-size: 16px;
    font-family: Verdana, sans-serif;
`
export const PieChartsBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 48%;
    height: 40vh;
`

export const PieChartWithHeaderBlockStyled = styled.div<{ sizeWidth: number, marginhorizontal: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${props => props.sizeWidth + '%'};
    height: 95%;
    margin: 0 ${props => props.marginhorizontal + 'px'};
    color: #C9D6DF;
    font-size: 16px;
    font-family: Verdana, sans-serif;

    & span {
        color: rgba(30, 32, 34, 1);
    }

    & h1 {
        font-family: Verdana, sans-serif;
        font-weight: 300;
        font-size: 1em;
        color: #C9D6DF;
    }
`
export const BlockPieChart = styled.div<{ border: boolean }>`
    width: 100%;
    height: 100%;
    border: ${props => props.border ? '1px solid #52616B' : 'none'};
    border-radius: 10px;
`
export const LegendBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-size: 10px;
    color: #C9D6DF;
    font-family: Verdana, sans-serif;
`


export const LegendContainer = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    font-size: 1.2em;
`;

export const LegendNameBlock = styled.div`
    display: flex;
    padding: 8px;
    cursor: pointer;
`;

export const ColorBlock = styled.div<{ color: string }>`
    width: 20px;
    height: 20px;
    background-color: ${({ color }) => color};
    margin-right: 8px;
    border-radius: 5px;
`;

export const Tooltip = styled.div`
    position: absolute;
    background: rgba(30, 32, 34, 1);
    border: 1px solid #52616B;
    border-radius: 5px;
    top:-70%;
    left: -100%;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
`;
export const BlockNameWithColor = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px 0;
`