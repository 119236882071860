import {
    ProgressBlockStyled,
    TagBlockStyled,
    EvaluationBlockStyled,
    BlockValueStyled,
    BlockRowStyled,
    ProgressStyled,
    PaginationBlock,
    ShortDescriptionTest,
    HeaderWithInfoTest, BtnResultBlock,
} from "./Skills.styled";
import React, {useState} from "react";
import {IEmployeeCard, IProfileInformation} from "../intefrace";
import {
    PaginationArrow,
    PaginationItem,
    PaginationList,
    PaginationNav
} from "../pagination/Pagination.styled";
import {ReactComponent as Info} from "../../../assets/pictures/InfoIcon.svg";
import {PopUp} from "../../../pop-up/Pop-Up";
import {useGetUserTestsQuery} from "../../../store/api/cardsApi";

type Props = {
    infoEmployee: IProfileInformation,
    name: string
    skills: string
}
export const Skills = ({infoEmployee, name, skills}: Props) => {
    const [testName, setTestName] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [currentChildrenPage, setCurrentChildrenPage] = useState<number>(0)
    const [activePopup, setActivePopup] = useState<boolean>(false)
    const [activePopupResultTests, setActivePopupResultTests] = useState<boolean>(false)
    const {data: result} = useGetUserTestsQuery({
        employeeId: infoEmployee?._id,
        name: testName,
        indexAnswer: currentChildrenPage,
        indexTest: currentPage,
        skills: skills
    }, {refetchOnMountOrArgChange: true});
    const [childrenPagination, setChildrenPagination] = useState<boolean>(false)


    function prePage(nameTest?: string): void {
        if (nameTest) {
            if (currentChildrenPage !== 0) {
                setCurrentChildrenPage(currentChildrenPage - 1)
                setTestName(nameTest)
                setChildrenPagination(true)
            }
        } else {
            if (currentPage !== 0) {
                setCurrentPage(currentPage - 1)
                setChildrenPagination(false)
                setTestName('')
                setCurrentChildrenPage(0)
            }
        }

    }

    function nextPage(nameTest?: string): void {
        if (nameTest) {
            if (currentChildrenPage < result.countAnswers - 1) {
                setCurrentChildrenPage(currentChildrenPage + 1)
                setTestName(nameTest)
                setChildrenPagination(true)
            } else {
                setCurrentChildrenPage(0)
                setTestName(nameTest)
                setChildrenPagination(true)
            }
        } else {
            if (currentPage < result.countTests - 1) {
                setCurrentPage(currentPage + 1)
                setChildrenPagination(false)
                setTestName('')
                setCurrentChildrenPage(0)
            } else {
                setCurrentPage(0)
                setChildrenPagination(false)
                setTestName('')
                setCurrentChildrenPage(0)
            }
        }
    }

    function changePage(id: number, nameTest?: string): void {
        if (nameTest) {
            setCurrentChildrenPage(id)
            setTestName(nameTest)
            setChildrenPagination(true)
        } else {
            setCurrentPage(id)
            setChildrenPagination(false)
            setTestName('')
            setCurrentChildrenPage(0)
        }
    }

    return (<>
            {activePopup && <PopUp active={activePopup} setActive={setActivePopup} testing={false}>
                {childrenPagination ? result?.answers?.testDescription : result?.tests?.testDescription}
            </PopUp>}
            {activePopupResultTests &&
                <PopUp active={activePopupResultTests} setActive={setActivePopupResultTests} testing={false}>
                    {result && Array.isArray(childrenPagination ? result?.answers?.testEvaluation : result?.tests?.testEvaluation) ? (childrenPagination ? result?.answers.testEvaluation : result?.tests?.testEvaluation).map((item: string, index: number) => (
                        <React.Fragment key={index}>
                            {item}
                            <br/>
                        </React.Fragment>
                    )) : <>{childrenPagination ? result?.answers?.testEvaluation : result?.tests?.testEvaluation}</>}
                </PopUp>}
            <HeaderWithInfoTest>
                <div>
                    <Info/>
                    <ShortDescriptionTest
                        onClick={() => setActivePopup(true)}>{result?.tests?.shortTestDescription}</ShortDescriptionTest>
                </div>
                <span>{name}</span>
                <div>
                    <BtnResultBlock onClick={() => setActivePopupResultTests(true)}>
                        результати
                    </BtnResultBlock>
                </div>
            </HeaderWithInfoTest>

            <PaginationNav>
                <PaginationList>
                    {result && <PaginationArrow>
                        <a href='#' onClick={() => {
                            prePage()
                        }}>❮</a>
                    </PaginationArrow>}
                    {result && Array.from(Array(result.countTests).keys()).map((n: number, i: number) => (
                        <PaginationItem active={currentPage === n} key={i}>
                            <a href='#' className='page-link' onClick={() => changePage(n)}>{n + 1}</a>
                        </PaginationItem>
                    ))}
                    {result && <PaginationArrow>
                        <a href='#' onClick={() => {
                            nextPage()
                        }}>❯</a>
                    </PaginationArrow>}
                </PaginationList>
            </PaginationNav>
            <PaginationBlock>
                <div>
                    <div>{result?.answers?.name}</div>
                    <PaginationNav>
                        <PaginationList>
                            {result && result?.countAnswers > 1 && <PaginationArrow>
                                <a href='#' onClick={() => {
                                    prePage(result.tests.name)
                                }}>❮</a>
                            </PaginationArrow>}
                            {result?.countAnswers > 1 && Array.from(Array(result.countAnswers).keys()).map((n: number, i: number) => (
                                <PaginationItem active={currentChildrenPage === n} key={i}>
                                    <a href='#' className='page-link'
                                       onClick={() => changePage(n, result.tests.name)}>{n + 1}</a>
                                </PaginationItem>
                            ))}
                            {result && result?.countAnswers > 1 && <PaginationArrow>
                                <a href='#' onClick={() => {
                                    nextPage(result.tests.name)
                                }}>❯</a>
                            </PaginationArrow>}
                        </PaginationList>
                    </PaginationNav>
                </div>
                <div>{result?.answers?.passageTime && new Date(result?.answers?.passageTime).toLocaleDateString()} {result?.answers?.passageTime && new Date(result?.answers?.passageTime).toLocaleTimeString()}
                </div>
            </PaginationBlock>
            <TagBlockStyled>
                <EvaluationBlockStyled>
                    {(result?.answers?.result || result?.tests?.result) && Object.entries(childrenPagination ? result?.answers?.result : result?.tests?.result)?.map((elem: any, index: number) => (
                        <BlockRowStyled key={index}>
                            <span>{elem[0]}</span>
                            <div>
                                <ProgressBlockStyled>
                                    <ProgressStyled color={'rgba(219, 232, 241, 1)'}
                                                    width={'100%'}></ProgressStyled>
                                    <ProgressStyled color={'#E3C565'} width={'60%'}>
                                        <div>{elem[1]}</div>
                                    </ProgressStyled>
                                    <BlockValueStyled>
                                        <div>{result?.answers?.keys?.find((item: {
                                            name: string;
                                        }) => item.name === elem[0])?.range[0]}</div>
                                        <div>{result?.answers?.keys?.find((item: {
                                            name: string;
                                        }) => item.name === elem[0])?.range[1]}</div>
                                    </BlockValueStyled>
                                </ProgressBlockStyled>
                            </div>

                        </BlockRowStyled>
                    ))
                    }
                </EvaluationBlockStyled>
            </TagBlockStyled>
        </>
    )
}

// const arr = [
//     {name: 'First Test', answer: 'FirstAnswer'},
//     {name: 'First Test', answer: 'SecondAnswer'},
//     {name: 'First Test', answer: 'ThirdAnswer'},
//     {name: 'Second Test', answer: 'FourthAnswer'},
//     {name: 'Second Test', answer: 'FiveAnswer'},
//     {name: 'Second Test', answer: 'NineAnswer'},
//     {name: 'Third Test', answer: 'TenAnswer'},
//     {name: 'Third Test', answer: 'SecondAnswer'},
//     {name: 'Third Test', answer: 'ThirdAnswer'},
// ]



