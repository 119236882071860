import styled from "styled-components";
import {Link} from "react-router-dom";

export const MenuBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 4vw;
  height: 100vh;
  border-right: 1px solid #52616B;
`

export const IconStyled = styled(Link)<{ activestroke?: string,activefill?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7vh;
  cursor: pointer;

  & svg {
    width: 30px;
    height: 30px;
    stroke: ${props => props.activestroke};
    fill: ${props => props.activefill};
  }
`