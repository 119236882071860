import styled from "styled-components";

export const ButtonStyled = styled.div<{marginTop?: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2vw;
  height: 3vh;
  margin: 5px 0;
  cursor: pointer;
  padding: 0 10px;
  & svg {
    margin-top: ${props=>props.marginTop};
    stroke: #C9D6DF;
    width:100%;
    height:100%;
    fill: #C9D6DF;
  }
`