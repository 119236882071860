import styled, { createGlobalStyle } from 'styled-components'
import px2vw from '../../utils/px2vw'
import Comfortaa_Regular from '../assets/font/Comfortaa_Regular.ttf'

export const Global = createGlobalStyle`
 @font-face {
    font-family: 'Comfortaa';
    src: url(${Comfortaa_Regular}) format('truetype');
  }
  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: rgba(17, 18, 22, 1);
    
    
  }
  :root { 
     font-family: 'Comfortaa',sans-serif;
    font-size: ${px2vw(24)};
    
      @media (min-width: 768px) {
        font-size: ${px2vw(18)};
      }

      @media (min-width: 1024px) {
        font-size: ${px2vw(16)};
      }
    }
`
export const AppStyled = styled.div`
  display: flex;
`
export const HeaderStyled = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`
export const MenuStyled = styled.div`
    flex: 0 0 auto
`
